import { makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) => ({
  teamMembersContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(2),
    alignSelf: 'stretch',
    width: theme.spacing(216 / 8),
    backgroundColor: theme.palette.text.white,
  },
  teamMembersHeading: {
    color: theme.palette.labraText.tertiary,
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '0.15px',
  },
  teamMembersType: {
    color: theme.palette.labraText.tertiary,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.25px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '16px',
    alignSelf: 'stretch',
  },
  teamMembersTypeContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: theme.spacing(1),
    alignSelf: 'stretch',
    height: theme.spacing(60 / 8), // this is required so the divider (hr) shows up
  },
  teamMembersTypeContainerItem: {
    padding: theme.spacing(0.5),
  },
  avatarGroup: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    height: theme.spacing(3),
    position: 'relative',
  },
  divider: {
    width: '1px',
    color: theme.palette.dividerV2.main,
    height: '100%',
  },
  avatar: {
    '&.MuiAvatar-root': {
      display: 'flex',
      width: theme.spacing(3),
      height: theme.spacing(3),
      justifyContent: 'center',
      alignItems: 'center',
      gap: theme.spacing(10 / 8),
      textAlign: 'center',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '16px',
      letterSpacing: '0.5px',
      border: '1px solid transparent',
      color: theme.palette.labraText.tertiary,
      borderRadius: '52px',
      '&:hover': {
        border: `1px solid ${theme.palette.labraText.tertiary}`,
      },
    },
  },
  admin: {
    '&.MuiAvatar-root': {
      background: `linear-gradient(0deg, ${theme.palette.avatarColors.blue} 0%, ${theme.palette.avatarColors.blue} 100%)`,
    },
  },
  user1: {
    '&.MuiAvatar-root': {
      background: `linear-gradient(0deg, ${theme.palette.avatarColors.green} 0%, ${theme.palette.avatarColors.green} 100%)`,
    },
  },
  user2: {
    '&.MuiAvatar-root': {
      background: `linear-gradient(0deg, ${theme.palette.avatarColors.orange} 0%, ${theme.palette.avatarColors.orange} 100%)`,
    },
  },
  user3: {
    '&.MuiAvatar-root': {
      background: `linear-gradient(0deg, ${theme.palette.avatarColors.purple} 0%, ${theme.palette.avatarColors.purple} 100%)`,
    },
  },
  avatarNumber: {
    '&.MuiAvatar-root': {
      fontSize: '14px',
      border: '1px solid transparent',
      borderRadius: '0px',
      backgroundColor: 'transparent',
      color: theme.palette.textField.light,
      '&:hover': {
        border: '1px solid transparent',
        color: theme.palette.labraText.tertiary,
      },
    },
  },
  infoIcon: {
    fontSize: '16px',
    color: theme.palette.labraIcon.main,
  },
  infoIconContainer: {
    textAlign: 'center',
    height: theme.spacing(2),
    width: theme.spacing(2),
  },
  teamMembersHeadingContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  orderList: {
    padding: theme.spacing(0, 2),
  }
}))
