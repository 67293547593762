import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import React from 'react'
import { timezones } from '../../../utils/timezones'
import { useStyles } from './UserProfile.styles'
import * as yup from 'yup'
import { useFormik } from 'formik'
import {
  UserProfile as UserProfileType,
  updateUserProfile,
} from '../../../modules/userProfile/action'
import { useDispatch, useSelector } from '../../../../store'
import { LoadingTypes } from '../../../modules/loading/reducer'
import { isLoading } from '../../../utils/loadingState'
import { Button, ButtonV2 } from '@labrav/react-components'
import { useFlagValue } from '@labrav/flags'
import { AuthProvider } from '../../../../oppsync/modules/userList/action'

const validationSchema = yup.object({
  firstName: yup
    .string()
    .required('First Name is required')
    .matches(
      /^[A-Za-z]+-*[A-Za-z]*$/,
      'First Name should not contain trailing spaces'
    ),

  lastName: yup
    .string()
    .required('Last Name is required')
    .matches(
      /^[A-Za-z]+-*[A-Za-z]*$/,
      'Last Name should not contain trailing spaces'
    ),
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
  mobilePhone: yup
    .string()
    .matches(
      /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
      'Phone Number is not valid'
    ),
  timeZone: yup.string().required('Timezone is required').nullable(),
  oldPassword: yup
    .string()
    .min(8, 'Password should be of minimum 8 characters length'),

  newPassword: yup
    .string()
    .min(8, 'Password should be of minimum 8 characters length')
    .matches(/[a-z]+/, 'Atleast one lowercase character')
    .matches(/[A-Z]+/, 'Atleast one uppercase character')
    .matches(
      /[@.$!%*?&#^]+/,
      'Atleast one special character (@, $, !, . , %, *, ?, &, #, ^)'
    )
    .matches(/\d+/, 'Atleast one number')
    .test(
      'check firstName',
      'Password should not contain first name',
      function (value) {
        return value
          ?.toLowerCase()
          ?.includes(this.parent.firstName?.toLowerCase())
          ? false
          : true
      }
    )
    .test(
      'check lastName',
      'Password should not contain last name',
      function (value) {
        return value
          ?.toLowerCase()
          ?.includes(this.parent.lastName?.toLowerCase())
          ? false
          : true
      }
    )
    .test(
      'check oldPassword',
      'New password cannot be same as old password',
      function (value) {
        return value && value === this.parent.oldPassword ? false : true
      }
    )
    .test(
      'check username',
      'Password should not contain username',
      function (value) {
        return value?.toLowerCase()?.includes(this.parent.email?.toLowerCase())
          ? false
          : true
      }
    ),
})

export const UserProfile: React.FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const loading = useSelector<boolean>(state =>
    isLoading(state.loading)(LoadingTypes.GENERAL)
  )
  const { loading: isFlagLoading, value: isAuth0Enabled } =
    useFlagValue('auth0')
  const partnerId = useSelector<string>(
    state => state.PartnerData.user.partnerData?.partnerId || ''
  )
  const userProfile = useSelector<UserProfileType>(
    state => state.userProfile.userProfile
  )

  const validateFields = () => {
    if (
      formik.values?.oldPassword === '' &&
      formik.values?.newPassword !== ''
    ) {
      return false
    } else if (
      formik.values.oldPassword !== '' &&
      formik.values.newPassword === ''
    ) {
      return false
    }
    return true
  }

  const formik = useFormik({
    initialValues: {
      firstName: userProfile.firstName || userProfile.givenName,
      lastName: userProfile.lastName || userProfile.familyName,
      email: userProfile.email,
      mobilePhone: userProfile.mobilePhone,
      timeZone: userProfile.timeZone,
      oldPassword: '',
      newPassword: '',
    },
    validationSchema: validationSchema,
    onSubmit: async values => {
      const payload = {
        ...values,
        mobilePhone: values?.mobilePhone?.toString(),
      }
      const userId = isAuth0Enabled ? userProfile.id : userProfile.idpUserId
      await dispatch(
        updateUserProfile(
          partnerId,
          userId || '',
          payload,
          isAuth0Enabled ? AuthProvider.AUTH0 : AuthProvider.OKTA
        )
      )
      formik.setFieldValue('oldPassword', '')
      formik.setFieldValue('newPassword', '')
    },
  })

  return (
    <div className={classes.root}>
      <span className={classes.title}>Profile detail</span>
      <form
        autoComplete="off"
        className={classes.form}
        onSubmit={formik.handleSubmit}
      >
        <div className={classes.row}>
          <div className={classes.col}>
            <TextField
              id="firstName"
              name="firstName"
              label="First name"
              value={formik.values.firstName}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              helperText={formik.touched.firstName && formik.errors.firstName}
              data-testid="firstName"
              fullWidth
            />
          </div>
          <div className={classes.col}>
            <TextField
              id="lastName"
              name="lastName"
              label="Last name"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
              data-testid="lastName"
              fullWidth
            />
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.col}>
            <TextField
              id="email"
              name="email"
              label="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              data-testid="email"
              disabled
              fullWidth
            />
          </div>
          {!isFlagLoading && !isAuth0Enabled && (
            <div className={classes.col}>
              <TextField
                id="mobilePhone"
                name="mobilePhone"
                label="Phone Number"
                value={formik.values.mobilePhone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.mobilePhone &&
                  Boolean(formik.errors.mobilePhone)
                }
                helperText={
                  formik.touched.mobilePhone && formik.errors.mobilePhone
                }
                type="number"
                data-testid="mobilePhone"
                fullWidth
              />
            </div>
          )}
        </div>
        <div className={classes.row}>
          <div className={classes.col}>
            <Autocomplete
              id="timeZone"
              options={timezones}
              getOptionLabel={option => option}
              style={{ width: '100%' }}
              value={formik.values.timeZone}
              onBlur={formik.handleBlur}
              onChange={(event, newValue: string | null) =>
                formik.setFieldValue('timeZone', newValue as string)
              }
              renderInput={params => (
                <TextField
                  {...params}
                  error={
                    formik.touched.timeZone && Boolean(formik.errors.timeZone)
                  }
                  helperText={formik.touched.timeZone && formik.errors.timeZone}
                  label="Time zone"
                  data-testid="timezone"
                  fullWidth
                />
              )}
            />
          </div>
        </div>
        {!isFlagLoading && !isAuth0Enabled && (
          <>
            <span className={classes.title}>Change Password</span>
            <div className={classes.row}>
              <div className={classes.col}>
                <TextField
                  id="oldPassword"
                  name="oldPassword"
                  label="Current Password"
                  value={formik.values.oldPassword}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.oldPassword &&
                    Boolean(formik.errors.oldPassword)
                  }
                  helperText={
                    formik.touched.oldPassword && formik.errors.oldPassword
                  }
                  type="password"
                  data-testid="oldPassword"
                  fullWidth
                />
              </div>
              <div className={classes.col}>
                <TextField
                  id="newPassword"
                  name="newPassword"
                  label="New Password"
                  value={formik.values.newPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.newPassword &&
                    Boolean(formik.errors.newPassword)
                  }
                  helperText={
                    formik.touched.newPassword && formik.errors.newPassword
                  }
                  type="password"
                  data-testid="newPassword"
                  fullWidth
                />
              </div>
            </div>
          </>
        )}
        <div className={classes.buttonGroup}>
          <ButtonV2
            disabled={loading || !formik.isValid || !validateFields()}
            styleType={'primary'}
            type="submit"
            data-testid="save-button"
          >
            Save
          </ButtonV2>
        </div>
      </form>
    </div>
  )
}
