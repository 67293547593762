import React, { useCallback } from 'react'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Drawer from '@material-ui/core/Drawer'
import { useStyles } from './SidePanel.styles'
import { Divider, Paper } from '@material-ui/core'
import { ProgressBar } from '../ProgressBar/ProgressBar'
import { HeaderAlertComponent } from '../HeaderAlert/HeaderAlert'

export type SidePanelVariant = 'primary' | 'secondary'
type Props = {
  children: React.ReactNode
  heading: string
  subHeading?: React.ReactNode
  open: boolean
  onClose: () => void
  anchor?: 'bottom' | 'left' | 'right' | 'top'
  width: number
  overFlowY: boolean
  showProgressBar?: boolean
  showAlert?: boolean
  actions?: React.FC
  variant?: SidePanelVariant
  showDivider?: boolean
}

export const SidePanel: React.FC<Props> = ({
  children,
  heading,
  subHeading,
  open,
  onClose,
  anchor,
  width,
  overFlowY,
  showProgressBar,
  showAlert,
  actions: Actions,
  variant,
  showDivider,
}) => {
  const classes = useStyles(width, overFlowY, variant || 'primary')()

  const handleCloseOnKeyPress = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose()
      }
    },
    [onClose]
  )
  return (
    <Drawer
      data-testid="sidePanel"
      open={open}
      className={classes.root}
      anchor={anchor}
      onClose={onClose || handleCloseOnKeyPress}
    >
      <div className={classes.container}>
        <Paper elevation={0} className={classes.header}>
          <div
            className={subHeading ? classes.titles : classes.titleWithoutSub}
          >
            <Typography className={classes.headingText}>{heading}</Typography>
            <span data-testid="actions" className={classes.actions}>
              {Actions ? <Actions /> : null}
            </span>

            <span className={classes.closeIcon}>
              <CloseIcon data-testid="closeDrawer" onClick={onClose} />
            </span>
          </div>
          {subHeading && (
            <div className={classes.subHeadingText} data-testid="faqheading">
              {subHeading}
            </div>
          )}
        </Paper>
        {showDivider ? <Divider className={classes.divider} /> : null}
        {showProgressBar ? <ProgressBar width={'100%'} /> : null}
        {showAlert ? <HeaderAlertComponent /> : null}
        {children}
      </div>
    </Drawer>
  )
}
