import { Card, CardActionArea, CardMedia, Typography } from '@material-ui/core'
import { useStyles } from './LabraCloudProvider.styles'
import azure from '../../../common/images/microsoftNew.svg'
import aws from '../../../common/images/aws.png'
import redhat from '../../../common/images/redhatimg.png'
import googleCloud from '../../../common/images/googlecloudimg.png'
import { CloudProvider } from '../../../common/modules/partner/action'
import { useCallback, useState } from 'react'
import { useFlag } from '@labrav/flags'
import { ButtonV2 } from '@labrav/react-components'

type CloudProviderLogoSquare = {
  name: string
  id: CloudProvider
  logo: string
  enabled: boolean
  height: string
  width: string
}

const cloudProviders: CloudProviderLogoSquare[] = [
  {
    name: 'AWS',
    id: CloudProvider.AWS,
    logo: aws,
    enabled: true,
    height: '54px',
    width: '90px',
  },
  {
    name: 'Azure',
    id: CloudProvider.AZ,
    logo: azure,
    enabled: false,
    height: '100px',
    width: '140px',
  },
  {
    name: 'Google',
    id: CloudProvider.GCP,
    logo: googleCloud,
    enabled: false,
    height: '58px',
    width: '92px',
  },
  {
    name: 'Redhat',
    id: CloudProvider.REDHAT,
    logo: redhat,
    enabled: false,
    height: '28px',
    width: '130px',
  },
]

export const LabraCloudProviderSelector: React.FC<{
  onSelect: (cloudProvider: CloudProvider) => void
}> = ({ onSelect }) => {
  const classes = useStyles()
  const [cloudSelected, setCloudSelected] = useState<CloudProvider | null>(null)
  const onSelectProvider = useCallback(
    (provider: CloudProvider) => () => {
      onSelect(provider)
    },
    [onSelect]
  )

  const onSelectCloud = useCallback(
    (provider: CloudProvider) => () => {
      setCloudSelected(provider)
    },
    []
  )
  const { flag: isAzureCloudSettings } = useFlag('azureCloudSettings')

  cloudProviders.map(cloud => {
    cloud.name === 'Azure' && isAzureCloudSettings.value
      ? (cloud.enabled = true)
      : cloud.enabled
  })

  const noOp = () => void 0

  const listCRM = () =>
    cloudProviders.map(provider => (
      <div
        className={`${classes.cloudImg} ${
          provider.enabled ? '' : classes.disabled
        } ${
          cloudSelected && provider.id === cloudSelected ? classes.selected : ''
        }`}
        onClick={provider.enabled ? onSelectCloud(provider.id) : noOp}
        data-testid={`cloud-provider-button-${provider.id}`}
      >
        <img
          style={{ height: provider.height, width: provider.width }}
          src={provider.logo}
        />
      </div>
    ))

  return (
    <div className={classes.root} data-testid="cloud-provider-selector">
      <div
        className={classes.container}
        data-testid="cloud-provide-selector-container"
      >
        <Typography
          className={classes.header}
          data-testid="cloud-provider-heading"
        >
          Select a cloud provider to integrate with
        </Typography>
        <Typography
          className={classes.subheader}
          data-testid="cloud-provider-subheading"
        >
          We are currently working with AWS. Other clouds are coming soon.
        </Typography>
        <div className={classes.imageList}>{listCRM()}</div>
        <div className={classes.continueButton}>
          {' '}
          <ButtonV2
            data-testid={'cloud-provider-continue-button'}
            variant="contained"
            styleType={'primary'}
            disabled={!cloudSelected}
            disableElevation
            buttonWeight={123}
            onClick={cloudSelected ? onSelectProvider(cloudSelected) : noOp}
          >
            Continue
          </ButtonV2>
        </div>
      </div>
    </div>
  )
}
