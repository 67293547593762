import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles(theme => ({
  root: {
    width: 'auto',
    padding: theme.spacing(0, 3.75),
  },
  userBasicInfoContainer: {
    marginTop: theme.spacing(4),
  },
  divider: {
    margin: `${theme.spacing(4, 0)} !important`,
  },
  emailInvite: {
    fontSize: '13px',
  },
  resendBtn: {
    marginLeft: theme.spacing(2),
    backgroundColor: theme.palette.opaque.main,
    color: theme.palette.secondary.main,
    padding: theme.spacing(1, 3),
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.opaque.main,
    },
  },
  editPasswordsContainer: {
    marginTop: theme.spacing(4),
  },
}))
