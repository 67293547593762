import { isEmpty } from 'lodash'

export const getValidValueBetweenMinMax = (
  value: string,
  minValue: number,
  maxValue: number
) => {
  if (isEmpty(value)) return undefined
  if (parseInt(value as string) < minValue) return minValue
  if (parseInt(value as string) >= maxValue) return maxValue
  return Number(value)
}

export const getValidValueBetweenMinMaxFloat = (
  value: string,
  minValue: number,
  maxValue: number
) => {
  if (isEmpty(value)) return undefined
  if (parseFloat(value as string) < minValue) return minValue
  if (parseFloat(value as string) >= maxValue) return maxValue
  return Number(value)
}

export const isNonEmptyNumber = (value?: number) => {
  const checkNumber = value === 0 ? 0 : value || NaN
  return !isNaN(checkNumber)
}
