import { Dispatch } from 'redux'
import { API } from '../../../common/api'
import {
  engineServiceUrl,
  subscriptionPriceId,
} from '../../../common/utils/constants'
import {
  startLoading,
  stopLoading,
} from '../../../common/modules/loading/actions'
import { LoadingTypes } from '../../../common/modules/loading/reducer'

export enum SubscriptionActionTypes {
  SET_SUBSCRIPTION = 'SET_SUBSCRIPTION',
  SET_ERROR = 'SET ERROR',
}

export const handleSubscription =
  (plan: string, subscriptionValidity: string, partnerId: string) =>
  (dispatch: Dispatch) => {
    const priceId =
      subscriptionPriceId[subscriptionValidity as never][plan as never]
    dispatch(startLoading(LoadingTypes.GENERAL))
    return API.post(
      `${engineServiceUrl}/partnerss/${partnerId}/subscription/`,
      {
        priceId: priceId,
      }
    )
      .then(() => {
        //----Use when stripe models are created------
        // window.stripe
        //   .redirectToCheckout({
        //     sessionId: res.data.sessionId,
        //   })
        //   .then((res: any) =>
        //     dispatch(setSubscription(res, plan, subscriptionValidity))
        //   )

        //Remove when stripe business model gets created.
        dispatch(setSubscription(plan))
      })
      .catch(() => dispatch(setSubscriptionError('Something went wrong.')))
      .finally(() => {
        dispatch(stopLoading(LoadingTypes.GENERAL))
      })
  }

export const setSubscription = (plan: string) => ({
  type: SubscriptionActionTypes.SET_SUBSCRIPTION,
  payload: {
    plan: plan,
    subscribed: true,
  },
})

export const setSubscriptionError = (errMess: string) => ({
  type: SubscriptionActionTypes.SET_ERROR,
  payload: errMess,
})
