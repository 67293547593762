import { RootState } from '../../store'
import { newrelicErrLogger } from './ErrorHandler'

export type LoggerContext = {
  globalState: RootState
}
export const errorLogger =
  ({ globalState }: LoggerContext) =>
  (error: Error) => {
    const activeProducts =
      globalState?.PartnerData?.user?.partnerData?.activeProducts
    const partnerId =
      globalState?.PartnerData['user']?.partnerData?.partnerId ?? ''
    const oktaUserId = globalState?.userProfile?.userProfile?.idpUserId ?? ''
    const user = globalState?.userProfile?.userProfile

    const logContext = {
      partnerId: partnerId || 'no partner ID',
      oktaUserId: oktaUserId || 'no okta user id',
      envVariables: process.env || 'no env variables available',
      activeProducts: activeProducts || 'no active products',
      user_roles: user.roles || 'no roles available',
      time_zone: user.timeZone || 'no timezone available',
    }
    newrelicErrLogger(error as Error, {
      ...logContext,
      error: error,
    })
  }
