export const awsLeadFields = [
  {
    title: 'Acceptance Status',
    place_holder: 'Acceptance Status',
    id: 'Labra__Acceptance_Status__c',
    data_type: 'enum',
    options: ['Accepted', 'Rejected'],
    info: 'Acceptance Status',
  },
  {
    title: 'ACE Created Date',
    place_holder: 'ACE Created Date',
    id: 'Labra__ACE_Created_Date__c',
    data_type: 'datetime',
    hide_in_creation: true,
    disable_in_creation: true,
    disable_in_update: true,
    info: 'Date-Time the lead was created',
  },
  {
    title: 'ACE Last Modified Date',
    place_holder: 'ACE Last Modified Date',
    id: 'Labra__ACE_Last_Modified_Date__c',
    data_type: 'datetime',
    hide_in_creation: true,
    disable_in_creation: true,
    disable_in_update: true,
    info: 'Date-Time when the lead was last modified',
  },
  {
    title: 'ACE CRM Unique Identifier',
    place_holder: 'ACE CRM Unique Identifier',
    id: 'Labra__APN_CRM_Unique_Identifier__c',
    data_type: 'string',
    max_length: 18,
    hide_in_creation: true,
    disable_in_creation: true,
    disable_in_update: true,
    info: 'Unique identifier of the lead in AWS APN system',
  },
  {
    title: 'AWS Campaign Name',
    place_holder: 'AWS Campaign Name',
    id: 'Labra__AWS_Campaign_Name__c',
    is_required: true,
    data_type: 'string',
    max_length: 255,
    hide_in_creation: true,
    disable_in_creation: true,
    disable_in_update: true,
    info: 'AWS Campaign name that is associated with the lead generation',
  },
  {
    title: 'AWS Lead Source',
    place_holder: 'AWS Lead Source',
    id: 'Labra__AWS_Lead_Source__c',
    data_type: 'string',
    max_length: 80,
    hide_in_creation: true,
    disable_in_creation: true,
    disable_in_update: true,
    info: 'Lead source as maintained by AWS',
  },
  {
    title: 'Labra Lead Name',
    place_holder: 'Labra Lead Name',
    id: 'Name',
    data_type: 'string',
    max_length: 255,
    hide_in_creation: true,
    disable_in_creation: true,
    info: 'Labra Lead Name',
  },
  {
    title: 'Labra AWS Lead Status Reason',
    place_holder: 'Labra AWS Lead Status Reason',
    id: 'Labra__AWS_Lead_Status_Reason__c',
    data_type: 'string',
    max_length: 255,
    hide_in_creation: true,
    disable_in_creation: true,
    info: 'Please provide details regarding your engagement and next steps with the lead. Do not disclose any information that is confidential, sensitive or that would be considered personal information of an individual.',
  },
  {
    title: 'AWS Lead Status',
    place_holder: 'AWS Lead Status',
    is_required: true,
    id: 'Labra__AWS_Lead_Status__c',
    data_type: 'enum',
    options: ['Open', 'Research', 'Disqualified', 'Qualified'],
    hide_in_creation: true,
    disable_in_creation: true,
    info: 'Field indicating the status of the lead based on lifecycle (E.g. Open or Qualified or Closed)',
  },
  {
    title: 'City',
    place_holder: 'City',
    id: 'Labra__City__c',
    data_type: 'string',
    max_length: 255,
    hide_in_creation: true,
    disable_in_creation: true,
    info: 'City (part of the address) of the end customer on the lead',
  },
  {
    title: 'Company name',
    place_holder: 'Company name',
    id: 'Labra__Company_Name__c',
    data_type: 'string',
    max_length: 255,
    hide_in_creation: true,
    disable_in_creation: true,
    disable_in_update: true,
    info: 'Name of the end customer company name on the lead',
  },
  {
    title: 'Country',
    place_holder: 'Country',
    id: 'Labra__Country__c',
    data_type: 'enum',
    options: [
      'United States',
      'Afghanistan',
      'Aland Islands',
      'Albania',
      'Algeria',
      'American Samoa',
      'Andorra',
      'Angola',
      'Anguilla',
      'Antarctica',
      'Antigua And Barbuda',
      'Argentina',
      'Armenia',
      'Aruba',
      'Australia',
      'Austria',
      'Azerbaijan',
      'Bahamas',
      'Bahrain',
      'Bangladesh',
      'Barbados',
      'Belarus',
      'Belgium',
      'Belize',
      'Benin',
      'Bermuda',
      'Bhutan',
      'Bolivia',
      'Bonaire, Sint Eustatius and Saba',
      'Bosnia and Herzegovina',
      'Botswana',
      'Bouvet Island',
      'Brazil',
      'British Indian Ocean Territory',
      'Brunei Darussalam',
      'Bulgaria',
      'Burkina Faso',
      'Burundi',
      'Cambodia',
      'Cameroon',
      'Canada',
      'Cape Verde',
      'Cayman Islands',
      'Central African Republic',
      'Chad',
      'Chile',
      'China',
      'Christmas Island',
      'Cocos (Keeling) Islands',
      'Colombia',
      'Comoros',
      'Congo',
      'Cook Islands',
      'Costa Rica',
      "Cote d'Ivoire",
      'Croatia',
      'Cuba',
      'Curacao',
      'Cyprus',
      'Czech Republic',
      'Democratic Republic Of The Congo',
      'Denmark',
      'Djibouti',
      'Dominica',
      'Dominican Republic',
      'Ecuador',
      'Egypt',
      'El Salvador',
      'Equatorial Guinea',
      'Eritrea',
      'Estonia',
      'Ethiopia',
      'Falkland Islands (Malvinas)',
      'Faroe Islands',
      'Fiji',
      'Finland',
      'France',
      'French Guiana',
      'French Polynesia',
      'French Southern Territories',
      'Gabon',
      'Gambia',
      'Georgia',
      'Germany',
      'Ghana',
      'Gibraltar',
      'Great Britain',
      'Greece',
      'Greenland',
      'Grenada',
      'Guadeloupe',
      'Guam',
      'Guatemala',
      'Guernsey',
      'Guinea',
      'Guinea-Bissau',
      'Guyana',
      'Haiti',
      'Heard Island and McDonald Islands',
      'Holy See (Vatican City State)',
      'Honduras',
      'Hong Kong',
      'Hungary',
      'Iceland',
      'India',
      'Indonesia',
      'Iran, Islamic Republic Of',
      'Iraq',
      'Ireland',
      'Isle of Man',
      'Israel',
      'Italy',
      'Jamaica',
      'Japan',
      'Jersey',
      'Jordan',
      'Kazakhstan',
      'Kenya',
      'Kiribati',
      'Korea',
      'Kuwait',
      'Kyrgyzstan',
      "Lao People's Democratic Republic",
      'Latvia',
      'Lebanon',
      'Lesotho',
      'Liberia',
      'Libyan Arab Jamahiriya',
      'Liechtenstein',
      'Lithuania',
      'Luxembourg',
      'MACAO',
      'Macedonia, The former Yugoslav Republic Of',
      'Madagascar',
      'Malawi',
      'Malaysia',
      'Maldives',
      'Mali',
      'Malta',
      'Marshall Islands',
      'Martinique',
      'Mauritania',
      'Mauritius',
      'Mayotte',
      'Mexico',
      'Micronesia, Federated States of',
      'Moldova, Republic of',
      'Monaco',
      'Mongolia',
      'Montenegro',
      'Montserrat',
      'Morocco',
      'Mozambique',
      'Myanmar',
      'Namibia',
      'Nauru',
      'Nepal',
      'Netherlands',
      'Netherlands Antilles',
      'New Caledonia',
      'New Zealand',
      'Nicaragua',
      'Niger',
      'Nigeria',
      'Niue',
      'Norfolk Island',
      'Northern Mariana Islands',
      'Norway',
      'Oman',
      'Pakistan',
      'Palau',
      'Palestinian Territory, Occupied',
      'Panama',
      'Papua New Guinea',
      'Paraguay',
      'Peru',
      'Philippines',
      'Pitcairn',
      'Poland',
      'Portugal',
      'Puerto Rico',
      'Qatar',
      'Reunion',
      'Romania',
      'Russia',
      'Rwanda',
      'Saint Barthelemy',
      'Saint Helena, Ascension and Tristan Da Cunha',
      'Saint Kitts and Nevis',
      'Saint Lucia',
      'Saint Martin (French Part)',
      'Saint Pierre and Miquelon',
      'Saint Vincent and the Grenadines',
      'Samoa',
      'San Marino',
      'Sao Tome and Principe',
      'Saudi Arabia',
      'Senegal',
      'Serbia',
      'Serbia and Montenegro',
      'Seychelles',
      'Sierra Leone',
      'Singapore',
      'Sint Maarten (Dutch Part)',
      'Slovakia',
      'Slovenia',
      'Solomon Islands',
      'Somalia',
      'South Africa',
      'South Georgia and the South Sandwich Islands',
      'South Sudan',
      'Spain',
      'Sri Lanka',
      'Sudan',
      'Suriname',
      'Svalbard and Jan Mayen',
      'Swaziland',
      'Sweden',
      'Switzerland',
      'Syrian Arab Republic',
      'Taiwan',
      'Tajikistan',
      'Tanzania',
      'Thailand',
      'Timor-Leste',
      'Togo',
      'Tokelau',
      'Tonga',
      'Trinidad and Tobago',
      'Tunisia',
      'Turkey',
      'Turkmenistan',
      'Turks and Caicos Islands',
      'Tuvalu',
      'Uganda',
      'Ukraine',
      'United Arab Emirates',
      'United Kingdom',
      'United States Minor Outlying Islands',
      'Uruguay',
      'Uzbekistan',
      'Vanuatu',
      'Venezuela',
      'Viet Nam',
      'VIRGIN ISLANDS, BRITISH',
      'VIRGIN ISLANDS, U.S.',
      'Wallis and Futuna',
      'Western Sahara',
      'Yemen',
      'Zambia',
      'Zimbabwe',
    ],
    hide_in_creation: true,
    disable_in_creation: true,
    info: 'Country (part of the address) of the end customer on the lead',
  },
  {
    title: 'Description',
    place_holder: 'Description',
    id: 'Labra__Description__c',
    data_type: 'textarea',
    max_length: 3200,
    min_length: 50,
    hide_in_creation: true,
    disable_in_creation: true,
    info: 'Descriptive field articulating the customer need on the lead',
  },
  {
    title: 'Email',
    place_holder: 'Email',
    id: 'Labra__Email__c',
    data_type: 'string',
    validation_type: 'email',
    max_length: 3200,
    hide_in_creation: true,
    disable_in_creation: true,
    info: 'Email of the end customer on the lead',
  },
  {
    title: 'First Name',
    place_holder: 'First Name',
    id: 'Labra__First_Name__c',
    data_type: 'string',
    validation_type: 'string',
    max_length: 80,
    hide_in_creation: true,
    disable_in_creation: true,
    info: 'First name of the end customer on the lead',
  },
  {
    title: 'Last Name',
    place_holder: 'Last Name',
    id: 'Labra__Last_Name__c',
    data_type: 'string',
    validation_type: 'string',
    max_length: 80,
    hide_in_creation: true,
    disable_in_creation: true,
    info: 'Last name of the end customer on the lead',
  },
  {
    title: 'Industry',
    place_holder: 'Industry',

    id: 'Labra__Industry__c',
    data_type: 'enum',
    options: [
      'Aerospace',
      'Agriculture',
      'Automotive',
      'Computers & Electronics',
      'Consumer Goods',
      'Education',
      'Financial Services',
      'Gaming',
      'Government',
      'Healthcare',
      'Hospitality',
      'Life Sciences',
      'Manufacturing',
      'Marketing & Advertising',
      'Media & Entertainment',
      'Mining',
      'Non-Profit Organization',
      'Energy - Oil & Gas',
      'Other',
      'Energy - Power & Utilities',
      'Professional Services',
      'Real Estate & Construction',
      'Retail',
      'Software & Internet',
      'Telecommunications',
      'Transportation & Logistics',
      'Travel',
      'Wholesale & Distribution',
    ],
    hide_in_creation: true,
    disable_in_creation: true,
    info: 'Industry of the end customer on the lead',
  },
  {
    title: 'Last Updated By',
    place_holder: 'Last Updated By',
    id: 'Labra__Last_Updated_By__c',
    data_type: 'string',
    max_length: 250,
    hide_in_creation: true,
    disable_in_creation: true,
    disable_in_update: true,
    info: 'Last Updated By',
  },
  {
    title: 'Phone',
    place_holder: 'Phone',
    id: 'Labra__Phone__c',
    data_type: 'string',
    validation_type: 'phone',
    hide_in_creation: true,
    disable_in_creation: true,
    info: 'Phone number of the end customer on the lead',
  },
  {
    title: 'Postal Code',
    place_holder: 'Postal Code',
    id: 'Labra__Postal_Code__c',
    data_type: 'string',
    max_length: 20,
    hide_in_creation: true,
    disable_in_creation: true,
    info: 'Postal Code (part of the address) of the end customer on the lead',
  },
  {
    title: 'State',
    place_holder: 'State',
    id: 'Labra__State__c',
    data_type: 'enum',
    options: [
      'Ahmedabad',
      'Alabama',
      'Alaska',
      'Alberta',
      'American Samoa',
      'Andaman & Nicobar',
      'Andrapradesh',
      'Anhui',
      'Arizona',
      'Arkansas',
      'Arunachal Pradesh',
      'Assam',
      'Australian Capital Territory',
      'Bangalore',
      'Baroda',
      'Beijing',
      'Bihar',
      'British Columbia',
      'California',
      'Chattisgargh',
      'Chennai',
      'Chongqing',
      'Cochin',
      'Colorado',
      'Connecticut',
      'Delaware',
      'Delhi',
      'Dist. of Columbia',
      'Federated States of Micronesia',
      'Florida',
      'Fujian',
      'Gansu',
      'Georgia',
      'Goa',
      'Guam',
      'Guangdong',
      'Guangxi',
      'Guizhou',
      'Gujarat',
      'Hainan',
      'Haryana',
      'Hawaii',
      'Hebei',
      'Heilongjiang',
      'Henan',
      'Himachal Pardesh',
      'Hong Kong Island',
      'Hubei',
      'Hunan',
      'Hyderabad',
      'Idaho',
      'Illinois',
      'Indiana',
      'Inner Mong',
      'Iowa',
      'Jiangsu',
      'Jiangxi',
      'Jilin',
      'Kansas',
      'Karnataka',
      'Kentucky',
      'Kerala',
      'Kolkata',
      'Kowloon',
      'Lantau Island',
      'Liaoning',
      'Louisiana',
      'Madhya Pradesh',
      'Maharashtra',
      'Maine',
      'Manipur',
      'Manitoba',
      'Marshall Islands',
      'Maryland',
      'Massachusetts',
      'Meghalaya',
      'Michigan',
      'Minnesota',
      'Mississippi',
      'Missouri',
      'Mizoram',
      'Montana',
      'Mumbai',
      'Mysore',
      'Nagaland',
      'Navi Mumbai',
      'Nebraska',
      'NeiMongol',
      'Nevada',
      'New Brunswick',
      'Newfoundland and Labrador',
      'New Hampshire',
      'New Jersey',
      'New Mexico',
      'New South Wales',
      'New Territories',
      'New York',
      'Ningxia',
      'North Carolina',
      'North Dakota',
      'Northern Mariana Islands',
      'Northern Territory',
      'Northwest Territories',
      'Nova Scotia',
      'Nunavut',
      'Ohio',
      'Oklahoma',
      'Ontario',
      'Oregon',
      'Orrisa',
      'Palau',
      'Pennsylvania',
      'Pondicherry',
      'Prince Edward Island',
      'Puerto Rico',
      'Pune',
      'Punjab',
      'Qinghai',
      'Quebec',
      'Queensland',
      'Rajasthan',
      'Rhode Island',
      'Saskatchewan',
      'Secunderabad',
      'Shaanxi',
      'Shandong',
      'Shanghai',
      'Shanxi',
      'Sichuan',
      'Singapore',
      'South Australia',
      'South Carolina',
      'South Dakota',
      'Surat',
      'Tamil Nadu',
      'Tasmania',
      'Tennessee',
      'Texas',
      'Thiruvananthapuram',
      'Tianjin',
      'Tibet',
      'Tripura',
      'Utah',
      'Uttar Pradesh',
      'Uttrakhand',
      'Vermont',
      'Victoria',
      'Virginia',
      'Virgin Islands',
      'Washington',
      'West Bangal',
      'Western Australia',
      'West Virginia',
      'Wisconsin',
      'Wyoming',
      'Xinjiang',
      'Xizang',
      'Yukon',
      'Yunnan',
      'Zhejiang',
      'APO/AE',
      'AFO/FPO',
      'FPO, AP',
    ],
    hide_in_creation: true,
    disable_in_creation: true,
    info: 'State (part of the address) of the end customer on the lead',
  },
  {
    title: 'Street',
    place_holder: 'Street',
    id: 'Labra__Street__c',
    data_type: 'string',
    max_length: 255,
    hide_in_creation: true,
    disable_in_creation: true,
    info: 'Address of the end customer on the lead',
  },
  {
    title: 'Title',
    place_holder: 'Title',
    id: 'Labra__Title__c',
    data_type: 'string',
    max_length: 255,
    hide_in_creation: true,
    disable_in_creation: true,
    info: 'Title of the end customer on the lead',
  },
  {
    title: 'Website',
    place_holder: 'Website',
    id: 'Labra__Website__c',
    data_type: 'string',
    validation_type: 'url',
    max_length: 255,
    hide_in_creation: true,
    disable_in_creation: true,
    info: 'Website of the end customer on the lead',
  },
  {
    title: 'AWS Partner Central User Email',
    place_holder: 'AWS Partner Central User Email',
    id: 'Labra__AWS_Partner_Central_User_Email__c',
    data_type: 'enum',
    validation_type: null,
    hide_in_creation: true,
    disable_in_creation: true,
    info: 'AWS Partner Central User Email',
  },
  {
    title: 'AWS Partner Central User Name',
    place_holder: 'AWS Partner Central User Name',
    id: 'Labra__AWS_Partner_Central_User_Name__c',
    data_type: 'string',
    max_length: 250,
    hide_in_creation: true,
    disable_in_creation: true,
    hide_in_update: true,
    disable_in_update: true,
    info: 'AWS Partner Central User Name',
  },
  {
    title: 'Referred by',
    place_holder: 'Referred by',
    id: 'Labra__Referred_By__c',
    data_type: 'enum',
    max_length: null,
    hide_in_creation: true,
    disable_in_creation: true,
    hide_in_update: true,
    disable_in_update: true,
    options: ['AWS Referral', 'Partner Referral'],
    info: 'Referred by',
  },
  {
    title: 'AWS Lead Age',
    place_holder: 'AWS Lead Age',
    id: 'Labra__AWS_Lead_Age__c',
    data_type: 'integer',
    max_length: 10,
    hide_in_creation: true,
    disable_in_creation: true,
    disable_in_update: true,
    info: 'Age in days since the lead was created',
  },
  {
    title: 'Campaign Member Status',
    place_holder: 'Campaign Member Status',
    id: 'Labra__Campaign_Member_Status__c',
    data_type: 'string',
    max_length: 40,
    hide_in_creation: true,
    disable_in_creation: true,
    disable_in_update: true,
    info: 'Level of engagement on the lead ',
  },
  {
    title: 'Company Size',
    place_holder: 'Company Size',
    id: 'Labra__Company_Size__c',
    data_type: 'string',
    max_length: 255,
    hide_in_creation: true,
    disable_in_creation: true,
    info: 'Company Size',
  },
  {
    title: 'Full Name',
    place_holder: 'Full Name',
    id: 'Labra__Full_Name__c',
    data_type: 'string',
    max_length: 120,
    hide_in_creation: true,
    disable_in_creation: true,
    disable_in_update: true,
    info: 'Full name of the end customer on the lead',
  },
  {
    title: 'Level of AWS Usage',
    place_holder: 'Level of AWS Usage',
    id: 'Labra__Level_of_AWS_Usage__c',
    data_type: 'string',
    max_length: 255,
    hide_in_creation: true,
    disable_in_creation: true,
    disable_in_update: true,
    info: 'End customer maturity on AWS',
  },
  {
    title: 'Use Case Workload',
    place_holder: 'Use Case Workload',
    id: 'Labra__Use_Case_Workload__c',
    data_type: 'enum',
    max_length: 255,
    options: [
      'Big Data',
      'Content Delivery',
      'DR/Archive/Backup',
      'High Performance Computing (HPC)',
      'IOT',
      'Marketplace',
      'Microsoft Apps',
      'Oracle/SAP/Enterprise Apps',
      'Other',
      'Web Application',
    ],
    hide_in_creation: true,
    disable_in_creation: true,
    info: 'End customer use case that the lead is solving for (E.g. Migration, Business applications)',
  },
  {
    title: 'Sync to AWS',
    place_holder: 'Sync to AWS',
    id: 'Labra__Sync_to_AWS__c',
    data_type: 'bool',
    hide_in_creation: true,
    disable_in_creation: true,
    info: 'Sync to AWS',
  },
]
