import { PartnerObjectActionTypes, partnerObjectResponse } from './actions'

export interface PartnerObjectType {
  id: string
  crmId: string
  stage: string
  aceId: string
  partnerAcceptanceStatus: string
  status: string
  lastSyncCreatedAt: string
  lastSyncUpdatedAt: string
  name: string
  originatedAt: string
  createdDate: string
  resultJsonType: string
}

export interface PartnerObjectsState {
  objects: PartnerObjectType[] | []
  pageSize: number
  noOfPage: number | null
  pageNumber: number
  errMess: unknown
}

export const initialState: PartnerObjectsState = {
  objects: [],
  pageSize: 10,
  noOfPage: null,
  pageNumber: 1,
  errMess: null,
}

export interface PartnerObjectPayload {
  data: partnerObjectResponse
  pageSize: number
}
type actionType =
  | {
      type: PartnerObjectActionTypes.SET_PARTNER_OBJECTS
      payload: PartnerObjectPayload
    }
  | {
      type: PartnerObjectActionTypes.SET_LIMIT
      payload: number
    }
  | {
      type: PartnerObjectActionTypes.SET_PAGE_NUMBER
      payload: number
    }
  | {
      type: PartnerObjectActionTypes.SET_PARTNER_OBJECTS_ERROR
      payload: unknown
    }
  | {
      type: PartnerObjectActionTypes.SET_CLEAR_OBJECTS_DATA
    }

export const reducer = (state = initialState, action: actionType) => {
  switch (action.type) {
    case PartnerObjectActionTypes.SET_PARTNER_OBJECTS: {
      return {
        ...state,
        objects: action.payload.data.objects,
        noOfPage: Math.ceil(
          action.payload.data.count / action.payload.pageSize
        ),
        errMess: null,
      }
    }

    case PartnerObjectActionTypes.SET_LIMIT: {
      return {
        ...state,
        pageSize: action.payload,
        errMess: null,
      }
    }

    case PartnerObjectActionTypes.SET_PAGE_NUMBER: {
      return {
        ...state,
        pageNumber: action.payload,
        errMess: null,
      }
    }

    case PartnerObjectActionTypes.SET_PARTNER_OBJECTS_ERROR: {
      return {
        ...state,
        errMess: action.payload,
      }
    }
    case PartnerObjectActionTypes.SET_CLEAR_OBJECTS_DATA: {
      return {
        ...initialState,
        pageSize: state.pageSize,
      }
    }
    default:
      return state
  }
}
