import { Action, Dispatch } from 'redux'
import { ThunkAction } from '@reduxjs/toolkit'
import { RootState } from '../../../store'
import { updateAppAlert } from '../appAlert/actions'
import { initialState as messageInitialstate } from '../appAlert/reducer'
import { fetchOnboarding, patchOnboarding } from '../../../common/api'
import { camelize, snakeize } from 'casing'
import { OnboardingState } from './reducer'
import { actionTypeWrapper } from '../../utils/actionTypeWrapper'
import { PartnerType } from '../partner/action'
import { startLoading, stopLoading } from '../loading/actions'
import { LoadingTypes } from '../loading/reducer'
import { errorLogger } from '../../utils/errorLogger'

export enum GetOnboardingTypes {
  GET_ONBOARDING = 'GET_ONBOARDING',
  SET_ONBOARDING = 'SET_ONBOARDING',
  ONBOARDING_CLEANUP = 'ONBOARDING_CLEANUP',
}

export type OnboardingActions =
  | {
      type: GetOnboardingTypes.GET_ONBOARDING
    }
  | {
      type: GetOnboardingTypes.SET_ONBOARDING
      payload: Partial<OnboardingState>
    }
  | {
      type: GetOnboardingTypes.ONBOARDING_CLEANUP
    }

export const getOnboarding =
  (
    partnerId: string,
    partnerObjectType: PartnerType = PartnerType.User
  ): ThunkAction<void, unknown, unknown, Action> =>
  async (dispatch: Dispatch) => {
    dispatch(startLoading(LoadingTypes.GENERAL))
    return fetchOnboarding(partnerId)
      .then(response => {
        dispatch(
          actionTypeWrapper(
            partnerObjectType,
            getOnboardingByPartnerType(camelize(response.data))
          )
        )
      })
      .catch(() => {
        dispatch(updateAppAlert(messageInitialstate))
      })
      .finally(() => {
        dispatch(stopLoading(LoadingTypes.GENERAL))
      })
  }
export const getOnboardingByPartnerType = (data: OnboardingState) => ({
  type: GetOnboardingTypes.GET_ONBOARDING,
  payload: data,
})

export const setOnboarding = (
  onboarding: Partial<OnboardingState>
): OnboardingActions => {
  return {
    type: GetOnboardingTypes.SET_ONBOARDING,
    payload: onboarding,
  }
}

export const cleanupOnboarding =
  (partnerType: PartnerType) => (dispatch: Dispatch) => {
    dispatch(
      actionTypeWrapper(partnerType, {
        type: GetOnboardingTypes.ONBOARDING_CLEANUP,
      })
    )
  }

export const updateOnboarding =
  (
    partnerId: string,
    partnerObjectType: PartnerType,
    dataOnboarding: OnboardingState
  ) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.GENERAL))
    try {
      const res = await patchOnboarding(partnerId, snakeize(dataOnboarding))
      dispatch(
        actionTypeWrapper(partnerObjectType, setOnboarding(camelize(res.data)))
      )
    } catch (err) {
      console.warn(err)
      const globalState = getState()
      errorLogger({ globalState })(err as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }
