import { AxiosResponse } from 'axios'
import { API } from '../../common/api'
import {
  azureEngineServiceUrl,
  cloudAccountServiceUrl,
  crmServiceUrl,
  labravAWSEngineServiceUrl,
} from '../../common/utils/constants'
import {
  AzureAccountDetails,
  AzureBackendAccountDetails,
} from '../modules/productAccount/reducer'
import { Account } from '../../common/modules/cmcm/types'

const mapAzBE2FE: (
  a: AzureBackendAccountDetails
) => AzureAccountDetails = details => ({
  id: details.id,
  name: details.company_name,
  MPNID: details.mpn_id,
  industries: details.industry,
  microsoftPartnerType: details.partner_type,
  ISV: details.is_isv,
  grantConsent: true,
  terms: true,
})

export const fetchAzureAccountDetails = (parnterId: string) =>
  API.get(`${azureEngineServiceUrl}/partners/${parnterId}`)
    .then(({ data }) => data as AzureBackendAccountDetails)
    .then(mapAzBE2FE)

export const deactivateAzureAccount = (
  partnerId: string,
  accountId: string,
  payload: Record<string, unknown>
) => {
  return API.put(
    `${cloudAccountServiceUrl}/azure/organizations/${partnerId}/accounts/${accountId}`,
    payload
  )
}

export const fetchCRMConfiguration = (crmId: string) =>
  API.get(`${crmServiceUrl}/crms/${crmId}`)

export const fetchSegmentAndGreenField = (
  partnerId: string
): Promise<AxiosResponse<{ rows: Account[] }>> =>
  API.get(`${labravAWSEngineServiceUrl}/partners/${partnerId}/segment_reports`)

export const getPreSignedUrlOppSync =  (partnerId: string,
path: string,
bucketName?: string
) => {
return API.get(`${labravAWSEngineServiceUrl}/partners/${partnerId}/assets`, {
  params: {
    fields: 'aws_presigned_url',
    assets: path,
    operation_method: 'POST',
    expires_in: 3600,
    bucket_name: bucketName ? bucketName : '',
  },
})
}

export const postAssetsOppSync =  (
  partnerId: string,
  assets: Record<string, unknown>
  ) => {
  return API.post(`${labravAWSEngineServiceUrl}/partners/${partnerId}/assets`, assets)
  }