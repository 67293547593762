import {
  Notifications,
  NotificationsDetails,
  NotificationsSettingPage,
  PartnerLevel,
  ProductNotificationsTypeMethods,
  recipients,
} from './reducer'
import {
  startLoading,
  stopLoading,
} from '../../../common/modules/loading/actions'
import { LoadingTypes } from '../../../common/modules/loading/reducer'
import { AnyAction, Dispatch } from 'redux'
import { camelize, snakeize } from 'casing'
import {
  fetchNotificationSettingsPageData,
  fetchReciepients,
  putNotificationsData,
  putUpdateRecipients,
  slackConnection,
  slackDisconecction,
} from '../../../common/api/notifcationAPI'
import { updateAppAlert } from '../../../common/modules/appAlert/actions'
import {
  NotificationSavedSuccessMessage,
  RequestFailureMessage,
} from '../../../common/utils/messagesContants'
import { setNotificationSidePanelLoading } from './notificationSidePanel/actions'
import { RootState } from '../../../store'
import { isEmpty } from 'lodash/fp'
import { AxiosError, AxiosResponse } from 'axios'
import { errorLogger } from '../../../common/utils/errorLogger'
import { getThirdPartyAppList } from '../../../common/modules/thirdPartyAppIntegration/action'
import { Product } from '../../../common/modules/partner/action'
import { actionTypeWrapper } from '../../../common/utils/actionTypeWrapper'
import { clearSlackChannels } from '../../../common/modules/slackChannels/action'
import { getErrorMessages } from '../../../common/utils/error'
export enum NotificationsActions {
  SET_NOTIFICATIONS_SETTING_PAGE_DATA = 'SET_NOTIFICATIONS_SETTING_PAGE_DATA',
  SET_NOTIFICATIONS = 'SET_NOTIFICATIONS',
  SET_PRODUCT_NOTIFICATIONS_TYPE_METHODS = 'SET_PRODUCT_NOTIFICATIONS_TYPE_METHODS',
  DELETE_USER = 'DELETE_USER',
  ADD_UNREGISTERED_USER = 'ADD_UNREGISTERED_USER',
  ADD_REGISTERED_USER = 'ADD_REGISTERED_USER',
  REMOVE_REGISTERED_USER = 'REMOVE_REGISTERED_USER',
  CLEAR_NOTIFICATIONS_DATA = 'CLEAR_NOTIFICATIONS_DATA',
  UPDATE_CHECKBOX = 'UPDATE_CHECKBOX',
  DISCONNECT_SLACK = 'DISCONNECT_SLACK',
  CONNECT_SLACK = 'CONNECT_SLACK',
  FORM_DIRTY = 'FORM_DIRTY',
}

export const getRecipients =
  (
    partnerId: string,
    productNotificationTypeId: string,
    notificationMethodId: string,
    product: Product
  ) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    await dispatch(setNotificationSidePanelLoading(true))
    try {
      const { data } = await fetchReciepients(
        partnerId,
        product,
        productNotificationTypeId
      )
      const { recipients } = data
      await dispatch(
        actionTypeWrapper(
          product,
          setProductNotificationsTypeMethods({
            partnerId,
            productNotificationTypeId,
            notificationMethodId,
            recipients: camelize(recipients) as unknown as recipients[],
          })
        )
      )
    } catch (error: any) {
      const errMess = getErrorMessages([RequestFailureMessage])(error?.response)
      dispatch(
        updateAppAlert({
          message: errMess,
          messageType: 'ERROR',
          autoClose: true,
          sidePanelAlert: true,
        })
      )

      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(setNotificationSidePanelLoading(false))
    }
  }
export const deleteUser = (index: number) => ({
  type: NotificationsActions.DELETE_USER,
  payload: index,
})
export const addUnregisteredUser = (inputValue: string) =>
  ({
    type: NotificationsActions.ADD_UNREGISTERED_USER,
    payload: inputValue,
  } as unknown as AnyAction)
export const setNotifications = (payload: Notifications) => ({
  type: NotificationsActions.SET_NOTIFICATIONS as NotificationsActions.SET_NOTIFICATIONS,
  payload,
})

export const addRegisteredUser = (idpUserId: string) => ({
  type: NotificationsActions.ADD_REGISTERED_USER,
  payload: idpUserId,
})

export const setFormDirty = (val: boolean) => ({
  type: NotificationsActions.FORM_DIRTY,
  payload: val,
})
export const removeRegisteredUser = (idpUserId: string) => ({
  type: NotificationsActions.REMOVE_REGISTERED_USER,
  payload: idpUserId,
})

export const disconnectSlack = (disconnectedData: {
  [x: string]: PartnerLevel
}) => ({
  type: NotificationsActions.DISCONNECT_SLACK,
  payload: disconnectedData,
})

export const setProductNotificationsTypeMethods = (
  payload: ProductNotificationsTypeMethods
) => ({
  type: NotificationsActions.SET_PRODUCT_NOTIFICATIONS_TYPE_METHODS as NotificationsActions.SET_PRODUCT_NOTIFICATIONS_TYPE_METHODS,
  payload,
})

const getExternalIntegrations = (
  partnerLevelData: { [x: string]: PartnerLevel },
  slackActiveId: string
) => {
  let externalIntegrations = {}

  for (const [key, value] of Object.entries(partnerLevelData)) {
    if (value?.isConfigured) {
      if (key === 'SLACK') {
        externalIntegrations = {
          ...externalIntegrations,
          SLACK: snakeize({
            activeChannel: slackActiveId,
          }),
        }
      }
    }
  }
  return externalIntegrations
}

export const saveNotifications =
  (partnerId: string, product: Product, idpUserId?: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const notificationList = getNotificationList(
      getState().notifications[product].notificationsSettingData,
      idpUserId
    )
    const slackActiveId = getState()?.slackChannels?.[product]?.activeChannelId
    const partnerLevelData =
      getState()?.notifications?.[product]?.notificationsSettingData
        ?.availableMethods?.partnerLevel
    const externalIntegrations = getExternalIntegrations(
      partnerLevelData,
      slackActiveId
    )

    await dispatch(startLoading(LoadingTypes.GENERAL))
    let dataToSend = {}
    if (isEmpty(idpUserId)) {
      dataToSend = {
        external_integrations: externalIntegrations,
        notifications: snakeize(notificationList),
      }
    } else {
      dataToSend = snakeize({
        notifications: notificationList,
        partnerId: partnerId,
        idpUserId,
      })
    }
    try {
      await putNotificationsData(product, dataToSend, partnerId, idpUserId)
      await dispatch(
        updateAppAlert({
          message: NotificationSavedSuccessMessage,
          messageType: 'SUCCESS',
          autoClose: true,
        })
      )
    } catch (error: any) {
      const errMess = getErrorMessages([RequestFailureMessage])(error?.response)
      dispatch(
        updateAppAlert({
          message: errMess,
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      await dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }

export const getNotificationList = (
  data: NotificationsSettingPage,
  idpUserId?: string
) => {
  const notificationList: NotificationsDetails[] = []
  const methodList = Object.keys(
    data.availableMethods[idpUserId ? 'userLevel' : 'partnerLevel']
  )

  for (const settings of data.productNotificationTypes) {
    for (const method of settings.productNotificationTypeMethods) {
      const isEmailPartnerLevelMethodType =
        method.type === 'partner_level' && method.methodId === 'EMAIL'
      if (
        (methodList.includes(method.methodId) ||
          isEmailPartnerLevelMethodType) &&
        method.isEnabled === true
      ) {
        const object = {
          productNotificationTypeId: settings.productNotificationTypeId,
          notificationMethodId: method.methodId,
          ...(isEmailPartnerLevelMethodType && {
            recipients: ['opportunity_owner'],
          }),
        }
        notificationList.push(object)
      }
    }
  }
  return notificationList
}

//Get Notifications Settings Page Data
export const getSettingsPageData =
  (partnerId: string, product: Product, idpUserId?: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    await dispatch(startLoading(LoadingTypes.GENERAL))
    try {
      const { data } = await fetchNotificationSettingsPageData(
        product,
        idpUserId
      )
      const settingsPage = camelize(data)
      await dispatch(
        actionTypeWrapper(
          product,
          setNotificationsSettingsPage(
            settingsPage,
            partnerId,
            idpUserId
          ) as unknown as AnyAction
        )
      )
    } catch (error: any) {
      const errMess = getErrorMessages([RequestFailureMessage])(error?.response)
      dispatch(
        updateAppAlert({
          message: errMess,
          messageType: 'ERROR',
          autoClose: true,
          sidePanelAlert: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }
export const setNotificationsSettingsPage = (
  settingsPage: NotificationsSettingPage,
  partnerId: string,
  idpUserId?: string
) => ({
  type: NotificationsActions.SET_NOTIFICATIONS_SETTING_PAGE_DATA,
  payload: { settingsPage, partnerId, idpUserId },
})

export const addUpdateRecipientsNotifications =
  (dataToSend: ProductNotificationsTypeMethods, productId: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(setNotificationSidePanelLoading(true))
    try {
      await putUpdateRecipients(snakeize(dataToSend), productId.toUpperCase())
      dispatch(
        updateAppAlert({
          message: NotificationSavedSuccessMessage,
          messageType: 'SUCCESS',
          autoClose: true,
          sidePanelAlert: true,
        })
      )
    } catch (error: any) {
      const errMess = getErrorMessages([RequestFailureMessage])(error?.response)
      dispatch(
        updateAppAlert({
          message: errMess,
          messageType: 'ERROR',
          autoClose: true,
          sidePanelAlert: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(setNotificationSidePanelLoading(false))
    }
  }

export const setSlackConnection =
  (
    partnerId: string,
    code: string,
    notificationMethodId: string,
    idpUserId?: string
  ) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    await dispatch(startLoading(LoadingTypes.GENERAL))
    try {
      await slackConnection(code, partnerId, notificationMethodId, 'oppsync')
      await dispatch(
        getSettingsPageData(
          partnerId,
          'oppsync',
          idpUserId
        ) as unknown as AnyAction
      )
    } catch (error: any) {
      const errMess = getErrorMessages([RequestFailureMessage])(error?.response)
      dispatch(
        updateAppAlert({
          message: errMess,
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }

export const clearNotificationsData = () => ({
  type: NotificationsActions.CLEAR_NOTIFICATIONS_DATA,
})

export const setSlackConnectionV2 =
  (partnerId: string, code: string, notificationMethodId: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    await dispatch(startLoading(LoadingTypes.GENERAL))
    try {
      await slackConnection(code, partnerId, notificationMethodId)
      await dispatch(getThirdPartyAppList(partnerId) as unknown as AnyAction)
    } catch (error: any) {
      const errMess = getErrorMessages([RequestFailureMessage])(error?.response)
      dispatch(
        updateAppAlert({
          message: errMess,
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }

export const disconnectSlackConnection =
  (partnerId: string, notificationMethodId: string, idpUserId?: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    await dispatch(startLoading(LoadingTypes.GENERAL))
    try {
      await dispatch(
        getSettingsPageData(
          partnerId,
          'oppsync',
          idpUserId
        ) as unknown as AnyAction
      )
    } catch (error: any) {
      const errMess = getErrorMessages([RequestFailureMessage])(error?.response)
      dispatch(
        updateAppAlert({
          message: errMess,
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }

export const disconnectSlackConnectionV2 =
  (partnerId: string, notificationMethodId: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    await dispatch(startLoading(LoadingTypes.GENERAL))
    try {
      await slackDisconecction(partnerId, notificationMethodId)
      await dispatch(getThirdPartyAppList(partnerId) as unknown as AnyAction)
      await dispatch(actionTypeWrapper('flyout', clearNotificationsData()))
      await dispatch(actionTypeWrapper('oppsync', clearNotificationsData()))
      await dispatch(actionTypeWrapper('flyout', clearSlackChannels()))
      await dispatch(actionTypeWrapper('oppsync', clearSlackChannels()))
    } catch (error: any) {
      const errMess = getErrorMessages([RequestFailureMessage])(error?.response)
      dispatch(
        updateAppAlert({
          message: errMess,
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }

export const updateCheckboxChange = (
  value: boolean,
  index: number,
  methodId: string
) => ({
  type: NotificationsActions.UPDATE_CHECKBOX,
  payload: { value, index, methodId },
})
