import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    marginInline: '22px',
    borderRadius: theme.spacing(0, 0, 1, 1),
  },
  heading: {
    display: 'inline-block',
  },
  count: {
    display: 'inline-block',
    marginLeft: theme.spacing(1),
    color: theme.palette.primary.light,
  },
}))
