import React, { useEffect } from 'react'
import { isEmpty } from 'lodash'
import { isLoading as Loading } from '../../utils/loadingState'
import { useDispatch, useSelector } from '../../../store'
import { CRMTypes } from '../constants'
import {
  FormData,
  getCRMFormData,
} from '../../modules/onboarding/crmAuth/action'
import { LoadingTypes } from '../../modules/loading/reducer'

export type crmAuth = {
  deletedAt: string
  crmIdFromCRMAuth: string
  crmType?: CRMTypes
  crmAuth?: FormData
  isLoading: boolean
  isCrmConnected: boolean
}

export const useCrmAuthInfo = (crmId: string | null | undefined) => {
  const dispatch = useDispatch()
  const {
    deletedAt,
    crmIdFromCRMAuth,
    crmType,
    crmAuth,
    isLoading,
    isCrmConnected,
  } = useSelector<crmAuth>(state => {
    const isCrmConnected =
      !isEmpty(state.CRMAuth.crmFormData) &&
      !isEmpty(crmId) &&
      (state.CRMAuth.crmFormData?.creds || []).length > 0 &&
      isEmpty(state.CRMAuth.crmFormData?.deleted_at)
    return {
      deletedAt: state.CRMAuth.crmFormData?.deleted_at || '',
      crmIdFromCRMAuth: state.CRMAuth.crmFormData?.crm_id || '',
      crmType: state.CRMAuth.crmFormData?.crm_type as CRMTypes,
      crmAuth: state.CRMAuth.crmFormData
        ? state.CRMAuth.crmFormData
        : undefined,
      isCrmConnected,
      isLoading: Loading(state.loading)(LoadingTypes.CRM_AUTH),
    }
  })

  const isNewCosellPipeline = crmType === CRMTypes.labra

  useEffect(() => {
    if (crmId && isEmpty(crmAuth) && !isEmpty(crmId)) {
      dispatch(getCRMFormData(crmId))
    }
  }, [crmIdFromCRMAuth, crmAuth, crmId, deletedAt])

  return {
    deletedAt,
    crmIdFromCRMAuth,
    isCrmSelected: isCrmConnected,
    crmType,
    crmAuth,
    isLoading,
    isNewCosellPipeline,
  }
}
