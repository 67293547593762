import { Reducer } from 'redux'
import { PartnerObjectActionTypes } from './actions'
import {
  directionType,
  actionName,
  syncAttemptResultType,
  syncAttemptErrorSource,
} from '../../../../common/utils/constants'

export interface ErrorResolutionsDetails {
  errorCode: string
  message: string
  resolution: string
}

export interface ErrorResolutions {
  awsKey: string
  awsLabel: string
  errorResolutions: ErrorResolutionsDetails[]
}

type SyncAttempt = {
  filePath: string
  direction: directionType
  actionName: actionName
  resultJsonType: syncAttemptResultType
  resultJson?: string
  createdAt: string
  updatedAt: string
  errorSource?: syncAttemptErrorSource
  syncInvoker?: {
    email: string
    name: string
    idpUserId: string
    id: string
  }
  errorResolution?: string
}

export interface SyncObjectType {
  id: string
  objectType: CRMObjectType
  crmId: string
  stage: string
  aceId: string
  lastSyncInvokedAt: string
  lastSyncCompletedAt: string | Date
  name: string
  company: string
  originatedAt: string
  createdAt: string
  resultJsonType: string
  syncAttempts: SyncAttempt[]
}

export interface SyncObjectState {
  syncObject: SyncObjectType
  isSyncPanelOpen: boolean
  errMess: string
}

export const initialState: SyncObjectState = {
  syncObject: {
    id: 'id',
    objectType: 'lead',
    crmId: 'crmId',
    stage: 'stage',
    aceId: 'aceId',
    lastSyncInvokedAt: 'date1',
    lastSyncCompletedAt: 'date2',
    name: 'name',
    company: 'company',
    originatedAt: 'ace',
    createdAt: 'date3',
    resultJsonType: 'error',
    syncAttempts: [
      {
        filePath: 'opportunity-outbound/dummy.json',
        direction: 'ace',
        actionName: 'create',
        resultJsonType: 'in_progress',
        createdAt: '2020-12-31T20:12:43Z',
        updatedAt: '2020-12-31T20:12:43Z',
        syncInvoker: {
          email: '',
          name: '',
          idpUserId: '',
          id: '',
        },
      },
    ],
  },
  isSyncPanelOpen: false,
  errMess: '',
}

type actionType = {
  type: PartnerObjectActionTypes
  payload?: any
}

export const reducer: Reducer<SyncObjectState, actionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case PartnerObjectActionTypes.SET_SYNC_OBJECT_DATA: {
      return {
        ...state,
        syncObject: action.payload,
      }
    }
    case PartnerObjectActionTypes.CLEAR_SYNC_OBJECT_DATA: {
      return {
        ...state,
        syncObject: initialState.syncObject,
      }
    }
    case PartnerObjectActionTypes.SHOW_SYNC_PANEL: {
      return {
        ...state,
        isSyncPanelOpen: true,
      }
    }
    case PartnerObjectActionTypes.HIDE_SYNC_PANEL: {
      return {
        ...state,
        isSyncPanelOpen: false,
      }
    }
    case PartnerObjectActionTypes.SET_PARTNER_OBJ_ERROR: {
      return {
        ...state,
        errMess: action.payload,
      }
    }
    default: {
      return state
    }
  }
}
