import { isEmpty } from 'lodash/fp'
import { filterByCompatibleDataTypes } from '../../../../admin/pages/components/mappings/ConditionalRules/RulesUIParams'
import { getArticle } from '../../../utils/getArticle'
import { MandatoryField } from '../CrmAceMapping/reducer'
import { DataType } from '../ruleDefinitions/reducer'
import { WarningMessageObj } from './reducer'

export enum WarningStatusState {
  SET_WARNING_STATUS = 'SET_WARNING_STATUS',
  IS_COMPLETELY_LOADED = 'IS_COMPLETELY_LOADED',
  SET_MANDATORY_WARNING_STATUS = 'SET_MANDATORY_WARNING_STATUS',
  REMOVE_MANDATORY_WARNING_STATUS = 'REMOVE_MANDATORY_WARNING_STATUS',
  CLEAR_WARNING_STATUS = 'CLEAR_WARNING_STATUS',
}

export const setWarningMessages = (
  aceKey: string,
  mappingWarningObj: WarningMessageObj
) => ({
  type: WarningStatusState.SET_WARNING_STATUS as WarningStatusState.SET_WARNING_STATUS,
  payload: { aceKey, mappingWarningObj },
})

export const setMandatoryWarningMessage = (
  index: number,
  mandatoryWarningObj: WarningMessageObj
) => ({
  type: WarningStatusState.SET_MANDATORY_WARNING_STATUS as WarningStatusState.SET_MANDATORY_WARNING_STATUS,
  payload: { index, mandatoryWarningObj },
})

export const removeMandatoryWarningMessage = (index: number) => ({
  type: WarningStatusState.REMOVE_MANDATORY_WARNING_STATUS as WarningStatusState.REMOVE_MANDATORY_WARNING_STATUS,
  payload: { index },
})

export const clearWarningMessages = () => ({
  type: WarningStatusState.CLEAR_WARNING_STATUS as WarningStatusState.CLEAR_WARNING_STATUS,
})

export type WarningMessageParams = {
  aceKey: string
  aceMappedDataType: string
  aceMaxLength?: number | null
  crmLabel: string
  crmKey: string
  table: string
  crmMappedDataType: string
  crmMaxLength?: number | null
}

export type MandatoryWarningParams = {
  field: MandatoryField
}

export type MappingWarning = {
  type:
    | 'dataTypeWarningMessage'
    | 'maxLengthWarningMessage'
    | 'mandatoryWarningMessage'
  message: string
}

export type WarningValidatorFn = (
  params: WarningMessageParams | MandatoryWarningParams
) => MappingWarning | null

export const dataTypeWarningValidator: WarningValidatorFn = params => {
  const { aceKey, aceMappedDataType, crmLabel, crmMappedDataType } =
    params as WarningMessageParams
  const warningMessageObj: MappingWarning = {
    type: 'dataTypeWarningMessage',
    message: '',
  }
  if (
    !isEmpty(crmLabel) &&
    !isEmpty(crmMappedDataType) &&
    !isEmpty(aceMappedDataType)
  ) {
    const showDateTypeWarning = !filterByCompatibleDataTypes(
      aceMappedDataType as DataType
    )(crmMappedDataType as DataType)
    if (showDateTypeWarning) {
      const articleBeforeACEDataType = getArticle(aceMappedDataType || '')
      const articleBeforeCRMDataType = getArticle(crmMappedDataType || '')
      warningMessageObj.message = `${aceKey} is ${articleBeforeACEDataType} ${
        aceMappedDataType || ''
      } field but ${crmLabel} is ${articleBeforeCRMDataType} ${crmMappedDataType} field.`
    }
  }
  return warningMessageObj
}

export const maxLengthWarningValidator: WarningValidatorFn = params => {
  const { aceKey, aceMaxLength, crmLabel, crmMaxLength } =
    params as WarningMessageParams
  const warningMessageObj: MappingWarning = {
    type: 'maxLengthWarningMessage',
    message: '',
  }

  if (
    !isEmpty(crmLabel) &&
    typeof crmMaxLength === 'number' &&
    crmMaxLength > 0 &&
    typeof aceMaxLength === 'number' &&
    aceMaxLength > 0
  ) {
    if (aceMaxLength !== crmMaxLength) {
      warningMessageObj.message = `${aceKey} has max length of ${aceMaxLength} characters but ${crmLabel} has max length of ${crmMaxLength} characters.`
    }
  }

  return warningMessageObj
}

export const mandatoryWarningValidator: WarningValidatorFn = params => {
  const { field } = params as MandatoryWarningParams
  const warningMessageObj: MappingWarning = {
    type: 'mandatoryWarningMessage',
    message: '',
  }
  if (field.isMandatory === false) {
    warningMessageObj.message = 'Not a mandatory Field.'
  }
  return warningMessageObj
}

export const getWarningMessages =
  (validators: WarningValidatorFn[]) =>
  (params: WarningMessageParams | MandatoryWarningParams) =>
    validators.reduce((acc, validator) => {
      const mappingWarning = validator(params)
      if (mappingWarning === null) {
        return acc
      }
      const { type, message } = mappingWarning
      if ('aceKey' in params) {
        const { crmKey, crmLabel, table } = params as WarningMessageParams
        return {
          ...acc,
          [type]: message,
          crmKey,
          crmLabel,
          table,
        }
      } else if ('field' in params) {
        const { field } = params as MandatoryWarningParams
        const { crmKey, crmLabel, parentTable } = field
        return {
          ...acc,
          [type]: message,
          crmKey,
          crmLabel,
          table: parentTable,
        }
      }
      return acc
    }, {} as WarningMessageObj)

export const getWarningsAfterValidation = getWarningMessages([
  dataTypeWarningValidator,
  maxLengthWarningValidator,
])

export const setIsCompletelyLoaded = () => ({
  type: WarningStatusState.IS_COMPLETELY_LOADED as WarningStatusState.IS_COMPLETELY_LOADED,
  payload: true,
})
export const getWarningsForMandatoryFields = getWarningMessages([
  mandatoryWarningValidator,
])
