import { isEmpty } from 'lodash'

export const mapObjectPropertyEmptyToNull = (
  emptyOrNonEmptyObject: Record<string, unknown>
) => {
  const returnObjectWithNull: Record<string, unknown> = {}
  Object.keys(emptyOrNonEmptyObject).forEach(objKey => {
    returnObjectWithNull[objKey] = isEmpty(emptyOrNonEmptyObject[objKey])
      ? null
      : emptyOrNonEmptyObject[objKey]
  })
  return returnObjectWithNull
}
