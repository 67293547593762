import React from 'react'
import { useStyles } from './ErrorFallback.styles'

interface FallbackProps {
  error: Error
}

export const ErrorFallback: React.FC<FallbackProps> = ({ error }) => {
  const classes = useStyles()
  return (
    <div data-testid="errorFallback" className={classes.main} role="alert">
      <h2>Something went wrong</h2>
    </div>
  )
}
