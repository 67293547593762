import { AnyAction } from 'redux'
import { getReports } from '../../../api/markeplace'
import { AppDispatch, RootState } from '../../../../store'
import { actionTypeWrapper } from '../../../../common/utils/actionTypeWrapper'
import {
  clearRevenueReports,
  getRevenueInsights,
  setRevenuePageNumber,
} from '../actions'
import { DateRange, ReportType } from '../reducer'
import { updateAppAlert } from '../../../../common/modules/appAlert/actions'
import { getErrorMessages } from '../../../../common/utils/error'
import { RequestFailureMessage } from '../../../../common/utils/messagesContants'
import { AxiosResponse } from 'axios'
import { get, isEmpty, snakeCase } from 'lodash'
import { errorLogger } from '../../../../common/utils/errorLogger'

export enum RevenueMiscellaneousActionTypes {
  SET_SETTLEMENT_ID = 'SET_SETTLEMENT_ID',
  SET_DISBURSEMENT_START_END_DATE = 'SET_DISBURSEMENT_START_END_DATE',
  SET_REQUEST_PROCESSED_DATE = 'SET_REQUEST_PROCESSED_DATE',
  SET_DISBURSEMENT_SIDE_PANEL_LOADING = 'SET_DISBURSEMENT_SIDE_PANEL_LOADING',
}
export const setDisbursementStartEndDate = (dates: DateRange) => ({
  type: RevenueMiscellaneousActionTypes.SET_DISBURSEMENT_START_END_DATE,
  payload: dates,
})

export const setSettlementId = (settlementId: string) => ({
  type: RevenueMiscellaneousActionTypes.SET_SETTLEMENT_ID,
  payload: settlementId,
})

export const setRequestProcessedAt = (requestedForDatetime: string) => ({
  type: RevenueMiscellaneousActionTypes.SET_REQUEST_PROCESSED_DATE,
  payload: requestedForDatetime,
})
export const setDisbursementSidePanelLoading = (isLoading: boolean) => ({
  type: RevenueMiscellaneousActionTypes.SET_DISBURSEMENT_SIDE_PANEL_LOADING,
  payload: isLoading,
})
export const getAWSDisburesementReport =
  (startDate: string, endDate: string, downloadReport: boolean) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    await dispatch(
      actionTypeWrapper(ReportType.DISBURSEMENT, clearRevenueReports())
    )
    await dispatch(
      actionTypeWrapper(ReportType.DISBURSEMENT, setRevenuePageNumber(1))
    )
    await dispatch(setDisbursementSidePanelLoading(true))
    try {
      const cloudMarketplace = 'AWS'
      const productId =
        getState().productsListing[cloudMarketplace].selectedProduct
      const partnerId = getState().PartnerData.user.partnerData?.partnerId || ''
      //TO DO LT-2930 update default parameters
      const { data } = await getReports({
        partnerId,
        reportType: snakeCase(ReportType.CLOUD_DISBURSEMENT),
        cloudMarketplace,
        finalStartDate: startDate,
        finalEndDate: endDate,
        downloadReport: false,
        pageSize: 1,
        pageNumber: 1,
        sortBy: 'requested_for_datetime',
        sortOrder: 'desc',
        productId,
      })
      const areSettlementsEmpty = isEmpty(data?.rows[0])
      if (!areSettlementsEmpty) {
        await dispatch(
          setSettlementId(
            get(data, 'rows[0].settlement_id', '')
          ) as unknown as AnyAction
        )
        await dispatch(
          setRequestProcessedAt(
            get(data, 'rows[0].requested_for_datetime', '')
          ) as unknown as AnyAction
        )
        await dispatch(
          getRevenueInsights(
            ReportType.DISBURSEMENT,
            downloadReport
          ) as unknown as AnyAction
        )
      }
    } catch (error: any) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
          sidePanelAlert: true,
        })
      )

      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      await dispatch(setDisbursementSidePanelLoading(false))
    }
  }
