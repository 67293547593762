import logger from 'redux-logger'
import { Middleware, Dispatch, Action } from 'redux'

export const getMiddlewares = () => {
  const productionMiddlewares = [] as unknown as Array<
    Middleware<unknown, unknown, Dispatch<Action>>
  >

  if (['production', 'test'].includes(process.env.NODE_ENV || '')) {
    return productionMiddlewares
  } else {
    const devMiddlewares = [logger]
    return productionMiddlewares.concat(...devMiddlewares)
  }
}
