import { useSelector } from '../../../store'
import { ActiveProducts, Product } from '../../modules/partner/action'
import { ProductType } from '../../modules/partner/reducer'
import { useLocation } from 'react-router-dom'
import { TeamMembers } from '../TeamMembers/TeamMembers'
import { useStyles } from './TeamMembersWrapper.styles'

export const TeamMembersWrapper: React.FC<{ open: boolean }> = ({ open }) => {
  const activeProducts = useSelector<ActiveProducts>(
    state => state.PartnerData.user.partnerData?.activeProducts || []
  )
  const currentProduct = useSelector<ProductType | undefined>(
    state => state.PartnerData.user.currentProduct
  )
  const product = currentProduct || activeProducts[0]
  const location = useLocation()

  if (
    !open ||
    (!location.pathname.split('/').includes('onboarding') &&
      !location.pathname.split('/').includes('product-selection')) ||
    !activeProducts.includes(product as Product)
  ) {
    return <></>
  }
  return <TeamMembersComponent />
}

export const TeamMembersComponent = () => {
  const classes = useStyles()
  return (
    <div>
      <div className={classes.divider}></div>
      <TeamMembers />
    </div>
  )
}
