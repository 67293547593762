import { makeStyles, Theme } from '@material-ui/core/styles'
export const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    color: theme.palette.primary.light,
    top: theme.spacing(1),
  },
  actionBtns: {
    backgroundColor: theme.palette.secondary.main,
    justifyContent: 'flex-start',
    padding: theme.spacing(3, 3),
  },
  paper: {
    width: '100%',
  },

  textFields: {
    width: '100%',
    fontSize: '14px',
    marginBottom: theme.spacing(1),
  },

  content: {
    background: theme.palette.secondary.main,
    padding: theme.spacing(3, 3),
  },

  linkBtn: {
    paddingTop: theme.spacing(2),
    color: theme.palette.opaque.dark,
    fontSize: 14,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'inherit',
      textDecoration: 'none',
    },
  },
  linkBtnDisabled: {
    fontSize: 14,
    textTransform: 'none',
    color: theme.palette.primary.light,
    '&:hover': {
      backgroundColor: 'inherit',
      textDecoration: 'none',
    },
    cursor: 'text',
  },
  dialogHeading: {
    padding: theme.spacing(1, 0),
    color: theme.palette.primary.dark,
    fontWeight: 600,
    letterSpacing: '0.9px',
    fontSize: '18px',
  },
  btnCancel: {
    marginLeft: '15px !important',
    padding: theme.spacing(1, 2),
    backgroundColor: `${theme.palette.opaque.light} !important`,
    color: theme.palette.black.main,
    boxShadow: 'none',
    border: '1px solid #ccd0d6',
    fontSize: '12px',
    textTransform: 'none',
  },
  submitBtn: {
    backgroundColor: theme.palette.opaque.main,
    color: theme.palette.secondary.main,
    padding: theme.spacing(1, 5, 1, 5),

    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.opaque.main,
    },
    '&.Mui-disabled': {
      color: theme.palette.primary.light,
      backgroundColor: theme.palette.opaque.light,
      fontWeight: 500,
    },
    borderRadius: theme.spacing(0.5),
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
  },
  timesIcon: {
    marginTop: theme.spacing(3.4),
    marginLeft: theme.spacing(3),

    '&.MuiIconButton-root': {
      padding: theme.spacing(1, 1),
    },
    float: 'right',
  },

  formControl: {
    width: '100%',
    textAlign: 'left',
  },
  select: {
    width: '100%',
    paddingTop: theme.spacing(0.6),
  },
  buttonPrimary: {
    backgroundColor: theme.palette.opaque.main,
    color: theme.palette.secondary.main,
    padding: theme.spacing(1, 2, 1, 2),
    marginLeft: theme.spacing(3),
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.opaque.main,
    },
    ['@media (max-width:960px)']: {
      marginBottom: theme.spacing(1),
    },
  },
  buttonGroup: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    margin: 'auto',
    width: '95%',
    padding: theme.spacing(1),
    ['@media (max-width:960px)']: {
      padding: theme.spacing(0),
    },
    textAlign: 'end',
  },
  paragraph: {
    padding: theme.spacing(2, 0),
    textAlign: 'left',
    color: theme.palette.black.main,
  },
  selectLabel: {
    marginTop: theme.spacing(1),
  },
  divider: {
    backgroundColor: theme.palette.dividers.main,
    height: '2px',
    margin: theme.spacing(3, 0),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: '12px',
    marginTop: theme.spacing(1)
  }
}))
