import { useEffect, useState } from 'react'
import { PartnerData, PartnerType } from '../../modules/partner/action'
import { UserProfile } from '../../modules/userProfile/action'
import { isAdminRole } from '../isAdminRole'
import { useDispatch, useSelector } from '../../../store'
import {
  AuthProvider,
  getUsers,
} from '../../../oppsync/modules/userList/action'
import { UserState } from '../../../oppsync/modules/userList/reducer'
import { useFlagValue } from '@labrav/flags'

export const useGetUsers = (partnerType:PartnerType = PartnerType.User) => {
  const [usersLoading, setUsersLoading] = useState<boolean>(false)
  const { partnerData, user, users } = useSelector<{
    partnerData: PartnerData | undefined
    user: UserProfile
    users: UserState[]
  }>(state => ({
    partnerData: state.PartnerData.user.partnerData || undefined,
    user: state.userProfile.userProfile,
    users: state.userList[partnerType].userList.users,
  }))
  const dispatch = useDispatch()
  const { loading: isFlagsLoading, value: auth0Enabled } = useFlagValue('auth0')
  const loading = Boolean(
    !partnerData || !user || usersLoading || isFlagsLoading
  )
  const isAdmin = isAdminRole(user)
  const authProvider = auth0Enabled ? AuthProvider.AUTH0 : AuthProvider.OKTA
  useEffect(() => {
    if (partnerData?.partnerId && user && !isAdmin && !isFlagsLoading) {
      setUsersLoading(true)
      dispatch(
        getUsers({ partnerId: partnerData?.partnerId || '', authProvider })
      )
      setUsersLoading(false)
    }
  }, [partnerData?.partnerId, user, isAdmin, authProvider, isFlagsLoading])
  return { loading, users }
}
