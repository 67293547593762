import { isEmpty } from 'lodash'
import { UserState } from '../../../../oppsync/modules/userList/reducer'
import { InvitationStatus, Role } from '../../../utils/roles'
import { AvatarName } from '../../TeamMembers/TeamMembers'
import { useStyles } from './InvitedUsers.styles'
import { getRoleName } from '../../../utils/getRoleName'
import {
  ColumnActionWithMenuV2,
  ColumnMenuOption,
  VerticalDivider,
} from '@labrav/react-components'
import { useDispatch, useSelector } from '../../../../store'
import { UserProfileState } from '../../../modules/userProfile/reducer'
import { postResendInvite, resendInvitationIdentityService } from '../../../api'
import { updateAppAlert } from '../../../modules/appAlert/actions'
import {
  InviteUserRequestSuccess,
  RequestFailureMessage,
} from '../../../utils/messagesContants'

import {
  AuthProvider,
  getUsers,
  startUserLoading,
  stopUserLoading,
  updateUser,
} from '../../../../oppsync/modules/userList/action'
import { useFlagValue } from '@labrav/flags'
import { newrelicErrLogger } from '../../../utils/ErrorHandler'
import {
  PartnerData,
  PartnerType,
  getPartner,
} from '../../../modules/partner/action'
import {
  getUserProfile,
  setUserProfile,
} from '../../../modules/userProfile/action'
import { useAuth0 } from '@auth0/auth0-react'

export const InvitedUsers = ({
  invitedUsers,
}: {
  invitedUsers: UserState[]
}) => {
  const classes = useStyles()
  const { loading: isFlagsLoading, value: auth0Enabled } = useFlagValue('auth0')

  const { userProfile } = useSelector<UserProfileState>(
    state => state.userProfile
  )
  const isStaffUser =
    userProfile?.roles && userProfile?.roles[0] === Role.PARTNER_STAFF

  const isAdminUser =
    userProfile?.roles && userProfile?.roles[0] === Role.PARTNER_ADMIN
  const partnerId = useSelector<string | undefined>(
    state => state.PartnerData.user.partnerData?.partnerId
  )
  const dispatch = useDispatch()

  const { user } = useAuth0()

  const { partnerLoading } = useSelector<{
    partnerLoading: boolean
  }>(state => ({
    partnerLoading: state.PartnerData.user.isLoading,
  }))

  const getUser = async () => {
    if (auth0Enabled && user?.partner_id) {
      try {
        dispatch(getPartner(user.partner_id as string, PartnerType.User))
        dispatch(getUserProfile(user.partner_id, user.partner_user_id))
      } catch (err: any) {
        newrelicErrLogger(err)
      }
    }
  }

  const handleResendInvite = async (
    selectedUser: UserState,
    handleClose?: () => void
  ) => {
    if (partnerId) {
      try {
        await dispatch(startUserLoading())
        const resendVerificationEmail = auth0Enabled
          ? resendInvitationIdentityService
          : postResendInvite
        const response = await resendVerificationEmail(
          partnerId,
          selectedUser.id
        )
        if (response.status) {
          dispatch(
            updateAppAlert({
              message: InviteUserRequestSuccess,
              messageType: 'SUCCESS',
              autoClose: true,
              sidePanelAlert: true,
            })
          )
        }
      } catch (error: any) {
        const errMess = error?.response?.data?.message || RequestFailureMessage
        dispatch(
          updateAppAlert({
            message: errMess,
            messageType: 'ERROR',
            autoClose: true,
            sidePanelAlert: true,
          })
        )
        newrelicErrLogger(error as Error, {
          message: error,
        })
      } finally {
        await dispatch(stopUserLoading())
        handleClose && handleClose()
      }
    }
  }

  const getRoleChangeSuccessMsg = (
    selectedUser: UserState,
    fromRole?: Role
  ) => {
    if (!fromRole) {
      return ''
    }
    return `Role of (${selectedUser.email}) changed to ${getRoleName(
      selectedUser.roles[0]
    )} from ${getRoleName(fromRole)}`
  }
  const changeRole = async (
    selectedUser: UserState,
    handleClose?: () => void,
    fromRole?: Role
  ) => {
    if (partnerId) {
      const adminToStaff =
        isAdminUser &&
        selectedUser.id === userProfile.id &&
        selectedUser.roles[0] === Role.PARTNER_STAFF
      const successMsg = getRoleChangeSuccessMsg(selectedUser, fromRole)
      const response = await dispatch(
        updateUser(
          partnerId,
          selectedUser.id,
          { ...selectedUser },
          auth0Enabled ? AuthProvider.AUTH0 : AuthProvider.OKTA,
          successMsg
        )
      )
      if (response && response.success) {
        handleClose && handleClose()
        if (adminToStaff) {
          getUser()
        }
        await dispatch(
          getUsers({
            partnerId: partnerId || '',
            pageNumber: 1,
            pageSize: 500,
            authProvider: auth0Enabled ? AuthProvider.AUTH0 : AuthProvider.OKTA,
          })
        )
      }
    }
  }

  const ownerUsers = (invitedUsers || []).filter(
    userItem => userItem.roles[0] === Role.PARTNER_OWNER
  )
  const otherUsers = (invitedUsers || [])
    .filter(userItem => userItem.roles[0] !== Role.PARTNER_OWNER)
    .sort(function (a, b) {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    })

  return (
    <div className={classes.root}>
      {(ownerUsers || []).map((userItem, index) => {
        return <InvitedUserRow index={index} userItem={userItem} />
      })}
      {(otherUsers || []).map((userItem, index) => {
        return (
          <InvitedUserRow
            index={index}
            userItem={userItem}
            isStaffUser={isStaffUser}
            handleResendInvite={handleResendInvite}
            changeRole={changeRole}
          />
        )
      })}
    </div>
  )
}

const InvitedUserRow = ({
  userItem,
  index,
  isStaffUser,
  handleResendInvite,
  changeRole,
}: {
  userItem: UserState
  index: number
  isStaffUser?: boolean
  handleResendInvite?: (user: UserState, handleClose?: () => void) => void
  changeRole?: (
    user: UserState,
    handleClose?: () => void,
    fromRole?: Role
  ) => void
}) => {
  const classes = useStyles()

  const handleSelectOption = (
    option: ColumnMenuOption,
    userItem: UserState,
    handleClose?: () => void
  ) => {
    if (option.key === 'resend-invite') {
      handleResendInvite && handleResendInvite(userItem, handleClose)
    } else if (option.key === 'admin-to-staff') {
      changeRole &&
        changeRole(
          {
            ...userItem,
            roles: [Role.PARTNER_STAFF],
          },
          handleClose,
          userItem.roles[0]
        )
    } else if (option.key === 'staff-to-admin') {
      changeRole &&
        changeRole(
          {
            ...userItem,
            roles: [Role.PARTNER_ADMIN],
          },
          handleClose,
          userItem.roles[0]
        )
    }
  }

  return (
    <div className={classes.listRow} data-testid={'list-row-item'}>
      <div className={classes.emailSection}>
        {!isEmpty((userItem?.name || '').trim()) ? (
          <AvatarName index={index} user={userItem} isAdmin={false} />
        ) : (
          <EmptyNameAvatar />
        )}
        <span className={classes.email}>{userItem.email}</span>

        {userItem.invitationStatus === InvitationStatus.INVITED ? (
          <span className={classes.pending}>Pending acceptance</span>
        ) : (
          <></>
        )}
      </div>
      <div className={classes.actionPart}>
        <div>{getRoleName(userItem.roles[0])}</div>
        <VerticalDivider height="24px" />
        <ColumnActionWithMenuV2
          menuOptions={[
            {
              key:
                getRoleName(userItem.roles[0]) === 'Admin'
                  ? 'admin-to-staff'
                  : 'staff-to-admin',
              label:
                getRoleName(userItem.roles[0]) === 'Admin'
                  ? 'Make staff'
                  : 'Make admin',
            },
            ...(userItem.invitationStatus === InvitationStatus.INVITED
              ? [
                  {
                    key: 'resend-invite',
                    label: 'Resend invite',
                  },
                ]
              : []),
          ]}
          handleSelectOption={(option, handleClose) =>
            handleSelectOption(option, userItem, handleClose)
          }
          key={'action'}
          zIndex={100000}
          disable={!!isStaffUser || getRoleName(userItem.roles[0]) === 'Owner'}
          disabledActionTooltip={
            !!isStaffUser
              ? 'Your role does not permit you to take this action.'
              : ''
          }
        />
      </div>
    </div>
  )
}

export const EmptyNameAvatar = () => {
  const classes = useStyles()
  return <div className={classes.emptyName} data-testid={'empty-avatar'}></div>
}
