import { configureStore } from '@reduxjs/toolkit'
import {
  TypedUseSelectorHook,
  useDispatch as useDispatchLegacy,
  useSelector as useSelectorLegacy,
} from 'react-redux'
import { rootReducer } from './reducer'
import { getMiddlewares } from './middlewares'
export const getStore = () =>
  configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      }).concat(...getMiddlewares()),
  })

export const store = getStore()
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useDispatch: () => AppDispatch = useDispatchLegacy
export const useSelector: TypedUseSelectorHook<RootState> = useSelectorLegacy
