import { Reducer } from 'redux'

import { CrmTableActions } from './action'

export interface CRMTables {
  [x: string]: string
}

export interface CrmTableState {
  tables: CRMTables
}

export type CrmTableAction =
  | {
      type: CrmTableActions.SET_CRM_TABLES
      payload: CRMTables
    }
  | {
      type: CrmTableActions.CLEAN_UP_CRM_TABLES
    }

export const initialState = {
  tables: {},
}

export const reducer: Reducer<CrmTableState, CrmTableAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case CrmTableActions.SET_CRM_TABLES: {
      return {
        ...state,
        tables: action.payload,
      }
    }
    case CrmTableActions.CLEAN_UP_CRM_TABLES: {
      return initialState
    }

    default:
      return state
  }
}
