import { SlackChannelsActionTypes, SlackChannelsData } from './action'
export interface SlackChannelsDataState {
  slackChannelsData: null | SlackChannelsData[]
  activeChannelId: string
}
export const initialState: SlackChannelsDataState = {
  slackChannelsData: null,
  activeChannelId: '',
}

type Action =
  | {
      type: SlackChannelsActionTypes.SET_SLACK_CHANNELS
      payload: SlackChannelsData[]
    }
  | { type: SlackChannelsActionTypes.SET_SLACK_ACTIVE_CHANNEL; payload: string }
  | {
      type: SlackChannelsActionTypes.CLEAR_SLACK_CHANNEL
    }

export const reducer = (
  state: SlackChannelsDataState = initialState,
  action: Action
) => {
  switch (action.type) {
    case SlackChannelsActionTypes.SET_SLACK_CHANNELS: {
      return {
        ...action.payload,
        slackChannelsData: action.payload,
      }
    }

    case SlackChannelsActionTypes.SET_SLACK_ACTIVE_CHANNEL:
      return { ...state, activeChannelId: action.payload }

    case SlackChannelsActionTypes.CLEAR_SLACK_CHANNEL:
      return { ...initialState }

    default:
      return state
  }
}
