import { Reducer } from 'redux'
import { FreeTrial } from '../productsListing/reducer'
import { FreeTrialsActionTypes } from './actions'

export interface ProductDimensionFreeTrial {
  productDimensionId?: string
  freeTrial?: FreeTrial
}
export enum FileUploadType {
  EULA_FILES = 'EULA_FILES',
  PRODUCT_LOGO = 'PRODUCT_LOGO',
}
export enum FreeTrialStatus {
  FREE_TRIAL_CREATION_IN_PROGRESS = 'FREE_TRIAL_CREATION_IN_PROGRESS',
  FREE_TRIAL_CREATED = 'FREE_TRIAL_CREATED',
  FREE_TRIAL_DEACTIVATION_IN_PROGRESS = 'FREE_TRIAL_DEACTIVATION_IN_PROGRESS',
  FREE_TRIAL_DEACTIVATED = 'FREE_TRIAL_DEACTIVATED',
}
export interface FreeTrials {
  productId: string
  freeTrial: FreeTrial
  eulaType?: string
  eulaFile?: string[]
  selectedEulaFiles?: File[]
  productDimensions: ProductDimensionFreeTrial[]
  onReview?: boolean
}
export interface FreeTrialsState {
  [x: string]: FreeTrials
}
export const initialState: FreeTrialsState = {}

type actionType =
  | {
      type: FreeTrialsActionTypes.UPDATE_FREE_TRIALS_DIMENSION_ROW_QUANTITY
      payload: {
        productId: string
        index: number
        quantity: number
      }
    }
  | {
      type: FreeTrialsActionTypes.ADD_FREE_TRIALS_DIMENSION_ROW
      payload: string
    }
  | {
      type: FreeTrialsActionTypes.REMOVE_FREE_TRIALS_DIMENSION_ROW
      payload: { productId: string; index: number }
    }
  | {
      type: FreeTrialsActionTypes.UPDATE_FREE_TRIALS_DIMENSION_ROW_ID
      payload: { productId: string; index: number; id: string }
    }
  | {
      type: FreeTrialsActionTypes.UPDATE_FREE_TRIALS_NUMBER_OF_DAYS
      payload: { productId: string; numberOfDays: number }
    }
  | {
      type: FreeTrialsActionTypes.ADD_FREE_TRIALS_PRODUCT
      payload: string
    }
  | {
      type: FreeTrialsActionTypes.UPDATE_EULA_TYPE
      payload: { productId: string; eulaType: string }
    }
  | {
      type: FreeTrialsActionTypes.ADD_EULA_FILES
      payload: { productId: string; eulaFiles: string[] }
    }
  | {
      type: FreeTrialsActionTypes.UPDATE_ON_REVIEW
      payload: { productId: string; onReview: boolean }
    }
export const reducer: Reducer<FreeTrialsState, actionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case FreeTrialsActionTypes.UPDATE_FREE_TRIALS_DIMENSION_ROW_QUANTITY: {
      const { productId, index, quantity } = action.payload
      const productDimensions = state[productId].productDimensions.map(
        (dimension, i) => {
          if (i === index) {
            return {
              ...dimension,
              freeTrial: {
                enabled:
                  !isNaN(quantity) && Number(quantity) !== 0 ? true : false,
                quantity,
              },
            }
          }
          return dimension
        }
      )
      return {
        ...state,
        [productId]: {
          ...state[productId],
          productDimensions,
        },
      }
    }
    case FreeTrialsActionTypes.ADD_FREE_TRIALS_DIMENSION_ROW: {
      const productId = action.payload
      const product = state[action.payload]
      const productDimensions = [...product.productDimensions]
      productDimensions.push({} as ProductDimensionFreeTrial)
      return {
        ...state,
        [productId]: {
          ...state[productId],
          productDimensions,
        },
      }
    }
    case FreeTrialsActionTypes.REMOVE_FREE_TRIALS_DIMENSION_ROW: {
      const { productId, index } = action.payload
      const productDimensions = [...state[productId].productDimensions]
      productDimensions.splice(index, 1)
      return {
        ...state,
        [productId]: {
          ...state[productId],
          productDimensions,
        },
      }
    }
    case FreeTrialsActionTypes.UPDATE_FREE_TRIALS_DIMENSION_ROW_ID: {
      const { productId, index, id } = action.payload
      const productDimensions = state[productId].productDimensions.map(
        (dimension, i) => {
          if (i === index) {
            return {
              ...dimension,
              productDimensionId: id,
            }
          }
          return dimension
        }
      )
      return {
        ...state,
        [productId]: {
          ...state[productId],
          productDimensions,
        },
      }
    }
    case FreeTrialsActionTypes.UPDATE_FREE_TRIALS_NUMBER_OF_DAYS: {
      const { productId, numberOfDays } = action.payload
      return {
        ...state,
        [productId]: {
          ...state[productId],
          freeTrial: {
            ...state[productId].freeTrial,
            freeTrialExpiresInDays: numberOfDays,
          },
        },
      }
    }
    case FreeTrialsActionTypes.ADD_FREE_TRIALS_PRODUCT: {
      const productId = action.payload
      return {
        ...state,
        [productId]: {
          productId,
          freeTrial: {
            enabled: true,
          },
          productDimensions: [],
        },
      }
    }
    case FreeTrialsActionTypes.UPDATE_EULA_TYPE: {
      const { productId, eulaType } = action.payload
      return {
        ...state,
        [productId]: {
          ...state[productId],
          eulaType,
        },
      }
    }
    case FreeTrialsActionTypes.ADD_EULA_FILES: {
      const { productId, eulaFiles } = action.payload

      return {
        ...state,
        [productId]: {
          ...state[productId],
          eulaFile: eulaFiles,
        },
      }
    }
    case FreeTrialsActionTypes.UPDATE_ON_REVIEW: {
      const { productId, onReview } = action.payload

      return {
        ...state,
        [productId]: {
          ...state[productId],
          onReview: onReview,
        },
      }
    }
    default: {
      return state
    }
  }
}
