import { API } from '../../../common/api'
import { crmServiceUrl } from '../../../common/utils/constants'

export const fetchReferralDetails = (
  crmPrimaryKeyValue: string,
  crmAccountId: string,
  crmUserId: string
) => {
  return API.get(`${crmServiceUrl}/hs_accounts/${crmAccountId}/referrals`, {
    params: {
      crm_primary_key_value: crmPrimaryKeyValue,
      crm_user_id: crmUserId,
    },
  })
}

export const fetchReferralFields = (
  crmUserId: string,
  referralType: 'Labra__Labra_Referral__c' | 'Labra__Labra_Lead__c'
) => {
  return API.get(
    `${crmServiceUrl}/crms/${crmUserId}/tables/${referralType}/fields`
  )
}
