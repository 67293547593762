import { omit } from 'lodash'
import { awsLeadFields } from './v13/awsLeadFields'
import { awsOppFields } from './v13/awsOppFields'
import { categories as categoriesV13 } from './v13/categories'
import { awsOppFields as awsOppFieldsV14 } from './v14/awsOppFields'
import { categories as categoriesV14 } from './v14/categories'

export const awsFields = {
  v13: {
    lead: {
      form_fields: awsLeadFields,
    },
    opportunity: {
      form_fields: awsOppFields,
    },
  },
  v14: {
    lead: {
      form_fields: awsLeadFields,
    },
    opportunity: {
      form_fields: awsOppFieldsV14,
    },
  },
}

export const getCategories = (
  defaultReferralType: string,
  referralStatus: string,
  aceVersion: 'v13' | 'v14'
) => {
  switch (aceVersion) {
    case 'v13':
      if (defaultReferralType === 'lead') {
        return omit(categoriesV13, 'Sale Related Details')
      }

      if (referralStatus === 'draft') {
        return categoriesV13
      }

      return Object.assign(categoriesV13, additionalCategoriesForUpdates)
    case 'v14':
      if (defaultReferralType === 'lead') {
        // ace v14 lead is same as v13
        return omit(categoriesV13, 'Sale Related Details')
      }

      if (referralStatus === 'draft') {
        return categoriesV14
      }

      return Object.assign(categoriesV14, additionalCategoriesForUpdates)
    default:
      return {}
  }
}

export const usaStates = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Dist. of Columbia',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Ontario',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
  'AFO/FPO',
  'FPO, AP',
]
export const stateDefaultOptions = [
  'Ahmedabad',
  'Alabama',
  'Alaska',
  'Alberta',
  'American Samoa',
  'Andaman & Nicobar',
  'Andrapradesh',
  'Anhui',
  'Arizona',
  'Arkansas',
  'Arunachal Pradesh',
  'Assam',
  'Australian Capital Territory',
  'Bangalore',
  'Baroda',
  'Beijing',
  'Bihar',
  'British Columbia',
  'California',
  'Chattisgargh',
  'Chennai',
  'Chongqing',
  'Cochin',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Delhi',
  'Dist. of Columbia',
  'Federated States of Micronesia',
  'Florida',
  'Fujian',
  'Gansu',
  'Georgia',
  'Goa',
  'Guam',
  'Guangdong',
  'Guangxi',
  'Guizhou',
  'Gujarat',
  'Hainan',
  'Haryana',
  'Hawaii',
  'Hebei',
  'Heilongjiang',
  'Henan',
  'Himachal Pardesh',
  'Hong Kong Island',
  'Hubei',
  'Hunan',
  'Hyderabad',
  'Idaho',
  'Illinois',
  'Indiana',
  'Inner Mong',
  'Iowa',
  'Jiangsu',
  'Jiangxi',
  'Jilin',
  'Kansas',
  'Karnataka',
  'Kentucky',
  'Kerala',
  'Kolkata',
  'Kowloon',
  'Lantau Island',
  'Liaoning',
  'Louisiana',
  'Madhya Pradesh',
  'Maharashtra',
  'Maine',
  'Manipur',
  'Manitoba',
  'Marshall Islands',
  'Maryland',
  'Massachusetts',
  'Meghalaya',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Mizoram',
  'Montana',
  'Mumbai',
  'Mysore',
  'Nagaland',
  'Navi Mumbai',
  'Nebraska',
  'NeiMongol',
  'Nevada',
  'New Brunswick',
  'Newfoundland and Labrador',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New South Wales',
  'New Territories',
  'New York',
  'Ningxia',
  'North Carolina',
  'North Dakota',
  'Northern Mariana Islands',
  'Northern Territory',
  'Northwest Territories',
  'Nova Scotia',
  'Nunavut',
  'Ohio',
  'Oklahoma',
  'Ontario',
  'Oregon',
  'Orrisa',
  'Palau',
  'Pennsylvania',
  'Pondicherry',
  'Prince Edward Island',
  'Puerto Rico',
  'Pune',
  'Punjab',
  'Qinghai',
  'Quebec',
  'Queensland',
  'Rajasthan',
  'Rhode Island',
  'Saskatchewan',
  'Secunderabad',
  'Shaanxi',
  'Shandong',
  'Shanghai',
  'Shanxi',
  'Sichuan',
  'Singapore',
  'South Australia',
  'South Carolina',
  'South Dakota',
  'Surat',
  'Tamil Nadu',
  'Tasmania',
  'Tennessee',
  'Texas',
  'Thiruvananthapuram',
  'Tianjin',
  'Tibet',
  'Tripura',
  'Utah',
  'Uttar Pradesh',
  'Uttrakhand',
  'Vermont',
  'Victoria',
  'Virginia',
  'Virgin Islands',
  'Washington',
  'West Bangal',
  'Western Australia',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
  'Xinjiang',
  'Xizang',
  'Yukon',
  'Yunnan',
  'Zhejiang',
  'APO/AE',
  'AFO/FPO',
  'FPO, AP',
]

export const additionalCategoriesForUpdates = {
  'SaaS Documentation Details': {
    expanded: false,
    fields: [
      'Labra__procurementType__c',
      'Labra__customerSoftwareValueCurrency__c',
      'Labra__customerSoftwareValue__c',
      'Labra__contractStartDate__c',
      'Labra__contractEndDate__c',
    ],
  },
  'AWS Contact Details': {
    expanded: false,
    fields: [
      'Labra__ISV_Manager_Email__c',
      'Labra__ISV_Manager_Name__c',
      'Labra__AWS_Account_Owner_Email__c',
      'Labra__AWS_Account_Owner_Name__c',
      'Labra__Success_Manager_Email__c',
      'Labra__Success_Manager_Name__c',
      'Labra__AWS_Sales_Rep_Email__c',
      'Labra__AWS_Sales_Rep_Name__c',
      'Labra__PDM__c',
      'Labra__PDM_Email__c',
      'Labra__Other_PDM__c',
      'Labra__Other_PDM_Email__c',
    ],
  },
}

export const uncategorized = {
  isHidden: true,
  expanded: false,
  fields: [],
}
