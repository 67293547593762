import { DateTime } from 'luxon'

export type LocalStorageOptions = {
  ttlInSeconds: number
}

export type RawValue = {
  value: unknown
  validUntil: string // Date ISO String
}

const throwIfLocalStorageNotAvailable = () => {
  if (typeof window.localStorage === 'undefined') {
    console.error('local storage is not avalable')
    throw Error('local storage not available')
  }
}

export const setItem = async (
  key: string,
  value: unknown,
  options?: LocalStorageOptions
) => {
  throwIfLocalStorageNotAvailable()
  let stringValue: string
  if (options && options.ttlInSeconds) {
    const validUntil = DateTime.now().plus({ second: options.ttlInSeconds })
    stringValue = JSON.stringify({ value, validUntil: validUntil.toISO() })
  } else {
    stringValue = JSON.stringify({ value })
  }

  return localStorage.setItem(key, stringValue)
}

export const getItem = async (key: string) => {
  throwIfLocalStorageNotAvailable()
  const stringValue = await localStorage.getItem(key)
  if (!stringValue) {
    return undefined
  }
  const rawValue = JSON.parse(stringValue) as RawValue
  if (
    rawValue.validUntil &&
    DateTime.now() > DateTime.fromISO(rawValue.validUntil)
  ) {
    // it is expired
    localStorage.removeItem(key)
    return undefined
  }
  return rawValue.value
}
