import React, { useEffect } from 'react'

import { DateAndTime } from '../../../../common/components/DateAndTime/DateAndTime'
import { useDispatch, useSelector } from '../../../../store'
import { UserListState, UserState } from '../../../modules/userList/reducer'
import {
  AuthProvider,
  getUsers,
  setUser,
  setUsersPageNumber,
  setUsersPageSize,
} from '../../../modules/userList/action'
import { UserStatusMapping } from '../../../../common/utils/statusMapping'
import { Role, UserRoleForTable } from '../../../../common/utils/roles'
import { UserSidePanel } from '../../../components/UserSidePanel/UserSidePanel'
import { checkRolePermissions } from '../../../../common/utils/helperFunctions'
import { AddUserFormComponent } from '../../../../common/components/AddUserForm/AddUserForm'
import { TableComponent } from '@labrav/react-components'
import { useStyles } from './UserAndPermission.styles'
import { useFlagValue } from '@labrav/flags'
import { useUserType } from '../../../../common/utils/Hooks/usePartnerData'

const noPermissionToClick = (
  users: UserState[],
  userId: string,
  oktaUserId: string,
  role: string
) => {
  if (
    users.some(user => user.id === userId && user.idpUserId === oktaUserId) ||
    role == Role.PARTNER_STAFF ||
    (role == Role.PARTNER_ADMIN &&
      users.some(
        user => user.id === userId && user.roles[0] === Role.PARTNER_OWNER
      ))
  ) {
    return true
  }
}

export const UserAndPermission = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { loading: isFlagsLoading, value: auth0Enabled } = useFlagValue('auth0')
  const partnerType = useUserType()
  const partnerId = useSelector<string | undefined>(
    state => state.PartnerData[partnerType].partnerData?.partnerId
  )
  const oktaUserId = useSelector<string | undefined>(
    state => state.userProfile.userProfile.idpUserId
  ) as string
  const userRoles = useSelector<Role[]>(
    state => state.userProfile.userProfile?.roles ?? []
  )
  const { userList, noOfPage, pageNumber, pageSize } =
    useSelector<UserListState>(state => state.userList[partnerType])
  const { users, count } = userList || { users: [], count: 0 }

  const mapping = {
    Name: 'name',
    Email: 'email',
    Role: 'role',
    Status: 'status',
    'Last active date': 'lastActivity',
  }
  useEffect(() => {
    if (partnerId && !isFlagsLoading)
      dispatch(
        getUsers(
          {
            partnerId,
            pageNumber,
            pageSize,
            authProvider: auth0Enabled ? AuthProvider.AUTH0 : AuthProvider.OKTA,
          },
          partnerType
        )
      )
  }, [partnerId, pageNumber, pageSize, auth0Enabled, isFlagsLoading])

  const newUserObject = users?.map((user: UserState) => ({
    ...user,
    name: !user?.name || user?.name.trim() === '' ? '-' : user.name,
    roles: null,
    role: UserRoleForTable[user?.roles[0] as never],
    email: user.email,
    status: UserStatusMapping[user.status] as never,
    lastActivity: user.lastActivity ? (
      <DateAndTime key={user.id as string} date={user.lastActivity as string} />
    ) : (
      '-'
    ),
  }))

  const handleChangeRows = (
    e: React.ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>
  ) => {
    const pageSize = Number(e.target.value)
    dispatch(setUsersPageSize(pageSize, partnerType))
  }

  const handlePageChange = (
    e: React.ChangeEvent<unknown>,
    pageNumber: number
  ) => {
    dispatch(setUsersPageNumber(pageNumber, partnerType))
  }
  const role = checkRolePermissions(userRoles)

  const openUserSidePanel = (userId: string) => {
    if (noPermissionToClick(users, userId, oktaUserId, role)) {
      return
    }
    dispatch(setUser(userId, partnerType))
  }

  return (
    <>
      <div className={classes.container}>
        <div className={classes.heading}>
          <p className={classes.heading}>{'Total users'}</p>
          <p className={classes.count}>{count || 0}</p>
        </div>

        <div className="">
          {userRoles.includes(Role.PARTNER_STAFF) ? undefined : (
            <AddUserFormComponent />
          )}
        </div>
      </div>
      <TableComponent
        mapping={mapping}
        objects={newUserObject}
        component="div"
        count={noOfPage || 0}
        pageNumber={pageNumber}
        rowsPerPage={pageSize}
        rowsPerPageOptions={[10, 20, 30]}
        onChange={handlePageChange}
        onChangeRowsPerPage={handleChangeRows}
        onClick={openUserSidePanel}
        noHover={role == Role.PARTNER_STAFF ? true : false}
        actions={
          userRoles.includes(Role.PARTNER_STAFF)
            ? undefined
            : AddUserFormComponent
        }
      />
      <UserSidePanel />
    </>
  )
}
