import { Dispatch } from 'redux'
import { AxiosError, AxiosResponse } from 'axios'
import { camelize } from 'casing'
import { IntegrationValue } from './reducer'
import { fetchThirdPartyAppList } from '../../api/notifcationAPI'
import { RequestFailureMessage } from '../../utils/messagesContants'
import { startLoading, stopLoading } from '../loading/actions'
import { updateAppAlert } from '../appAlert/actions'
import { LoadingTypes } from '../loading/reducer'
import { errorLogger } from '../../utils/errorLogger'
import { RootState, AppDispatch } from '../../../store'
import { getErrorMessages } from '../../utils/error'

export enum IntegrationsType {
  SET_INTEGRATIONS = 'SET_INTEGRATIONS',
}

export const getThirdPartyAppList =
  (partnerId: string) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(startLoading(LoadingTypes.GENERAL))
      const { data } = await fetchThirdPartyAppList(partnerId)
      dispatch(setIntegrations(camelize(data.integrations)))
    } catch (err: unknown) {
      const errMess = getErrorMessages([RequestFailureMessage])(
        err as AxiosResponse<ErrorResponse>
      )
      const globalState = getState()
      errorLogger({ globalState })(err as Error)

      dispatch(
        updateAppAlert({
          message: errMess,
          messageType: 'ERROR',
          autoClose: true,
        })
      )
    } finally {
      dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }

export const setIntegrations = (
  integrationsObj: Record<string, IntegrationValue>
) => ({
  type: IntegrationsType.SET_INTEGRATIONS,
  payload: { integrationsObj: camelize(integrationsObj) },
})
