import { ProductCardProps } from './ProductCard'
import { useStyles } from './LabraProductCard.styles'
import { Product } from '../../modules/partner/action'
import { ButtonV2 } from '@labrav/react-components'
import LabraTitle from '../../../../src/common/images/LabraTitle.png'
import CheckIcon from '@material-ui/icons/Check'

export type ProductCardUIProps = ProductCardProps & {
  activeProducts: Product[] | string[]
  selectedProduct?: string
}

export const LabraProductCardUI: React.FC<ProductCardUIProps> = ({
  product,
  handleCloudProviderClick,
  selectedProduct,
  activeProducts,
}) => {
  const {
    id,
    productName,
    productFullForm,
    productDescription,
    productAvailable,
    headerStripeColor,
  } = product
  const classes = useStyles({
    headerStripeColor,
    selected: selectedProduct === id,
    active: activeProducts.includes(id),
  })()

  const isProductSelected = selectedProduct === id
  const isProductActive = activeProducts.includes(id)

  return (
    <>
      <div className={classes.body} data-testid="card">
        <div className={classes.root} data-testid={`${productName}-card`}>
          <div className={classes.container}>
            <img src={LabraTitle} className={classes.imgTitle} />
            <div
              className={classes.heading}
              data-testid={`${productName}-heading`}
            >
              {productName}
            </div>
            <div
              className={classes.subheading}
              data-testid={`${productName}-subheading`}
            >
              {productFullForm}
            </div>
            <div
              className={classes.description}
              data-testid={`${productName}-description`}
            >
              {productDescription}
            </div>
          </div>

          <div data-testid="actions" className={classes.actions}>
            {(isProductSelected && productAvailable) ||
            (isProductActive && productAvailable) ? (
              <div className={classes.checked}>
                <CheckIcon
                  className={classes.checkIcon}
                  data-testid={`${productName}-check-circle`}
                />
              </div>
            ) : (
              <ButtonV2
                data-testid={`${productName}-product-card-button`}
                variant="contained"
                styleType={'primary'}
                disableElevation
                disabled={!productAvailable}
                buttonWeight={148}
                onClick={handleCloudProviderClick}
              >
                {productAvailable ? 'Select' : 'Coming soon'}
              </ButtonV2>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
