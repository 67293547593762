import { AnyAction } from 'redux'
import {
  startLoading,
  stopLoading,
} from '../../../common/modules/loading/actions'
import { LoadingTypes } from '../../../common/modules/loading/reducer'
import { updateAppAlert } from '../../../common/modules/appAlert/actions'
import { RequestFailureMessage } from '../../../common/utils/messagesContants'
import { AxiosResponse } from 'axios'
import { camelize } from 'casing'
import { getReports } from '../../api/markeplace'
import { getErrorMessages } from '../../../common/utils/error'
import { DateRange, ReportsPayload, ReportType } from './reducer'
import { actionTypeWrapper } from '../../../common/utils/actionTypeWrapper'
import { AppDispatch, RootState } from '../../../store'
import { setDisbursementSidePanelLoading } from './revenueInsightsDisbursementPanel/actions'
import { snakeCase } from 'lodash'
import { errorLogger } from '../../../common/utils/errorLogger'
import FileDownload from 'js-file-download'
import { useCurrentCloudProvider } from '../../../common/utils/Hooks/useCurrentCloudProvider'
export enum RevenueTrackingActionTypes {
  SET_REPORTS_DATA = 'SET_REPORTS_DATA',
  REVENUE_SET_PAGE_NUMBER = 'REVENUE_SET_PAGE_NUMBER',
  REVENUE_SET_LIMIT = 'REVENUE_SET_LIMIT',
  SET_PRODUCT_ID = 'SET_PRODUCT_ID',
  CLEAR_REVENUE_REPORTS = 'CLEAR_REVENUE_REPORTS',
  SET_DATE_RANGE = 'SET_DATE_RANGE',
}
export type GetRevenueInsightsReport = {
  partnerId: string
  reportType: string
  cloudMarketplace: string
  finalStartDate: string
  finalEndDate: string
  downloadReport: boolean
  pageSize: number
  pageNumber: number
  productId?: string
  sortBy?: string
  sortOrder?: string
}
export const getRevenueInsights =
  (reportType: ReportType, downloadReport: boolean) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    if (reportType !== ReportType.DISBURSEMENT) {
      await dispatch(startLoading(LoadingTypes.GENERAL))
    } else {
      await dispatch(setDisbursementSidePanelLoading(true))
    }
    if (!downloadReport) {
      await dispatch(actionTypeWrapper(reportType, clearRevenueReports()))
    }
    try {
      const dateRange = getState().revenueInsights[reportType].dateRange
      //TODO:LT-5307 When Azure enable for revenue then we have to do dynamic
      const cloudMarketplace = 'AWS' //getState().productListingFilters.cloudMarketplace
      const productId =
        getState().productsListing[cloudMarketplace].selectedProduct || ''
      const partnerId = getState().PartnerData.user.partnerData?.partnerId || ''
      const { pageSize, pageNumber } = getState().revenueInsights[reportType]
      const finalStartDate =
        reportType === ReportType.DISBURSEMENT
          ? getState().revenueDisbursementSidePanel.requestedForDatetime
          : dateRange.startDate
      const finalEndDate =
        reportType === ReportType.DISBURSEMENT
          ? getState().revenueDisbursementSidePanel.requestedForDatetime
          : dateRange.endDate
      if (!downloadReport) {
        //TO DO LT-2930 update default parameters
        const { data } = await getReports({
          partnerId,
          reportType: snakeCase(reportType),
          cloudMarketplace,
          finalStartDate,
          finalEndDate,
          downloadReport,
          pageSize,
          pageNumber,
          sortBy: 'requested_for_datetime',
          sortOrder: 'desc',
          productId,
        })
        await dispatch(
          actionTypeWrapper(
            reportType,
            setReportsData(camelize(data), pageSize)
          )
        )
      } else {
        await downloadCSVForRevenueInsights({
          partnerId,
          reportType,
          cloudMarketplace,
          finalStartDate,
          finalEndDate,
          downloadReport,
          pageSize,
          pageNumber,
          productId,
        })
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
          sidePanelAlert: reportType === ReportType.DISBURSEMENT ? true : false,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      if (reportType !== ReportType.DISBURSEMENT) {
        dispatch(stopLoading(LoadingTypes.GENERAL))
      } else {
        await dispatch(setDisbursementSidePanelLoading(false))
      }
    }
  }

export const downloadCSVForRevenueInsights = async ({
  partnerId,
  reportType,
  cloudMarketplace,
  finalStartDate,
  finalEndDate,
  downloadReport,
  productId,
}: GetRevenueInsightsReport) => {
  //TO DO LT-2930 update default parameters
  return getReports({
    partnerId,
    reportType: snakeCase(reportType),
    cloudMarketplace,
    finalStartDate,
    finalEndDate,
    downloadReport,
    pageNumber: 0,
    pageSize: 0,
    sortBy: 'requested_for_datetime',
    sortOrder: 'desc',
    productId,
  }).then(response => {
    FileDownload(
      response.data,
      `${snakeCase(reportType)}_${finalStartDate}_${finalEndDate}.csv`
    )
  })
}
export const setReportsData = (data: ReportsPayload, pageSize: number) => ({
  type: RevenueTrackingActionTypes.SET_REPORTS_DATA,
  payload: { ...data, pageSize },
})

export const updateRevenuePageSize =
  (reportType: ReportType, pageSize: number) =>
  async (dispatch: AppDispatch) => {
    await dispatch(actionTypeWrapper(reportType, setRevenuePageSize(pageSize)))
    await dispatch(
      getRevenueInsights(reportType, false) as unknown as AnyAction
    )
  }

export const setRevenuePageSize = (pageSize: number) => ({
  type: RevenueTrackingActionTypes.REVENUE_SET_LIMIT,
  payload: pageSize,
})

export const setRevenuePageNumber = (pageNumber: number) => ({
  type: RevenueTrackingActionTypes.REVENUE_SET_PAGE_NUMBER,
  payload: pageNumber,
})

export const clearRevenueReports = () => ({
  type: RevenueTrackingActionTypes.CLEAR_REVENUE_REPORTS,
})

export const clearAllRevenueData = () => async (dispatch: AppDispatch) => {
  await dispatch(actionTypeWrapper('daily', clearRevenueReports()))
  await dispatch(actionTypeWrapper('monthly', clearRevenueReports()))
  await dispatch(actionTypeWrapper('cloudDisbursement', clearRevenueReports()))
}

export const setPageOneForRevenue = () => async (dispatch: AppDispatch) => {
  await dispatch(actionTypeWrapper('daily', setRevenuePageNumber(1)))
  await dispatch(actionTypeWrapper('monthly', setRevenuePageNumber(1)))
  await dispatch(
    actionTypeWrapper('cloudDisbursement', setRevenuePageNumber(1))
  )
}

export const updateDateRange =
  (dates: DateRange, reportType: ReportType) =>
  async (dispatch: AppDispatch) => {
    const { startDate, endDate } = dates
    await dispatch(
      actionTypeWrapper(reportType, setDateRange({ startDate, endDate }))
    )
    await dispatch(actionTypeWrapper(reportType, clearRevenueReports()))
    await dispatch(actionTypeWrapper(reportType, setRevenuePageNumber(1)))
  }
export const setDateRange = (dates: DateRange) => ({
  type: RevenueTrackingActionTypes.SET_DATE_RANGE,
  payload: dates,
})
