import { makeStyles, Theme } from '@material-ui/core/styles'

type styles = {
  headerStripeColor: string
  selected: boolean
  active: boolean
}

export const useStyles = (stylesObj: styles) =>
  makeStyles((theme: Theme) => ({
    body: {
      display: 'flex',
      flexDirection: 'column',
      maxHeight: '382px',
      maxWidth: '338px',
      padding: theme.spacing(1),
    },

    root: {
      display: 'flex',
      padding: theme.spacing(5),
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: ' 39px',
      flex: '1 0 0',
      borderRadius: '8px',
      background:
        stylesObj.selected || stylesObj.active
          ? '#FFFCF0'
          : theme.palette.bgPrimary.main,
      boxShadow:
        stylesObj.selected || stylesObj.active
          ? '0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15)'
          : 'none',
      textAlign: 'left',
    },
    container: {},
    stripe: {
      flex: '0 1 9px',
      backgroundColor: stylesObj.headerStripeColor,
    },
    heading: {
      color: '#2E3132',
      fontWeight: 400,
      fontSize: '36px',

      marginBottom: theme.spacing(5),
      fontStyle: 'normal',
      lineHeight: '44px',
    },
    subheading: {
      color: theme.palette.black.main,
      textAlign: 'left',
      fontWeight: 500,
      fontSize: '16px',
      height: '60px',
    },
    description: {
      color: theme.palette.primary.dark,
      textAlign: 'left',
      fontSize: '14px',
      height: '60px',
    },

    divider: {
      position: 'relative',
      backgroundColor: theme.palette.opaque.light,
      height: '1px',
    },

    actions: {},

    imgTitle: {
      paddingLeft: theme.spacing(0.2),
    },
    checked: {
      display: 'flex',
      width: '62px',
      height: '40px',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '8px',
      borderRadius: '4px',
      background: '#49DD46',
    },
    checkIcon: {
      color: theme.palette.text.primary,
    },
  }))
