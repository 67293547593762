import { startLoading, stopLoading } from '../../loading/actions'
import { Dispatch } from 'redux'
import { AxiosError } from 'axios'
import { updateAppAlert } from '../../appAlert/actions'
import {
  RequestFailureMessage,
  UpdateCRMSchemaSuccessMessage,
} from '../../../utils/messagesContants'
import { getCRMAuth, patchCRMSchema } from '../../../api/index'
import { LoadingTypes } from '../../loading/reducer'
import { errorLogger } from '../../../utils/errorLogger'
import { RootState } from '../../../../store'
import { getErrorMessages } from '../../../utils/error'

export enum CRMSchemaActionTypes {
  PATCH_CRM_SCHEMA = 'PATCH_CRM_SCHEMA',
  SET_CRM_SCHEMA = 'SET_CRM_SCHEMA',
  SET_CRM_SCHEMA_ERROR = 'SET_CRM_SCHEMA_ERROR',
}

export const updateCRMSchema =
  (crmId: string, crmSchema: Record<string, never>) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      dispatch(startLoading(LoadingTypes.GENERAL))
      await patchCRMSchema(crmId, crmSchema)
      dispatch(
        updateAppAlert({
          message: UpdateCRMSchemaSuccessMessage,
          messageType: 'SUCCESS',
          autoClose: true,
        })
      )
    } catch (err) {
      const errMess = getErrorMessages([RequestFailureMessage])(
        (err as AxiosError<ErrorResponse>).response
      )
      dispatch(setCRMSchemaError(errMess))
      dispatch(
        updateAppAlert({
          message: errMess,
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(err as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }

export const getCRM =
  (crmId: string) => async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      dispatch(startLoading(LoadingTypes.GENERAL))
      const { data } = await getCRMAuth(crmId)
      try {
        const stringifiedSchema = JSON.stringify(data.crm_schema, null, 2)
        dispatch(setCRMSchema(stringifiedSchema))
      } catch (error) {
        dispatch(setCRMSchemaError(error))
        dispatch(
          updateAppAlert({
            message: String(error),
            messageType: 'ERROR',
            autoClose: true,
          })
        )
        const globalState = getState()
        errorLogger({ globalState })(error as Error)
      }
    } catch (err) {
      const errMess = getErrorMessages([RequestFailureMessage])(
        (err as AxiosError<ErrorResponse>).response
      )
      dispatch(setCRMSchemaError(errMess))
      dispatch(
        updateAppAlert({
          message: errMess,
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(err as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }

export const setCRMSchema = (crmSchema: string) => ({
  type: CRMSchemaActionTypes.SET_CRM_SCHEMA,
  payload: crmSchema,
})
export const setCRMSchemaError = (error: unknown) => ({
  type: CRMSchemaActionTypes.SET_CRM_SCHEMA_ERROR,
  payload: error,
})
