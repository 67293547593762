/* istanbul ignore file */
export const setIdentifyAndGroup = (
  id: string,
  data: Record<string, unknown>
) => {
  if (typeof window !== undefined && window.analytics) {
    window.analytics.identify(id, data)
    window.analytics.group(data.partner_id as string)
  }
}

export const setAnalyticsTracking = (
  id: string,
  data: Record<string, unknown>
) => {
  if (typeof window !== undefined && window.analytics) {
    window.analytics.track(id, data)
  }
}
