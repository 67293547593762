import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles(theme => ({
  root: {
    height: '300px',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  header: {
    fontSize: '13px',
    fontWeight: 'bold',
    marginBottom: theme.spacing(-1),
  },
  passwordContainer: {
    display: 'flex',
    marginTop: theme.spacing(3),
    '& > .MuiFormControl-root': {
      flex: 1,
    },
  },
  passwordDivider: {
    margin: theme.spacing(5, 0, 0, -4),
    width: '120%',
    backgroundColor: theme.palette.primary.dark,
  },
  saveBtn: {
    backgroundColor: theme.palette.opaque.main,
    color: theme.palette.secondary.main,
    padding: theme.spacing(1, 4, 1, 4),
    marginLeft: theme.spacing(0),
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.opaque.main,
    },
  },
  btnCancel: {
    marginLeft: `${theme.spacing(3)}px !important`,
    padding: theme.spacing(1, 3),
    backgroundColor: `${theme.palette.opaque.light} !important`,
    color: theme.palette.primary.dark,
    boxShadow: 'none',
    border: `1px solid ${theme.palette.primary.dark}`,
    fontSize: '13px',
    textTransform: 'none',
  },
  btnsContainer: {
    display: 'flex',
    marginTop: theme.spacing(5),
  },
  deleteUser: {
    marginLeft: 'auto',
    alignSelf: 'center',
    color: theme.palette.select.light,
    fontSize: '14px',
    cursor: 'pointer',
  },
}))
