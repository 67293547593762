import { Reducer } from 'redux'
import { ResellerActions } from './actions'
import { ResellerInfo } from '../../../admin/modules/resaleAuthorizations/reducer'

export interface ResellerState {
  resellers: ResellerInfo[]
}
export const initialState = {
  resellers: [],
}
type actionType = {
  type: ResellerActions.SET_RESELLERS_DATA
  payload: ResellerInfo[]
}

export const reducer: Reducer<ResellerState, actionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ResellerActions.SET_RESELLERS_DATA: {
      return { ...state, resellers: action.payload }
    }
    default: {
      return state
    }
  }
}
