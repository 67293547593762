import { API } from '../../../common/api'
import {
  crmServiceUrl,
  engineServiceUrl,
} from '../../../common/utils/constants'
import {
  CosellReferralType,
  CoseelReferralBy,
} from '../../../oppsync/modules/CoSell/actions'

export const fetchReferrals = (
  partnerId: string,
  pageSize?: number,
  pageNumber?: number,
  referralType?: CosellReferralType,
  referralBy?: CoseelReferralBy,
  sortBy?: string,
  sortOrder?: 'asc' | 'desc'
) =>
  API.get(`${crmServiceUrl}/partners/${partnerId}/referrals`, {
    params: {
      referral_type:
        referralType === 'opportunity'
          ? 'referral_opportunity'
          : 'referral_lead',
      referred_by: referralBy === 'ace' ? 'AWS Referral' : 'Partner Referral',
      page_size: pageSize,
      page_number: pageNumber,
      sort_By:
        sortBy === 'targetCloseDate' ? 'Labra__Target_Close_Date__c' : sortBy,
      sort_order: sortOrder,
    },
  })
export const fetchMetrics = (partnerId: string) =>
  API.get(`${crmServiceUrl}/partners/${partnerId}/referrals/metrics`)
export const fetchAwsProductsAndSolutions = (partnerId: string) =>
  API.get(`${engineServiceUrl}/partners/${partnerId}/cloud-configuration`)

export const fetchPartnerCentralUsers = (partnerId: string) =>
  API.get(`${crmServiceUrl}/partners/${partnerId}/partner-central-users`)

export const fetchCRMfiels = (crmId: string) =>
  API.get(
    `${crmServiceUrl}/crms/${crmId}/tables/Labra__Labra_Referral__c/fields`
  )
