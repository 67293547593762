import { Role } from "./roles"

export const getRoleName = (role: Role) => {
    if (role === Role.PARTNER_OWNER) {
      return 'Owner'
    } else if (role === Role.PARTNER_ADMIN) {
      return 'Admin'
    } else if (role === Role.PARTNER_STAFF) {
      return 'Staff'
    } else {
      return ''
    }
  }