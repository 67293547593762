import { Dispatch } from 'redux'
import faqData from '../../../mocks/faqData.json'
import { Qna } from './reducer'

const faqs: Record<string, Qna[]> = faqData

export enum FaqActionTypes {
  SHOW_FAQ_SECTION = 'SHOW_FAQ_SECTION',
  HIDE_FAQ_DRAWER = 'HIDE_FAQ_DRAWER',
}

export const showFaqSection =
  (sectionName: string) => async (dispatch: Dispatch) => {
    return dispatch({
      type: FaqActionTypes.SHOW_FAQ_SECTION,
      payload: faqs[sectionName],
    })
  }

export const hideFaqSection = () => ({
  type: FaqActionTypes.HIDE_FAQ_DRAWER,
})
