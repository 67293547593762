import { CRMTableFieldsLoadingActionTypes } from './actions'

export type CRMTableFieldLoadingState = Record<string, boolean>

export type CRMTableFieldLoadingAction = {
  type: CRMTableFieldsLoadingActionTypes.SET_TABLE_FIELDS_LOADING
  payload: string
}

export const reducer = (
  state: CRMTableFieldLoadingState = {},
  action: CRMTableFieldLoadingAction
) => {
  switch (action.type) {
    case CRMTableFieldsLoadingActionTypes.SET_TABLE_FIELDS_LOADING: {
      return {
        ...state,
        [action.payload]: true,
      }
    }

    default:
      return state
  }
}
