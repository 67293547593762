import * as Yup from 'yup'
import { ThreeDecimalPointRegex, TwoDecimalPointRegex, doubleQuoteRegex } from './constants'
declare module 'yup' {
  interface NumberSchema {
    checkDecimalpointValidation({ message }: { message?: string }): NumberSchema
    checkThreeDecimalpointValidation({
      message,
    }: {
      message?: string
    }): NumberSchema
  }
}

declare module 'yup' {
  interface StringSchema {
    doubleQuoteValidation({ message }: { message?: string }): StringSchema
  }
}

Yup.addMethod(Yup.number, 'checkDecimalpointValidation', function (args) {
  const { message } = args
  return this.test('checkDecimalpointValidation', message, function (value) {
    if (!value) return true
    return TwoDecimalPointRegex.test(value as unknown as string)
  })
})
Yup.addMethod(Yup.number, 'checkThreeDecimalpointValidation', function (args) {
  const { message } = args
  return this.test(
    'checkThreeDecimalpointValidation',
    message,
    function (value) {
      if (!value) return true
      return ThreeDecimalPointRegex.test(value as unknown as string)
    }
  )
})

Yup.addMethod(Yup.string, 'doubleQuoteValidation', function (args) {
  const { message } = args
  return this.test(
    'doubleQuoteValidation',
    message,
    function (value) {
      if (!value) return true
      return !doubleQuoteRegex.test(value)
    }
  )
})
