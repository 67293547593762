export enum ComponentLoadingActionTypes {
  START_LOADING = 'START_LOADING',
  STOP_LOADING = 'STOP_LOADING',
}

export type LoadingActions =
  | { type: ComponentLoadingActionTypes.START_LOADING }
  | { type: ComponentLoadingActionTypes.STOP_LOADING }

export const startLoading = (loadingType: string) => ({
  type: ComponentLoadingActionTypes.START_LOADING,
  payload: loadingType,
})

export const stopLoading = (loadingType: string) => ({
  type: ComponentLoadingActionTypes.STOP_LOADING,
  payload: loadingType,
})
