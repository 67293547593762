import { useState, useEffect } from 'react'
import { getOnboarding } from '../../modules/onboarding/actions'
import {
  G2ReviewResponseType,
  getG2Review,
  getPartner,
  PartnerType,
} from '../../modules/partner/action'
import { PartnerDataState } from '../../modules/partner/reducer'
import { useDispatch, useSelector } from '../../../store'
import { useFlagValue } from '@labrav/flags'
import { AuthProvider } from '../../../oppsync/modules/userList/action'
import { isEmpty } from 'lodash'

export const usePartner = (partnerId: string, partnerType: PartnerType) => {
  const { partnerData, isLoading, error } = useSelector<PartnerDataState>(
    state => state.PartnerData[partnerType]
  )
  const dispatch = useDispatch()
  const { value: auth0Enabled, loading: isFlagsLoading } = useFlagValue('auth0')

  useEffect(() => {
    if (
      (!partnerData && !isLoading && !isFlagsLoading && !error) ||
      partnerData?.partnerId !== partnerId
    ) {
      dispatch(getPartner(partnerId, partnerType))
      dispatch(getOnboarding(partnerId, partnerType))
      dispatch(getG2Review(partnerId, partnerType))
    }
  }, [partnerId, isFlagsLoading, auth0Enabled])

  if (partnerData?.partnerId !== partnerId) {
    return { isLoading: true, error: null, partnerData: null }
  }
  const loading = isLoading || isFlagsLoading
  return { partnerData, loading, error }
}

export const useG2Review = (
  partnerId: string,
  partnerObjectType: PartnerType,
  shouldCheck?: boolean
) => {
  const dispatch = useDispatch()
  const g2ReviewList = useSelector<G2ReviewResponseType[] | undefined>(state => state.PartnerData[partnerObjectType].partnerData?.G2ReviewList  ) 

  useEffect(() => {
    if (!isEmpty(partnerId) && shouldCheck && !g2ReviewList) {
      dispatch(getG2Review(partnerId, partnerObjectType))
    }
  }, [dispatch, partnerId, partnerObjectType, shouldCheck, g2ReviewList])

  return { g2ReviewList }
}
