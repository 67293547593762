import { makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = (productBgColor?: string) =>
  makeStyles((theme: Theme) => ({
    root: {
      backgroundColor: productBgColor
        ? productBgColor
        : theme.palette.primary.dark,
      marginBottom: theme.spacing(4),
    },
    container: {
      margin: theme.spacing(0, 'auto'),
      maxWidth: '95%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      ['@media(max-width:1200px)']: {
        alignItems: 'center',
      },
      ['@media(max-width:768px)']: {
        alignItems: 'center',
      },
    },
    header: {
      color: theme.palette.bg.main,
      fontSize: '18px',
      margin: theme.spacing(3.5, 0),
    },
    card: {
      width: '100%',
      height: '125px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    cardAction: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      '&:hover': {
        backgroundColor: theme.palette.secondary.light,
      },
    },
    imageList: {
      display: 'flex',
      gap: theme.spacing(3),
      flexDirection: 'row',
      marginBottom: theme.spacing(3),
      width: '100%',
      ['@media(max-width:1200px)']: {
        flexDirection: 'column',
      },
      ['@media(max-width:768px)']: {
        flexDirection: 'column',
      },
    },
    disabled: {
      opacity: '0.8',
    },
  }))
