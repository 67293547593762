import { API } from './'
import { engineServiceUrl } from '../utils/constants'

export const getRuleDefintions = (partnerId: string) =>
  API.get(`${engineServiceUrl}/partners/${partnerId}/rule-engine-definitions`)

export const getOverridingRulesApi = (partnerId: string, crmFielId: string) =>
  API.get(
    `${engineServiceUrl}/partners/${partnerId}/overriding-rules/${crmFielId}`
  )

export const putRuleDefinitions = (
  partnerId: string,
  crmFieldId: string,
  ruleDefinitions: unknown
) =>
  API.put(
    `${engineServiceUrl}/partners/${partnerId}/overriding-rules/${crmFieldId}`,
    ruleDefinitions
  )
