import React, { useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from '../../../components/ErrorFallback/ErrorFallback'
import HeaderWrapper from '../../../components/HeaderWrapper/HeaderWrapper'
import { newrelicErrLogger } from '../../../utils/ErrorHandler'
import { useStyles } from './RouteApp.styles'
interface RouteAppProps extends Partial<ReactChildrenProps> {
  title?: string
  titleIcon?: React.ReactNode
  actions?: React.FC
  component?: React.FC
  hideTitle?: boolean
  withOpenSidePanel?: boolean
}

export const WithErrorBoundary: React.FC<ReactChildrenProps> = (
  props: ReactChildrenProps
) => (
  <ErrorBoundary FallbackComponent={ErrorFallback} onError={newrelicErrLogger}>
    {props.children}
  </ErrorBoundary>
)

const RouteApp: React.FC<RouteAppProps> = ({
  title,
  actions: Actions,
  component: Component,
  children,
  titleIcon,
  hideTitle,
  withOpenSidePanel,
}) => {
  const classes = useStyles()
  const [pageYOffset, setPageYOffset] = useState(0)
  const onScrollValue = (pageYOffsetValue: number) => {
    setPageYOffset(pageYOffsetValue)
  }
  return (
    <div
      className={`${
        withOpenSidePanel
          ? classes.withOpenSidePanel
          : classes.withRegularSidePanel
      } ${classes.root}`}
    >
      <div
        className={classes.content}
        onScroll={(e: any) => onScrollValue(e.target.scrollTop)}
      >
        <HeaderWrapper
          title={title}
          titleIcon={titleIcon}
          actions={Actions}
          pageYOffset={pageYOffset}
          hideTitle={hideTitle}
        />

        <div data-testid="app-body" className={classes.appBody}>
          {Component ? (
            <WithErrorBoundary>
              <Component />
            </WithErrorBoundary>
          ) : (
            children
          )}
        </div>
      </div>
    </div>
  )
}

export default RouteApp
