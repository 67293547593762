import { useEffect } from 'react'
import { ProductDetails } from '../../utils/ProductDetails'
import { PartnerData } from '../../modules/partner/action'
import { useSelector } from '../../../store'
import { ProductCardUI } from './ProductCardUI'
import { LabraProductCardUI } from './LabraProductCardUI'
import { useFlagValue } from '@labrav/flags'

export interface ProductCardProps {
  product: ProductDetails
  handleCloudProviderClick: () => void
  selectedProduct?: string
}

export const ProductCard: React.FC<ProductCardProps> = ({
  product,
  handleCloudProviderClick,
  selectedProduct,
}) => {
  const partner = useSelector<PartnerData | null>(
    state => state.PartnerData.user.partnerData
  )
  const activeProducts = partner?.activeProducts || []
  const { value: isLabraCustomerOnboarding } = useFlagValue(
    'labraCustomerOnboarding'
  )
  useEffect(() => {
    const { queryId, productAvailable } = product
    const queryParam = new URLSearchParams(window.location.search).get(
      'product'
    )
    if (queryParam === queryId && productAvailable) {
      handleClick()
    }
  }, [product])

  const handleClick = () => {
    handleCloudProviderClick()
  }

  return isLabraCustomerOnboarding ? (
    <LabraProductCardUI
      product={product}
      selectedProduct={selectedProduct}
      handleCloudProviderClick={handleClick}
      activeProducts={activeProducts}
    />
  ) : (
    <ProductCardUI
      product={product}
      selectedProduct={selectedProduct}
      handleCloudProviderClick={handleClick}
      activeProducts={activeProducts}
    />
  )
}
