import { Button, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  primaryButton: {
    backgroundColor: theme.palette.opaque.main,
    color: theme.palette.secondary.main,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.opaque.main,
    },
  },
}))

type OverritableComponent = {
  className?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any
}

export const PrimaryButton: React.FC<OverritableComponent> = ({
  className,
  ...props
}) => {
  const classes = useStyles()
  return (
    <Button
      className={[classes.primaryButton, className].join(' ')}
      variant="contained"
      {...props}
    />
  )
}
