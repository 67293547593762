export const syncDashboardColors = {
  success: '#00B995',
  error: '#F54242',
  progress: '#1AA2ED',
  warning: '#F2d600',
  atCRM: '#98A9BC',
  atACE: '#475672',
  dataError: '#EB8456',
  systemError: '#F54242',
}
