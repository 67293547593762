import { Reducer } from 'redux'
import { LeadChangeType, LeadFormActions } from './actions'
import { mapLeadApiToLead } from '../../../../common/utils/CoSellHelpers/helperFunctions'
import { PartialLeadObject, ReferralLead, ReferralLeadSections } from './types'

export interface ReferralForm {
  referralForm: ReferralLeadSections
  referral?: PartialLeadObject
  formChanged: boolean
  errorsFromBackend?: Partial<Record<keyof ReferralLead, string>>
}
export const initialState: ReferralForm = {
  referralForm: {
    customerDetails: {
      companyName: '',
      website: '',
      industry: '',
      city: '',
      state: '',
      country: '',
      postalCode: '',
      address: '',
      fullName: '',
    },
    leadDetails: {
      partnerCRMLeadId: '',
      segmentCompanySize: '',
      useCaseWorkload: '',
      projectDescription: '',
      industry: '',
      leadStatusReason: '',
      leadOwnerName: '',
      leadOwnerEmail: '',
      referredBy: 'AWS Referral',
    },
  },
  formChanged: false,
}

type actionType =
  | {
      type: LeadFormActions.SET_LEAD_FORM
      payload: ReferralLeadSections
    }
  | {
      type: LeadFormActions.UPDATE_LEAD_FORM
      payload: LeadChangeType
    }
  | {
      type: LeadFormActions.SET_LEAD
      payload: PartialLeadObject
    }
  | {
      type: LeadFormActions.SET_FORM_LEAD_ERRORS
      payload: Partial<Record<keyof ReferralLead, string>>
    }
  | {
      type: LeadFormActions.CLEAR_LEAD_FORM
    }

export const reducer: Reducer<ReferralForm, actionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case LeadFormActions.SET_LEAD_FORM: {
      return {
        ...state,
        referralForm: action.payload,
      }
    }
    case LeadFormActions.UPDATE_LEAD_FORM: {
      const { sectionName, data } = action.payload
      return {
        ...state,
        referralForm: {
          ...state.referralForm,
          [sectionName]: {
            ...state.referralForm[sectionName],
            ...data,
          },
        },
      }
    }
    case LeadFormActions.SET_LEAD: {
      const referralsObject = mapLeadApiToLead({
        ...state.referral?.referralObject,
        ...action.payload.referralObject,
      })
      return {
        ...state,
        referral: {
          ...state.referral,
          ...action.payload,
          referralObjectTransformed: referralsObject,
        },
      }
    }
    case LeadFormActions.SET_FORM_LEAD_ERRORS: {
      return {
        ...state,
        errorsFromBackend: {
          ...action.payload,
        },
      }
    }
    case LeadFormActions.CLEAR_LEAD_FORM: {
      return (state = initialState)
    }

    default: {
      return state
    }
  }
}
