import { camelize } from 'casing'
import { Reducer } from 'redux'
import { ObjectType } from '../../../utils/constants'
import { OverrideRuleType } from '../../../utils/ruleTypes'
import { RuleDefinitionsActions, RuleType } from './action'

export interface ACEFieldsForRules {
  awsKey: string
  awsFieldId: string
  awsLabel: string
  mappedDataType: DataType
  helpText: string
  conditionText: string
  applicableOperators: Operators[]
  awsStandardValues?: null | string[]
  isHidden?: boolean
}

export interface CRMFieldsForRules {
  crmKey: string
  crmLabel: string
  mappedDataType: DataType
  crmFieldId: string
  applicableOperators: Operators[]
  crmStandardValues?: null | string[]
  parentTable: string
  isPrimaryKey: boolean
  defaultValueFromAwsKey?: string
  defaultValue?: string
  hardcodedValue?: string
}

export enum DataType {
  string = 'string',
  integer = 'integer',
  float = 'float',
  bool = 'bool',
  date = 'date',
  datetime = 'datetime',
  picklist = 'picklist',
  multipicklist = 'multipicklist',
}

export enum OverallConditionalRuleType {
  ALL = 'all',
  ANY = 'any',
}

export enum Operators {
  equal_to = 'equal_to',
  greater_or_equal_to = 'greater_or_equal_to',
  less_or_equal_to = 'less_or_equal_to',
  greater_than = 'greater_than',
  less_than = 'less_than',
  not_equal_to = 'not_equal_to',
  not_contain = 'not_contain',
  contain = 'contain',
  start_with = 'start_with',
  end_with = 'end_with',
}

export type Condition = {
  type: string
  dataType?: DataType | null
  awsField?: string | null
  awsValue?: null | string
  operator?: Operators | null
  crmField?: string | null
  crmValue?: null | string
  byValue?: string | null
  parentTable?: string | null
  isPrimaryKey?: boolean
  defaultValueFromAwsKey?: string | null
  defaultValue?: string | null
  hardcodedValue?: string | null
}
export interface OverridingRules {
  override: OverrideRuleType
  type?: OverallConditionalRuleType
  conditions: Condition[]
}
export interface Rules {
  crmKey: string
  awsKey: string
  crmLabel: string
  awsLabel: string
  objectType?: ObjectType
  parentTable?: string
  isPrimaryKey?: boolean
  defaultValueFromAwsKey?: string
  defaultValue?: string
  hardcodedValue?: string
  overridingRules: OverridingRules
}

export const initialState: RuleDefinitionMappingState = {
  aceFields: [],
  crmFields: [],
  conditionalRules: {
    crmKey: '',
    awsKey: '',
    crmLabel: '',
    awsLabel: '',
    parentTable: '',
    overridingRules: {
      override: OverrideRuleType.ALWAYS,
      type: OverallConditionalRuleType.ALL,
      conditions: [],
    },
  },
  ruleLoader: false,
}

export interface RuleDefinitionMappingState {
  aceFields: ACEFieldsForRules[]
  crmFields: CRMFieldsForRules[]
  conditionalRules: Rules
  ruleLoader: boolean
}

export type RulesDefinitionAction =
  | {
      type: RuleDefinitionsActions.SET_RULE_DEFINITIONS
      payload: any
    }
  | {
      type: RuleDefinitionsActions.ADD_EMPTY_RULES_ROW
      payload: any
    }
  | {
      type: RuleDefinitionsActions.SET_ACE_KEY
      payload: any
    }
  | {
      type: RuleDefinitionsActions.SET_CRM_KEY
      payload: any
    }
  | {
      type: RuleDefinitionsActions.DELETE_ROW
      payload: any
    }
  | {
      type: RuleDefinitionsActions.UPDATE_OPERATOR
      payload: any
    }
  | {
      type: RuleDefinitionsActions.UPDATE_RULE_TYPE
      payload: any
    }
  | {
      type: RuleDefinitionsActions.UPDATE_BY_VALUE
      payload: any
    }
  | {
      type: RuleDefinitionsActions.SET_OVERRIDE_RULE_TYPE
      payload: OverrideRuleType
    }
  | {
      type: RuleDefinitionsActions.SET_OVERRIDING_RULES
      payload: any
    }
  | {
      type: RuleDefinitionsActions.OVERRIDING_RULES_CLEANUP
    }
  | {
      type: RuleDefinitionsActions.START_RULE_LOADING
    }
  | {
      type: RuleDefinitionsActions.STOP_RULE_LOADING
    }

export const initializeACEAndCRMFields = (data: any, type: string) => {
  const list = []
  if (type === 'aws') {
    for (const key in data) {
      list.push({ ...camelize(data[key]), awsKey: key })
    }
  } else if (type === 'crm') {
    const objectTable = Object.keys(data)
    objectTable.forEach(table => {
      for (const key in data[table]) {
        list.push({
          ...camelize(data[table][key]),
          crmKey: key,
          parentTable: table,
        })
      }
    })
  }
  return list
}

export const getACEObject = (key: string, aceFields: ACEFieldsForRules[]) =>
  aceFields.find(ace => ace.awsKey === key)
export const getCRMObject = (
  key: string,
  table: string,
  crmFields: CRMFieldsForRules[]
) => crmFields.find(crm => crm.crmKey === key && crm.parentTable === table)

export const reducer: Reducer<
  RuleDefinitionMappingState,
  RulesDefinitionAction
> = (state = initialState, action) => {
  switch (action.type) {
    case RuleDefinitionsActions.SET_RULE_DEFINITIONS: {
      const { payload } = action
      return {
        ...state,
        aceFields: initializeACEAndCRMFields(payload.aws_fields, 'aws'),
        crmFields: initializeACEAndCRMFields(payload.crm_fields, 'crm'),
      }
    }
    case RuleDefinitionsActions.ADD_EMPTY_RULES_ROW: {
      const { payload } = action
      const list = [
        ...(state.conditionalRules?.overridingRules.conditions || []),
      ]
      list.push({
        type: payload.ruleType.toLowerCase(),
        dataType: null,
        crmField: null,
        crmValue: null,
        awsField: null,
        awsValue: null,
        operator: null,
        byValue: null,
        isPrimaryKey: false,
        defaultValue: null,
        parentTable: null,
        defaultValueFromAwsKey: null,
        hardcodedValue: null,
      })
      return {
        ...state,
        conditionalRules: {
          ...state.conditionalRules,
          overridingRules: {
            ...state.conditionalRules?.overridingRules,
            conditions: list,
          },
        },
      }
    }
    case RuleDefinitionsActions.SET_ACE_KEY: {
      const { index, awsKey, operand, selectedValue, type, mappingData } =
        action.payload
      const list = [...state.conditionalRules.overridingRules.conditions]
      const aceElement = getACEObject(selectedValue, state.aceFields)
      if (operand === 'first' && type === RuleType.COMPARATOR) {
        const crmElement = getCRMObject(
          mappingData?.crmKey || '',
          mappingData?.parentTable || '',
          state.crmFields
        )
        if (aceElement) {
          list[index] = {
            ...list[index],
            dataType: aceElement?.mappedDataType,
            awsField: selectedValue,
            awsValue: null,
            operator: null,
            crmField: mappingData?.crmKey || null,
            crmValue: null,
            byValue: null,
            isPrimaryKey: crmElement?.isPrimaryKey || false,
            defaultValue: crmElement?.defaultValue || null,
            parentTable: crmElement?.parentTable || null,
            defaultValueFromAwsKey: crmElement?.defaultValueFromAwsKey || null,
            hardcodedValue: crmElement?.hardcodedValue || null,
          }
        }
      } else if (operand === 'first' && type === RuleType.ACE) {
        if (aceElement) {
          list[index] = {
            ...list[index],
            dataType: aceElement?.mappedDataType,
            awsField: selectedValue,
            awsValue: null,
            operator: null,
            crmField: null,
            crmValue: null,
            byValue: null,
            isPrimaryKey: false,
            defaultValue: null,
            parentTable: null,
            defaultValueFromAwsKey: null,
            hardcodedValue: null,
          }
        }
      } else if (operand === 'second') {
        list[index] = {
          ...list[index],
          awsValue: selectedValue,
        }
      }
      return {
        ...state,
        conditionalRules: {
          ...state.conditionalRules,
          overridingRules: {
            ...state.conditionalRules?.overridingRules,
            conditions: list,
          },
        },
      }
    }
    case RuleDefinitionsActions.SET_CRM_KEY: {
      const { index, awsKey, operand, selectedValue, type } = action.payload
      const list = [
        ...(state.conditionalRules?.overridingRules.conditions || []),
      ]

      if (operand === 'first') {
        const crmElement = getCRMObject(
          selectedValue.key,
          selectedValue.table,
          state.crmFields
        )
        if (crmElement) {
          list[index] = {
            ...list[index],
            dataType: crmElement?.mappedDataType,
            crmField: selectedValue.key,
            crmValue: null,
            awsField: null,
            awsValue: null,
            operator: null,
            byValue: null,
            isPrimaryKey: crmElement.isPrimaryKey,
            defaultValue: crmElement.defaultValue,
            parentTable: crmElement.parentTable,
            defaultValueFromAwsKey: crmElement.defaultValueFromAwsKey,
            hardcodedValue: crmElement.hardcodedValue,
          }
        }
      } else if (operand === 'second') {
        if (type === RuleType.CRM) {
          list[index] = {
            ...list[index],
            crmValue: selectedValue,
          }
        }
      } else if (operand === 'parent') {
        if (type === RuleType.CRM) {
          list[index] = {
            ...list[index],
            parentTable: selectedValue,
          }
        }
      }
      return {
        ...state,
        conditionalRules: {
          ...state.conditionalRules,
          overridingRules: {
            ...state.conditionalRules?.overridingRules,
            conditions: list,
          },
        },
      }
    }
    case RuleDefinitionsActions.DELETE_ROW: {
      const { index, awsKey } = action.payload
      const list = [...state.conditionalRules.overridingRules.conditions]
      list.splice(index, 1)
      return {
        ...state,
        conditionalRules: {
          ...state.conditionalRules,
          overridingRules: {
            ...state.conditionalRules?.overridingRules,
            conditions: list,
          },
        },
      }
    }
    case RuleDefinitionsActions.UPDATE_OPERATOR: {
      const { index, awsKey, operator } = action.payload
      const list = [...state.conditionalRules.overridingRules.conditions]
      list[index] = { ...list[index], operator: operator }
      return {
        ...state,
        conditionalRules: {
          ...state.conditionalRules,
          overridingRules: {
            ...state.conditionalRules?.overridingRules,
            conditions: list,
          },
        },
      }
    }
    case RuleDefinitionsActions.UPDATE_RULE_TYPE: {
      const { type, awsKey } = action.payload
      return {
        ...state,
        conditionalRules: {
          ...state.conditionalRules,
          overridingRules: {
            ...state.conditionalRules?.overridingRules,
            type: type,
          },
        },
      }
    }

    case RuleDefinitionsActions.UPDATE_BY_VALUE: {
      const { byValue, index } = action.payload
      const list = [
        ...(state.conditionalRules?.overridingRules.conditions || []),
      ]
      list[index] = { ...list[index], byValue: byValue }
      return {
        ...state,
        conditionalRules: {
          ...state.conditionalRules,
          overridingRules: {
            ...state.conditionalRules?.overridingRules,
            conditions: list,
          },
        },
      }
    }
    case RuleDefinitionsActions.SET_OVERRIDE_RULE_TYPE: {
      return {
        ...state,
        conditionalRules: {
          ...state.conditionalRules,
          overridingRules: {
            ...state.conditionalRules?.overridingRules,
            override: action.payload,
          },
        },
      }
    }

    case RuleDefinitionsActions.SET_OVERRIDING_RULES: {
      return { ...state, conditionalRules: action.payload }
    }
    case RuleDefinitionsActions.OVERRIDING_RULES_CLEANUP: {
      return { ...state, conditionalRules: initialState.conditionalRules }
    }
    case RuleDefinitionsActions.START_RULE_LOADING: {
      return { ...state, ruleLoader: true }
    }
    case RuleDefinitionsActions.STOP_RULE_LOADING: {
      return { ...state, ruleLoader: false }
    }
    default:
      return state
  }
}
