import { Reducer } from 'redux'
import { ResaleAuthorizationCreationActionTypes } from './actions'
import {
  getDimensionError,
  getErrorObjectForAField,
  getInstallmentAmountError,
  getInstallmentErrors,
  getMetadataError,
  getOfferRecipientsError,
  getRowObjectForAField,
  getServiceLengthOrDurationError,
  validateOfferResaleName,
} from '../privateOffer/privateOfferCreation/reducer'
import { get, isEmpty } from 'lodash'
import { KeyValue } from '../privateOffer/privateOfferCreation/actions'
import { isStringOfLength } from '../../../common/utils/helperFunctions'
import {
  DiscountType,
  ResaleAuthorizationsResellerAgreements,
} from '../../../admin/modules/resaleAuthorizations/reducer'
import { isNonEmptyNumber } from '../../../common/utils/validateMinMax'
import { Dimension, PriceTypes } from '../privateOffer/reducer'
import { getShortName } from '../../../common/utils/getShortName'
import { ProductDimension } from '../productsListing/reducer'

export const getUpdatedErrors = (
  key: string,
  resaleAuthorization: ResaleAuthorizationsResellerAgreements,
  errors: Record<string, unknown>,
  field?: string,
  index?: number,
  standardDimensions?: ProductDimension[]
) => {
  switch (key) {
    case 'dimensions': {
      const finalIndex = index || 0
      const finalField = (field || '') as unknown as keyof PriceTypes
      const dimensionError = errors?.dimensions as Record<string, unknown>[]
      const errorObject = dimensionError[finalIndex]
      const dimension = get(resaleAuthorization, 'dimensions', [])
      const error = getDimensionError(
        dimension,
        errorObject,
        finalIndex,
        finalField,
        true,
        standardDimensions
      )
      dimensionError[finalIndex] = error
      return { ...errors, dimensions: dimensionError }
    }
    case 'resellersInfo': {
      const resellerInfo = resaleAuthorization.resellersInfo[0]
      if (isEmpty(field)) {
        errors.labraPlatformResellerId = isEmpty(
          resellerInfo?.labraPlatformResellerId
        )
          ? 'AWS reseller cannnot be empty.'
          : ''
        errors.resellerAwsAccountId = !isEmpty(
          resellerInfo?.labraPlatformResellerId
        )
          ? ''
          : errors.resellerAwsAccountId || ''
      } else {
        if (isEmpty(resellerInfo?.resellerAwsAccountId)) {
          errors.resellerAwsAccountId = 'Reseller AWS Account cannot be empty.'
        } else {
          const isValid = isStringOfLength(
            12,
            resellerInfo?.resellerAwsAccountId as string
          )
          errors.resellerAwsAccountId = isValid
            ? ''
            : 'Please enter a 12 digit account ID'
        }
      }
      return errors
    }
    case 'companyName': {
      const companyName = resaleAuthorization.companyName
      errors.companyName = isEmpty(companyName)
        ? 'Company name cannot be empty.'
        : ''
      return errors
    }
    case 'contractDurationInMonthsForEndCustomer':
    case 'agreementExpirationDate':
    case 'installmentInfo': {
      const installmentInfo = resaleAuthorization.installmentInfo
      const errorsInstallmentInfo = errors.installmentInfo as Array<
        Record<string, unknown>
      >
      if (
        field === 'paymentDate' ||
        key === 'agreementExpirationDate' ||
        key === 'contractDurationInMonthsForEndCustomer'
      ) {
        if (!isEmpty(installmentInfo)) {
          errors.installmentInfo = getInstallmentErrors({
            installments: installmentInfo,
            installmentErrors: errorsInstallmentInfo,
            agreementStartDate: resaleAuthorization.agreementStartDate,
            offerExpirationDate: resaleAuthorization.agreementExpirationDate,
            serviceOrDurationLength:
              resaleAuthorization.contractDurationInMonthsForEndCustomer,
            isResale: true,
          })
        }
        if (key === 'contractDurationInMonthsForEndCustomer') {
          errors.contractDurationInMonthsForEndCustomer =
            getServiceLengthOrDurationError({
              lengthValue:
                resaleAuthorization?.contractDurationInMonthsForEndCustomer,
              errKeyText: 'Custom duration',
            })
        }
      } else if (field === 'amount') {
        const finalIndex = index || 0
        const getInstallmentAmountErrorParam = installmentInfo[finalIndex]
          .amount
          ? { amountValue: installmentInfo[finalIndex].amount as number }
          : {}
        errorsInstallmentInfo[finalIndex].amount = getInstallmentAmountError(
          getInstallmentAmountErrorParam
        )
        errors.installmentInfo = errorsInstallmentInfo
      }
      return errors
    }
    case 'agreementName': {
      const agreementName = resaleAuthorization.agreementName
      errors.agreementName = validateOfferResaleName(agreementName, true, 100)
      return errors
    }
    case 'emailCustomMessage': {
      const emailCustomMessage = resaleAuthorization.emailCustomMessage
      errors.emailCustomMessage = isEmpty(emailCustomMessage)
        ? 'Message cannot be empty.'
        : ''
      return errors
    }
    case 'percentageDiscount': {
      if (resaleAuthorization.discountType === DiscountType.DISCOUNT) {
        errors.percentageDiscount = !isNonEmptyNumber(
          resaleAuthorization.percentageDiscount
        )
          ? 'Percantage Discount cannot be empty.'
          : ''
      }
      return errors
    }
    case 'agreementRecipients': {
      const agreementRecipients = resaleAuthorization.agreementRecipients
      const agreementRecipientsErrors = errors.agreementRecipients as Array<
        Record<string, unknown>
      >
      if (!isEmpty(agreementRecipients)) {
        const finalIndex = index || 0
        const agreementRecipientError = agreementRecipientsErrors[finalIndex]
        const error = getOfferRecipientsError(
          agreementRecipients,
          { ...agreementRecipientError },
          finalIndex,
          field
        )
        agreementRecipientsErrors[finalIndex] = error
        errors.agreementRecipients = [...agreementRecipientsErrors]
      }
      return errors
    }
    case 'buyerAwsAccountId': {
      const buyerAwsAccountId = resaleAuthorization.buyerAwsAccountId
      if (isEmpty(buyerAwsAccountId)) {
        errors.buyerAwsAccountId = ''
      } else {
        const isValid = isStringOfLength(12, buyerAwsAccountId as string)
        errors.buyerAwsAccountId = isValid
          ? ''
          : 'Please enter a 12 digit account ID'
      }
      return errors
    }
    case 'metaData': {
      const metaData = resaleAuthorization.metaData as unknown as Array<
        Record<string, string>
      >
      return getMetadataError({ metaData, errors, field, index })
    }
    case 'discountType': {
      const discountType = resaleAuthorization.discountType
      errors.discountType = isEmpty(discountType)
        ? 'Discount type cannot be empty.'
        : ''
      errors.percentageDiscount = ''
      errors.contractDurationInMonthsForEndCustomer = ''
      return errors
    }
    case 'contractDurationType': {
      const contractDurationType = resaleAuthorization.contractDurationType
      errors.contractDurationType = isEmpty(contractDurationType)
        ? 'Contract duration type cannot be empty.'
        : ''
      errors.contractDurationInMonthsForEndCustomer = ''
      return errors
    }
    default:
      return errors
  }
}

export enum ResaleAuthorizationFields {
  DIMENSIONS = 'dimensions',
  INSTALLMENTSINFO = 'installmentInfo',
  AGREEMENT_RECIPIENTS = 'agreementRecipients',
  RESELLERS_INFO = 'resellersInfo',
}

export type ResaleAuthorizationErrors = {
  errors: Record<string, unknown>
  hasBeenSent: boolean
  noOfTimesSaved: number
}
export type ResaleAuthorizationWithErrors =
  ResaleAuthorizationsResellerAgreements & ResaleAuthorizationErrors
export interface ResaleAuthorizationObjectInCreation {
  [x: string]: ResaleAuthorizationWithErrors
}
export interface ResaleAuthorizationsInCreation {
  currentResaleAuthorizationInCreation: ResaleAuthorizationObjectInCreation
}
const initialState: ResaleAuthorizationsInCreation = {
  currentResaleAuthorizationInCreation: {},
}

type actionType =
  | {
      type: ResaleAuthorizationCreationActionTypes.SET_CREATE_RESALE_AUTHORIZATIONS_DATA
      payload: {
        productId: string
        resaleAuthorizationData: ResaleAuthorizationsResellerAgreements
        errors: Record<string, unknown>
        noOfTimesSaved: number
      }
    }
  | {
      type: ResaleAuthorizationCreationActionTypes.ADD_ROWS_ON_RESALE_AUTHORIZATION
      payload: { productId: string; key: string; type: string }
    }
  | {
      type: ResaleAuthorizationCreationActionTypes.DELETE_ROWS_ON_RESALE_AUTHORIZATION
      payload: { productId: string; key: string; index: number }
    }
  | {
      type: ResaleAuthorizationCreationActionTypes.UPDATE_VALUES_OF_ARRAY_ON_RESALE_AUTHORIZATION
      payload: {
        productId: string
        key: string
        index: number
        resaleAuthorizationData: KeyValue
      }
    }
  | {
      type: ResaleAuthorizationCreationActionTypes.UPDATE_RESALE_AUTHORIZATION_CREATION_ERRORS
      payload: {
        productId: string
        key: string
        index?: number
        field?: string
        standardDimensions?: ProductDimension[]
      }
    }
  | {
      type: ResaleAuthorizationCreationActionTypes.CLEAR_RESALE_AUTHORIZATION_IN_CREATION
      payload: string
    }
  | {
      type: ResaleAuthorizationCreationActionTypes.UPDATE_RESALE_AUTHORIZATIONS
      payload: {
        productId: string
        resaleAuthorizationData: Record<string, string>
      }
    }
  | {
      type: ResaleAuthorizationCreationActionTypes.UPDATE_PRICES_ON_RESALE_AUTHORIZATION_DIMENSIONS
      payload: {
        productId: string
        index: number
        priceData: KeyValue
      }
    }
  | {
      type: ResaleAuthorizationCreationActionTypes.UPDATE_PRICES_ON_RESALE_AUTHORIZATION_DIMENSIONS
      payload: {
        productId: string
        index: number
        priceData: KeyValue
      }
    }
  | {
      type: ResaleAuthorizationCreationActionTypes.ADD_DIMENSION_ROWS_ON_RESALE_AUTHORIZATIONS
      payload: { productId: string; key: string; type: string }
    }
  | {
      type: ResaleAuthorizationCreationActionTypes.UPDATE_DIMENSION_ON_RESALE_AUTHORIZATION
      payload: { index: number; productId: string; dimension: Dimension }
    }
  | {
      type: ResaleAuthorizationCreationActionTypes.UPDATE_NUMBER_OF_TIMES_SAVED_ON_RESALE_AUTHORIZATION
      payload: string
    }
  | {
      type: ResaleAuthorizationCreationActionTypes.UPDTE_RESALE_AUTHORIZATION_HAS_BEEN_SENT
      payload: { hasBeenSent: boolean; productId: string }
    }
  | {
      type: ResaleAuthorizationCreationActionTypes.ADD_FILES_ON_RESALE_AUTHORIZATION
      payload: {
        productId: string
        files: string[]
        key: 'endCustomerEulaFiles' | 'resellerLicenseAgreementFiles'
      }
    }

export const reducer: Reducer<ResaleAuthorizationsInCreation, actionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ResaleAuthorizationCreationActionTypes.SET_CREATE_RESALE_AUTHORIZATIONS_DATA: {
      const { productId, resaleAuthorizationData, errors, noOfTimesSaved } =
        action.payload
      return {
        ...state,
        currentResaleAuthorizationInCreation: {
          ...state.currentResaleAuthorizationInCreation,
          [productId]: {
            ...resaleAuthorizationData,
            errors: {
              ...errors,
            },
            hasBeenSent: false,
            noOfTimesSaved: noOfTimesSaved,
          },
        },
      }
    }
    case ResaleAuthorizationCreationActionTypes.ADD_ROWS_ON_RESALE_AUTHORIZATION: {
      const { productId, key, type } = action.payload
      const resaleAuthorization = state.currentResaleAuthorizationInCreation[
        productId
      ] as unknown as Record<string, unknown>
      const updatedArray = [
        ...(resaleAuthorization[key] as unknown as Array<
          Record<string, unknown>
        >),
      ]
      const errors = resaleAuthorization.errors as unknown as Record<
        string,
        unknown
      >
      const updatedErrorsArray = [
        ...(errors[key] as unknown as Array<Record<string, unknown>>),
      ]
      const pricesArray = (resaleAuthorization?.pricesArray || []) as [
        keyof PriceTypes
      ]
      const objectRowToBeAdded = getRowObjectForAField(
        key,
        productId,
        type,
        true,
        pricesArray
      )
      const objectErrorToBeAdded = getErrorObjectForAField(
        key,
        type,
        true,
        pricesArray
      )
      updatedArray.push({ ...objectRowToBeAdded })
      updatedErrorsArray.push({ ...objectErrorToBeAdded })
      return {
        ...state,
        currentResaleAuthorizationInCreation: {
          ...state.currentResaleAuthorizationInCreation,
          [productId]: {
            ...state.currentResaleAuthorizationInCreation[productId],
            [key]: updatedArray,
            errors: {
              ...state.currentResaleAuthorizationInCreation[productId].errors,
              [key]: updatedErrorsArray,
            },
          },
        },
      }
    }
    case ResaleAuthorizationCreationActionTypes.DELETE_ROWS_ON_RESALE_AUTHORIZATION: {
      const { productId, key, index } = action.payload
      const resaleAuthorization = state.currentResaleAuthorizationInCreation[
        productId
      ] as unknown as ResaleAuthorizationObjectInCreation
      const updatedArray = [
        ...(resaleAuthorization[key] as unknown as Array<
          Record<string, unknown>
        >),
      ]
      updatedArray.splice(index, 1)
      const errors = resaleAuthorization.errors as unknown as Record<
        string,
        unknown
      >
      const updatedErrorsArray = [
        ...(errors[key] as unknown as Array<Record<string, unknown>>),
      ]
      updatedErrorsArray.splice(index, 1)
      return {
        ...state,
        currentResaleAuthorizationInCreation: {
          ...state.currentResaleAuthorizationInCreation,
          [productId]: {
            ...state.currentResaleAuthorizationInCreation[productId],
            [key]: updatedArray,
            errors: {
              ...state.currentResaleAuthorizationInCreation[productId].errors,
              [key]: updatedErrorsArray,
            },
          },
        },
      }
    }
    case ResaleAuthorizationCreationActionTypes.UPDATE_VALUES_OF_ARRAY_ON_RESALE_AUTHORIZATION: {
      const { productId, key, index, resaleAuthorizationData } = action.payload
      const resaleAuthorization = state.currentResaleAuthorizationInCreation[
        productId
      ] as unknown as Record<string, unknown>
      const updatedArray = [
        ...(resaleAuthorization[key] as unknown as Array<
          Record<string, unknown>
        >),
      ]
      const updatedObject = updatedArray[index] as Record<string, unknown>
      updatedObject[resaleAuthorizationData.key] = resaleAuthorizationData.value
      updatedArray[index] = { ...updatedObject }
      return {
        ...state,
        currentResaleAuthorizationInCreation: {
          ...state.currentResaleAuthorizationInCreation,
          [productId]: {
            ...state.currentResaleAuthorizationInCreation[productId],
            [key]: updatedArray,
          },
        },
      }
    }
    case ResaleAuthorizationCreationActionTypes.UPDATE_RESALE_AUTHORIZATION_CREATION_ERRORS: {
      const { key, productId, index, field, standardDimensions } =
        action.payload
      const { errors, ...resaleAuthorization } =
        state.currentResaleAuthorizationInCreation[productId]
      const updatedErrors = getUpdatedErrors(
        key,
        { ...resaleAuthorization },
        { ...errors },
        field,
        index,
        standardDimensions
      )
      return {
        ...state,
        currentResaleAuthorizationInCreation: {
          ...state.currentResaleAuthorizationInCreation,
          [productId]: {
            ...state.currentResaleAuthorizationInCreation[productId],
            errors: {
              ...(updatedErrors as Record<string, unknown>),
            },
          },
        },
      }
    }

    case ResaleAuthorizationCreationActionTypes.CLEAR_RESALE_AUTHORIZATION_IN_CREATION: {
      const productId = action.payload
      const resaleAuthorizations = {
        ...state.currentResaleAuthorizationInCreation,
      }
      delete resaleAuthorizations[productId]
      return {
        ...state,
        currentResaleAuthorizationInCreation: {
          ...resaleAuthorizations,
        },
      }
    }

    case ResaleAuthorizationCreationActionTypes.UPDATE_RESALE_AUTHORIZATIONS: {
      const { productId, resaleAuthorizationData } = action.payload
      const { key, value } = resaleAuthorizationData
      const updatedResaleAuthorization =
        key === 'discountType'
          ? {
              contractDurationInMonthsForEndCustomer: undefined,
              percentageDiscount: undefined,
            }
          : {}
      return {
        ...state,
        currentResaleAuthorizationInCreation: {
          ...state.currentResaleAuthorizationInCreation,
          [productId]: {
            ...state.currentResaleAuthorizationInCreation[productId],
            ...updatedResaleAuthorization,
            [key]: value,
          },
        },
      }
    }
    case ResaleAuthorizationCreationActionTypes.UPDATE_PRICES_ON_RESALE_AUTHORIZATION_DIMENSIONS: {
      const { productId, index, priceData } = action.payload
      const { key, value } = priceData
      const dimensions = [
        ...(state.currentResaleAuthorizationInCreation[productId]?.dimensions ||
          []),
      ]
      const updatedDimension = { ...dimensions[index] }
      updatedDimension.prices = {
        ...(updatedDimension.prices as PriceTypes),
        [key]: value as number,
      }
      dimensions[index] = updatedDimension
      return {
        ...state,
        currentResaleAuthorizationInCreation: {
          ...state.currentResaleAuthorizationInCreation,
          [productId]: {
            ...state.currentResaleAuthorizationInCreation[productId],
            dimensions: [...dimensions],
          },
        },
      }
    }
    case ResaleAuthorizationCreationActionTypes.UPDATE_DIMENSION_ON_RESALE_AUTHORIZATION: {
      const { index, productId, dimension } = action.payload
      const resaleAuthorization =
        state.currentResaleAuthorizationInCreation[productId]
      const updatedDimensions = [...(resaleAuthorization?.dimensions || [])]
      const updatedObject = updatedDimensions[index]
      const updatedDimension = { ...updatedObject, ...dimension }
      updatedDimensions[index] = { ...updatedDimension }
      return {
        ...state,
        currentResaleAuthorizationInCreation: {
          ...state.currentResaleAuthorizationInCreation,
          [productId]: {
            ...state.currentResaleAuthorizationInCreation[productId],
            dimensions: [...updatedDimensions],
          },
        },
      }
    }

    case ResaleAuthorizationCreationActionTypes.UPDTE_RESALE_AUTHORIZATION_HAS_BEEN_SENT: {
      const { productId, hasBeenSent } = action.payload
      return {
        ...state,
        currentResaleAuthorizationInCreation: {
          ...state.currentResaleAuthorizationInCreation,
          [productId]: {
            ...state.currentResaleAuthorizationInCreation[productId],
            hasBeenSent,
          },
        },
      }
    }

    case ResaleAuthorizationCreationActionTypes.UPDATE_NUMBER_OF_TIMES_SAVED_ON_RESALE_AUTHORIZATION: {
      const productId = action.payload
      const noOfTimesSaved =
        state.currentResaleAuthorizationInCreation[productId].noOfTimesSaved + 1
      return {
        ...state,
        currentResaleAuthorizationInCreation: {
          ...state.currentResaleAuthorizationInCreation,
          [productId]: {
            ...state.currentResaleAuthorizationInCreation[productId],
            noOfTimesSaved,
          },
        },
      }
    }

    case ResaleAuthorizationCreationActionTypes.ADD_FILES_ON_RESALE_AUTHORIZATION: {
      const { productId, files, key } = action.payload
      const updatedFiles =
        state.currentResaleAuthorizationInCreation[productId][key]
      return {
        ...state,
        currentResaleAuthorizationInCreation: {
          ...state.currentResaleAuthorizationInCreation,
          [productId]: {
            ...state.currentResaleAuthorizationInCreation[productId],
            [key]: (updatedFiles !== null && !isEmpty(updatedFiles)
              ? updatedFiles
              : []
            ).concat(files),
          },
        },
      }
    }

    default:
      return state
  }
}
