import {
  ACEFieldsForRules,
  Condition,
  CRMFieldsForRules,
  DataType,
} from '../../../../../common/modules/onboarding/ruleDefinitions/reducer'

const getSecondList = (
  crmFields: CRMFieldsForRules[],
  firstValue: AceOrCrmFieldsForRules | undefined
) => {
  if (firstValue) {
    return crmFields.filter(field =>
      filterByCompatibleDataTypes(firstValue.mappedDataType)(
        field.mappedDataType
      )
    ) as any
  } else {
    return crmFields
  }
}

const getCompatibleDataTypes = (dataType: DataType) => {
  switch (dataType) {
    case DataType.string:
      return [DataType.picklist, DataType.multipicklist]
    case DataType.picklist:
      return [DataType.string, DataType.multipicklist]
    case DataType.integer:
      return [DataType.float]
    case DataType.date:
      return [DataType.datetime]
    case DataType.datetime:
      return [DataType.date]
    case DataType.multipicklist:
      return [DataType.string, DataType.picklist]
    default:
      return []
  }
}

export const filterByCompatibleDataTypes = (from: DataType) => (to: DataType) =>
  getCompatibleDataTypes(from).concat(from).includes(to)

export type AceOrCrmFieldsForRules = ACEFieldsForRules | CRMFieldsForRules
type CRMKeyTableType = { key: string; table: string }
export abstract class RulesUIParams {
  protected aceFields: ACEFieldsForRules[]
  protected crmFields: CRMFieldsForRules[]
  ruleObject: Condition
  abstract getFirstValue: () => AceOrCrmFieldsForRules | undefined
  abstract getSecondValue: () => CRMFieldsForRules | string | undefined
  abstract label: string
  abstract getOptionLabel: (field: AceOrCrmFieldsForRules) => string
  abstract getSecondOptionLabel: (
    field: CRMFieldsForRules | string | undefined
  ) => string
  abstract getKeyValue: (
    x: AceOrCrmFieldsForRules | null
  ) => string | CRMKeyTableType
  abstract getFirstList: () => AceOrCrmFieldsForRules[]
  abstract getSecondList: () => string[] | CRMFieldsForRules[]
  abstract getObjectByKey: (
    key: string,
    aceFields: AceOrCrmFieldsForRules[]
  ) => AceOrCrmFieldsForRules | undefined

  constructor(
    aceFields: ACEFieldsForRules[],
    crmFields: CRMFieldsForRules[],
    ruleObject: Condition
  ) {
    this.aceFields = aceFields
    this.crmFields = crmFields
    this.ruleObject = ruleObject
  }
}

export class AceUIParams extends RulesUIParams {
  constructor(
    aceFields: ACEFieldsForRules[],
    crmFields: CRMFieldsForRules[],
    ruleObject: Condition
  ) {
    super(aceFields, crmFields, ruleObject)
  }

  getFirstValue = () =>
    this.aceFields.find(object => this.ruleObject.awsField === object.awsKey)

  getSecondValue = () =>
    this.getSecondList()?.find(
      (value: string) => this.ruleObject.awsValue === value
    )
  getOptionLabel = (field: AceOrCrmFieldsForRules) =>
    (field as ACEFieldsForRules).awsLabel

  getKeyValue = (x: AceOrCrmFieldsForRules | null) =>
    (x as ACEFieldsForRules)?.awsKey

  label = 'Select ACE key'

  getObjectByKey = (key: string, aceFields: AceOrCrmFieldsForRules[]) =>
    aceFields.find(
      ace => (ace as ACEFieldsForRules).awsKey === key
    ) as ACEFieldsForRules

  getFirstList = () => this.aceFields.filter(field => !field?.isHidden)

  getSecondList = () => {
    const aceElement = this.getObjectByKey(
      this.ruleObject.awsField || '',
      this.aceFields
    )
    return aceElement?.awsStandardValues || []
  }

  getSecondOptionLabel = (a: unknown) => a as string
}

export class ComparatorUIParams extends AceUIParams {
  getSecondList = () => getSecondList(this.crmFields, this.getFirstValue())

  getSecondValue = () =>
    this.getSecondList()?.find(
      (object: CRMFieldsForRules) => this.ruleObject.crmField === object.crmKey
    )

  getSecondOptionLabel = (field: unknown) =>
    (field as CRMFieldsForRules)?.crmLabel
}

export class CrmUIParams extends RulesUIParams {
  getFirstValue = () =>
    this.crmFields.find(object => this.ruleObject.crmField === object.crmKey)

  getSecondValue = () =>
    this.getSecondList()?.find(
      (value: string) => this.ruleObject.crmValue === value
    )
  getOptionLabel = (field: AceOrCrmFieldsForRules) =>
    (field as CRMFieldsForRules).crmLabel

  getKeyValue = (x: AceOrCrmFieldsForRules | null) => ({
    key: (x as CRMFieldsForRules)?.crmKey,
    table: (x as CRMFieldsForRules)?.parentTable,
  })

  label = 'Select CRM key'

  getObjectByKey = (key: string, aceFields: AceOrCrmFieldsForRules[]) =>
    aceFields.find(
      ace => (ace as CRMFieldsForRules).crmKey === key
    ) as CRMFieldsForRules

  getFirstList = () => this.crmFields

  getSecondList = () => {
    const aceElement = this.getObjectByKey(
      this.ruleObject.crmField || '',
      this.crmFields
    )
    return aceElement?.crmStandardValues || []
  }

  getSecondOptionLabel = (a: unknown) => a as string
}
