import { useDispatch, useSelector } from '../../../store'
import { useEffect, useState } from 'react'
import { usePartnerId } from './usePartnerData'
import { AzureAccountDetails } from '../../../oppsync/modules/productAccount/reducer'
import { getAzureAccountDetails } from '../../../oppsync/modules/productAccount/action'
import { isEmpty } from 'lodash'
import { LoadingTypes } from '../../modules/loading/reducer'
import { isLoading } from '../loadingState'
import { fetchAccounts } from '../../modules/cmcm/accounts/actions'
import { awsTemplateNameCMCM } from '../constants'
import { CloudType } from '../../modules/types'
import { CmcmAccountState } from '../../modules/cmcm/accounts/reducer'

export const getActiveCloudProviders = () => {
  const dispatch = useDispatch()
  const partnerId = usePartnerId()
  const [cloudsConnected, setCloudConnected] = useState<string[]>([])
  const accountRegistrationData = useSelector<CmcmAccountState>(
    state => state.accounts.oppsync
  )
  const isAwsConnected = !isEmpty(accountRegistrationData)
  const azureLoading = useSelector<boolean>(state =>
    isLoading(state.loading)(LoadingTypes.AZURE_FETCHING_ACCOUNT_DETAILS)
  )
  const cmcmLoading = useSelector<boolean>(state =>
    isLoading(state.loading)(LoadingTypes.CMCM_FETCHING_ACCOUNTS)
  )

  const { MPNID, name, industries, microsoftPartnerType, fetchedForPartner } =
    useSelector<AzureAccountDetails>(
      state => state.accountForm.oppsync.accountDetails.AZURE
    )

  const isAzureConnected =
    ![MPNID, name, industries, microsoftPartnerType, fetchedForPartner].some(
      isEmpty
    ) && partnerId === fetchedForPartner

  useEffect(() => {
    if (!azureLoading && !isAzureConnected && !!partnerId) {
      dispatch(getAzureAccountDetails(partnerId, 'oppsync'))
    }
    if (partnerId) {
      dispatch(fetchAccounts(awsTemplateNameCMCM, CloudType.AWS, 'oppsync'))
    }
  }, [partnerId])

  useEffect(() => {
    if (!azureLoading && !isEmpty(partnerId)) {
      const actualCloudsConnected = [
        {
          cloudType: 'AWS',
          connected: isAwsConnected,
        },
        {
          cloudType: 'AZURE',
          connected: isAzureConnected,
        },
      ]
        .map(a => (a.connected ? a.cloudType : ''))
        .filter(a => !isEmpty(a))
      setCloudConnected(actualCloudsConnected)
    }
  }, [isAwsConnected, isAzureConnected, azureLoading, partnerId])
  return { cloudsConnected, azureLoading, cmcmLoading }
}
