import { Reducer } from 'redux'
import { AppAlertActionTypes } from './actions'

export type AlertMessageType = 'SUCCESS' | 'ERROR' | 'WARNING' | 'INFO' | 'NONE'
export type AppAlertState = {
  message?: string
  messageType: AlertMessageType
  autoClose?: boolean
  sidePanelAlert?: boolean
}
export const initialState: AppAlertState = {
  message: undefined,
  messageType: 'NONE',
  sidePanelAlert: false,
}
type actionType = {
  type: AppAlertActionTypes
  error: AppAlertState
}
export const reducer: Reducer<AppAlertState, actionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case AppAlertActionTypes.UPDATE_APP_ALERT: {
      return (state = action.error)
    }
    default: {
      return state
    }
  }
}
