import { Reducer } from 'redux'
import { FlyoutMigrationProductsList } from './action'

export enum ProductVisibility {
  PUBLIC = 'Public',
  DRAFT = 'Draft',
  LABRA_TESTING = 'Labra testing',
  LIMITED = 'Limited',
  CREATION_FAILED = 'Failed',
  AWS_TESTING = 'AWS testing',
  RESTRICTED = 'Restricted',
  REJECTED = 'Rejected',
}

export interface MigrationProduct {
  Name: string
  EntityType: string
  EntityId: string
  EntityArn: string
  LastModifiedDate: string
  Visibility: ProductVisibility
}

interface FlyOutMigrationProducts {
  products: MigrationProductsData
}

export interface FlyOutMigrationProductsInfo {
  migrationProducts: FlyOutMigrationProducts
}
export interface MigrationProductsData {
  rows: MigrationProduct[]
}
export const initialState: FlyOutMigrationProductsInfo = {
  migrationProducts: {
    products: {
      rows: [],
    },
  },
}

type actionType = {
  type: FlyoutMigrationProductsList.SET_FLYOUT_MIGRATION_PRODUCTS
  payload: FlyOutMigrationProducts
}

export const reducer: Reducer<FlyOutMigrationProductsInfo, actionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case FlyoutMigrationProductsList.SET_FLYOUT_MIGRATION_PRODUCTS: {
      return {
        ...state,
        migrationProducts: action.payload,
      }
    }
    default: {
      return state
    }
  }
}
