import {
  startLoading,
  stopLoading,
} from '../../../common/modules/loading/actions'
import { LoadingTypes } from '../../../common/modules/loading/reducer'
import { AxiosResponse } from 'axios'
import { getErrorMessages } from '../../../common/utils/error'
import { AppDispatch, RootState } from '../../../store'
import { isEmpty } from 'lodash'
import {
  getPreSignedUrl,
  postFreeTrial,
  postToSignedUrl,
} from '../../api/markeplace'
import { updateAppAlert } from '../../../common/modules/appAlert/actions'
import {
  freeTrialRequestSuccess,
  freeTrialRequestUnsuccess,
} from '../../../common/utils/messagesContants'
import { snakeize } from 'casing'
import { errorLogger } from '../../../common/utils/errorLogger'
import { clearProductsListing } from '../productsListing/actions'
import { actionTypeWrapper } from '../../../common/utils/actionTypeWrapper'
export enum FreeTrialsActionTypes {
  UPDATE_FREE_TRIALS_DIMENSION_ROW_QUANTITY = 'UPDATE_FREE_TRIALS_DIMENSION_ROW_QUANTITY',
  ADD_FREE_TRIALS_DIMENSION_ROW = 'ADD_FREE_TRIALS_DIMENSION_ROW',
  REMOVE_FREE_TRIALS_DIMENSION_ROW = 'REMOVE_FREE_TRIALS_DIMENSION_ROW',
  UPDATE_FREE_TRIALS_DIMENSION_ROW_ID = 'UPDATE_FREE_TRIALS_DIMENSION_ROW_ID',
  UPDATE_FREE_TRIALS_NUMBER_OF_DAYS = 'UPDATE_FREE_TRIALS_NUMBER_OF_DAYS',
  ADD_FREE_TRIALS_PRODUCT = 'ADD_FREE_TRIALS_PRODUCT',
  UPDATE_EULA_TYPE = 'UPDATE_EULA_TYPE',
  ADD_EULA_FILES = 'ADD_EULA_FILES',
  UPDATE_ON_REVIEW = 'UPDATE_ON_REVIEW',
}

export const updateDimensionRowQuantityOnFreeTrial = (
  productId: string,
  index: number,
  quantity: number
) => ({
  type: FreeTrialsActionTypes.UPDATE_FREE_TRIALS_DIMENSION_ROW_QUANTITY,
  payload: { productId, index, quantity },
})

export const addDimensionRowOnFreeTrial = (productId: string) => ({
  type: FreeTrialsActionTypes.ADD_FREE_TRIALS_DIMENSION_ROW,
  payload: productId,
})
export const removeDimensionRowOnFreeTrial = (
  productId: string,
  index: number
) => ({
  type: FreeTrialsActionTypes.REMOVE_FREE_TRIALS_DIMENSION_ROW,
  payload: { productId, index },
})
export const updateDimensionRowIdOnFreeTrial = (
  productId: string,
  index: number,
  id: string
) => ({
  type: FreeTrialsActionTypes.UPDATE_FREE_TRIALS_DIMENSION_ROW_ID,
  payload: { productId, index, id },
})
export const updateNumberOfDaysOnFreeTrial = (
  productId: string,
  numberOfDays: number
) => ({
  type: FreeTrialsActionTypes.UPDATE_FREE_TRIALS_NUMBER_OF_DAYS,
  payload: { productId, numberOfDays },
})

export const addProductOnFreeTrial =
  (productId: string) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    if (isEmpty(getState().freeTrials[productId])) {
      await dispatch(setProductOnFreeTrial(productId))
    }
  }

export const setProductOnFreeTrial = (productId: string) => ({
  type: FreeTrialsActionTypes.ADD_FREE_TRIALS_PRODUCT,
  payload: productId,
})

export const updateEulaType = (eulaType: string, productId: string) => ({
  type: FreeTrialsActionTypes.UPDATE_EULA_TYPE,
  payload: { eulaType, productId },
})

export const addEulaFiles = (productId: string, eulaFiles: string[]) => ({
  type: FreeTrialsActionTypes.ADD_EULA_FILES,
  payload: { productId, eulaFiles },
})
export const updateOnReview = (productId: string, onReview: boolean) => ({
  type: FreeTrialsActionTypes.UPDATE_ON_REVIEW,
  payload: { productId, onReview },
})
export const activateFreeTrial =
  (partnerId: string, productId: string) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    await dispatch(startLoading(LoadingTypes.GENERAL))
    try {
      const product = getState().productsListing[
        getState().productListingFilters.cloudMarketplace
      ].products.rows.find(product => product.productId === productId)
      if (!isEmpty(product)) {
        const cloudMarketplace = product?.cloudMarketplace
        const productName = product?.productName
        const { freeTrial, productDimensions, eulaFile, eulaType } =
          getState().freeTrials[productId]
        const productFreeTrial: Record<string, unknown> = {}
        const finalProductDimensions = productDimensions
          .map(dimension => {
            const { productDimensionId, freeTrial } = dimension
            const quantity = Number(freeTrial?.quantity)
            return { productDimensionId, quantity }
          })
          .filter(dimension => dimension.quantity !== 0)
        productFreeTrial.freeTrialDimensionData = finalProductDimensions
        productFreeTrial.freeTrialExpiresInDays = Number(
          freeTrial.freeTrialExpiresInDays
        )
        productFreeTrial.eulaFiles = eulaFile
        productFreeTrial.eulaType = eulaType
        productFreeTrial.productId = productId
        productFreeTrial.cloudMarketplace = cloudMarketplace
        productFreeTrial.cloudFreeTrialOfferName = `${productName}: Free trial`
        await postFreeTrial(partnerId, snakeize(productFreeTrial))
        dispatch(
          updateAppAlert({
            message: freeTrialRequestSuccess,
            messageType: 'SUCCESS',
            autoClose: true,
          })
        )
        await dispatch(
          actionTypeWrapper(
            getState().productListingFilters.cloudMarketplace,
            clearProductsListing()
          )
        )
      }
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: freeTrialRequestUnsuccess,
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      await dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }
