import { notificationMethod } from '../../../../common/utils/constants'
import { NotificationsActionTypes } from './actions'

export type OtherSidePanels = 'SLACK' | 'WEBHOOK'

export interface Recipient {
  destinationValue?: string
  idpUserId?: string
  metaData?: Record<string, string>
}

export interface NotificationPanelState {
  notificationType: string
  method: notificationMethod
  productNotificationTypeMethodId: string
  productNotificationTypeId: string
  registeredUsers: Recipient[]
  externalUsers: Recipient[]
  isNotificationSidePanelOpen: boolean
  notificationSidePanelLoading: boolean
  otherNotificationsSidePanel: {
    [x in OtherSidePanels]: boolean
  }
}

export const initialState: NotificationPanelState = {
  notificationType: '',
  method: '' as notificationMethod,
  productNotificationTypeId: '',
  productNotificationTypeMethodId: '',
  registeredUsers: [],
  externalUsers: [],
  isNotificationSidePanelOpen: false,
  notificationSidePanelLoading: false,
  otherNotificationsSidePanel: {
    SLACK: false,
    WEBHOOK: false,
  },
}

type Action =
  | {
      type: NotificationsActionTypes.SHOW_NOTIFICATION_SIDE_PANEL
    }
  | {
      type: NotificationsActionTypes.HiDE_NOTIFICATION_SIDE_PANEL
    }
  | {
      type: NotificationsActionTypes.SHOW_OTHER_NOTIFICATION_SIDE_PANEL
      payload: string
    }
  | {
      type: NotificationsActionTypes.HIDE_OTHER_NOTIFICATION_SIDE_PANEL
      payload: string
    }
  | {
      type: NotificationsActionTypes.SET_NOTIFICATION_ON_SIDE_PANEL
      payload: Record<string, string | notificationMethod>
    }
  | {
      type: NotificationsActionTypes.SET_NOTIFICATION_SIDE_PANEL_LOADING
      payload: boolean
    }

export const reducer = (
  state: NotificationPanelState = initialState,
  action: Action
): NotificationPanelState => {
  switch (action.type) {
    case NotificationsActionTypes.SHOW_NOTIFICATION_SIDE_PANEL:
      return {
        ...state,
        isNotificationSidePanelOpen: true,
      }
    case NotificationsActionTypes.HiDE_NOTIFICATION_SIDE_PANEL:
      return {
        ...state,
        isNotificationSidePanelOpen: false,
      }
    case NotificationsActionTypes.SET_NOTIFICATION_SIDE_PANEL_LOADING:
      return {
        ...state,
        notificationSidePanelLoading: action.payload,
      }
    case NotificationsActionTypes.SET_NOTIFICATION_ON_SIDE_PANEL: {
      const {
        methodId,
        notificationType,
        productNotificationTypeMethodId,
        productNotificationTypeId,
      } = action.payload
      return {
        ...state,
        notificationType,
        method: methodId.toLowerCase() as notificationMethod,
        productNotificationTypeId,
        productNotificationTypeMethodId,
        isNotificationSidePanelOpen: true,
      }
    }
    case NotificationsActionTypes.SHOW_OTHER_NOTIFICATION_SIDE_PANEL:
      return {
        ...state,
        otherNotificationsSidePanel: {
          ...state.otherNotificationsSidePanel,
          [action.payload]: true,
        },
      }
    case NotificationsActionTypes.HIDE_OTHER_NOTIFICATION_SIDE_PANEL:
      return {
        ...state,
        otherNotificationsSidePanel: {
          ...state.otherNotificationsSidePanel,
          [action.payload]: false,
        },
      }
    default:
      return state
  }
}
