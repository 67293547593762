import React, { useState } from 'react'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import { useStyles } from './DeleteUser.styles'
import { ModalConfirm } from '../../../../admin/components/ModalConfirm/ModalConfirm'
import { UserInfoProps } from '../UserBasicInfo/UserBasicInfo'
import { deleteUser, deleteUserIdenityService } from '../../../../common/api'
import { useDispatch, useSelector } from '../../../../store'
import { updateAppAlert } from '../../../../common/modules/appAlert/actions'
import {
  AuthProvider,
  getUsers,
  hideUserSidePanel,
} from '../../../modules/userList/action'
import { RequestFailureMessage } from '../../../../common/utils/messagesContants'
import {
  startLoading,
  stopLoading,
} from '../../../../common/modules/loading/actions'
import { UserListState } from '../../../modules/userList/reducer'
import { LoadingTypes } from '../../../../common/modules/loading/reducer'
import { errorLogger } from '../../../../common/utils/errorLogger'
import { useFlagValue } from '@labrav/flags'
import { useUserType } from '../../../../common/utils/Hooks/usePartnerData'

const modalTitle = 'Are you sure?'
const modalAcceptBtn = 'Delete'

export const DeleteUser: React.FC<UserInfoProps> = ({ user }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const partnerType = useUserType()
  const globalState = useSelector(state => state)
  const [openModal, setOpenModal] = useState(false)
  const { loading: isFlagsLoading, value: auth0Enabled } = useFlagValue('auth0')
  const partnerId = useSelector<string | undefined>(
    state => state.PartnerData.user.partnerData?.partnerId
  )
  const { pageNumber, pageSize } = useSelector<UserListState>(
    state => state.userList[partnerType]
  )
  const subtitleConfirm = (
    <span>
      If you delete <strong>{user.name}</strong>, the user will lose access to
      this account permanently.
    </span>
  )

  const handleOpenModal = () => {
    setOpenModal(true)
  }
  const handleSubmit = () => {
    dispatch(hideUserSidePanel())
    setOpenModal(false)
    dispatch(startLoading(LoadingTypes.GENERAL))
    if (partnerId) {
      const removeUser = auth0Enabled ? deleteUserIdenityService : deleteUser
      removeUser(partnerId, user.id)
        .then(() => {
          dispatch(
            updateAppAlert({
              message: `${user.name} deleted successfully.`,
              messageType: 'SUCCESS',
              autoClose: true,
            })
          )
        })
        .catch(err => {
          const errMess = err?.response?.data?.message || RequestFailureMessage
          dispatch(
            updateAppAlert({
              message: errMess,
              messageType: 'ERROR',
              autoClose: true,
            })
          )
          errorLogger({ globalState })(err as Error)
        })
        .finally(() => {
          dispatch(stopLoading(LoadingTypes.GENERAL))
          dispatch(
            getUsers({
              partnerId,
              pageNumber,
              pageSize,
              authProvider: AuthProvider.AUTH0,
            })
          )
        })
    }
  }

  return (
    <div data-testid="deleteUser">
      <span data-testid="deleteUserBtn" onClick={handleOpenModal}>
        Delete user
      </span>
      <div data-testid="deleteModal">
        <ModalConfirm
          icon={<HighlightOffIcon className={classes.deleteIcon} />}
          title={modalTitle}
          subTitle={subtitleConfirm}
          showIcon={true}
          content={''}
          open={openModal}
          titleAcceptButton={modalAcceptBtn}
          onAccept={handleSubmit}
          onDecline={() => setOpenModal(false)}
          btnDanger={true}
        />
      </div>
    </div>
  )
}
