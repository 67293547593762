import { fetchCRMs } from '../../../../common/api'
import { Dispatch } from 'redux'
import { startLoading, stopLoading } from '../../loading/actions'
import { AxiosError } from 'axios'
import { LoadingTypes } from '../../loading/reducer'
import { errorLogger } from '../../../utils/errorLogger'
import { RootState } from '../../../../store'

export interface Credentials {
  key: string
  label: string
  type: string
  required: boolean
  max_length?: number
  min_length?: number
  min_value?: number
  max_value?: number
  help_text: string
  value?: string
  link?: string
}

export interface CRMListState {
  list: CRM[]
  errMess: unknown
}

export interface CRM {
  crm_type: string
  credential_fields: Credentials[]
  instruction_text: string
}

export enum crmListActionTypes {
  GET_CRMs = 'GET_CRMs',
  GET_CRMs_FAILED = 'GET_CRMs_FAILED',
}

export const getCRMs =
  () => async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      await dispatch(startLoading(LoadingTypes.GENERAL))
      const { data } = await fetchCRMs()
      await dispatch({
        type: crmListActionTypes.GET_CRMs,
        payload: data.auth_definitions,
      })
    } catch (error: unknown) {
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
      await dispatch(setErrorMess((error as AxiosError).response?.data))
    } finally {
      await dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }

export const setErrorMess = (err: unknown) => ({
  type: crmListActionTypes.GET_CRMs_FAILED,
  payload: err,
})
