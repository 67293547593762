import { Reducer, Action } from 'redux'
import { SupportFormActionTypes } from './actions'

export type SupportFormState = boolean

export const reducer: Reducer<
  SupportFormState,
  Action<SupportFormActionTypes>
> = (state = false, action) => {
  switch (action.type) {
    case SupportFormActionTypes.SHOW_SUPPORT_FORM: {
      return (state = true)
    }
    case SupportFormActionTypes.HIDE_SUPPORT_FORM: {
      return (state = false)
    }
    default: {
      return state
    }
  }
}
