import { isEmpty } from 'lodash'

export const checkIsEmptyAndLength = (
  key: string,
  data: string,
  length: number
) => {
  if (isEmpty(data)) {
    return `${key} cannot be empty.`
  } else {
    if (data.length > length) {
      return `${key} cannot be greater than ${length} characters.`
    } else {
      return ''
    }
  }
}
