import { camelize, snakeize } from 'casing'
import { isEmpty } from 'lodash'
import { compose } from 'lodash/fp'
import { AnyAction, Dispatch } from 'redux'
import {
  fetchACEFields,
  fetchMappingData,
  getCRMAuth,
  postPartnerMappings,
  saveCrmAceMapping,
} from '../../../../common/api'
import { RootState } from '../../../../store'
import { actionTypeWrapper } from '../../../utils/actionTypeWrapper'
import { internalRelation } from '../../../utils/constants'
import { sortObjectAlpbhabetic } from '../../../utils/helperFunctions'
import { RequestFailureMessage } from '../../../utils/messagesContants'
import { hasNotEmptyProps } from '../../../utils/object'
import { updateAppAlert } from '../../appAlert/actions'
import { startLoading, stopLoading } from '../../loading/actions'
import { PartnerType } from '../../partner/action'
import { updateSaved } from '../../savedStatus/actions'
import { setOnboarding } from '../actions'
import { CrmFieldReducerState } from '../CrmFields/reducer'
import { CrmTableState } from '../crmTables/reducer'
import { CRMSettingStatuses } from './../reducer'
import {
  ACEField,
  CrmAceMappingState,
  FieldCrmToAce,
  FieldsMap,
  MandatoryField,
  MappingKeys,
} from './reducer'
import { FormData } from '../crmAuth/action'
import { LoadingTypes } from '../../loading/reducer'
import {
  getWarningsAfterValidation,
  setIsCompletelyLoaded,
  getWarningsForMandatoryFields,
  removeMandatoryWarningMessage,
  setMandatoryWarningMessage,
  setWarningMessages,
} from '../warningStatus/action'
import { errorLogger } from '../../../utils/errorLogger'

export enum CrmAceMappingActions {
  SET_ACE_FIELDS = 'SET_ACE_FIELDS',
  SET_MAPPING_FIELD = 'SET_MAPPING_FIELD',
  SET_MAPPING_KEY_VALUE = 'SET_MAPPING_KEY_VALUE',
  GET_CRM_FIELDS = 'GET_CRM_FIELDS',
  GET_ACE_FIELDS = 'GET_ACE_FIELDS',
  SET_MANDATORY_FIELDS = 'SET_MANDATORY_FIELDS',
  ADD_EMPTY_MANDATORY_FIELD_ROW = 'ADD_EMPTY_MANDATORY_FIELD_ROW',
  SET_GET_MAPPED_DATA = 'SET_GET_MAPPED_DATA',
  MAPPING_CLEANUP = 'MAPPING_CLEANUP',
  REMOVE_MANDATORY_FIELD_ROW = 'REMOVE_MANDATORY_FIELD_ROW',
  SET_HAS_RULES = 'SET_HAS_RULES',
  SET_CRM_TABLES = 'SET_CRM_TABLES',
  SET_MAPPING_PARENT_TABLE = 'SET_MAPPING_PARENT_TABLE',
}

export enum SubmitType {
  SAVE = 'SAVE',
  SUBMIT = 'SUBMIT',
  RULES_SUBMIT = 'RULES_SUBMIT',
}

export const setAceFields = (payload: ACEField[]) => ({
  type: CrmAceMappingActions.SET_ACE_FIELDS as CrmAceMappingActions.SET_ACE_FIELDS,
  payload,
})

export const setMappingFieldAndWarningState =
  (
    objectType: CRMObjectType,
    payload: FieldCrmToAce,
    aceMappedDataType: string,
    aceMaxLength?: number
  ) =>
  async (dispatch: Dispatch) => {
    await dispatch(actionTypeWrapper(objectType, setMappingField(payload)))
    const {
      aceKey,
      crmLabel,
      crmMappedDataType,
      maxLength,
      crmKey,
      parentTable,
    } = payload
    if (isEmpty(payload.standardValuesMap)) {
      const warningMessages = getWarningsAfterValidation({
        aceKey,
        aceMappedDataType,
        aceMaxLength,
        crmLabel: crmLabel ?? '',
        crmKey: crmKey ?? '',
        table: parentTable ?? '',
        crmMappedDataType: crmMappedDataType ?? '',
        crmMaxLength: maxLength,
      })

      await dispatch(
        actionTypeWrapper(
          objectType,
          setWarningMessages(aceKey, warningMessages)
        )
      )
    }
  }

export const setMappingField = (payload: FieldCrmToAce) => ({
  type: CrmAceMappingActions.SET_MAPPING_FIELD as CrmAceMappingActions.SET_MAPPING_FIELD,
  payload,
})

export const setKeyValueMapping = (payload: Partial<MappingKeys>) => ({
  type: CrmAceMappingActions.SET_MAPPING_KEY_VALUE as CrmAceMappingActions.SET_MAPPING_KEY_VALUE,
  payload,
})

export const setTableParentValueMapping = (value: string) => ({
  type: CrmAceMappingActions.SET_MAPPING_PARENT_TABLE as CrmAceMappingActions.SET_MAPPING_PARENT_TABLE,
  payload: { value: value },
})

export const setMandatoryFields = (
  index: number,
  field: Record<string, string | boolean>
) => ({
  type: CrmAceMappingActions.SET_MANDATORY_FIELDS as CrmAceMappingActions.SET_MANDATORY_FIELDS,
  payload: {
    field,
    index,
  },
})

export const setMandatoryFieldsAndWarningState =
  (objectType: CRMObjectType, index: number, field: MandatoryField) =>
  (dispatch: Dispatch) => {
    dispatch(actionTypeWrapper(objectType, setMandatoryFields(index, field)))
    const warningMessageObj = getWarningsForMandatoryFields({ field })
    dispatch(
      actionTypeWrapper(
        objectType,
        setMandatoryWarningMessage(index, warningMessageObj)
      )
    )
  }

export const addEmptyMandatoryFieldRow = (
  crmFields: CrmFieldReducerState,
  crmTables: CrmTableState
) => ({
  type: CrmAceMappingActions.ADD_EMPTY_MANDATORY_FIELD_ROW as CrmAceMappingActions.ADD_EMPTY_MANDATORY_FIELD_ROW,
  payload: { crmFields, crmTables },
})

export const cleanupCrmAceMapping = () => (dispatch: Dispatch) => {
  dispatch(
    actionTypeWrapper('lead', {
      type: CrmAceMappingActions.MAPPING_CLEANUP,
    })
  )
  dispatch(
    actionTypeWrapper('opportunity', {
      type: CrmAceMappingActions.MAPPING_CLEANUP,
    })
  )
}

export const updateKeyValueMapping = (
  objectType: string,
  payload: Partial<MappingKeys>
) => actionTypeWrapper(objectType, setKeyValueMapping(payload))

export const updateTableParentValueMapping = (
  objectType: string,
  value: string
) => actionTypeWrapper(objectType, setTableParentValueMapping(value))

export const getACEFields =
  (objectType: CRMObjectType, partnerType: PartnerType = PartnerType.User) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState()
    let aceVersion =
      state.PartnerData[partnerType].partnerData?.awsFieldsVersion
    if (!aceVersion) {
      errorLogger({ globalState: state })(
        new Error('No ace version found for partner, defaulting to "v14"')
      )
      aceVersion = 'v14'
    }
    if (!isEmpty(state.crmAceMapping[objectType].aceFields)) {
      return void 0
    }
    dispatch(startLoading(LoadingTypes.TABLE_MAPPING))
    try {
      const { data } = await fetchACEFields(objectType, aceVersion)
      const sortedData = sortObjectAlpbhabetic(data, 'label')
      dispatch(
        actionTypeWrapper(objectType, setAceFields(camelize(sortedData)))
      )
    } catch (error) {
      dispatch(
        updateAppAlert({
          message: RequestFailureMessage,
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.TABLE_MAPPING))
    }
  }

export const clearEmptyMandatoryCrmFields = (
  mappingKeys: CrmAceMappingState['mappingKeys']
): CrmAceMappingState['mappingKeys'] => ({
  ...mappingKeys,
  crmMandatoryFields: mappingKeys.crmMandatoryFields.filter(
    ({ crmKey }) => crmKey !== ''
  ),
})
export const appendRelationalFields =
  (crmFields: CrmFieldReducerState) => (mappingFields: FieldCrmToAce[]) => {
    const parentTablesWithoutPrimaryKeysMapped = Object.values(
      mappingFields
    ).reduce((acc, { parentTable, isPrimaryKey }) => {
      if (!parentTable) {
        return acc
      }
      if (isPrimaryKey) {
        // remove parentTable from return value
        return acc.filter(table => table != parentTable)
      } else {
        if (!acc.includes(parentTable)) {
          // add parentTable to the returned value if it isn't there already
          return acc.concat(parentTable)
        }
        return acc
      }
    }, [] as string[])
    let missingCrmPrimaryKeys: FieldCrmToAce[] =
      parentTablesWithoutPrimaryKeysMapped.map(parentTable => {
        const tablePrimaryKeyCrmField = crmFields[parentTable]?.find(
          obj => obj.is_primary_key === true
        )
        return {
          aceKey: internalRelation,
          parentTable,
          crmKey: tablePrimaryKeyCrmField?.name,
          crmLabel: tablePrimaryKeyCrmField?.label,
          crmDataType: tablePrimaryKeyCrmField?.type,
          crmMappedDataType: tablePrimaryKeyCrmField?.mapped_data_type,
          isPrimaryKey: tablePrimaryKeyCrmField?.is_primary_key,
        }
      })

    missingCrmPrimaryKeys = missingCrmPrimaryKeys.filter(
      obj =>
        obj.crmKey !==
        mappingFields.find(
          obj1 =>
            obj1.crmKey === obj.crmKey && obj1.parentTable === obj.parentTable
        )?.crmKey
    )
    return [...mappingFields, ...missingCrmPrimaryKeys]
  }
export const removeEmptyCrmFields = (fields: { crmKey?: string }[]) =>
  fields.filter(({ crmKey }) => !!crmKey && crmKey != '')

export const cleanUpInternalRelationships = (fields: FieldCrmToAce[]) =>
  fields.filter(({ aceKey }) => aceKey !== internalRelation)

export const returnFieldsAsArray = (fields: FieldsMap) =>
  Object.keys(fields).map(key => {
    return { ...fields[key], aceKey: key }
  })

export const prepareMappingFields = (crmFields: CrmFieldReducerState) =>
  compose(
    appendRelationalFields(crmFields),
    cleanUpInternalRelationships,
    removeEmptyCrmFields,
    returnFieldsAsArray
  )

export const submitMappingFields =
  (
    partnerType: PartnerType = PartnerType.User,
    submitType: SubmitType = SubmitType.SAVE
  ) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const submitLoading = getState().savedStatus.alertType === 'LOADING'
    const showAutoSaveStatus = submitType === SubmitType.SAVE

    if (submitLoading && showAutoSaveStatus) {
      return void 0
    }

    if (submitType === SubmitType.SAVE) {
      dispatch(
        updateSaved({
          alertType: 'LOADING',
          autoClose: !showAutoSaveStatus,
        })
      )
    } else {
      dispatch(startLoading(LoadingTypes.GENERAL))
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (
      partnerType === PartnerType.User &&
      submitType === SubmitType.RULES_SUBMIT
    ) {
      try {
        const partnerId =
          getState().PartnerData[partnerType].partnerData!.partnerId
        await postPartnerMappings(partnerId)
        dispatch(
          actionTypeWrapper(
            PartnerType.User,
            setOnboarding({
              crmSettingStatus: CRMSettingStatuses.READY_FOR_REVIEW,
            })
          )
        )

        await dispatch(
          updateSaved({
            alertType: 'SUCCESS',
            autoClose: !showAutoSaveStatus,
          })
        )
      } catch (e) {
        await dispatch(
          updateAppAlert({
            message: RequestFailureMessage,
            messageType: 'ERROR',
            autoClose: true,
          })
        )
        const globalState = getState()
        errorLogger({ globalState })(e as Error)

        await dispatch(
          updateSaved({
            alertType: 'ERROR',
            autoClose: !showAutoSaveStatus,
          })
        )
      } finally {
        dispatch(stopLoading(LoadingTypes.GENERAL))
      }
      return
    }
    const crmAceMapping = getState().crmAceMapping
    const crmFields = getState().crmFields

    const crmAceMappingNewMappingKeys = {
      lead: clearEmptyMandatoryCrmFields(crmAceMapping.lead.mappingKeys),
      opportunity: clearEmptyMandatoryCrmFields(
        crmAceMapping.opportunity.mappingKeys
      ),
    }

    const dataCrmToAce = {
      lead: {
        mappingKeys: {
          ...crmAceMappingNewMappingKeys.lead,
          fields: prepareMappingFields(crmFields)(
            crmAceMappingNewMappingKeys.lead.fields
          ),
          crmMandatoryFields: removeEmptyCrmFields(
            crmAceMappingNewMappingKeys.lead.crmMandatoryFields
          ),
        },
      },
      opportunity: {
        mappingKeys: {
          ...crmAceMappingNewMappingKeys.opportunity,
          fields: prepareMappingFields(crmFields)(
            crmAceMappingNewMappingKeys.opportunity.fields
          ),
          crmMandatoryFields: removeEmptyCrmFields(
            crmAceMappingNewMappingKeys.opportunity.crmMandatoryFields
          ),
        },
      },
    }

    try {
      const partnerId =
        getState().PartnerData[partnerType].partnerData!.partnerId
      const updateStatus =
        partnerType === PartnerType.User && submitType === SubmitType.SUBMIT
      await saveCrmAceMapping(partnerId, snakeize(dataCrmToAce), updateStatus)
      if (updateStatus) {
        await dispatch(
          actionTypeWrapper(
            PartnerType.User,
            setOnboarding({
              crmSettingStatus: CRMSettingStatuses.IN_PROGRESS,
            })
          )
        )
      } else if (submitType === SubmitType.SAVE) {
        await dispatch(
          updateSaved({
            alertType: 'SUCCESS',
            autoClose: !showAutoSaveStatus,
          })
        )
      }
      if (partnerType === PartnerType.Admin) {
        await dispatch(
          updateAppAlert({
            message: 'Mappings updated',
            autoClose: true,
            messageType: 'SUCCESS',
          })
        )
      }
    } catch (error) {
      if (submitType === SubmitType.SUBMIT) {
        await dispatch(
          updateAppAlert({
            message: RequestFailureMessage,
            messageType: 'ERROR',
            autoClose: true,
          })
        )
      }
      dispatch(
        updateSaved({
          alertType: 'ERROR',
          autoClose: !showAutoSaveStatus,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }

export const validateMandatory = (crmAceMapping: CrmAceMappingState) => {
  const filterAceMapping = (acefields: ACEField[], fieldsMap: FieldsMap) => {
    return acefields
      .filter(
        c =>
          fieldsMap[c.aceKey] &&
          c.requiredForCreation === true &&
          !isEmpty(fieldsMap[c.aceKey].crmKey?.trim()) &&
          c.isHidden === false
      )
      .map(({ aceKey }) => aceKey).length
  }

  const aceMandatory = (acefields: ACEField[]) => {
    return acefields
      .filter(
        ({ requiredForCreation, isHidden }) =>
          requiredForCreation === true && isHidden === false
      )
      .map(({ aceKey }) => aceKey).length
  }
  const hasEmptyMandatoryDefaultValue = (
    crmMandatoryFields: MandatoryField[]
  ) => {
    const hasEmptyDefaultValue = crmMandatoryFields.filter(
      field =>
        !isEmpty(field.crmKey) &&
        isEmpty(field.defaultValue) &&
        isEmpty(field.hardcodedValue)
    )
    return hasEmptyDefaultValue.length > 0 ? true : false
  }
  const hasEmptyStandardValues = Object.entries(
    crmAceMapping.mappingKeys.fields
  )
    .filter(
      ([key, value]) =>
        crmAceMapping.aceFields.find(({ aceKey }) => aceKey === key)
          ?.mappedDataType === 'picklist'
    )
    .map(([_, value]) => value)
    .some(
      ({ crmKey, standardValuesMap }) =>
        // crmKey must be present
        !isEmpty(crmKey?.trim()) &&
        (isEmpty(standardValuesMap) ||
          standardValuesMap?.some(({ crmValue }) => isEmpty(crmValue)))
    )
  if (
    crmAceMapping.aceFields.length > 0 &&
    filterAceMapping(
      crmAceMapping.aceFields,
      crmAceMapping.mappingKeys.fields
    ) == aceMandatory(crmAceMapping.aceFields) &&
    !isEmpty(crmAceMapping.mappingKeys.key?.trim()) &&
    !isEmpty(crmAceMapping.mappingKeys.value?.trim()) &&
    !hasEmptyStandardValues &&
    !hasEmptyMandatoryDefaultValue(crmAceMapping.mappingKeys.crmMandatoryFields)
  ) {
    return true
  } else {
    return false
  }
}

export const getMappedData =
  (partnerId: string, crmId: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      const state = getState()
      if (
        state.crmAceMapping.lead.completeData &&
        state.crmAceMapping.opportunity.completeData
      ) {
        return void 0
      }
      dispatch(startLoading(LoadingTypes.TABLE_MAPPING))
      const { data } = await fetchMappingData(partnerId)
      const dataCrmToAce = {
        lead: camelize(data.lead),
        opportunity: camelize(data.opportunity),
      }
      await dispatch(getACEFields('opportunity') as never)
      await dispatch(getACEFields('lead') as never)

      await dispatch(getMappingValues(crmId) as never)

      await dispatch(
        actionTypeWrapper(
          'lead',
          setMappedData(dataCrmToAce.lead.mappingKeys as never)
        )
      )
      await dispatch(
        actionTypeWrapper(
          'opportunity',
          setMappedData(dataCrmToAce.opportunity.mappingKeys as never)
        )
      )
      await dispatch(
        setWarningStatus(
          'lead',
          dataCrmToAce.lead.mappingKeys as never
        ) as unknown as AnyAction
      )
      await dispatch(
        setWarningStatus(
          'opportunity',
          dataCrmToAce.opportunity.mappingKeys as never
        ) as unknown as AnyAction
      )
      await dispatch(
        setCrmMandatoryWarningStatus(
          'lead',
          dataCrmToAce.lead.mappingKeys as never
        ) as unknown as AnyAction
      )
      await dispatch(
        setCrmMandatoryWarningStatus(
          'opportunity',
          dataCrmToAce.opportunity.mappingKeys as never
        ) as unknown as AnyAction
      )
      await dispatch(
        actionTypeWrapper(
          'lead',
          setIsCompletelyLoaded() as unknown as AnyAction
        )
      )
      await dispatch(
        actionTypeWrapper(
          'opportunity',
          setIsCompletelyLoaded() as unknown as AnyAction
        )
      )
    } catch (error) {
      console.error(error)
      dispatch(
        updateAppAlert({
          message: RequestFailureMessage,
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.TABLE_MAPPING))
    }
  }
export const setWarningStatus =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any


    (objectType: CRMObjectType, data: any) =>
    async (dispatch: Dispatch, getState: () => RootState) => {
      // eslint-disable-next-line @typescript-eslint/no-extra-semi, @typescript-eslint/no-explicit-any
      ;(data.fields || []).forEach(async (field: FieldCrmToAce) => {
        const {
          aceKey,
          crmLabel,
          crmMappedDataType,
          maxLength,
          crmKey,
          parentTable,
        } = field
        const aceMappedDataType =
          getState().crmAceMapping[objectType].aceFields.find(
            ace => ace.aceKey === aceKey
          )?.mappedDataType ?? ''
        const aceMaxLength =
          getState().crmAceMapping[objectType].aceFields.find(
            ace => ace.aceKey === aceKey
          )?.maxLength ?? 0

        const warningMessages = getWarningsAfterValidation({
          aceKey,
          aceMappedDataType,
          aceMaxLength,
          crmLabel: crmLabel ?? '',
          crmKey: crmKey ?? '',
          table: parentTable ?? '',
          crmMappedDataType: crmMappedDataType ?? '',
          crmMaxLength: maxLength,
        })
        await dispatch(
          actionTypeWrapper(
            objectType,
            setWarningMessages(aceKey, warningMessages)
          )
        )
      })
    }

export const setCrmMandatoryWarningStatus =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any

  (objectType: CRMObjectType, data: any) => async (dispatch: Dispatch) => {
    // eslint-disable-next-line @typescript-eslint/no-extra-semi, @typescript-eslint/no-explicit-any
    ;(data.crmMandatoryFields || []).forEach(
      async (field: MandatoryField, index: number) => {
        const warningMessageObj = getWarningsForMandatoryFields({
          field,
        })
        await dispatch(
          actionTypeWrapper(
            objectType,
            setMandatoryWarningMessage(index, warningMessageObj)
          )
        )
      }
    )
  }
export const setMappedData = (data: never) => ({
  type: CrmAceMappingActions.SET_GET_MAPPED_DATA,
  payload: data,
})

export const getMappingValues =
  (crmId: string) => async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      const crmFormData = getState().CRMAuth.crmFormData

      if (
        !isEmpty(crmFormData) &&
        hasNotEmptyProps([
          'ace_syncable_lead_field_key',
          'ace_syncable_lead_field_value',
          'ace_syncable_opp_field_key',
          'ace_syncable_opp_field_value',
        ])(crmFormData as unknown as Record<string, unknown>)
      ) {
        dispatch(
          updateKeyValueMapping('lead', {
            key: crmFormData?.ace_syncable_lead_field_key || '',
            value: crmFormData?.ace_syncable_lead_field_value || '',
            parentTable: crmFormData?.ace_syncable_lead_parent_table || '',
            dataType: crmFormData?.ace_syncable_lead_field_data_type || '',
          })
        )
        dispatch(
          updateKeyValueMapping('opportunity', {
            key: crmFormData?.ace_syncable_opp_field_key || '',
            value: crmFormData?.ace_syncable_opp_field_value || '',
            parentTable: crmFormData?.ace_syncable_opp_parent_table || '',
            dataType: crmFormData?.ace_syncable_opp_field_data_type || '',
          })
        )
      } else {
        const { data } = await getCRMAuth(crmId)

        const crmData = data as FormData
        dispatch(
          updateKeyValueMapping('lead', {
            key: crmData?.ace_syncable_lead_field_key || '',
            value: crmData?.ace_syncable_lead_field_value || '',
            parentTable: crmData?.ace_syncable_lead_parent_table || '',
            dataType: crmData?.ace_syncable_lead_field_data_type || '',
          })
        )

        dispatch(
          updateKeyValueMapping('opportunity', {
            key: crmData?.ace_syncable_opp_field_key || '',
            value: crmData?.ace_syncable_opp_field_value || '',
            parentTable: crmData?.ace_syncable_opp_parent_table || '',
            dataType: crmData?.ace_syncable_opp_field_data_type || '',
          })
        )
      }
    } catch (error) {
      console.error(error)
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    }
  }

export const removeMandatoryFieldRow = (index: number) => ({
  type: CrmAceMappingActions.REMOVE_MANDATORY_FIELD_ROW as CrmAceMappingActions.REMOVE_MANDATORY_FIELD_ROW,
  payload: index,
})

export const removeMandatoryFieldAndWarning =
  (objectType: CRMObjectType, index: number) => (dispatch: Dispatch) => {
    dispatch(actionTypeWrapper(objectType, removeMandatoryFieldRow(index)))
    dispatch(
      actionTypeWrapper(objectType, removeMandatoryWarningMessage(index))
    )
  }

export const setHasRules = (aceKey: string, value: boolean) => ({
  type: CrmAceMappingActions.SET_HAS_RULES,
  payload: {
    aceKey,
    value,
  },
})
