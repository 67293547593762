import { OtherSidePanels } from './reducer'

export enum NotificationsActionTypes {
  SHOW_NOTIFICATION_SIDE_PANEL = 'SHOW_NOTIFICATION_SIDE_PANEL',
  HiDE_NOTIFICATION_SIDE_PANEL = 'HiDE_NOTIFICATION_SIDE_PANEL',
  SET_NOTIFICATION_ON_SIDE_PANEL = 'SET_NOTIFICATION_SIDE_PANEL',
  SET_NOTIFICATION_SIDE_PANEL_LOADING = 'SET_NOTIFICATION_SIDE_PANEL_LOADING',
  SHOW_OTHER_NOTIFICATION_SIDE_PANEL = 'SHOW_OTHER_NOTIFICATION_SIDE_PANEL',
  HIDE_OTHER_NOTIFICATION_SIDE_PANEL = 'HIDE_OTHER_NOTIFICATION_SIDE_PANEL',
}

export const showNotificationSidePanel = () => ({
  type: NotificationsActionTypes.SHOW_NOTIFICATION_SIDE_PANEL,
})

export const hideNotificationSidePanel = () => ({
  type: NotificationsActionTypes.HiDE_NOTIFICATION_SIDE_PANEL,
})

export const showOtherNotificationSidePanel = (key: OtherSidePanels) => ({
  type: NotificationsActionTypes.SHOW_OTHER_NOTIFICATION_SIDE_PANEL,
  payload: key,
})

export const hideOtherNotificationSidePanel = (key: OtherSidePanels) => ({
  type: NotificationsActionTypes.HIDE_OTHER_NOTIFICATION_SIDE_PANEL,
  payload: key,
})

export const setNotificationSidePanelLoading = (showLoading: boolean) => ({
  type: NotificationsActionTypes.SET_NOTIFICATION_SIDE_PANEL_LOADING,
  payload: showLoading,
})

export const setNotificationOnSidePanel = (
  notificationInfo: Record<string, string>
) => ({
  type: NotificationsActionTypes.SET_NOTIFICATION_ON_SIDE_PANEL,
  payload: notificationInfo,
})
