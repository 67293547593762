import { SnackBarAlertType, SnackbarAlert } from '@labrav/react-components'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from '../../../store'
import { updateAppAlert } from '../../modules/appAlert/actions'
import { CRMAuthSuccessMessage } from '../../utils/messagesContants'
import { AppAlertState } from '../../modules/appAlert/reducer'

export const SnackBar = () => {
  const errorState = useSelector<AppAlertState>(state => state.appAlert)

  const dispatch = useDispatch()

  const close = () => {
    const resetError: AppAlertState = {
      message: undefined,
      messageType: 'NONE',
    }
    dispatch(updateAppAlert(resetError))
  }
  useEffect(() => {
    if (errorState !== undefined) {
      if (errorState.autoClose && errorState.message !== CRMAuthSuccessMessage)
        setTimeout(close, 10000)
    }
  }, [errorState])

  if (!errorState.message) {
    return <></>
  }

  return (
    <SnackbarAlert
      snackbarAlertType={(errorState.messageType as string).toLowerCase() as unknown as SnackBarAlertType}
      open={true}
      handleClose={close}
      message={errorState.message}
    />
  )
}
