import { Dispatch } from 'redux'
import { AxiosError, AxiosResponse } from 'axios'
import { getErrorMessages } from '../../utils/error'
import { RequestFailureMessage } from '../../utils/messagesContants'

export enum AutoSaveActionTypes {
  SET_AUTO_SAVE_STATUS = 'SET_AUTO_SAVE_STATUS',
  CLEAR_AUTO_SAVE_STATUS = 'CLEAR_AUTO_SAVE_STATUS',
}

export type AutoSaveTypes = 'standBy' | 'loading' | 'success' | 'error'

export type AutoSavePayload = {
  key: string
  status: AutoSaveTypes
  error?: string
}

export const setAutoSaveLoading = (key: string) => ({
  type: AutoSaveActionTypes.SET_AUTO_SAVE_STATUS,
  payload: {
    key,
    status: 'loading',
  } as AutoSavePayload,
})
export const clearAutosaveStatus = () => ({
  type: AutoSaveActionTypes.CLEAR_AUTO_SAVE_STATUS,
})
export const setAutoSaveError = (key: string, error: string) => ({
  type: AutoSaveActionTypes.SET_AUTO_SAVE_STATUS,
  payload: {
    key,
    status: 'error',
    error,
  } as AutoSavePayload,
})

export const setAutoSaveSuccess = (key: string) => ({
  type: AutoSaveActionTypes.SET_AUTO_SAVE_STATUS,
  payload: {
    key,
    status: 'success',
  } as AutoSavePayload,
})

export const autoSave =
  (key: string, autoSaveFn: () => Promise<any>) =>
  async (dispatch: Dispatch) => {
    dispatch(setAutoSaveLoading(key))
    try {
      const error = await autoSaveFn()
      if (error) {
        dispatch(
          setAutoSaveError(
            key,
            getErrorMessages([RequestFailureMessage])(
              (error as AxiosError).response as AxiosResponse<ErrorResponse>
            )
          )
        )
      } else {
        dispatch(setAutoSaveSuccess(key))
      }
    } catch (err) {
      dispatch(
        setAutoSaveError(
          key,
          getErrorMessages([RequestFailureMessage])(
            (err as AxiosError).response as AxiosResponse<ErrorResponse>
          )
        )
      )
    }
  }
