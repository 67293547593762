import InsertChartIcon from '@material-ui/icons/InsertChart'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'
import DescriptionIcon from '@material-ui/icons/Description'
import SettingsIcon from '@material-ui/icons/Settings'
import {
  IconTypeForSidePanel,
  NavigationRoutesType,
} from '@labrav/react-components/lib/@types/v2/components/SidePanel/SidePanel'
import { AccountCircle, Dvr } from '@material-ui/icons'
import { AutoGraph, HandshakeOutlined } from '@mui/icons-material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBuilding,
  faCloud,
  faSquareSliders,
  faGear,
  faListCheck,
  faGrid2,
  faHistory,
  faObjectExclude,
  faList,
  faBadgeCheck,
  faChartMixedUpCircleDollar,
  faEye,
  faRankingStar,
} from '@fortawesome/pro-regular-svg-icons'
import { ProductType } from '../../modules/partner/reducer'
const settingsRoute = (product?: ProductType): NavigationRoutesType => ({
  key: 101,
  name: 'My settings',
  path: product ? `/settings/${product.toLowerCase()}` : '/settings',
  icon: () => (
    <FontAwesomeIcon style={{ height: '20px', width: '24px' }} icon={faGear} />
  ),
  disabled: false,
})
export const OppSyncNavigationRoutes: NavigationRoutesType[] = [
  {
    key: 1,
    path: '/oppsync/dashboard',
    name: 'Dashboard',
    icon: () => (
      <FontAwesomeIcon
        style={{ height: '20px', width: '24px' }}
        icon={faGrid2}
      />
    ),
    disabled: false,
  },
  {
    key: 2,
    path: '/oppsync/sales?object_type=opportunity',
    name: 'Sales pipeline',
    icon: () => (
      <FontAwesomeIcon
        style={{ height: '20px', width: '24px' }}
        icon={faObjectExclude}
      />
    ),
    disabled: false,
  },
  {
    key: 3,
    path: '/oppsync/sync-history',
    name: 'Sync history',
    icon: () => (
      <FontAwesomeIcon
        style={{ height: '20px', width: '24px' }}
        icon={faHistory}
      />
    ),
    disabled: false,
  },
  settingsRoute('oppsync'),
]
export const OppSyncNavigationRoutesCosell: NavigationRoutesType[] = [
  {
    key: 1,
    path: '/oppsync/dashboard',
    name: 'Dashboard',
    icon: () => <FontAwesomeIcon style={{ height: '16px' }} icon={faGrid2} />,
    disabled: false,
  },
  {
    key: 2,
    path: '/oppsync/cosell/outgoing-referrals',
    name: 'Co-Sell pipeline',
    icon: () => (
      <FontAwesomeIcon style={{ height: '16px' }} icon={faObjectExclude} />
    ),
    disabled: false,
  },
  {
    key: 3,
    path: '/oppsync/sync-history',
    name: 'Sync history',
    icon: () => <FontAwesomeIcon style={{ height: '16px' }} icon={faHistory} />,
    disabled: false,
  },
  settingsRoute('oppsync'),
]
export const OppSyncOnboardingRoutes: {
  title: string
  routes: NavigationRoutesType[]
} = {
  title: 'Onboarding',
  routes: [
    {
      key: 1,
      path: '/oppsync/onboarding/company-information',
      name: 'Company details',
      icon: () => (
        // this style is required, I don't know why after compiling
        // it doesn't care about the classes styles and just goes out of it's way
        // to make the icon really big, so this style keeps it in place.
        // if a better way is found to do this, please do it.
        <FontAwesomeIcon style={{ height: '16px' }} icon={faBuilding} />
      ),
      disabled: false,
    },
    {
      key: 2,
      path: '/oppsync/onboarding/aws-settings',
      name: 'Cloud settings',
      icon: () => <FontAwesomeIcon style={{ height: '16px' }} icon={faCloud} />,
      disabled: false,
    },
    {
      key: 3,
      path: '/oppsync/onboarding/crm-settings',
      name: 'CRM settings',
      icon: () => (
        <FontAwesomeIcon style={{ height: '16px' }} icon={faSquareSliders} />
      ),
      disabled: false,
    },
  ],
}
export const FlyoutOnboardingRoutes: {
  title: string
  routes: NavigationRoutesType[]
} = {
  title: 'Onboarding steps -',
  routes: [
    {
      key: 1,
      path: '/flyout/onboarding/company-information',
      name: 'Company details',
      icon: () => (
        <FontAwesomeIcon style={{ height: '16px' }} icon={faBuilding} />
      ),
      disabled: false,
    },
    {
      key: 2,
      path: '/flyout/onboarding/cloud-settings',
      name: 'Cloud settings',
      icon: () => <FontAwesomeIcon style={{ height: '16px' }} icon={faCloud} />,
      disabled: false,
    },
    {
      key: 4,
      path: '/flyout/onboarding/crm-settings',
      name: 'CRM settings',
      icon: () => (
        <FontAwesomeIcon style={{ height: '16px' }} icon={faSquareSliders} />
      ),
      disabled: false,
    },
  ],
}
export const CloudFaaSNavigationRoutes: NavigationRoutesType[] = []
export const FlyoutOverviewRoute: NavigationRoutesType[] = [
  {
    key: 0,
    path: '/flyout/overview',
    name: 'Overview',
    icon: () => <FontAwesomeIcon style={{ height: '16px' }} icon={faEye} />,
    disabled: false,
  }
]
export const FlyOutNavigationRoutes: NavigationRoutesType[] = [
  {
    key: 1,
    path: '/flyout/products-listing',
    name: 'Product listings',
    icon: () => <FontAwesomeIcon style={{ height: '16px' }} icon={faList} />,
    disabled: false,
  },
  {
    key: 2,
    path: '/flyout/offers-authorizations/private-offers',
    name: 'Offers & authorizations',
    icon: () => (
      <FontAwesomeIcon style={{ height: '16px' }} icon={faBadgeCheck} />
    ),
    disabled: false,
  },
  {
    key: 3,
    path: '/flyout/revenue',
    name: 'Revenue insights',
    icon: () => (
      <FontAwesomeIcon
        style={{ height: '16px' }}
        icon={faChartMixedUpCircleDollar}
      />
    ),
    disabled: false,
  },
  settingsRoute('flyout'),
]
export const oldFooterRoutes: NavigationRoutesType[] = [
  {
    key: 5,
    path: '/oppsync/settings/crm',
    name: 'My settings',
    icon: SettingsIcon,
    disabled: false,
  },
  {
    key: 6,
    path: '/user/profile',
    name: 'Profile',
    hasSubMenu: true,
    icon: AccountCircle,
    disabled: false,
  },
]
export const footerRoutes: NavigationRoutesType[] = [
  {
    key: 5,
    path: '/settings',
    name: 'My settings',
    icon: SettingsIcon,
    disabled: false,
  },
  {
    key: 6,
    path: '/user/profile',
    name: 'Profile',
    hasSubMenu: true,
    icon: AccountCircle,
    disabled: false,
  },
]
export const footerRoutesForAdmin: NavigationRoutesType[] = [
  {
    key: 6,
    path: '/user/profile',
    name: 'Profile',
    hasSubMenu: true,
    icon: AccountCircle,
    disabled: false,
  },
]
export const productSelectorDataV2 = {
  active: '',
  options: [
    {
      shortLabel: 'FO',
      label: 'FlyOut',
      value: 'flyout',
      link: 'labra.io/oppsync/',
    },
    {
      shortLabel: 'OS',
      label: 'OppSync',
      value: 'oppsync',
      link: 'labra.io/oppsync/',
    },
  ],
}

const marketplaceSeoIcon = () => <FontAwesomeIcon style={{ height: '20px' }} icon={faRankingStar} />

export const marketplaceSeo: NavigationRoutesType[] = [
  {
    key: 6,
    path: '/search-ranking',
    name: 'Marketplace seo',
    hasSubMenu: true,
    icon: marketplaceSeoIcon,
    disabled: false,
    subRoutes: [{
      key: 6,
      path: '/flyout/search-ranking',
      name: 'Search Ranking',
      hasSubMenu: true,
      icon: marketplaceSeoIcon,
      disabled: false,
    }]
  },
]