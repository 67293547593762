import { UserProfile } from '../modules/userProfile/action'
import { Role } from './roles'

export const isAdminRole = (user: UserProfile | undefined) =>
  user?.roles?.some(r =>
    [Role.ADMIN, Role.SUPER_ADMIN, Role.ACCOUNT_EXECUTIVE].includes(r)
  ) || false

export const isAdminOrOwner = (user: UserProfile | undefined) =>
  user?.roles?.some(r =>
    [Role.PARTNER_ADMIN, Role.PARTNER_OWNER].includes(r)
  ) || false
