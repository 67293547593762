import { UserType } from '../modules/AddUserForm/reducer'
import { ArrayReducerRow } from '../modules/arrayReducer'

export const isAtleastAndAtMostOneOwner = (
  users: ArrayReducerRow<UserType>[]
) => {
  return (
    users.filter(({ data }) => (data.role as string) === 'PARTNER_OWNER')
      .length === 1
  )
}
