import { uniqueId } from 'lodash'
import { ArrayActionType } from './action'

export type ArrayAction =
  | {
      type: ArrayActionType.REMOVE
      payload: string
    }
  | {
      type: ArrayActionType.REMOVE_BY_INDEX
      payload?: number
    }
  | {
      type: ArrayActionType.UPDATE
      payload: {
        id: string
        data: unknown
      }
    }
  | {
      type: ArrayActionType.UPDATE_BY_INDEX
      payload: {
        index: number
        data: unknown
      }
    }
  | {
      type: ArrayActionType.ADD
    }
  | {
      type: ArrayActionType.REPLACE_ALL
      payload: unknown[]
    }

export type ArrayReducerRow<T> = {
  id: string
  data: T
}

export type IdConstructor = () => string
export const defaultIdConstructor: IdConstructor = () =>
  uniqueId('arrayReducer_')

const generateRow: (
  idConstructor: IdConstructor
) => <T>(data: T) => ArrayReducerRow<T> = getId => data => ({
  id: getId(),
  data,
})

const zeroValueIfEmpty =
  <T>(getZeroValue: () => ArrayReducerRow<T>) =>
  (state: ArrayReducerRow<T>[]) =>
    state.length === 0 ? [getZeroValue()] : state

export const arrayReducer =
  <T>(zeroValueRow: T, idConstructor: IdConstructor = defaultIdConstructor) =>
  (
    state: ArrayReducerRow<T>[] = [generateRow(idConstructor)(zeroValueRow)],
    action: ArrayAction = {} as unknown as ArrayAction
  ) => {
    const getRowWithId = generateRow(idConstructor)
    const getZeroValueIfEmpty = zeroValueIfEmpty(() =>
      getRowWithId(zeroValueRow)
    )
    switch (action.type) {
      case ArrayActionType.ADD: {
        return state.concat(getRowWithId(zeroValueRow))
      }
      case ArrayActionType.REPLACE_ALL: {
        const data = action.payload
        const rowsWithId = data.map(getRowWithId)
        return [...(rowsWithId as ArrayReducerRow<T>[])]
      }
      case ArrayActionType.REMOVE: {
        // if all values are removed, then fill with zero value
        const idToRemove = action.payload
        const newState = state.filter(({ id }) => id !== idToRemove)
        return getZeroValueIfEmpty(newState)
      }
      case ArrayActionType.REMOVE_BY_INDEX: {
        // if the index is not provided, remove the last item
        // if all values are removed, then fill with zero value
        const index = action.payload
        if (!index) {
          const newState = state.slice(0, state.length - 1)
          return getZeroValueIfEmpty(newState)
        }
        const newState = [...state]
        newState.splice(index, 1)
        return getZeroValueIfEmpty(newState)
      }
      case ArrayActionType.UPDATE: {
        const { id, data } = action.payload
        return state.map(item => {
          if (item.id === id) {
            return { ...item, data }
          }
          return item
        })
      }
      case ArrayActionType.UPDATE_BY_INDEX: {
        const { index: indexToUpdate, data } = action.payload
        return state.map((item, index) =>
          indexToUpdate === index ? { ...item, data } : item
        )
      }
      default:
        return state
    }
  }
