import { Reducer } from 'redux'
import { AutoSavePayload, AutoSaveActionTypes, AutoSaveTypes } from './actions'

export type AutoSaveState = Record<
  string,
  { error?: string; status: AutoSaveTypes }
>

type actionType =
  | {
      type: AutoSaveActionTypes.SET_AUTO_SAVE_STATUS
      payload: AutoSavePayload
    }
  | {
      type: AutoSaveActionTypes.CLEAR_AUTO_SAVE_STATUS
    }

export const reducer: Reducer<AutoSaveState, actionType> = (
  state = {},
  action
) => {
  switch (action.type) {
    case AutoSaveActionTypes.SET_AUTO_SAVE_STATUS:
      return {
        ...state,
        [action.payload.key]: {
          status: action.payload.status,
          error: action.payload.error,
        },
      }
    case AutoSaveActionTypes.CLEAR_AUTO_SAVE_STATUS:
      return (state = {})
    default:
      return state
  }
}
