import { makeStyles, Theme } from '@material-ui/core/styles'

type styles = {
  headerStripeColor: string
  selected: boolean
  active: boolean
}

export const useStyles = (stylesObj: styles) =>
  makeStyles((theme: Theme) => ({
    body: {
      display: 'flex',
      flexDirection: 'column',
      height: '523px',
    },
    root: {
      backgroundColor:
        stylesObj.selected || stylesObj.active
          ? stylesObj.headerStripeColor
          : theme.palette.bg.main,
      borderRadius: stylesObj.selected ? '8px 8px 0 0' : '8px',
      boxShadow: '0px 3px 6px #00000019',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
    },
    container: {
      padding: theme.spacing(0.4, 5.5),
      height: '349px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      position: 'relative',
    },
    stripe: {
      flex: '0 1 9px',
      backgroundColor: stylesObj.headerStripeColor,
    },
    heading: {
      color:
        stylesObj.selected || stylesObj.active
          ? theme.palette.bg.main
          : theme.palette.black.main,
      fontWeight: 800,
      fontSize: '48px',
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
    subheading: {
      color:
        stylesObj.selected || stylesObj.active
          ? theme.palette.bg.main
          : theme.palette.black.main,

      textAlign: 'left',
      fontWeight: 500,
      fontSize: '18px',
      marginBottom: theme.spacing(5),
    },
    description: {
      color:
        stylesObj.selected || stylesObj.active
          ? theme.palette.bg.main
          : theme.palette.primary.dark,

      textAlign: 'left',
      fontSize: '16px',
      height: '72px',
    },
    knowMore: {
      color: theme.palette.opaque.dark,
      marginTop: theme.spacing(2.5),
      '&:hover': {
        cursor: 'pointer',
      },
    },
    buttonPrimary: {
      width: '148px',
      height: '37px',
      backgroundColor: theme.palette.opaque.dark,
      color: theme.palette.secondary.main,
      padding: theme.spacing(1, 2, 1, 2),
      textTransform: 'none',
      fontSize: '12px',
      borderRadius: '2px',
      alignSelf: 'center',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.opaque.main,
      },
      '&:disabled': {
        backgroundColor: theme.palette.bg.main,
        color: theme.palette.check.light,
      },
    },
    oppsynButton: {
      height: '2.5rem',
      whiteSpace: 'normal',
      width: '1rem',
    },
    checkIcon: {
      alignSelf: 'center',
      marginTop: theme.spacing(0.2),
      color: theme.palette.labraCheckbox.main,
      width: '39.98px',
      height: '39.98px',
    },
    divider: {
      position: 'relative',
      backgroundColor: theme.palette.opaque.light,
      height: '1px',
    },
    arrowContainer: {
      position: 'relative',
      display: 'flex',
      height: '41px',
      backgroundColor: stylesObj.headerStripeColor,
      opacity: 10,
      borderRadius: '0 0 8px 8px',
    },
    arrowDown: {
      margin: '0 auto',
      width: 0,
      height: 0,
      borderLeft: '20px solid transparent',
      borderRight: '20px solid transparent',
      borderTop: `20px solid ${stylesObj.headerStripeColor}`,
    },
    actions: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      flexDirection: 'column',
      flex: '1 1 auto',
      justifyContent: 'center',
      textAlign: 'center',
    },
    cloudLink: {
      textDecoration: 'none',
      marginTop: theme.spacing(0.5),
    },
    checkIconFilled: {
      alignSelf: 'center',
      color: theme.palette.labraCheckbox.main,
      paddingRight: 3,
      width: '1.2rem',
      height: '1.2rem',
      marginBottom: theme.spacing(-0.5),
    },
    licenseDescription: {
      color:
        stylesObj.selected || stylesObj.active
          ? theme.palette.bg.main
          : theme.palette.primary.dark,

      textAlign: 'left',
      fontSize: '16px',
      marginTop: '-2.5rem',
    },
    licenseContainer: {
      padding: theme.spacing(0, 5.5),
      height: '2.5rem',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
    },
  }))
