import { SyncData, SyncHistoryActionTypes, syncResponse } from './action'

export interface SyncHistoryState {
  syncHistory: SyncData[]
  pageSize: number
  noOfPage: number | null
  lastSyncDate?: string | null
  lastSyncStatus?: string
  pageNumber: number
  errMess: unknown
}
export const initialState: SyncHistoryState = {
  syncHistory: [],
  pageSize: 10,
  noOfPage: null,
  pageNumber: 1,
  errMess: null,
  lastSyncDate: '',
  lastSyncStatus: '',
}
export interface syncHistoryPayload {
  data: syncResponse
  pageSize: number
}
type Action =
  | {
      type: SyncHistoryActionTypes.SET_SYNC_HISTORY
      payload: syncHistoryPayload
    }
  | {
      type: SyncHistoryActionTypes.SET_LIMIT
      payload: number
    }
  | {
      type: SyncHistoryActionTypes.SET_PAGE_NUMBER
      payload: number
    }
  | {
      type: SyncHistoryActionTypes.SET_SYNC_ERROR
      payload: unknown
    }
  | {
      type: SyncHistoryActionTypes.SET_LAST_SYNC
      payload: syncHistoryPayload
    }

export const SyncHistoryReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case SyncHistoryActionTypes.SET_SYNC_HISTORY: {
      return {
        ...state,
        syncHistory: action.payload.data.syncs,
        noOfPage: Math.ceil(
          action.payload.data.totalCount / action.payload.pageSize
        ),
        errMess: null,
      }
    }
    case SyncHistoryActionTypes.SET_LIMIT: {
      return {
        ...state,
        pageSize: action.payload,
        errMess: null,
      }
    }
    case SyncHistoryActionTypes.SET_PAGE_NUMBER: {
      return {
        ...state,
        pageNumber: action.payload,
      }
    }
    case SyncHistoryActionTypes.SET_SYNC_ERROR: {
      return {
        ...state,
        errMess: action.payload,
      }
    }
    case SyncHistoryActionTypes.SET_LAST_SYNC: {
      return {
        ...state,
        lastSyncDate: action.payload.data.syncs[0].completedAt,
        lastSyncStatus: action.payload.data.syncs[0].status,
        errMess: null,
      }
    }
    default:
      return state
  }
}
