import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    gap: theme.spacing(4),
    flexDirection: 'column',
  },
  listRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  emailSection: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: theme.spacing(1.5),
  },
  actionPart: {
    marginRight: theme.spacing(2),
    fontSize: '14px',
    color: theme.palette.labraText.description,
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  email: {
    fontSize: '16px',
    color: theme.palette.labraText.tertiary,
  },
  pending: {
    fontSize: '12px',
    color: theme.palette.labraText.description,
  },
  emptyName: {
    border: `1px dotted ${theme.palette.dividerV2.main}`,
    height: '24px',
    width: '24px',
    borderRadius: '52px',
  },
}))
