import { Box, Typography } from '@material-ui/core'
import { Product } from '../modules/partner/action'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'

export type ProductDetails = {
  productName: string
  id: Product
  productFullForm: string
  queryId?: string
  productDescription: string
  headerStripeColor: string
  productAvailable: boolean
  info?: string | JSX.Element
}

export const productDetailsEnabled: ProductDetails[] = [
  {
    productName: 'OppSync',
    id: 'oppsync',
    queryId: 'oppsync',
    productFullForm: 'Cloud Co-sell Management System',
    productDescription:
      ' Navigate co-selling with cloud providers efficiently, accelerating sales growth and strengthening partnerships.',
    headerStripeColor: '#475672',
    productAvailable: true,
    info: (
      <>
        <Typography>
          <Box fontWeight={600} m={1}>
            Sales pipeline integration and management
          </Box>

          <Box fontWeight="fontWeightRegular" m={1}>
            OppSync is built for sales and alliance leads at high-performing
            ISVs, SaaS, and Consulting partners to effectively co-sell with
            their cloud hyperscaler partner by integrating their CRM across
            various cloud hyperscaler co-selling solutions.
          </Box>

          <Box fontWeight="fontWeightRegular" m={1}>
            With OppSync, swap the manual double-entry system for automated
            bi-directional updates of opportunity and lead data sharing between
            your CRM (e.g. Salesforce, Hubspot, Connectwise, and more) and your
            cloud hyperscaler co-selling solution.
          </Box>
          <br />
          <Box fontWeight="fontWeightRegular" m={1} style={{ display: 'flex' }}>
            <CheckCircleOutlineIcon style={{ paddingRight: '10px' }} />
            Opportunity and lead data updates and events are replicated in both
            directions: by your staff in your CRM to your hyperscaler co-selling
            platform and vice versa.
          </Box>
          <Box fontWeight="fontWeightRegular" m={1} style={{ display: 'flex' }}>
            <CheckCircleOutlineIcon style={{ paddingRight: '10px' }} />
            Manage your entire co-sell pipeline from your CRM and make your
            sales and alliance teams happily productive.
          </Box>
          <Box fontWeight="fontWeightRegular" m={1} style={{ display: 'flex' }}>
            <CheckCircleOutlineIcon style={{ paddingRight: '10px' }} />
            Intuitively map data in a simple, flexible, and intuitive manner to
            keep information consistent across different sales systems.
          </Box>
          <Box fontWeight="fontWeightRegular" m={1} style={{ display: 'flex' }}>
            <CheckCircleOutlineIcon style={{ paddingRight: '10px' }} />
            Keep your team updated in real-time with automated notifications to
            third-party integrations like Slack etc.
          </Box>
        </Typography>
      </>
    ),
  },
  {
    productName: 'FlyOut',
    id: 'flyout',
    queryId: 'flyout',
    productFullForm: ' Cloud Marketplace Commerce System',
    productDescription:
      'List, Manage and scale your products on cloud Marketplaces.',
    headerStripeColor: '#518C84',
    productAvailable: true,
    info: (
      <>
        <Typography>
          <Box fontWeight={600} m={1}>
            Cloud Marketplace Commerce Management
          </Box>

          <Box fontWeight="fontWeightRegular" m={1}>
            Flyout is a zero code solution designed to makes it easier and
            efficient for B2B businesses to operate and conquer cloud
            marketplaces.
          </Box>
          <br />
          <Box fontWeight="fontWeightRegular" m={1} style={{ display: 'flex' }}>
            <CheckCircleOutlineIcon style={{ paddingRight: '10px' }} />
            Fast track your listing on cloud marketplaces
          </Box>
          <Box fontWeight="fontWeightRegular" m={1} style={{ display: 'flex' }}>
            <CheckCircleOutlineIcon style={{ paddingRight: '10px' }} />
            Integrate and manage workflows directly from your CRM.
          </Box>
          <Box fontWeight="fontWeightRegular" m={1} style={{ display: 'flex' }}>
            <CheckCircleOutlineIcon style={{ paddingRight: '10px' }} />
            Manage metering, Private offers, upgrades and renewals.
          </Box>
          <Box fontWeight="fontWeightRegular" m={1} style={{ display: 'flex' }}>
            <CheckCircleOutlineIcon style={{ paddingRight: '10px' }} />
            Track the right metrics and simplify your quote to cash workflow.
          </Box>
        </Typography>
      </>
    ),
  },
  {
    productName: 'CloudFaaS',
    id: 'cloudfaas',
    queryId: 'cloudfaas',
    productFullForm: 'Cloud Management and Governance',
    productDescription:
      'Efficiently manage your cloud resources with better security and lesser cost.',
    headerStripeColor: '#77578C',
    productAvailable: false,
  },
]
