import { startLoading, stopLoading } from '../loading/actions'
import { updateAppAlert } from '../appAlert/actions'
import {
  inviteUsersIdentityService,
  postAddNewPartnerData,
  postAddUsersData,
} from '../../../common/api'
import {
  RequestFailureMessage,
  AddUserRequestSuccess,
} from '../../utils/messagesContants'
import { ErrorUsers, UserType } from './reducer'
import { AxiosError } from 'axios'
import {
  AuthProvider,
  getUsers,
} from '../../../oppsync/modules/userList/action'
import { LoadingTypes } from '../loading/reducer'
import { errorLogger } from '../../utils/errorLogger'
import { arrayReducerActionWrapper } from '../arrayReducer/action'
import { RootState, AppDispatch } from '../../../store'

const errorMessMapping: Record<string, string> = {
  'error while creating users: error while creating okta user: The API returned an error: Api validation failed: login. Causes: errorSummary: login: An object with this field already exists in the current organization':
    'Email already exists.',
  'Limit reached to register users':
    'You have reached the maximum number of users allowed for your account. Please upgrade now to add more users.',
}

export enum AddUSerFormActionTypes {
  SHOW_ADD_USER_FORM = 'SHOW_ADD_USER_FORM',
  HIDE_ADD_USER_FORM = 'HIDE_ADD_USER_FORM',
  START_USER_DATA_LOADING = 'START_USER_DATA_LOADING',
  END_USER_DATA_LOADING = 'END_USER_DATA_LOADING',
  ADD_USER_ERROR_DATA = 'ADD_USER_ERROR_DATA',
  ADD_USER_DATA_CLEANUP = 'ADD_USER_DATA_CLEANUP',
}

export const showAddUserForm = () => ({
  type: AddUSerFormActionTypes.SHOW_ADD_USER_FORM,
})

export const hideAddUserForm = () => ({
  type: AddUSerFormActionTypes.HIDE_ADD_USER_FORM,
})

export const addUserErrorData = (errors: ErrorUsers[]) => ({
  type: AddUSerFormActionTypes.ADD_USER_ERROR_DATA,
  payload: errors,
})

export const startUserDataLoading = () => ({
  type: AddUSerFormActionTypes.START_USER_DATA_LOADING,
})

export const endUserDataLoading = () => ({
  type: AddUSerFormActionTypes.END_USER_DATA_LOADING,
})

export const cleanUpUserData = () => ({
  type: AddUSerFormActionTypes.ADD_USER_DATA_CLEANUP,
})

export const submitAddNewUsersData =
  (data: UserType[], partnerId: string, authProvider: AuthProvider, isAdmin: boolean = false, callBackAfterSuccess?: () => void, closeModal: boolean = true) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    await dispatch(startLoading(LoadingTypes.ADD_NEW_USER))
    try {
      const invitationData = {
        users: data,
      }
      if(isAdmin){
        await postAddNewPartnerData(invitationData)
      }else{
        if (authProvider == AuthProvider.AUTH0) {
          await inviteUsersIdentityService(invitationData, partnerId)
        } else {
          await postAddUsersData(invitationData, partnerId)
        }
      }
      
      await dispatch(
        updateAppAlert({
          message:
            data.length === 1
              ? `Invitation sent succesfully to "${data[0].email}".`
              : AddUserRequestSuccess,
          messageType: 'SUCCESS',
          autoClose: true,
        })
      )
      if(closeModal){
        await dispatch(cleanUpUserData())
        await dispatch(hideAddUserForm())
      }
    } catch (error: unknown) {
            const errMess =
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ((error as AxiosError).response?.data as any)?.message ||
        RequestFailureMessage
       dispatch(
        updateAppAlert({
          message: errorMessMapping[errMess] || errMess,
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
      if(closeModal){
        await dispatch(hideAddUserForm())
      }
    } finally {
      if(!isAdmin){
        dispatch(
          getUsers({
            partnerId,
            pageNumber: 1,
            pageSize: 500,
            authProvider,
          }) as never
        )
      }else{
        callBackAfterSuccess && callBackAfterSuccess()
      }
      dispatch(stopLoading(LoadingTypes.ADD_NEW_USER))
    }
  }
const arrayReducerActions = arrayReducerActionWrapper<UserType>('addNewUsers')
export const updateRowForAddUser = arrayReducerActions.updateRow
export const addRowForAddUser = arrayReducerActions.addRow
export const removeRowForAddUser = arrayReducerActions.removeRow
