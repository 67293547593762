import { AnyAction, Dispatch } from 'redux'
import { PartnerType, setPartner, updateCrm } from '../../partner/action'
import {
  postCRMAuthData,
  postCheckCrmConnection,
  getCRMAuth,
  deleteCRM,
  patchCRMAuthData,
  putAppMappings,
} from '../../../../common/api'
import { startLoading, stopLoading } from '../../loading/actions'
import { updateAppAlert } from '../../appAlert/actions'
import {
  CRMAuthFailureMessage,
  CRMAuthSuccessMessage,
  CRMDisconnectFailureMessage,
  CRMDisconnectSuccessMessage,
  CRMConnectionFailureMessage,
  CRMConnectionSuccessMessage,
} from '../../../utils/messagesContants'
import { AxiosError, AxiosResponse } from 'axios'
import { crmEnv } from '../../../utils/constants'
import { LoadingTypes } from '../../loading/reducer'
import { cleanUpCRMTables } from '../crmTables/action'
import { cleanUpTableFields } from '../CrmFields/actions'

import { getErrorMessages } from '../../../utils/error'
import { actionTypeWrapper } from '../../../utils/actionTypeWrapper'
import { setOnboarding, updateOnboarding } from '../actions'
import { CRMSettingStatuses } from '../reducer'
import { RootState } from '../../../../store'
import { trimVersion } from '../../../utils/helperFunctions'
import { errorLogger } from '../../../utils/errorLogger'
import { snakeize } from 'casing'
import { faLoveseat } from '@fortawesome/pro-regular-svg-icons'


export interface Creds {
  created_at?: string
  crm_auth_id?: string
  auth_key_name: string
  auth_key_value: string
}
export interface CrmAppCreds {
  secret_key: string
  client_id: string
  app_name: string
  app_link: string
  version?: string
  secret_key_updated_at: string
}
export interface FormData {
  created_at: string
  updated_at: string
  crm_id: string
  crm_type: string
  status: string
  environment_type: crmEnv
  ace_syncable_lead_field_key?: string
  ace_syncable_lead_field_value?: string
  ace_syncable_opp_field_key?: string
  ace_syncable_opp_field_value?: string
  ace_syncable_lead_parent_table?: string
  ace_syncable_opp_parent_table?: string
  ace_syncable_lead_field_data_type?: string
  ace_syncable_opp_field_data_type?: string
  last_connection_check_status?: string
  last_connection_checked_at?: string
  last_connection_result_message?: string
  deleted_at?: string
  creds: Creds[]
  crm_app_creds?: CrmAppCreds
}
export interface CRMInfo {
  crm_type: string
  update_status: boolean
}

export enum CRMAuthActionTypes {
  SET_CRM_FORM = 'SET_CRM_FORM',
  POST_CRM_LOADING = 'POST_CRM_LOADING',
  POST_CRM_FAILED = 'POST_CRM_FAILED',
  GET_CRM_FORM_DATA = 'GET_CRM_FORM_DATA',
  SET_CRM_DELETED_AT = 'SET_CRM_DELETED_AT',
  SET_VALIDATE_MISSING_FIELDS = 'SET_VALIDATE_MISSING_FIELDS',
  CHECK_CRM_CONNECTION = 'CHECK_CRM_CONNECTION',
}
export const postCRMAuth =
  (authData: unknown, partnerId: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      await dispatch(startLoading(LoadingTypes.CRM_AUTH))
      const { data } = await postCRMAuthData(snakeize(authData), partnerId)
      await dispatch(getCRMFormData(data.crm_id) as never)
      await dispatch(updateCrm(data.crm_id, PartnerType.User))
      dispatch(
        actionTypeWrapper(PartnerType.User, setPartner({ crmId: data.crm_id }))
      )

      const { CRMAuth } = getState()
      const CRMInfo: CRMInfo = {
        crm_type: CRMAuth?.crmFormData?.crm_type || '',
        update_status: true,
      }

      if (partnerId !== null) {
        await putAppMappings(partnerId, CRMInfo)
      }

      await dispatch(
        updateAppAlert({
          message: CRMAuthSuccessMessage,
          messageType: 'SUCCESS',
          autoClose: true,
        })
      )

      await dispatch(
        actionTypeWrapper(
          PartnerType.User,
          setOnboarding({
            crmSettingStatus: CRMSettingStatuses.IN_PROGRESS,
          })
        )
      )
      const { onboarding } = getState()
      await dispatch(
        updateOnboarding(
          partnerId,
          PartnerType.User,
          onboarding.user
        ) as unknown as AnyAction
      )
    } catch (error: unknown) {
      const errorMessages = getErrorMessages([CRMAuthFailureMessage])(
        (error as AxiosError<ErrorResponse>).response
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
      await dispatch(
        updateAppAlert({
          message: errorMessages,
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      await dispatch(getPostCRMFailed(errorMessages))
    } finally {
      await dispatch(stopLoading(LoadingTypes.CRM_AUTH))
    }
  }

export const patchCRMAuth =
  (crmId: string, authData: unknown, partnerId: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      dispatch(startLoading(LoadingTypes.CRM_AUTH))
      const { data } = await patchCRMAuthData(crmId, snakeize(authData))
      dispatch(setCRMFormData(data))
      await dispatch(updateCrm(data.crm_id, PartnerType.User))
      await dispatch(getCRMFormData(data.crm_id) as never)
      await dispatch(
        actionTypeWrapper(
          PartnerType.User,
          setOnboarding({
            crmSettingStatus: CRMSettingStatuses.IN_PROGRESS,
          })
        )
      )

      const { onboarding, CRMAuth } = getState()

      const CRMInfo: CRMInfo = {
        crm_type: CRMAuth?.crmFormData?.crm_type || '',
        update_status: true,
      }

      if (partnerId !== null) {
        await putAppMappings(partnerId, CRMInfo)
        await dispatch(
          updateOnboarding(
            partnerId,
            PartnerType.User,
            onboarding.user
          ) as unknown as AnyAction
        )
      }

      await dispatch(
        updateAppAlert({
          message: CRMAuthSuccessMessage,
          messageType: 'SUCCESS',
          autoClose: true,
        })
      )
    } catch (error) {
      const errorMessages = getErrorMessages([CRMAuthFailureMessage])(
        (error as AxiosError<ErrorResponse>).response
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)

      dispatch(
        updateAppAlert({
          message: errorMessages,
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      dispatch(getPostCRMFailed(errorMessages))
    } finally {
      dispatch(stopLoading(LoadingTypes.CRM_AUTH))
    }
  }

export const checkCrmConnection =
  (crm_id: string) => async (dispatch: Dispatch, getState: () => RootState) => {
    const globalState = getState()
      dispatch(startLoading(LoadingTypes.CRM_CONNECTION))
    try {
      const response = await postCheckCrmConnection(crm_id)
      dispatch(crmConnectionStatus(true))
        dispatch(
          updateAppAlert({
            message: CRMConnectionSuccessMessage,
            autoClose: true,
            messageType: 'SUCCESS',
          })
        )
    } catch (error: unknown) {
      const errMessage = getErrorMessages(['Unable to connet to CRM'])(
        (error as AxiosError<ErrorResponse>).response
      )
      errorLogger({ globalState })(error as Error)
      dispatch(crmConnectionStatus(false))
      dispatch(
        updateAppAlert({
          message: errMessage,
          autoClose: true,
          messageType: 'ERROR',
        })
      )
    } finally {
      dispatch(stopLoading(LoadingTypes.CRM_CONNECTION))
    }
  }

export const postCRMLoading = () => ({
  type: CRMAuthActionTypes.POST_CRM_LOADING,
})

export const setCRMFormData = (data: Partial<FormData>) => ({
  type: CRMAuthActionTypes.SET_CRM_FORM,
  payload: data,
})

export const setValidateMissingFields = (validate: boolean) => ({
  type: CRMAuthActionTypes.SET_VALIDATE_MISSING_FIELDS,
  payload: validate,
})

export const crmConnectionStatus = (validate: boolean) => ({
  type: CRMAuthActionTypes.CHECK_CRM_CONNECTION,
  payload: validate,
})

export const getPostCRMFailed = (errmess: unknown) => ({
  type: CRMAuthActionTypes.POST_CRM_FAILED,
  payload: errmess,
})

export const getCRMFormData =
  (crmId: string) => async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      dispatch(startLoading(LoadingTypes.CRM_AUTH))
      const { data } = await getCRMAuth(crmId)
      dispatch(showCRMFormData(data as FormData))
    } catch (error: any) {
      const errorMessage = getErrorMessages(['Unable to fetch data'])(
        error.response as AxiosResponse<ErrorResponse>
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
      await dispatch(
        updateAppAlert({
          message: errorMessage,
          messageType: 'ERROR',
          autoClose: true,
        })
      )
    } finally {
      dispatch(stopLoading(LoadingTypes.CRM_AUTH))
    }
  }
export const deleteCRMAuth =
  (partnerId: string, crmId: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      await dispatch(startLoading(LoadingTypes.CRM_AUTH))
      await deleteCRM(partnerId, crmId)
      const date = new Date().toISOString()
      dispatch(setDeletedAt(date))
      dispatch(cleanUpCRMTables())
      dispatch(cleanUpTableFields())
      await dispatch(
        updateAppAlert({
          message: CRMDisconnectSuccessMessage,
          messageType: 'SUCCESS',
          autoClose: true,
        })
      )
    } catch (err) {
      const errMess = getErrorMessages([CRMDisconnectFailureMessage])(
        (err as AxiosError<ErrorResponse>).response
      )
      dispatch(
        updateAppAlert({
          message: errMess,
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(err as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.CRM_AUTH))
    }
  }
export const showCRMFormData = (data: FormData) => ({
  type: CRMAuthActionTypes.GET_CRM_FORM_DATA,
  payload: data,
})

export const setDeletedAt = (date: string) => ({
  type: CRMAuthActionTypes.SET_CRM_DELETED_AT,
  payload: date,
})
