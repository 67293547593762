import { DateTime } from 'luxon'

export const getGraphDateLabel = (date: string) => {
  const formattedMonth = DateTime.fromISO(date).toFormat('MMM')
  const formattedYear = DateTime.fromISO(date).toFormat('yy')
  return `${formattedMonth}'${formattedYear}`
}

export const getMonthAbbreviation = (date: string): string => {
  const dateValue = new Date(date)
  return dateValue.toLocaleString('en-US', { month: 'short' })
}
