import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: '8px',
      background: theme.palette.bgPrimary.main,
      boxShadow:
        '0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15)',
      marginBottom: theme.spacing(4),
      width: '80%',
      marginLeft: theme.spacing(1.5),
      boxSizing: 'border-box',
      padding: '40px',
    },
    container: {
      maxWidth: '95%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      ['@media(max-width:1200px)']: {
        alignItems: 'center',
      },
      ['@media(max-width:768px)']: {
        alignItems: 'center',
      },
    },
    header: {
      color: theme.palette.labraText.primary,
      marginBottom: '8px',
      fontWeight: 400,
      fontSize: '22px',
      lineHeight: '28px',
    },
    subheader: {
      color: theme.palette.textField.main,
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: '0.25px',
      marginBottom: '32px',
    },
    card: {
      width: '100%',
      height: '125px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    cardAction: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      '&:hover': {
        backgroundColor: theme.palette.secondary.light,
      },
    },
    imageList: {
      display: 'flex',
      gap: theme.spacing(3),
      flexDirection: 'row',
      width: '100%',
      marginBottom: '32px',
      ['@media(max-width:1200px)']: {
        flexDirection: 'column',
      },
      ['@media(max-width:768px)']: {
        flexDirection: 'column',
      },
    },
    disabled: {
      opacity: '0.3',
      cursor: 'initial !important',
    },
    cloudImg: {
      display: 'flex',
      width: '180px',
      height: '100px',
      padding: '32px',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '10px',
      borderRadius: '8px',
      border: ' 1px solid #ECEDEF',
      background: theme.palette.bgPrimary.main,
      cursor: 'pointer',
      boxSizing: 'border-box',
    },

    continueButton: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '8px',
      flex: '1 0 0',
    },
    selected: {
      border: `2px solid ${theme.palette.button.secondary}`,
    },
  })
)
