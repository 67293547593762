import { Reducer } from 'redux'
import { AddUSerFormActionTypes } from './actions'
import { UserRole } from '../../utils/roles'

export interface UserType {
  email: string
  role: UserRole
}

export interface ErrorUsers {
  index: number
  error: string
  touched?: boolean
}

export interface HandleType {
  name: string
  value: string
}

export type AddNewUsersState = {
  openForm: boolean
  errors: ErrorUsers[]
  loading: boolean
}

export const zeroValueAddUserForm = {
  email: '',
  role: 'PARTNER_STAFF' as UserRole,
}

export const initialState: AddNewUsersState = {
  openForm: false,
  errors: [],
  loading: false,
}

export type AddUsersAction =
  | {
      type: AddUSerFormActionTypes.SHOW_ADD_USER_FORM
    }
  | {
      type: AddUSerFormActionTypes.HIDE_ADD_USER_FORM
    }
  | {
      type: AddUSerFormActionTypes.START_USER_DATA_LOADING
    }
  | {
      type: AddUSerFormActionTypes.END_USER_DATA_LOADING
    }
  | {
      type: AddUSerFormActionTypes.ADD_USER_ERROR_DATA
      payload: ErrorUsers[]
    }
  | {
      type: AddUSerFormActionTypes.ADD_USER_DATA_CLEANUP
    }

export const reducer: Reducer<AddNewUsersState, AddUsersAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case AddUSerFormActionTypes.SHOW_ADD_USER_FORM: {
      return { ...state, openForm: true }
    }
    case AddUSerFormActionTypes.HIDE_ADD_USER_FORM: {
      return { ...state, openForm: false }
    }
    case AddUSerFormActionTypes.START_USER_DATA_LOADING: {
      return { ...state, loading: true }
    }
    case AddUSerFormActionTypes.END_USER_DATA_LOADING: {
      return { ...state, loading: false }
    }
    case AddUSerFormActionTypes.ADD_USER_DATA_CLEANUP: {
      return {
        ...state,
        ...initialState,
      }
    }
    case AddUSerFormActionTypes.ADD_USER_ERROR_DATA: {
      const { payload } = action
      return { ...state, errors: payload }
    }
    default: {
      return state
    }
  }
}
