import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 'auto',
  },
  keyValContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: '0 0 auto',
    marginLeft: theme.spacing(7),
    '&:nth-of-type(1)': {
      marginLeft: theme.spacing(0),
    },
  },
  objKey: {
    fontSize: '13px',
    color: theme.palette.primary.light,
  },
  objVal: {
    fontSize: '14px',
    color: theme.palette.black.dark,
    fontWeight: 500,
  },
  resendInvite: {
    color: theme.palette.select.light,
    cursor: 'pointer',
  },
}))
