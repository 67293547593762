import {
  startLoading,
  stopLoading,
} from '../../../../common/modules/loading/actions'
import { LoadingTypes } from '../../../../common/modules/loading/reducer'
import { updateAppAlert } from '../../../../common/modules/appAlert/actions'
import { RequestFailureMessage } from '../../../../common/utils/messagesContants'
import { AxiosResponse } from 'axios'
import { camelize } from 'casing'
import { fetchProductsList } from '../../../api/markeplace'
import { getErrorMessages } from '../../../../common/utils/error'
import { errorLogger } from '../../../../common/utils/errorLogger'
import { AppDispatch, RootState } from '../../../../store'
import { CloudType } from '../../../../common/modules/types'

export enum FlyoutMigrationProductsList {
  SET_FLYOUT_MIGRATION_PRODUCTS = 'SET_FLYOUT_MIGRATION_PRODUCTS',
}
export const getFlyOutMigrationProductsData =
  (partnerId: string, cloudType: CloudType) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.FLYOUT_PRODUCT_MIGRATION_PRODUCTS))
    try {
      const { data } = await fetchProductsList(
        partnerId,
        'product_dimensions,free_trial',
        cloudType,
        true
      )
      await dispatch(setMigrationProductsData(camelize(data)))
    } catch (error) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: false,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.FLYOUT_PRODUCT_MIGRATION_PRODUCTS))
    }
  }

export const setMigrationProductsData = (
  migrationProductsData: Record<string, unknown>
) => ({
  type: FlyoutMigrationProductsList.SET_FLYOUT_MIGRATION_PRODUCTS,
  payload: migrationProductsData,
})
