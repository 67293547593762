import {
  getOverridingRulesApi,
  getRuleDefintions,
  putRuleDefinitions,
} from '../../../api/rules'
import { RootState, AppDispatch } from '../../../../store'
import { actionTypeWrapper } from '../../../utils/actionTypeWrapper'
import { RequestFailureMessage } from '../../../utils/messagesContants'
import { startLoading, stopLoading } from '../../loading/actions'
import { updateAppAlert } from '../../appAlert/actions'
import { camelize, snakeize } from 'casing'
import { OverrideRuleType } from '../../../utils/ruleTypes'
import { Rules } from './reducer'
import { setHasRules } from '../CrmAceMapping/action'
import { FieldsMap } from '../CrmAceMapping/reducer'
import { LoadingTypes } from '../../loading/reducer'
import { errorLogger } from '../../../utils/errorLogger'

export enum RuleDefinitionsActions {
  SET_RULE_DEFINITIONS = 'SET_RULE_DEFINITIONS',
  ADD_EMPTY_RULES_ROW = 'ADD_EMPTY_RULES_ROW',
  UPDATE_OPERATOR = 'UPDATE_OPERATOR',
  SET_ACE_KEY = 'SET_ACE_KEY',
  SET_CRM_KEY = 'SET_CRM_KEY',
  DELETE_ROW = 'DELETE_ROW',
  UPDATE_RULE_TYPE = 'UPDATE_RULE_TYPE',
  UPDATE_BY_VALUE = 'UPDATE_BY_VALUE',
  SET_OVERRIDE_RULE_TYPE = 'OVERRIDE_RULE_TYPE',
  SET_OVERRIDING_RULES = 'SET_OVERRIDING_RULES',
  OVERRIDING_RULES_CLEANUP = 'OVERRIDING_RULES_CLEANUP',
  START_RULE_LOADING = 'START_RULE_LOADING',
  STOP_RULE_LOADING = 'STOP_RULE_LOADING',
}
export enum RuleType {
  ACE = 'ACE',
  CRM = 'CRM',
  COMPARATOR = 'COMPARATOR',
}

export const getRuleDefinitions =
  (partnerId: string) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.GENERAL))
    try {
      const { data } = await getRuleDefintions(partnerId)
      dispatch(actionTypeWrapper('lead', setRulesDefintion(data['lead'])))
      dispatch(
        actionTypeWrapper('opportunity', setRulesDefintion(data['opportunity']))
      )
    } catch (error) {
      dispatch(
        updateAppAlert({
          message: RequestFailureMessage,
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }

export const getOverridingRules =
  (partnerId: string, crmFieldId: string, objectType: CRMObjectType) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(actionTypeWrapper(objectType, startRuleLoading()))
    try {
      const { data } = await getOverridingRulesApi(partnerId, crmFieldId)
      dispatch(
        actionTypeWrapper(data.object_type, setOverridingRules(camelize(data)))
      )
    } catch (error: unknown) {
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
      //send alert message with specific code or just ignore?
    } finally {
      dispatch(actionTypeWrapper(objectType, stopRuleLoading()))
    }
  }

export const setRulesDefintion = (data: any) => ({
  type: RuleDefinitionsActions.SET_RULE_DEFINITIONS as RuleDefinitionsActions.SET_RULE_DEFINITIONS,
  payload: data,
})

export const updateRulesDefinition = (data: unknown) => ({
  type: RuleDefinitionsActions.SET_RULE_DEFINITIONS as RuleDefinitionsActions.SET_RULE_DEFINITIONS,
  payload: data,
})

export const addEmptyRulesRow = (ruleType: string, awsKey: string) => ({
  type: RuleDefinitionsActions.ADD_EMPTY_RULES_ROW as RuleDefinitionsActions.ADD_EMPTY_RULES_ROW,
  payload: { ruleType, awsKey },
})

export const setOverridingRules = (data: unknown) => ({
  type: RuleDefinitionsActions.SET_OVERRIDING_RULES as RuleDefinitionsActions.SET_OVERRIDING_RULES,
  payload: data,
})

export const handleFieldUpdation =
  (
    ruleType: string,
    selectedValue: string | Record<string, string>,
    operand: string,
    index: number,
    awsKey: string,
    objectType: CRMObjectType
  ) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const type = ruleType.toUpperCase()
    if (operand === 'operator') {
      await dispatch(
        actionTypeWrapper(
          objectType,
          setOperator(selectedValue as string, index, awsKey)
        )
      )
    } else {
      switch (type) {
        case RuleType.ACE:
          await dispatch(
            actionTypeWrapper(
              objectType,
              setAceKey(awsKey, selectedValue as string, index, operand, type)
            )
          )

          break
        case RuleType.COMPARATOR:
          {
            const mappingData = getState().crmAceMapping[objectType].mappingKeys
              .fields[selectedValue as string] as FieldsMap
            await dispatch(
              actionTypeWrapper(
                objectType,
                setAceKey(
                  awsKey,
                  selectedValue as string,
                  index,
                  operand,
                  type,
                  mappingData
                )
              )
            )
          }
          break
        case RuleType.CRM:
          if (operand === 'first') {
            await dispatch(
              actionTypeWrapper(
                objectType,
                setCRMKey(
                  awsKey,
                  selectedValue as Record<string, string>,
                  index,
                  operand,
                  type
                )
              )
            )
          } else if (operand === 'second') {
            await dispatch(
              actionTypeWrapper(
                objectType,
                setCRMKey(awsKey, selectedValue as string, index, operand, type)
              )
            )
          } else if (operand === 'parent') {
            await dispatch(
              actionTypeWrapper(
                objectType,
                setCRMKey(awsKey, selectedValue as string, index, operand, type)
              )
            )
          }
          break
      }
    }
  }

export const setOperator = (
  operator: string,
  index: number,
  awsKey: string
) => ({
  type: RuleDefinitionsActions.UPDATE_OPERATOR,
  payload: { operator: operator, awsKey: awsKey, index: index },
})

export const setAceKey = (
  awsKey: string,
  selectedValue: string,
  index: number,
  operand: string,
  type: string,
  mappingData?: FieldsMap
) => ({
  type: RuleDefinitionsActions.SET_ACE_KEY,
  payload: {
    awsKey,
    selectedValue,
    index,
    operand,
    type,
    mappingData,
  },
})

export const setCRMKey = (
  awsKey: string,
  selectedValue: string | Record<string, string>,
  index: number,
  operand: string,
  type: string
) => ({
  type: RuleDefinitionsActions.SET_CRM_KEY,
  payload: {
    awsKey,
    selectedValue,
    index,
    operand,
    type,
  },
})

export const deleteRulesRow = (index: number, awsKey: string) => ({
  type: RuleDefinitionsActions.DELETE_ROW,
  payload: { index, awsKey },
})

export const updateRule = (type: string, awsKey: string) => ({
  type: RuleDefinitionsActions.UPDATE_RULE_TYPE,
  payload: { type, awsKey },
})

const isRuleConditional = (rules: Rules) =>
  rules.overridingRules.override === OverrideRuleType.CONDITIONAL

const clearRuleConditions = (rules: Rules) => ({
  ...rules,
  overridingRules: {
    override: rules.overridingRules.override,
  },
})

export const saveRulesDefinitions =
  (
    partnerId: string,
    crmFieldId: string,
    data: Rules,
    objectType: CRMObjectType
  ) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(actionTypeWrapper(objectType, startRuleLoading()))
    try {
      let newData: unknown = data
      if (!isRuleConditional(data)) {
        newData = clearRuleConditions(data)
      }
      await putRuleDefinitions(partnerId, crmFieldId, snakeize(newData))
      await dispatch(
        actionTypeWrapper(
          data.objectType as CRMObjectType,
          setHasRules(data.awsKey, isRuleConditional(data))
        )
      )
    } catch (e) {
      dispatch(
        updateAppAlert({
          message: RequestFailureMessage,
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(e as Error)
    } finally {
      dispatch(actionTypeWrapper(objectType, stopRuleLoading()))
    }
  }

export const updateByValue = (index: number, byValue: string) => ({
  type: RuleDefinitionsActions.UPDATE_BY_VALUE,
  payload: { byValue, index },
})

export const setOverrideRuleType = (ruleType: OverrideRuleType) => ({
  type: RuleDefinitionsActions.SET_OVERRIDE_RULE_TYPE,
  payload: ruleType,
})

export const cleanupOverrideRule = () => (dispatch: AppDispatch) => {
  dispatch(
    actionTypeWrapper('lead', {
      type: RuleDefinitionsActions.OVERRIDING_RULES_CLEANUP,
    })
  )
  dispatch(
    actionTypeWrapper('opportunity', {
      type: RuleDefinitionsActions.OVERRIDING_RULES_CLEANUP,
    })
  )
}

export const startRuleLoading = () => ({
  type: RuleDefinitionsActions.START_RULE_LOADING,
})

export const stopRuleLoading = () => ({
  type: RuleDefinitionsActions.STOP_RULE_LOADING,
})
