export const calculatePercentageFromObj = (obj: Record<string, number>) => {
  let sum = 0
  const newObj = { ...obj }
  for (const i in obj) {
    sum += obj[i]
  }
  for (const [key, value] of Object.entries(obj)) {
    const percentage = Math.round((value / sum) * 100) || 0
    newObj[key] = percentage
  }
  return {
    totalCounts: sum,
    errorCausesWithPercentage: newObj,
  }
}
