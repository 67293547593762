import { actionTypeWrapper } from '../../../utils/actionTypeWrapper'
import { DataTypes, yupObjectSchema } from '../../../utils/YupObjectShaper'
import { RootState } from '../../../../store'
import { ACEField } from '../CrmAceMapping/reducer'
import { Dispatch } from 'redux'
import { errorLogger } from '../../../utils/errorLogger'

export interface ErrorObject {
  error: boolean
  errMess: string
}

export interface DefaultValueErrorObject {
  [x: string]: {
    error: boolean
    errMess: string
  }
}

export interface DefaultValueState {
  errors: Record<string, ErrorObject>
  valid: boolean
}
export enum DefaultValueValidationActionTypes {
  SET_DEFAULT_VALIDATIONS = 'SET_DEFAULT_VALIDATIONS',
}

export const updateDefaultValidations = (
  objectType: string,
  aceKey: string,
  error: boolean,
  errorMess: string
) => actionTypeWrapper(objectType, setValidations(aceKey, error, errorMess))

export const setValidations = (
  aceKey: string,
  error: boolean,
  errorMess: string
) => ({
  type: DefaultValueValidationActionTypes.SET_DEFAULT_VALIDATIONS as DefaultValueValidationActionTypes.SET_DEFAULT_VALIDATIONS,
  payload: { aceKey, error, errorMess },
})

export const checkDefaultValueError = (state: DefaultValueState) => {
  const errors = Object.values(state.errors).filter(err => err.error === true)
  return errors.length > 0 ? true : false
}

export const validateDefaultValue =
  (aceKey: string, objectType: CRMObjectType, defaultValue?: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const aceField = getState().crmAceMapping[objectType].aceFields.find(
      (ACE: ACEField) => ACE.aceKey === aceKey
    )
    const dataTypes = Object.values(DataTypes) as string[]
    if (aceField) {
      if (dataTypes.includes(aceField?.dataType)) {
        const yupObject = yupObjectSchema(aceField)
        await yupObject
          .validate({
            [aceKey]: defaultValue,
          })
          .then(async function () {
            await dispatch(
              updateDefaultValidations(objectType, aceKey, false, '')
            )
          })
          .catch(async function (error) {
            const globalState = getState()
            errorLogger({ globalState })(error as Error)
            if (defaultValue === '') {
              await dispatch(
                updateDefaultValidations(objectType, aceKey, false, '')
              )
            } else await dispatch(updateDefaultValidations(objectType, aceKey, true, error.message))
          })
      }
    }
  }
