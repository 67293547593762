import {
  LoadingLogo,
  ModalConfirmV2,
  ModalSizeVariants,
} from '@labrav/react-components'
import { useStyles } from './G2ReviewModal.styles'
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { isLoading as Loading } from '../../utils/loadingState'
import {
  getG2Review,
  PartnerType,
  UpdateG2Review,
} from '../../modules/partner/action'
import { usePartnerId, useUserType } from '../../utils/Hooks/usePartnerData'
import { useDispatch, useSelector } from '../../../store'
import { fetchG2ReviewUrl } from '../../modules/userProfile/action'
import { UserProfileState } from '../../modules/userProfile/reducer'
import { LoadingTypes } from '../../modules/loading/reducer'
import useScreenSize from '../../utils/Hooks/useScreenSize'
import { putCustomerReviewG2 } from '../../api'
import { DateTime } from 'luxon'
import { useG2Review } from '../../utils/Hooks/hooks'

export const G2ReviewModal = ({
  openModal,
  setOpenModal,
}: {
  openModal: boolean
  setOpenModal: Dispatch<SetStateAction<boolean>>
}) => {
  const { screenHeight } = useScreenSize()

  const modalHeight = `${
    (screenHeight || 500) * (screenHeight <= 1080 ? 0.5 : 0.65)
  }px`
  const classes = useStyles(modalHeight)()
  const dispatch = useDispatch()
  const isAdmin = useUserType() === PartnerType.Admin

  const partnerTypeVal = isAdmin ? PartnerType.Admin : PartnerType.User

  const [g2ReviewUrl, setG2ReviewUrl] = useState<string>('')
  const partnerId = usePartnerId() || ''
  const g2ReviewCompeted = useSelector<boolean>(state => !!state.PartnerData[partnerTypeVal].partnerData?.G2Review)
  const { userProfile } = useSelector<UserProfileState>(
    state => state.userProfile
  )
  const isLoading = useSelector<boolean>(state =>
    Loading(state.loading)(LoadingTypes.GENERAL)
  )

  useEffect(() => {
    if (partnerId && userProfile?.id && openModal) {
      const fetchG2ReviewDataCall = async () => {
        const response = await dispatch(
          fetchG2ReviewUrl({ partnerId, userId: userProfile?.id! })
        )
        if (response?.url) {
          setG2ReviewUrl(response?.url)
        }
      }
      fetchG2ReviewDataCall()
    }
  }, [partnerId, userProfile, openModal])

  const handleClose = useCallback(() => {
    setOpenModal(false)
  }, [])

  const handleSkip = async () => {
    try {
      if (partnerId) {
        const data: UpdateG2Review = {
          completed: g2ReviewCompeted,
          openedAt: DateTime.now().toISO(),
        }
        await putCustomerReviewG2(partnerId, data)
        await dispatch(getG2Review(partnerId, partnerTypeVal))
      }
    } catch (error) {
      console.error('Error while skipping G2 review:', error)
    } finally {
      handleClose()
    }
  }
  return (
    <ModalConfirmV2
      title="Review us on G2"
      onlyCloseModal
      onCloseModal={handleClose}
      onDecline={handleSkip}
      description="It would mean the world to us if you can take 5 mins of your time and review us on G2."
      content={
        isLoading ? (
          <LoadingLogo className={classes.loading} />
        ) : (
          <iframe
            style={{ minHeight: modalHeight }}
            width={'100%'}
            src={g2ReviewUrl}
            title="G2 Review"
          ></iframe>
        )
      }
      hideAcceptButton
      open={openModal}
      titleCancelButton={'Skip now'}
      variant={ModalSizeVariants.xLarge}
      hideCancelButton={false}
    />
  )
}
