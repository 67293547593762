import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '20px',
    margin: 'auto',
    width: '60%',
    padding: '20px',
    ['@media (max-width:960px)']: {
      margin: '0px',
      width: '100%',
    },
  },
  title: {
    marginTop: '40px',
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '20px',
    margin: 'auto',
    width: '100%',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    width: '100%',
    ['@media (max-width:960px)']: {
      flexDirection: 'column',
    },
  },
  col: {
    display: 'flex',
    flex: 1,
    maxWidth: 'calc(50% - 25px)',
    ['@media (max-width:960px)']: {
      maxWidth: 'calc(100% - 40px)',
      marginBottom: '20px',
    },
  },
  divider: {
    backgroundColor: theme.palette.dividers.main,
    height: '2px',
    width: '100%',
    marginTop: '30px',
  },
  buttonPrimary: {
    backgroundColor: theme.palette.opaque.main,
    color: theme.palette.secondary.main,
    paddingLeft: '30px',
    paddingRight: '30px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.opaque.main,
    },
  },
  buttonBase: {
    paddingLeft: '30px',
    paddingRight: '30px',
    textTransform: 'none',
  },
  buttonGroup: {
    marginTop: '30px',
    display: 'flex',
    gap: '10px',
  },
}))
