import { Reducer } from 'redux'
import { PartnerObjectsStatsTypes } from './actions'
import { PartnerObjectType } from '../partnerObjects/reducer'

export interface ObjectSyncAttempt {
  count: number
  synced: number
  failed: number
  warning: number
  initiatedAtCrm: number
  initiatedAtAce: number
}

export interface ErrorCauses {
  systemError?: number
  dataError?: number
  warnings?: number
}
export interface PartnerObjectsStatsResponse {
  leads: ObjectSyncAttempt
  opportunities: ObjectSyncAttempt
  failedLeads?: PartnerObjectType[]
  failedOpportunities?: PartnerObjectType[]
  errorCauses: ErrorCauses
}
export interface PartnerObjectsStatsState {
  partnerObjectsStats: PartnerObjectsStatsResponse
  errMess: unknown
}
export const initialState: PartnerObjectsStatsState = {
  partnerObjectsStats: {
    leads: {
      count: 0,
      synced: 0,
      failed: 0,
      warning: 0,
      initiatedAtCrm: 0,
      initiatedAtAce: 0,
    },
    opportunities: {
      count: 0,
      synced: 0,
      failed: 0,
      warning: 0,
      initiatedAtCrm: 0,
      initiatedAtAce: 0,
    },
    failedLeads: [],
    failedOpportunities: [],
    errorCauses: {},
  },
  errMess: null,
}

export type actionType = {
  type: PartnerObjectsStatsTypes
  payload: any
}

export const reducer: Reducer<PartnerObjectsStatsState, actionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case PartnerObjectsStatsTypes.GET_PARTNER_OBJS_STATS: {
      return {
        ...state,
        partnerObjectsStats: action.payload,
      }
    }
    case PartnerObjectsStatsTypes.SET_PARTNER_OBJS_STATS_ERROR: {
      return {
        ...state,
        errMess: action.payload,
      }
    }
    default:
      return state
  }
}
