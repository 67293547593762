export const convertRGBAAlpha = (
  rgbaStr: string,
  isAlphaDecimal?: boolean
): string => {
  if (!rgbaStr) {
    return ''
  }
  if (rgbaStr.startsWith('#')) {
    return rgbaStr
  }

  if (rgbaStr.startsWith('rgba')) {
    const [r, g, b, alpha] = rgbaStr.match(/\d+(\.\d+)?/g)!.map(Number)

    if (isAlphaDecimal) {
      return `rgba(${r}, ${g}, ${b}, ${alpha / 100})`
    }

    return `rgba(${r}, ${g}, ${b}, ${alpha * 100})`
  }
  return rgbaStr
}
