import { CRMListState, crmListActionTypes } from './action'
const initialState: CRMListState = {
  list: [],
  errMess: null,
}
type Action = { type: crmListActionTypes; payload: any }
export const crmSelectorReducer = (
  state: CRMListState = initialState,
  action: Action
) => {
  switch (action.type) {
    case crmListActionTypes.GET_CRMs: {
      return { ...state, list: action.payload, errMess: null }
    }
    case crmListActionTypes.GET_CRMs_FAILED: {
      return { ...state, list: [], errMess: action.payload }
    }
    default:
      return state
  }
}
