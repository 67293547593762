import * as yup from 'yup'
import { ACEField } from '../modules/onboarding/CrmAceMapping/reducer'
import { CRM } from '../modules/onboarding/crmSelector/action'
import { DateTime } from 'luxon'
import { getErrorMessages } from './error'
import { AxiosResponse } from 'axios'
import { updateAppAlert } from '../modules/appAlert/actions'
import { newrelicErrLogger } from './ErrorHandler'
//For validating schema
export const yupObjectShaper = (crm: CRM) => {
  const errors: Record<string, yup.AnySchema> = {}
  crm.credential_fields.forEach(c => {
    if (c.type === 'string') {
      let stringSchema = yup
        .string()
        .typeError(`${c.label} should be of type string.`)
      c.required
        ? (stringSchema = stringSchema.required(`${c.label} is required.`))
        : ''
      c.min_length && c.min_length > 0
        ? (stringSchema = stringSchema.min(
            c.min_length,
            `${c.label} should have minimum ${c.min_length} character(s).`
          ))
        : ''
      c.max_length && c.max_length > 0
        ? (stringSchema = stringSchema.max(
            c.max_length,
            `${c.label} should have maximum ${c.max_length} character(s).`
          ))
        : ''
      errors[c.key] = stringSchema
    } else if (c.type === 'integer') {
      let numberSchema = yup
        .number()
        .typeError(`${c.label} should be of type number.`)
      c.required
        ? (numberSchema = numberSchema.required(`${c.label} is required.`))
        : ''
      c.min_value && c.min_value > 0
        ? (numberSchema = numberSchema.min(
            c.min_value,
            `${c.label} should have minimum value of ${c.min_value}.`
          ))
        : ''
      c.max_value && c.max_value > 0
        ? (numberSchema = numberSchema.max(
            c.max_value,
            `${c.label} should have maximum value of ${c.max_value}.`
          ))
        : ''
      errors[c.key] = numberSchema
    }
  })
  return errors
}

export enum DataTypes {
  DATE = 'DATE',
  Text = 'Text',
  Email = 'Email',
  Phone = 'Phone',
  Number = 'Number',
  Currency = 'Currency(16,2)',
  LongTextArea = 'Long Text Area',
  String = 'String',
  URL = 'URL',
}

const getSchemaForDataType = (dataType: DataTypes | string, key: string) => {
  switch (dataType) {
    case DataTypes.Text:
    case DataTypes.LongTextArea:
    case DataTypes.String:
      return yup.string().typeError(`${key}should be of type text.`)
    case DataTypes.Number:
      return yup.number().typeError(`${key} should be of type number.`)
    case DataTypes.Currency:
      return yup.number().typeError(`${key} should be of type number.`)
    default:
      return yup.string()
  }
}

const getMatchingSchema = (
  dataType: DataTypes | string,
  key: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  schema: any
) => {
  switch (dataType) {
    case DataTypes.Email:
      return schema.matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        `${key} should be of type email.`
      )
    case DataTypes.Phone:
      return schema.matches(
        /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
        `${key} should be of type phone number.`
      )
    case DataTypes.Currency:
      return schema
        .test('check currency', `Invalid ${key}.`, function (value: number) {
          return /^\d{1,16}(\.\d+)?$/.test(String(value)) ? true : false
        })
        .test(
          'check 0',
          `${key} should be greater than 0.`,
          function (value: number) {
            return value > 0 ? true : false
          }
        )
    case DataTypes.DATE:
      return schema
        .matches(
          /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
          `${key} should be of format YYYY-MM-DD. Eg. 2016-11-02`
        )
        .test(
          'check date',
          `${key} is not a valid date.`,
          function (value: string) {
            const date = DateTime.fromFormat(value, 'yyyy-MM-dd')
            return date.isValid ? true : false
          }
        )

    case DataTypes.URL:
      return schema.matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        `${key} should be a URL.`
      )
    default:
      return schema
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getMinimumSchema = (mandatorySchema: any, ACE: ACEField) => {
  try {
    if (ACE.dataType === 'Number' && ACE?.minValue) {
      return mandatorySchema.min(
        ACE.minValue,
        `${ACE.aceKey} can have minimum ${ACE.minValue} value.`
      )
    } else if (
      (ACE.dataType === DataTypes.Text ||
        ACE.dataType === DataTypes.String ||
        ACE.dataType === DataTypes.LongTextArea) &&
      ACE?.minLength
    ) {
      return mandatorySchema.min(
        ACE?.minLength,
        `${ACE.aceKey} can have minimum ${ACE?.minLength} character(s).`
      )
    } else return mandatorySchema
  } catch (error) {
    console.error(error)
    newrelicErrLogger(error as Error, {
      message: error,
    })
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getMaximumSchema = (minimumSchema: any, ACE: ACEField) => {
  try {
    if (ACE.dataType === 'Number' && ACE?.maxValue) {
      return minimumSchema.max(
        ACE?.maxValue,
        `${ACE.aceKey} can have maximum ${ACE?.maxValue} value.`
      )
    } else if (
      (ACE.dataType === DataTypes.Text ||
        ACE.dataType === DataTypes.String ||
        ACE.dataType === DataTypes.LongTextArea) &&
      ACE?.maxLength &&
      ACE?.maxLength !== 0
    ) {
      return minimumSchema.max(
        ACE?.maxLength,
        `${ACE.aceKey} can have maximum ${ACE?.maxLength} character(s).`
      )
    } else return minimumSchema
  } catch (error) {
    console.error(error)
    newrelicErrLogger(error as Error, {
      message: error,
    })
  }
}

export const yupObjectSchema = (ACE: ACEField) => {
  const dataTypeSchema = getSchemaForDataType(ACE.dataType, ACE.aceKey)
  const matchingSchema = getMatchingSchema(
    ACE.dataType,
    ACE.aceKey,
    dataTypeSchema
  )
  const minimumSchema = getMinimumSchema(matchingSchema, ACE)
  const finalSchema = getMaximumSchema(minimumSchema, ACE)
  return yup.object().shape({ [ACE.aceKey]: finalSchema })
}
