import { Product } from '../modules/partner/action'
import { UserProfile } from '../modules/userProfile/action'
import { Role } from './roles'

export const ProductRoutingMap: Record<Product, string> = {
  oppsync: 'oppsync',
  cloudfaas: 'cloudfaas',
  flyout: 'flyout',
}

export const ProductRoutingMapShort: Record<Product, string> = {
  oppsync: 'OS',
  cloudfaas: 'CF',
  flyout: 'FO',
}

export const isAdminRole = (user: UserProfile | undefined) =>
  user?.roles?.some(r =>
    [Role.ADMIN, Role.SUPER_ADMIN, Role.ACCOUNT_EXECUTIVE].includes(r)
  ) || false
