import { Reducer } from 'redux'
import { FaqActionTypes } from './actions'

export interface Qna {
  question: string
  answer: string
  link?: string
  images?: string[]
}

export type FaqDrawerState = {
  isOpen: boolean
  sectionData: Qna[] | undefined
}

export const initialState: FaqDrawerState = {
  isOpen: false,
  sectionData: [
    {
      question: 'question 1',
      answer: 'ans 1',
    },
  ],
}

type actionType = {
  type: FaqActionTypes
  payload?: Qna[]
}

export const reducer: Reducer<FaqDrawerState, actionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case FaqActionTypes.SHOW_FAQ_SECTION: {
      return {
        ...state,
        isOpen: true,
        sectionData: action.payload,
      }
    }
    case FaqActionTypes.HIDE_FAQ_DRAWER: {
      return {
        ...state,
        isOpen: false,
        sectionData: [],
      }
    }

    default: {
      return state
    }
  }
}
