import { Reducer } from 'redux'
import { CRMSchemaActionTypes } from './actions'

export interface CRMSchemaState {
  crmSchema: string
  error: unknown
}

export type CRMSchemaActions =
  | {
      type: CRMSchemaActionTypes.SET_CRM_SCHEMA
      payload: string
    }
  | {
      type: CRMSchemaActionTypes.SET_CRM_SCHEMA_ERROR
      payload: unknown
    }

export const initialState = {
  crmSchema: '',
  error: '',
}

export const CRMSchemaReducer: Reducer<CRMSchemaState, CRMSchemaActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case CRMSchemaActionTypes.SET_CRM_SCHEMA:
      return {
        ...state,
        crmSchema: action.payload,
      }
    case CRMSchemaActionTypes.SET_CRM_SCHEMA_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}
