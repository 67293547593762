import { makeStyles } from '@material-ui/core/styles'
import OppSyncSideImage from '../../../images/OppSyncSideImage.jpg'
import RectangleImage from '../../../images/Rectangle.png'
export const useStyles = (isNewDesign: boolean) =>
  makeStyles(theme => ({
    root: {
      height: '100vh',
    },
    image: {
      backgroundImage: `url(${
        isNewDesign ? RectangleImage : OppSyncSideImage
      })`,
      backgroundRepeat: 'no-repeat',
      backgroundColor:
        theme.palette.type === 'light'
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
    paper: {
      margin: theme.spacing(1, 4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    FormDiv: {
      backgroundColor: theme.palette.common.white,
      height: '100vh',
      overflowY: 'auto',
    },
    AuthForm: {
      boxShadow: '-1px 5px 15px 0px rgba(0,0,0,0.4) !important',
      transform: 'scale(1.05)',
      transition: 'transform 500ms',
      textAlign: 'center',
    },
    formLinks: {
      paddingTop: theme.spacing(5),
      color: theme.palette.primary.light,
    },
    formLinksV2: {
      paddingTop: theme.spacing(5),
      color: theme.palette.textField.dark,
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: '0.5px',
    },
    refLink: {
      color: theme.palette.opaque.dark,
      fontWeight: 600,
      textDecoration: 'none',
    },
    refLinkV2: {
      color: theme.palette.select.light,
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '24px',
      letterSpacing: '0.15px',
      textDecoration: 'none',
    },
  }))
