import {
  ChartDetailsProps,
  dataProps,
} from '../oppsync/components/RadioIndicator/RadioIndicator'
import { PieSvgProps } from '@nivo/pie'
import { syncDashboardColors } from '../common/utils/syncDashboardColors'

export const oppsConfig: PieSvgProps<dataProps> = {
  data: [
    {
      id: 'Synced',
      label: 'Synced',
      value: 336,
      color: syncDashboardColors.success,
    },
    {
      id: 'Failed',
      label: 'Failed',
      value: 84,
      color: syncDashboardColors.error,
    },
    {
      id: 'InProgress',
      label: 'In progress',
      value: 84,
      color: syncDashboardColors.progress,
    },
    {
      id: 'Warning',
      label: 'Warning',
      value: 84,
      color: syncDashboardColors.warning,
    },
  ],
  height: 350,
  width: 330,
  innerRadius: 0.92,
  enableArcLinkLabels: false,
  startAngle: -180,
  enableArcLabels: false,
  colors: { datum: 'data.color' },
  margin: { top: 50, right: 75, bottom: 75, left: 75 },
  theme: {
    background: '#fff',
  },
}

export const oppsChartDetails: ChartDetailsProps = {
  chartTitle: 'Opportunities synced volume',
  total: 420,
  chartLabel: 'Opportunities',
  chartWidth: 22,
  legendDetails: {
    data: oppsConfig.data,
    top: 90,
    left: 50,
    width: 14,
    type: 'normal',
  },
}

export const leadsConfig: PieSvgProps<dataProps> = {
  data: [
    {
      id: 'Synced',
      label: 'Synced',
      value: 336,
      color: syncDashboardColors.success,
    },
    {
      id: 'Failed',
      label: 'Failed',
      value: 84,
      color: syncDashboardColors.error,
    },
    {
      id: 'InProgress',
      label: 'In progress',
      value: 84,
      color: syncDashboardColors.progress,
    },
    {
      id: 'Warning',
      label: 'Warning',
      value: 84,
      color: syncDashboardColors.warning,
    },
  ],
  height: 350,
  width: 330,
  innerRadius: 0.92,
  enableArcLinkLabels: false,
  enableArcLabels: false,
  colors: { datum: 'data.color' },
  margin: { top: 50, right: 75, bottom: 75, left: 75 },
  theme: {
    background: '#fff',
  },
}
export const leadsChartDetails: ChartDetailsProps = {
  chartTitle: 'Leads synced volume',
  total: 420,
  chartLabel: 'Leads',
  chartWidth: 22,
  legendDetails: {
    data: leadsConfig.data,
    top: 90,
    left: 50,
    width: 14,
    type: 'normal',
  },
}

export const oppsInitAtConfig: PieSvgProps<dataProps> = {
  data: [
    {
      id: 'CRM',
      label: 'CRM',
      value: 336,
      color: syncDashboardColors.atCRM,
    },
    {
      id: 'ACE',
      label: 'ACE',
      value: 84,
      color: syncDashboardColors.atACE,
    },
  ],
  height: 350,
  width: 330,
  innerRadius: 0.92,
  enableArcLinkLabels: false,
  enableArcLabels: false,
  colors: { datum: 'data.color' },
  startAngle: -180,
  margin: { top: 50, right: 75, bottom: 75, left: 75 },
  theme: {
    background: '#fff',
  },
}

export const oppsInitAtChartDetails: ChartDetailsProps = {
  chartTitle: 'Opportunities referred by',
  total: 420,
  chartLabel: 'Opportunities',
  chartWidth: 22,
  legendDetails: {
    data: oppsInitAtConfig.data,
    top: 90,
    left: 55,
    width: 13,
    type: 'normal',
  },
}

export const errorCausesConfig: PieSvgProps<dataProps> = {
  data: [
    {
      id: 'Data Error',
      label: 'Data error',
      value: 18,
      color: syncDashboardColors.dataError,
    },
    {
      id: 'System Error',
      label: 'System error',
      value: 18,
      color: syncDashboardColors.systemError,
    },
    {
      id: 'Warnings',
      label: 'Warnings',
      value: 18,
      color: syncDashboardColors.warning,
    },
  ],
  height: 333,
  width: 636,
  innerRadius: 0.9,
  enableArcLinkLabels: false,
  enableArcLabels: false,
  colors: { datum: 'data.color' },
  margin: { top: 90, right: 300, bottom: 40, left: 0 },
  theme: {
    background: '#fff',
  },
}

export const errorCausesChartDetails: ChartDetailsProps = {
  chartTitle: 'Sync failure cause distribution',
  total: 100,
  chartLabel: 'Sync failure causes',
  chartWidth: 40,
  legendDetails: {
    data: errorCausesConfig.data,
    top: 55,
    left: 80,
    width: 16,
    type: 'errorDistribution',
  },
}
