import { Reducer } from 'redux'
import {
  ChannelType,
  Dimension,
  EulaType,
  Installments,
  MetaData,
  OfferRecipients,
  PriceTypes,
} from '../../../flyout/modules/privateOffer/reducer'
import { ResaleAuthorizationsActionTypes } from './actions'

export interface ResaleAuthorizationsResellerAgreements {
  id: string
  sellerName: string
  crmAwsResellerAgreementObjectId: string
  agreementDescription: string
  agreementName: string
  buyerAwsAccountId: string
  agreementStartDate?: string
  agreementExpirationDate: string
  emailCustomMessage: string
  contractDurationInMonthsForEndCustomer?: number
  agreementDurationType: AgreementDurationType
  discountType: DiscountType
  contractDurationType: ContractDurationType
  endCustomerEulaType: EulaType
  endCustomerEulaFiles: string[]
  resellerLicenseAgreementType: EulaType
  resellerLicenseAgreementFiles: string[]
  percentageDiscount?: number
  resellersInfo: ResellerInfo[]
  awsAgreementId: string
  agreementApprovers: AgreementApprovers[]
  agreementRecipients: OfferRecipients[]
  agreementStakeholders: Stakeholders[]
  installmentInfo: Installments[]
  metaData: MetaData[]
  products: CPPOProducts[]
  renewal?: boolean
  renewalChannel?: ChannelType | null
  createdAt: string
  status: ResellerAgreementStatus
  resellerName: string
  draft?: boolean
  pricesArray?: [keyof PriceTypes]
  dimensions?: Dimension[]
  companyName?: string
  awsProductId?: string
}
export enum ResellerAgreementStatus {
  IN_PROGRESS = 'in_progress',
  ACTIVE = 'active',
  EXPIRED = 'expired',
  COMPLETED = 'completed',
  RESTRICTED = 'restricted',
  DEACATIVATION_IN_PROGRESS = 'deactivation_in_progress',
  DEACATIVATED = 'deactivated',
  FAILED = 'failed',
  DRAFT = 'draft',
}
export enum AgreementDurationType {
  SINGLE_USE = 'SINGLE_USE',
  SPECIFIC_TIME = 'SPECIFIC_TIME',
  NO_SET_TIME = 'NO_SET_TIME',
}
export enum DiscountType {
  DISCOUNT = 'DISCOUNT',
  INDIVIDUAL_PRICING = 'INDIVIDUAL_PRICING',
  FLEXIBLE_PAYMENT_TERMS = 'FLEXIBLE_PAYMENT_TERMS',
}
export enum ContractDurationType {
  STANDARD = 'standard',
  CUSTOM = 'custom',
}
export enum ResellerLicenseAgreementType {
  STANDARD = 'standard',
  CUSTOM = 'custom',
}
export type AWSPartnerTier = 'registered' | 'select' | 'advanced' | 'premier'
export interface ResellerInfo {
  resellerCompanyName?: string
  labraPlatformResellerId?: string
  geoLocation?: string
  resellerAwsAccountId?: string
  crmAwsResellerObjectId?: string
  awsPartnerTier?: AWSPartnerTier
}
export interface AgreementApprovers {
  name: string
  email: string
  hierarchyOrder: number
}

export interface Stakeholders {
  firstName: string
  lastName: string
  title: string
  email: string
}

export interface CPPOProducts {
  awsProductId: string
  dimensions: Dimension[]
  productName?: string
}

export interface ResaleAuthorizationsState {
  awsResellerAgreements: ResaleAuthorizationsResellerAgreements[]
  count: number
  pageNumber: number
  noOfPage: number | null
  pageSize: number
  currentResaleAuthorization: {
    [x: string]: ResaleAuthorizationsResellerAgreements
  }
}
export const initialState = {
  awsResellerAgreements: [],
  count: 0,
  pageNumber: 0,
  noOfPage: null,
  pageSize: 10,
  currentResaleAuthorization: {},
}
type actionType =
  | {
      type: ResaleAuthorizationsActionTypes.RESALE_SET_RESELLER_DATA
      payload: {
        agreements: ResaleAuthorizationsState
        pageSize: number
        pageNumber: number
      }
    }
  | {
      type: ResaleAuthorizationsActionTypes.RESALE_SET_PAGE_NUMBER
      payload: number
    }
  | {
      type: ResaleAuthorizationsActionTypes.RESALE_SET_LIMIT
      payload: number
    }
  | {
      type: ResaleAuthorizationsActionTypes.UPDATE_AGREEMENT_ID
      payload: {
        agreementId: string
        awsAgreementId: string
      }
    }
  | {
      type: ResaleAuthorizationsActionTypes.UPDATE_AGREEMENT_STATUS
      payload: {
        agreementId: string
        resellerAgreementStatus: ResellerAgreementStatus
      }
    }
  | {
      type: ResaleAuthorizationsActionTypes.RESALE_CLEAR_RESELLER_DATA
    }
  | {
      type: ResaleAuthorizationsActionTypes.SET_CURRENT_RESALE_AUTHORIZATION
      payload: ResaleAuthorizationsResellerAgreements
    }
  | {
      type: ResaleAuthorizationsActionTypes.CLEAR_CURRENT_RESALE_AUTHORIZATION
    }
export const reducer: Reducer<ResaleAuthorizationsState, actionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ResaleAuthorizationsActionTypes.RESALE_SET_RESELLER_DATA: {
      const existingIds = new Set(
        state.awsResellerAgreements.map(agreement => agreement.id)
      )

      const newAgreements =
        action.payload.agreements.awsResellerAgreements.filter(
          agreement => !existingIds.has(agreement.id)
        )
      return {
        ...state,
        awsResellerAgreements:
          state.awsResellerAgreements.concat(newAgreements),
        count: action.payload.agreements.count,
        pageSize: action.payload.pageSize,
        pageNumber: action.payload.pageNumber,
        noOfPage: Math.ceil(
          action.payload.agreements.count / action.payload.pageSize
        ),
      }
    }
    case ResaleAuthorizationsActionTypes.UPDATE_AGREEMENT_ID: {
      const { agreementId, awsAgreementId } = action.payload
      const awsResellerAgreements = [...state.awsResellerAgreements].map(
        agreement => {
          if (agreement.id === agreementId) {
            return {
              ...agreement,
              awsAgreementId,
            }
          } else {
            return agreement
          }
        }
      )
      return {
        ...state,
        awsResellerAgreements,
      }
    }
    case ResaleAuthorizationsActionTypes.UPDATE_AGREEMENT_STATUS: {
      const { agreementId, resellerAgreementStatus } = action.payload
      const awsResellerAgreements = [...state.awsResellerAgreements].map(
        agreement => {
          if (agreement.id === agreementId) {
            return {
              ...agreement,
              status: resellerAgreementStatus,
            }
          } else {
            return agreement
          }
        }
      )
      return {
        ...state,
        awsResellerAgreements,
      }
    }
    case ResaleAuthorizationsActionTypes.RESALE_SET_LIMIT: {
      return {
        ...state,
        pageSize: action.payload,
      }
    }
    case ResaleAuthorizationsActionTypes.RESALE_SET_PAGE_NUMBER: {
      return {
        ...state,
        pageNumber: action.payload,
      }
    }
    case ResaleAuthorizationsActionTypes.RESALE_CLEAR_RESELLER_DATA: {
      return {
        ...state,
        awsResellerAgreements: [],
        noOfPage: null,
        count: 0,
      }
    }
    case ResaleAuthorizationsActionTypes.SET_CURRENT_RESALE_AUTHORIZATION: {
      return {
        ...state,
        currentResaleAuthorization: {
          [action.payload.id]:
            action.payload as ResaleAuthorizationsResellerAgreements,
        },
      }
    }
    case ResaleAuthorizationsActionTypes.CLEAR_CURRENT_RESALE_AUTHORIZATION: {
      return {
        ...state,
        currentResaleAuthorization: {},
      }
    }
    default:
      return state
  }
}
