export const dateAndTimeConversion = (date: string | Date, tZone: string) => {
  const options: Intl.DateTimeFormatOptions = {
    year: '2-digit',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    timeZoneName: 'short',
    timeZone: tZone,
  }
  const dateString = new Date(date).toLocaleString('en-US', options)
  const modifiedDate = dateString.split(',').slice(0, 2).join(', ')
  const modifiedTime = dateString.split(',')[2]
  return { modifiedDate, modifiedTime, dateString }
}
