import { AnyAction, Reducer } from 'redux'
import { Account } from '../types'
import { CMCM_ONBOARDING_ACTIONS } from './actions'

export type CmcmAccountState = Account[] | null

export const initialState = null

export const reducer: Reducer<CmcmAccountState> = (
  state = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case CMCM_ONBOARDING_ACTIONS.SET_ACCOUNTS: {
      return action.payload
    }
    default:
      return state
  }
}
