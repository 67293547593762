import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles(theme => ({
  header: {
    fontSize: '13px',
    fontWeight: 'bold',
    marginBottom: theme.spacing(-1),
  },
  selectContainer: {
    display: 'flex',
    marginTop: theme.spacing(4),
    '& > .MuiFormControl-root': {
      flex: 1,
    },
  },
  select: {
    fontWeight: 500,
  },
  menuItem: {
    fontWeight: 500,
  },
}))
