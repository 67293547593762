import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  textUrl: {
    color: theme.palette.select.light,
    display: 'contents',
    cursor: 'pointer',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '0.1px',
  },
}))
