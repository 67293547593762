import { isNonEmptyNumber } from './validateMinMax'

export const getDiscountedPriceValue = ({
  price,
  discount,
}: {
  price: number
  discount?: number
}) => {
  if (!isNonEmptyNumber(discount)) {
    return Number(price)
  }

  return Number(
    (Number(price) - (Number(price) * Number(discount)) / 100).toFixed(3)
  )
}

export { isNonEmptyNumber }
