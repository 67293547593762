import { AppDispatch, RootState } from '../../../store'
import { camelize } from 'casing'
import { fetchResellers } from '../../api/markeplace'
import { updateAppAlert } from '../../../common/modules/appAlert/actions'
import { getErrorMessages } from '../../../common/utils/error'
import { AxiosResponse } from 'axios'
import { errorLogger } from '../../../common/utils/errorLogger'
import {
  startLoading,
  stopLoading,
} from '../../../common/modules/loading/actions'
import { LoadingTypes } from '../../../common/modules/loading/reducer'
import { RequestFailureMessage } from '../../../common/utils/messagesContants'
import { ResellerInfo } from '../../../admin/modules/resaleAuthorizations/reducer'

export enum ResellerActions {
  SET_RESELLERS_DATA = 'SET_RESELLERS_DATA',
}

export const getResellersData =
  (partnerId: string) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    await dispatch(startLoading(LoadingTypes.RESELLERS_DATA))
    try {
      const { data } = await fetchResellers(partnerId)
      await dispatch(setResellersData(camelize(data).awsResellers))
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.RESELLERS_DATA))
    }
  }

export const setResellersData = (resellers: ResellerInfo[]) => ({
  type: ResellerActions.SET_RESELLERS_DATA,
  payload: resellers,
})
