import { makeStyles } from '@material-ui/core/styles'
import { isEmpty } from 'lodash'
import { SidePanelVariant } from './SidePanel'

export const useStyles = (
  width: number,
  overflowY: boolean,
  variant: SidePanelVariant
) =>
  makeStyles(theme => ({
    root: {
      height: '100%',
      width: '100%',
      marginTop: theme.spacing(10),
    },
    container: {
      width: `${width}px`,
      overflowX: 'hidden',
      overflowY: overflowY ? 'auto' : 'hidden',
      backgroundColor:
        variant === 'secondary'
          ? theme.palette.opaque.light
          : theme.palette.textConstrast.main,
      height: '100%',
    },
    header: {
      backgroundColor: theme.palette.opaque.light,
      overflow: 'auto',
    },
    headingText: {
      fontSize: '18px',
      padding: theme.spacing(3.15, 3.75, 2.5, 3.75),
      fontWeight: 'bold',
      display: 'inline-block',
      float: 'left',
      color: theme.palette.primary.dark,
      letterSpacing: '0.9px',
    },
    subHeadingText: {
      fontSize: '18px',
      padding: theme.spacing(0, 3.75, 3.75, 3.75),
      fontWeight: 'bold',
      display: 'inline-block',
      float: 'left',
      color: theme.palette.primary.dark,
      letterSpacing: '0.9px',
    },
    titles: { display: 'flow-root', height: '55px' },
    titleWithoutSub: {
      display: 'flex',
      justifyContent: 'space-between',
      wordBreak: 'break-all',
    },
    closeIcon: {
      float: 'right',
      display: 'inline-block',
      padding: theme.spacing(3.15, 3.75),
      cursor: 'pointer',
      color: theme.palette.primary.light,
    },
    actions: {
      display: 'inline-block',
      float: 'right',
      marginLeft: 'auto',
      padding: theme.spacing(2.5, 3, 2.5, 3),
    },
    divider: {
      backgroundColor: theme.palette.dividers.main,
      height: '2px',
    },
  }))
