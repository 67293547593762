import { Card, CardActionArea, CardMedia } from '@material-ui/core'
import { useStyles } from './CloudProvider.styles'
import azure from '../../../common/images/azure.png'
import aws from '../../../common/images/aws.png'
import redhat from '../../../common/images/redhat.png'
import googleCloud from '../../../common/images/googleCloud.png'
import { CloudProvider } from '../../../common/modules/partner/action'
import { useCallback } from 'react'
import { useFlag } from '@labrav/flags'

type CloudProviderLogoSquare = {
  name: string
  id: CloudProvider
  logo: string
  enabled: boolean
  height: string
  width: string
}

const cloudProviders: CloudProviderLogoSquare[] = [
  {
    name: 'AWS',
    id: CloudProvider.AWS,
    logo: aws,
    enabled: true,
    height: '43px',
    width: '72px',
  },
  {
    name: 'Azure',
    id: CloudProvider.AZ,
    logo: azure,
    enabled: false,
    height: '89px',
    width: '159px',
  },
  {
    name: 'Google',
    id: CloudProvider.GCP,
    logo: googleCloud,
    enabled: false,
    height: '77px',
    width: '104px',
  },
  {
    name: 'Redhat',
    id: CloudProvider.REDHAT,
    logo: redhat,
    enabled: false,
    height: '43px',
    width: '200px',
  },
]

export const CloudProviderSelector: React.FC<{
  productBgColor?: string
  onSelect: (cloudProvider: CloudProvider) => void
}> = ({ onSelect, productBgColor }) => {
  const classes = useStyles(productBgColor)()
  const onSelectProvider = useCallback(
    (provider: CloudProvider) => () => {
      onSelect(provider)
    },
    [onSelect]
  )

  const { flag: isAzureCloudSettings } = useFlag('azureCloudSettings')

  cloudProviders.map(cloud => {
    cloud.name === 'Azure' && isAzureCloudSettings.value
      ? (cloud.enabled = true)
      : cloud.enabled
  })

  const listCRM = () =>
    cloudProviders.map(provider => (
      <Card
        key={provider.name}
        className={`${classes.card} ${
          provider.enabled ? '' : classes.disabled
        }`}
      >
        <CardActionArea
          data-testid={`cloud-provider-button-${provider.id}`}
          onClick={onSelectProvider(provider.id)}
          className={classes.cardAction}
          disabled={!provider.enabled}
        >
          <CardMedia
            style={{ height: provider.height, width: provider.width }}
            component="img"
            image={provider.logo}
          />
        </CardActionArea>
      </Card>
    ))

  return (
    <div className={classes.root} data-testid="cloud-provider-selector">
      <div
        className={classes.container}
        data-testid="cloud-provide-selector-container"
      >
        <p className={classes.header} data-testid="cloud-provider-heading">
          Now Select a Cloud Provider to Integrate With
        </p>
        <div className={classes.imageList}>{listCRM()}</div>
      </div>
    </div>
  )
}
