import { Dispatch } from 'redux'
import {
  fetchAllPartners,
  fetchAllPartnersFromIdentityService,
  fetchPartnersStatitics,
  searchPartnerList,
  searchPartnerListFromIdentityService,
} from '../../../common/api'
import { startLoading, stopLoading } from '../loading/actions'
import { updateAppAlert } from '../appAlert/actions'
import { camelize } from 'casing'
import { RequestFailureMessage } from '../../utils/messagesContants'
import { LoadingTypes } from '../loading/reducer'
import { errorLogger } from '../../utils/errorLogger'
import { RootState } from '../../../store'
import { AuthProvider } from '../../../oppsync/modules/userList/action'

export enum PartnerActionTypes {
  SET_PARTNER_STATITICS = 'SET_PARTNER_STATITICS',
  SET_PARTNER_LIST = 'SET_PARTNER_LISTS',
  SET_LIMIT = 'SET_LIMIT',
  SEARCH = 'SEARCH',
  CLEAR_PARTNERS = 'CLEAR_PARTNERS',
}
export const clearPartners = () => ({
  type: PartnerActionTypes.CLEAR_PARTNERS,
})
export const getSearchResults =
  (
    searchQuery: string,
    pageSize: number,
    pageNumber: number,
    authProvider: AuthProvider
  ) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.GENERAL))
    dispatch(clearPartners())
    const searchPartners =
      authProvider === AuthProvider.AUTH0
        ? searchPartnerListFromIdentityService
        : searchPartnerList
    return searchPartners(searchQuery, pageSize, pageNumber)
      .then(res => {
        dispatch({
          type: PartnerActionTypes.SET_PARTNER_LIST,
          payload: {
            partners: res.data.partners ? camelize(res.data) : [],
            pageSize: pageSize,
          },
        })
      })
      .catch(e => {
        dispatch(
          updateAppAlert({
            message: RequestFailureMessage,
            messageType: 'ERROR',
            autoClose: true,
          })
        )
        const globalState = getState()
        errorLogger({ globalState })(e as Error)
      })
      .finally(() => dispatch(stopLoading(LoadingTypes.GENERAL)))
  }

export const getPartnersStatitics =
  () => (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.GENERAL))
    return fetchPartnersStatitics()
      .then(res => {
        dispatch({
          type: PartnerActionTypes.SET_PARTNER_STATITICS,
          payload: res.data,
        })
      })
      .catch(e => {
        dispatch(
          updateAppAlert({
            message: RequestFailureMessage,
            messageType: 'ERROR',
            autoClose: true,
          })
        )
        const globalState = getState()
        errorLogger({ globalState })(e as Error)
      })
      .finally(() => dispatch(stopLoading(LoadingTypes.GENERAL)))
  }

export const getPartnersList =
  (pageSize: number, pageNumber: number) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    await dispatch(startLoading(LoadingTypes.GENERAL))
    await dispatch(setDefaultPartners(pageSize))

    return fetchAllPartnersFromIdentityService(pageSize, pageNumber)
      .then(res => {
        if (res.data.partners) {
          dispatch({
            type: PartnerActionTypes.SET_PARTNER_LIST,
            payload: {
              partners: camelize(res.data),
              pageSize: pageSize,
            },
          })
        }
      })
      .catch(e => {
        dispatch(
          updateAppAlert({
            message: RequestFailureMessage,
            messageType: 'ERROR',
            autoClose: true,
          })
        )
        const globalState = getState()
        errorLogger({ globalState })(e as Error)
      })
      .finally(() => dispatch(stopLoading(LoadingTypes.GENERAL)))
  }
export const setDefaultPartners = (pageSize: number) => ({
  type: PartnerActionTypes.SET_PARTNER_LIST,
  payload: {
    partners: { partners: [], count: 0 },
    pageSize: pageSize,
  },
})
export const updatePageSize =
  (pageSize: number) => async (dispatch: Dispatch) => {
    await dispatch(getPartnersList(pageSize, 1) as any)
  }
export const setPageSize = (pageSize: number) => ({
  type: PartnerActionTypes.SET_LIMIT,
  payload: pageSize,
})
