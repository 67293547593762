import { AxiosError } from 'axios'
import { fetchCRMTableFields } from '../../../../common/api'
import { RequestFailureMessage } from '../../../utils/messagesContants'
import { updateAppAlert } from '../../appAlert/actions'
import { CRMField } from '../CrmAceMapping/reducer'
import { CrmTableFieldAction, CrmTableFieldActionTypes } from './reducer'
import { isEmpty } from 'lodash'
import { AppDispatch, RootState } from '../../../../store'
import { startLoading, stopLoading } from '../../loading/actions'
import { errorLogger } from '../../../utils/errorLogger'
import { PartnerType } from '../../partner/action'
import { getErrorMessages } from '../../../utils/error'

export const setTableFields = (
  table: string,
  fields: CRMField[]
): CrmTableFieldAction => {
  return {
    type: CrmTableFieldActionTypes.SET_TABLE_FIELDS,
    payload: {
      table,
      fields,
    },
  }
}

export const cleanUpTableFields = (): CrmTableFieldAction => {
  return {
    type: CrmTableFieldActionTypes.CLEAN_UP_TABLE_FIELDS,
  }
}

export const fetchTableFields =
  (crmId: string, table: string, partnerType: PartnerType = PartnerType.User) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState()
    if (!isEmpty(state.crmFields[table]) || state.loading[table]) {
      return void 0
    }
    dispatch(startLoading(table))
    try {
      let awsFieldsVersion =
        state.PartnerData[partnerType].partnerData?.awsFieldsVersion
      if (!awsFieldsVersion) {
        errorLogger({ globalState: state })(
          new Error('No ace version found for partner, defaulting to "v14"')
        )
        awsFieldsVersion = 'v14'
      }
      const fields = await fetchCRMTableFields(crmId, table, awsFieldsVersion)
      dispatch(setTableFields(table, fields))
    } catch (error) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            (error as AxiosError<ErrorResponse>).response
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(table))
    }
  }
