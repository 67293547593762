import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(5),
    alignSelf: 'stretch',
  },
  questionText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '0.15px',
    color: theme.palette.labraText.tertiary,
  },
  answerText: {
    fontSize: '14px !important',
    fontWeight: 400,
    color: theme.palette.labraText.tertiary,
  },
  qnaContainer: {
    gap: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
  },
  linkText: {
    fontWeight: 500,
    color: theme.palette.select.light,
  },
  link: {
    '& > p': {
      textDecoration: 'none',
    },
    '&:hover': {
      textDecoration: 'none',
    },
  },
  icon: {
    marginLeft: '2px',
  },
  withoutQuestion: {
    marginTop: '-25px',
  },
}))
