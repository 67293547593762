import { camelize } from 'casing'
import { Dispatch } from 'redux'
import {
  startLoading,
  stopLoading,
} from '../../../common/modules/loading/actions'
import { fetchSyncHistory, fetchLastSyncDetails } from '../../../common/api'
import { AxiosError } from 'axios'
import { LoadingTypes } from '../../../common/modules/loading/reducer'
import { RootState } from '../../../store'
import { errorLogger } from '../../../common/utils/errorLogger'
import { RequestFailureMessage } from '../../../common/utils/messagesContants'
import { getErrorMessages } from '../../../common/utils/error'
export enum SyncHistoryActionTypes {
  SET_SYNC_HISTORY = 'SET_SYNC_HISTORY',
  SET_LIMIT = 'SET_LIMIT',
  SET_PAGE_NUMBER = 'SET_PAGE_NUMBER',
  SET_SYNC_ERROR = 'SET_SYNC_ERROR',
  SET_LAST_SYNC = 'SET_LAST_SYNC',
}

export interface SyncData {
  id: string
  type: string
  isSuccess: string
  status: string
  toAce: string
  createdAt: string
  updatedAt: string
  completedAt: string
}

export interface syncResponse {
  syncs: SyncData[]
  totalCount: number
}

export const getSyncHistory =
  (partnerId: string, pageSize: number, pageNumber: number) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.GENERAL))
    return fetchSyncHistory(partnerId, pageSize, pageNumber)
      .then(res => {
        dispatch(setSyncHistory(res.data, pageSize))
      })
      .catch(error => {
        dispatch(
          setSyncError(error.response.data?.message || 'Some error occured')
        )

        const globalState = getState()
        errorLogger({ globalState })(error as Error)
      })
      .finally(() => {
        dispatch(stopLoading(LoadingTypes.GENERAL))
      })
  }
export const getLastSync =
  (partnerId: string) => (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.GENERAL))
    return fetchLastSyncDetails(partnerId)
      .then(res => {
        dispatch(setLastSync(res.data))
      })
      .catch((error: unknown) => {
        const errorMessage = getErrorMessages([RequestFailureMessage])(
          (error as AxiosError<ErrorResponse>).response
        )
        dispatch(setSyncError(errorMessage))
        const globalState = getState()
        errorLogger({ globalState })(error as Error)
      })
      .finally(() => {
        dispatch(stopLoading(LoadingTypes.GENERAL))
      })
  }
export const setSyncHistory = (data: syncResponse, pageSize: number) => ({
  type: SyncHistoryActionTypes.SET_SYNC_HISTORY,
  payload: { data: camelize(data), pageSize: pageSize },
})

export const setSyncError = (error: unknown) => ({
  type: SyncHistoryActionTypes.SET_SYNC_ERROR,
  payload: error,
})

export const setPageNumber = (pageNumber: number) => ({
  type: SyncHistoryActionTypes.SET_PAGE_NUMBER,
  payload: pageNumber,
})

export const updatePageSize =
  (partnerId: string, pageSize: number) => async (dispatch: Dispatch) => {
    await dispatch(setPageSize(pageSize))
    await dispatch(getSyncHistory(partnerId, pageSize, 1) as any)
  }

export const setPageSize = (pageSize: number) => ({
  type: SyncHistoryActionTypes.SET_LIMIT,
  payload: pageSize,
})

export const setLastSync = (data: syncResponse) => ({
  type: SyncHistoryActionTypes.SET_LAST_SYNC,
  payload: { data: camelize(data) },
})
