import React from 'react'
import { useSelector } from '../../../store'
import { dateAndTimeConversion } from '../../utils/dateAndTimeConversion'
import { useStyles } from './DateAndTime.styles'
type Props = {
  date: string | Date
}

export const DateAndTime: React.FC<Props> = ({ date }) => {
  const timeZone = useSelector<string>(
    state =>
      state.userProfile.userProfile.timeZone ||
      state.userProfile.userProfile.defaultTimeZone ||
      'UTC'
  )
  const { modifiedDate, modifiedTime } = dateAndTimeConversion(date, timeZone)
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <p className={classes.date} data-testid="date">
        {modifiedDate}
      </p>
      <p className={classes.time} data-testid="time">
        {modifiedTime}
      </p>
    </div>
  )
}
