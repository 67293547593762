import { isEmpty } from 'lodash'

export type APIReferralError = {
  errors: Array<{ loc: string[]; msg: string; type: string }>
  code: string
  service: string
  track_id: string
  aditional_info?: Record<string, unknown>
  environment: string
}

export const parseReferralAPIError = (
  error: APIReferralError
): Record<string, string> => {
  if (!isEmpty(error.errors)) {
    return error.errors
      .map(e => {
        if (e.loc.length > 1) {
          return { key: e.loc[1], msg: e.msg }
        }
        return { key: e.loc[0], msg: e.msg }
      })
      .reduce((acc, e) => ({ ...acc, [e.key]: e.msg }), {})
  }
  return {}
}
