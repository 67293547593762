import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.bgV2.main,
  },
  withRegularSidePanel: {
    marginLeft: '73px',
  },
  withOpenSidePanel: {
    marginLeft: '280px',
  },
  content: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflow: 'auto',
    height: '100vh',
    backgroundColor: theme.palette.bgV2.main,
  },
  appBody: {
    width: '100%',
  },
}))
