import { makeStyles } from '@material-ui/core'

export const useStyles = (
  isSidePanelOpened: boolean,
  isExternal: boolean,
  isLabraUiRevampEnable?: boolean,
  paddingZero?: boolean
) =>
  makeStyles(theme => ({
    root: {
      backgroundColor: theme.palette.textBackground.light,
      ...(isExternal
        ? { marginLeft: theme.spacing(0) }
        : {
            marginLeft: isSidePanelOpened
              ? theme.spacing(35)
              : theme.spacing(9),
          }),
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
      height: '100vh',
      backgroundColor: theme.palette.textBackground.light,
    },
    appBody: {
      width: '100%',
      padding: paddingZero
        ? theme.spacing(0)
        : isLabraUiRevampEnable
        ? isExternal
          ? theme.spacing(2.5, 2.5, 0, 2.5)
          : theme.spacing(4, 4, 0, 4)
        : theme.spacing(0, 5, 0, 5),
      boxSizing: 'border-box',
    },
  }))
