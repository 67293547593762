import { useAuth0 } from '@auth0/auth0-react'
import { LoadingLogo } from '@labrav/react-components'
import React, { useEffect } from 'react'

const Auth0SAMLCallback = () => {
    const {
        loginWithRedirect,
      } = useAuth0()
      const login = async (targetUrl: string | null, connection: string| null) => {
        try {
          const options: Record<string, unknown> = {
            redirect_uri: window.location.origin,
          };
      
          if (connection) {
            options.connection = connection;
          }
      
          if (targetUrl) {
            options.appState = { targetUrl };
          }
      
          await loginWithRedirect(options);
        } catch (err) {
          console.error("Log in failed", err);
        }
      }
      useEffect(() => {
        const startlogin = async () => {
            const url = new URL(window.location.href)
            const conn = url.searchParams.get("connection") || ''
            return  login(null, conn);
          }
        startlogin()
      },[])
    return (
        <LoadingLogo />
    )
}

export default Auth0SAMLCallback