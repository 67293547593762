import { Creds, CRMAuthActionTypes, FormData } from './action'
import { Reducer } from 'redux'
export interface CRMAuthState {
  isLoading: boolean
  errMess: unknown
  crmFormData?: FormData
  validateMissingFields: boolean
  crmConnection: boolean
}

const initialState: CRMAuthState = {
  isLoading: false,
  errMess: null,
  crmFormData: undefined,
  validateMissingFields: false,
  crmConnection: false,
}
type CRMAuthAction =
  | { type: CRMAuthActionTypes.SET_CRM_FORM; payload?: FormData }
  | {
      type: CRMAuthActionTypes.GET_CRM_FORM_DATA
      payload?: FormData
    }
  | { type: CRMAuthActionTypes.POST_CRM_LOADING; payload: unknown }
  | { type: CRMAuthActionTypes.POST_CRM_FAILED; payload: unknown }
  | { type: CRMAuthActionTypes.SET_CRM_DELETED_AT; payload: string }
  | { type: CRMAuthActionTypes.SET_VALIDATE_MISSING_FIELDS; payload: boolean }
  | { type: CRMAuthActionTypes.CHECK_CRM_CONNECTION; payload: boolean }

export const CRMAuthReducer: Reducer<CRMAuthState, CRMAuthAction> = (
  state: CRMAuthState = initialState,
  action: CRMAuthAction
) => {
  switch (action.type) {
    case CRMAuthActionTypes.SET_CRM_FORM: {
      return {
        ...state,
        isLoading: false,
        errMess: null,
        crmFormData: action.payload,
      }
    }

    case CRMAuthActionTypes.POST_CRM_LOADING:
      return {
        ...state,
        isLoading: true,
        errMess: null,
        crmFormData: undefined,
      }

    case CRMAuthActionTypes.POST_CRM_FAILED:
      return {
        ...state,
        isLoading: false,
        errMess: action.payload,
      }

    case CRMAuthActionTypes.GET_CRM_FORM_DATA:
      return {
        ...state,
        isLoading: false,
        errMess: null,
        crmFormData: action.payload,
      }
    case CRMAuthActionTypes.SET_CRM_DELETED_AT:
      return {
        ...state,
        crmFormData: {
          ...(state.crmFormData ? state.crmFormData : {}),
          deleted_at: action.payload,
          creds: [] as Creds[],
        } as FormData,
      }
    case CRMAuthActionTypes.SET_VALIDATE_MISSING_FIELDS:
      return {
        ...state,
        validateMissingFields: action.payload,
      }
    case CRMAuthActionTypes.CHECK_CRM_CONNECTION:
      return {
        ...state,
        crmConnection: action.payload,
      }
    default:
      return state
  }
}
