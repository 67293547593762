import { Reducer } from 'redux'
import { CRMField } from '../CrmAceMapping/reducer'

export enum CrmTableFieldActionTypes {
  SET_TABLE_FIELDS = 'SET_TABLE_FIELDS',
  CLEAN_UP_TABLE_FIELDS = 'CLEAN_UP_TABLE_FIELDS',
}

export type CrmTableFieldAction =
  | {
      type: CrmTableFieldActionTypes.SET_TABLE_FIELDS
      payload: {
        table: string
        fields: CRMField[]
      }
    }
  | {
      type: CrmTableFieldActionTypes.CLEAN_UP_TABLE_FIELDS
    }

export type CrmFieldReducerState = { [key: string]: CRMField[] }

const initialState = {}

export const reducer: Reducer<CrmFieldReducerState, CrmTableFieldAction> = (
  state: CrmFieldReducerState = initialState,
  action: CrmTableFieldAction
) => {
  switch (action.type) {
    case CrmTableFieldActionTypes.SET_TABLE_FIELDS: {
      const { table, fields } = action.payload
      return {
        ...state,
        [table]: fields,
      }
    }
    case CrmTableFieldActionTypes.CLEAN_UP_TABLE_FIELDS: {
      return initialState
    }

    default:
      return state
  }
}
