import { AxiosResponse } from 'axios'
import { AnyAction } from 'redux'
import { updateAppAlert } from '../../../common/modules/appAlert/actions'
import { arrayReducerActionWrapper } from '../../../common/modules/arrayReducer/action'
import { CloudType } from '../../../common/modules/types'
import { getErrorMessages } from '../../../common/utils/error'
import { errorLogger } from '../../../common/utils/errorLogger'
import { RequestFailureMessage } from '../../../common/utils/messagesContants'
import {
  fetchPipelines,
  fetchLabraReferralFields,
  updateCRMDetails,
} from '../../../oppsync/api/HubSpot'
import { RootState, AppDispatch } from '../../../store'
import {
  CRMConfigurationSchema,
  DefaultValues,
  PartnerCentralDetails,
  Pipelines,
  zeroValueDefaultValues,
  zeroValuePartnerCentralDetails,
} from './reducer'
import { fetchCRMConfiguration } from '../../api'
import { camelize } from 'casing'
import {
  startLoading,
  stopLoading,
} from '../../../common/modules/loading/actions'
import { LoadingTypes } from '../../../common/modules/loading/reducer'
import { mapAwsCrmConfigurationForBackend } from './helper'
import { isEmpty } from 'lodash'

export type ReferralPipelinesType =
  | 'opportunityReferralPipeline'
  | 'leadReferralPipeline'
export enum CRMConfigurationActionTypes {
  SET_CRM_CONFIGURATION_PIPELINES = 'SET_CRM_CONFIGURATION_PIPELINES',
  SET_SELECTED_PIPELINES = 'SET_SELECTED_PIPELINES',
  SET_CRM_CONFIGURATION = 'SET_CRM_CONFIGURATION',
  SET_CRM_TABLES_FIELD_DETAILS = 'SET_CRM_TABLES_FIELDS_DETAILS',
  UPDATE_SYNC_FIELDS_VALUE_BY_KEY = 'UPDATE_SYNC_FIELDS_VALUE_BY_KEY',
  UPDATE_FROM_DATA_FIELDS_VALUE_BY_KEY = 'UPDATE_FROM_DATA_FIELDS_VALUE_BY_KEY',
  UPDATE_CONTACT_FIELDS_VALUE_BY_KEY = 'UPDATE_CONTACT_FIELDS_VALUE_BY_KEY',
  UPDATE_CRM_CONFIGURATIONS_ERRORS = 'UPDATE_CRM_CONFIGURATIONS_ERRORS',
}

export const setPipelines = (data: Pipelines[]) => ({
  type: CRMConfigurationActionTypes.SET_CRM_CONFIGURATION_PIPELINES,
  payload: data,
})
export const setTablesFieldDetails = (data: Record<string, unknown>[]) => ({
  type: CRMConfigurationActionTypes.SET_CRM_TABLES_FIELD_DETAILS,
  payload: data,
})

export const setCRMConfiguration = (data: CRMConfigurationSchema) => ({
  type: CRMConfigurationActionTypes.SET_CRM_CONFIGURATION,
  payload: data,
})

export const setSelectedPipelines = (
  referralPipelinesType: ReferralPipelinesType,
  key: string,
  value: string,
  cloudType?: CloudType
) => ({
  type: CRMConfigurationActionTypes.SET_SELECTED_PIPELINES,
  payload: { referralPipelinesType, key, value, cloudType },
})

export const updateSyncFieldsValueByKey = (
  key: string,
  checked: boolean,
  cloudType: CloudType = CloudType.AWS
) => ({
  type: CRMConfigurationActionTypes.UPDATE_SYNC_FIELDS_VALUE_BY_KEY,
  payload: { key, checked, cloudType },
})

export const updateContactFieldsValueByKey = (
  key: string,
  checked: boolean,
  cloudType: CloudType = CloudType.AWS
) => ({
  type: CRMConfigurationActionTypes.UPDATE_CONTACT_FIELDS_VALUE_BY_KEY,
  payload: { key, checked, cloudType },
})

export const updateFormDataValueByKey = (
  key: string,
  value: unknown,
  cloudType: CloudType = CloudType.AWS
) => ({
  type: CRMConfigurationActionTypes.UPDATE_FROM_DATA_FIELDS_VALUE_BY_KEY,
  payload: { key, value, cloudType },
})

export const updateCrmConfigurationsErrors = (
  key: string,
  hasErrors: boolean
) => ({
  type: CRMConfigurationActionTypes.UPDATE_CRM_CONFIGURATIONS_ERRORS,
  payload: { key, hasErrors },
})

export const getCRMConfiguration =
  (crmId: string) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(startLoading(LoadingTypes.CRM_CONFIGURATION_AUTOFILL))
      const { data } = await fetchCRMConfiguration(crmId)
      const processedData = {
        ...camelize(data),
        crmSchema: {
          ...camelize(data?.crm_schema),
          aws: {
            ...camelize(data?.crm_schema?.aws),
            labraFieldsHubspotFieldsMapping:
              data?.crm_schema?.aws?.labra_fields_hubspot_fields_mapping,
          },
        },
      }
      await dispatch(
        setCRMConfiguration({
          AWS: processedData?.crmSchema?.aws,
          AZURE: processedData?.crmSchema?.azure,
          GCP: processedData?.crmSchema?.gcp,
          REDHAT: processedData?.crmSchema?.redhat,
        })
      )

      const defaultValueFromResponse =
        data?.crm_schema?.aws?.labra_fields_default_values || []
      const defaultValues = !isEmpty(defaultValueFromResponse)
        ? Object.keys(defaultValueFromResponse).map(key => {
            return {
              defaultValueField: key,
              value: defaultValueFromResponse[key],
            }
          })
        : [zeroValueDefaultValues]
      const dataFromBackend =
        processedData?.crmSchema?.aws?.partnerCentralDetails
      const awsPartnerCentralUsersDetails = !isEmpty(dataFromBackend)
        ? dataFromBackend
        : [zeroValuePartnerCentralDetails]
      await dispatch(
        replaceAllRowsForPartnerCentralDetails(
          awsPartnerCentralUsersDetails,
          CloudType.AWS
        )
      )
      await dispatch(replaceAllRowsForDefaultValues(defaultValues))
    } catch (err: unknown) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            err as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(err as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.CRM_CONFIGURATION_AUTOFILL))
    }
  }

export const getPipelines =
  (crmId: string) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const { data } = await fetchPipelines(crmId)
      await dispatch(setPipelines(data.pipelines))
    } catch (err: unknown) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            err as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(err as Error)
    }
  }

export const getTablesFieldsDetails =
  () => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.CRM_TABLES_FIELDS_DETAILS))
    try {
      const { data } = await fetchLabraReferralFields()
      await dispatch(setTablesFieldDetails(data))
    } catch (err: unknown) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            err as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(err as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.CRM_TABLES_FIELDS_DETAILS))
    }
  }

export const saveCRMConfiguration =
  (crmId: string) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.CRM_CONFIGURATION))
    try {
      const config = getState().CRMConfiguration
      const mapResult = mapAwsCrmConfigurationForBackend(config)

      await updateCRMDetails(crmId, mapResult)
      await dispatch(
        updateAppAlert({
          message: 'CRM configuration successfully updated',
          messageType: 'SUCCESS',
          autoClose: true,
        })
      )
    } catch (err: unknown) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            err as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(err as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.CRM_CONFIGURATION))
    }
  }

const awsArrayReducerActions = arrayReducerActionWrapper<PartnerCentralDetails>(
  'awsPartnerCentralUsersDetails'
)
const azureArrayReducerActions =
  arrayReducerActionWrapper<PartnerCentralDetails>(
    'azurePartnerCentralUsersDetails'
  )
const gcpArrayReducerActions = arrayReducerActionWrapper<PartnerCentralDetails>(
  'gcpPartnerCentralUsersDetails'
)
const redhatArrayReducerActions =
  arrayReducerActionWrapper<PartnerCentralDetails>(
    'redhatPartnerCentralUsersDetails'
  )
const getArrayReducerActions = (cloudProvider: CloudType) => {
  switch (cloudProvider) {
    case CloudType.AWS:
      return awsArrayReducerActions
    case CloudType.AZURE:
      return azureArrayReducerActions
    case CloudType.GCP:
      return gcpArrayReducerActions
    case CloudType.REDHAT:
      return redhatArrayReducerActions
    default:
      return awsArrayReducerActions
  }
}

export const updateRowForPartnerCentralDetails = (
  id: string,
  data: PartnerCentralDetails,
  cloudProvider: CloudType
): AnyAction => {
  const arrayReducerActions = getArrayReducerActions(cloudProvider)
  return arrayReducerActions.updateRow(id, data)
}

export const addRowForPartnerCentralDetails = (
  cloudProvider: CloudType
): AnyAction => {
  const arrayReducerActions = getArrayReducerActions(cloudProvider)
  return arrayReducerActions.addRow()
}

export const replaceAllRowsForPartnerCentralDetails = (
  data: PartnerCentralDetails[],
  cloudProvider: CloudType
): AnyAction => {
  const arrayReducerActions = getArrayReducerActions(cloudProvider)
  return arrayReducerActions.replaceAllRows(data)
}

export const removeRowForPartnerCentralDetails = (
  id: string,
  cloudProvider: CloudType
): AnyAction => {
  const arrayReducerActions = getArrayReducerActions(cloudProvider)
  return arrayReducerActions.removeRow(id)
}

const arrayReducerActionsForDefaultValues =
  arrayReducerActionWrapper<DefaultValues>('defaultValues')
export const updateRowForCRMDefaultValues =
  arrayReducerActionsForDefaultValues.updateRow
export const addRowForCRMDefaultValues =
  arrayReducerActionsForDefaultValues.addRow
export const removeRowForCRMDefaultValues =
  arrayReducerActionsForDefaultValues.removeRow
export const replaceAllDefaultValuesRows = (data: DefaultValues[]) =>
  arrayReducerActionsForDefaultValues.replaceAllRows(data)

export const replaceAllRowsForDefaultValues = (
  data: DefaultValues[]
): AnyAction => {
  return replaceAllDefaultValuesRows(data)
}
