import { makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = (isNewDesign?: boolean) =>
  makeStyles((theme: Theme) => ({
    root: {
      backgroundColor: theme.palette.bgV2.main,
      width: '100%',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      flexGrow: 1,
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: isNewDesign ? '' : 'center',
      height: '100%',
      overflow: 'auto',
      ['@media(max-width:1200px)']: {
        justifyContent: 'flex-start',
      },
      ['@media(max-width:768px)']: {
        justifyContent: 'flex-start',
      },
    },
    heading: {
      color: theme.palette.primary.dark,
      fontWeight: 600,
      marginTop: theme.spacing(14.25),
      ['@media(max-width:1500px)']: {
        marginTop: theme.spacing(10),
      },
      marginLeft: theme.spacing(20),
    },
    productsContainer: {
      display: 'flex',
      margin: theme.spacing(3.75, 10, 10, 20),
      justifyContent: 'center',
      gap: theme.spacing(3),
      marginBottom: theme.spacing(1),
      flexDirection: 'row',

      height: '600px',
      position: 'relative',
    },
    cloudSelectorContainer: {
      overflow: 'visible',
      paddingBottom: theme.spacing(2),
    },

    welcomeTitle: {
      color: theme.palette.labraText.primary,
      fontSize: '28px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '36px',
      marginTop: theme.spacing(4),
      ['@media(max-width:1500px)']: {
        marginTop: theme.spacing(10),
      },
      marginLeft: theme.spacing(21),
    },
  }))
