import React from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'
import { useStyles } from './ProgressBar.styles'
import clsx from 'clsx'

export const ProgressBar: React.FC<{
  width: string
  height?: string
  colorLight?: boolean
}> = ({ width, height, colorLight }) => {
  const classes = useStyles(width, height)()

  return (
    <div className={clsx(classes.root)}>
      <LinearProgress
        data-testid="progressBar"
        classes={{
          barColorPrimary: colorLight
            ? classes.barColorLight
            : classes.barColorPrimary,
        }}
      />
    </div>
  )
}
