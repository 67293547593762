import React, { useEffect, useState } from 'react'
import { useStyles } from './PageNotFound.styles'
import { Typography, Grid } from '@material-ui/core'
import { PrimaryButton } from '../Button'
import { useLocation } from 'react-router-dom'
import { ProductRoutingMap } from '../../utils/productRoutingMap'
import { getFirstPath } from '../../utils/helperFunctions'
export const PageNotFound: React.FC = () => {
  const classes = useStyles()
  const location = useLocation()
  const product = location.pathname
  const [route, setRoute] = useState<string | null>(null)

  useEffect(() => {
    const productSelected = getFirstPath(product)
    if (Object.values(ProductRoutingMap).includes(productSelected)) {
      setRoute(productSelected)
    } else {
      setRoute(null)
    }
  }, [product])
  return (
    <div className={classes.root}>
      <Grid container className={classes.container} spacing={5}>
        <Grid item>
          <div className={classes.content}>
            <Typography className={classes.heading} data-testid="title">
              404
            </Typography>
            <Typography className={classes.subtitle} data-testid="subtitle">
              Page Not Found
            </Typography>
            <br />
            <Typography className={classes.subtitle}>
              We are Sorry, but the page you have requested is not found
            </Typography>
            <br />
            <PrimaryButton
              className={classes.button}
              onClick={() =>
                route !== null
                  ? (window.location.href = `/${route}`)
                  : (window.location.href = '/')
              }
              data-testid="button"
            >
              Go Home
            </PrimaryButton>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
