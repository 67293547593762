import { DimensionType } from '../../../flyout/modules/privateOffer/reducer'
import { cppoAgreementActionTypes } from './actions'

export type Status =
  | 'completed'
  | 'in_progress'
  | 'restricted'
  | 'expired'
  | 'rejected'
  | 'active'

export type ResellersInfo = {
  labraPlatformResellerId: string
  resellerAwsAccountId: string
  crmObjectId: string
  resellerCompanyName: string
  resellerLogoUrl: string
  companyName: string
}

export type Dimension = {
  dimensionId: string
  crmObjectId: string
  awsProductId: string
  isAdditionalUsageAllowed: boolean
  type: DimensionType
  price: number
  currency: 'USD'
  label: null | string
  description: null | string
  name: null | string
}
export type Product = {
  awsProductId: string
  productName: string
  dimensions: Dimension[]
}

export type InstallmentInfo = {
  amount: number
  currency: string
  paymentDate: string
}

export type AgreementRecipient = {
  email: string
  title?: string
  crmObjectId?: string
  lastName: string
  firstName: string
  resellerLogoUrl: string
}
export type AgreementApprovers = {
  hierarchyOrder?: number
  email: string
  name: string
}

export type Seller = {
  sellerLogoUrl: string
  sellerName: string
}

export type CPPOResellerAgreementState = {
  id?: string
  createdAt?: null | string
  crmAwsResellerAgreementObjectId?: null | string
  // product details
  sellerName: string // ISV
  sellerLogoUrl: string
  agreementRecipients: AgreementRecipient[]
  status: Status
  // About Agreement
  agreementName: string
  agreementExpirationDate?: null | string
  discountType?: null | string
  agreementDescription: string
  percentageDiscount?: number
  flexiblePaymentTerms?: boolean
  buyerAwsAccountId?: null | string
  // Product dimensions
  products: Product[]
  // Installments
  installmentInfo: InstallmentInfo[]
  // Others
  emailCustomMessage?: null | string
  contractDurationInMonthsForEndCustomer?: number
  agreementDurationType?: null | string
  contractDurationType?: null | string
  endCustomerEulaType?: null | string
  endCustomerEulaFiles?: string[]
  resellerLicenseAgreementType?: null | string
  resellerLicenseAgreementFiles?: string[]
  agreementApprovers?: null | AgreementApprovers[]
  agreementStakeholders?: any[]
  awsAgreementId?: null | string
  resellersInfo?: ResellersInfo[]
  metaData?: null | string
} & Seller

export const initialState: Partial<CPPOResellerAgreementState> = {}

type actionType = {
  type: cppoAgreementActionTypes
  payload?: unknown
}

export const reducer = (
  state: Partial<CPPOResellerAgreementState> = initialState,
  action: actionType
) => {
  switch (action.type) {
    case cppoAgreementActionTypes.SET_CPPO_AGREENEMENT_DATA: {
      const newState = action.payload as CPPOResellerAgreementState
      return {
        ...state,
        ...newState,
      }
    }
    default:
      return state
  }
}
