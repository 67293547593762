import { isEmpty } from 'lodash'
import { Reducer } from 'redux'
import { NotificationsActions } from './actions'

export interface PartnerLevel {
  name: string
  isConfigured: boolean
  metaDataFields: Record<string, string>
}
export interface NotificationsTypeMethods {
  productNotificationTypeMethodId: string
  methodId: string
  type: string
  isEnabled?: boolean
}

export interface availableMethodsData {
  partnerLevel: {
    [x: string]: PartnerLevel
  }
  userLevel: {
    [x: string]: Record<string, string>
  }
}

export interface ProductNotificationTypes {
  productNotificationTypeId: string
  notificationTypeId: string
  notificationTypeDescription: string
  notificationTypeName: string
  productNotificationTypeMethods: NotificationsTypeMethods[]
}
export interface NotificationsSettingPage {
  productName: string
  productId: string
  availableMethods: availableMethodsData
  productNotificationTypes: ProductNotificationTypes[]
}

export interface NotificationsDetails {
  productNotificationTypeId: string
  notificationMethodId: string
  recipients?: string[]
}

export interface Notifications {
  partnerId: string
  idpUserId?: string
  notifications: NotificationsDetails[]
}

export type MetaData = Record<string, string>

export interface recipients {
  idpUserId: string | null
  destinationValue: string | null
  metaData?: MetaData
}

export interface ProductNotificationsTypeMethods {
  partnerId: string
  productNotificationTypeId: string
  notificationMethodId: string
  recipients: recipients[]
}

export type NotificationsState = {
  notifications?: Notifications
  notificationsSettingData: NotificationsSettingPage
  productNotificationTypeMethods: ProductNotificationsTypeMethods
  isFormDirty: boolean
}
export const initialState: NotificationsState = {
  isFormDirty: false,
  notifications: undefined,
  notificationsSettingData: {
    productName: '',
    productId: '',
    productNotificationTypes: [],
    availableMethods: {
      partnerLevel: {},
      userLevel: {},
    },
  },
  productNotificationTypeMethods: {
    partnerId: '',
    productNotificationTypeId: '',
    notificationMethodId: '',
    recipients: [],
  },
}
type actionType =
  | {
      type: NotificationsActions.SET_NOTIFICATIONS_SETTING_PAGE_DATA
      payload: {
        settingsPage: NotificationsSettingPage
        partnerId: string
        idpUserId?: string
      }
    }
  | {
      type: NotificationsActions.SET_NOTIFICATIONS
      payload: Notifications
    }
  | {
      type: NotificationsActions.ADD_UNREGISTERED_USER
      payload: string
    }
  | {
      type: NotificationsActions.ADD_REGISTERED_USER
      payload: string
    }
  | {
      type: NotificationsActions.REMOVE_REGISTERED_USER
      payload: string
    }
  | {
      type: NotificationsActions.SET_PRODUCT_NOTIFICATIONS_TYPE_METHODS
      payload: ProductNotificationsTypeMethods
    }
  | {
      type: NotificationsActions.DELETE_USER
      payload: number
    }
  | {
      type: NotificationsActions.UPDATE_CHECKBOX
      payload: { value: boolean; index: number; methodId: string }
    }
  | {
      type: NotificationsActions.DISCONNECT_SLACK
      payload: {
        [x: string]: PartnerLevel
      }
    }
  | {
      type: NotificationsActions.CONNECT_SLACK
      payload: {
        [x: string]: PartnerLevel
      }
    }
  | {
      type: NotificationsActions.FORM_DIRTY
      payload: boolean
    }
  | {
      type: NotificationsActions.CLEAR_NOTIFICATIONS_DATA
    }
export const reducer: Reducer<NotificationsState, actionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case NotificationsActions.SET_NOTIFICATIONS_SETTING_PAGE_DATA: {
      return {
        ...state,
        notificationsSettingData: action.payload.settingsPage,
      }
    }
    case NotificationsActions.SET_NOTIFICATIONS: {
      return {
        ...state,
        notifications: action.payload,
      }
    }
    case NotificationsActions.SET_PRODUCT_NOTIFICATIONS_TYPE_METHODS: {
      return {
        ...state,
        productNotificationTypeMethods: action.payload,
      }
    }
    case NotificationsActions.DELETE_USER: {
      const recipientList = [
        ...(state.productNotificationTypeMethods?.recipients || []),
      ]
      recipientList.splice(action.payload, 1)
      return {
        ...state,
        isFormDirty: true,
        productNotificationTypeMethods: {
          ...state.productNotificationTypeMethods,
          recipients: recipientList,
        },
      }
    }
    case NotificationsActions.ADD_UNREGISTERED_USER: {
      const list = [...state.productNotificationTypeMethods.recipients]
      const recipientList = isEmpty(action.payload)
        ? list
        : list.concat({
            destinationValue: action.payload,
            idpUserId: null,
          })
      return {
        ...state,
        isFormDirty: true,
        productNotificationTypeMethods: {
          ...state.productNotificationTypeMethods,
          recipients: recipientList,
        },
      }
    }
    case NotificationsActions.ADD_REGISTERED_USER: {
      const list = [...state.productNotificationTypeMethods.recipients]
      const recipientList = isEmpty(action.payload)
        ? list
        : list.concat({
            idpUserId: action.payload,
            destinationValue: null,
          })
      return {
        ...state,
        isFormDirty: true,
        productNotificationTypeMethods: {
          ...state.productNotificationTypeMethods,
          recipients: recipientList,
        },
      }
    }
    case NotificationsActions.REMOVE_REGISTERED_USER: {
      const recipientList = [
        ...(state.productNotificationTypeMethods?.recipients || []),
      ].filter(user => user.idpUserId !== action.payload)

      return {
        ...state,
        isFormDirty: true,
        productNotificationTypeMethods: {
          ...state.productNotificationTypeMethods,
          recipients: recipientList,
        },
      }
    }

    case NotificationsActions.DISCONNECT_SLACK: {
      return {
        ...state,
        notificationsSettingData: {
          ...state.notificationsSettingData,
          availableMethods: {
            ...state.notificationsSettingData.availableMethods,
            partnerLevel: action.payload,
          },
        },
      }
    }

    case NotificationsActions.CONNECT_SLACK: {
      return {
        ...state,
        notificationsSettingData: {
          ...state.notificationsSettingData,
          availableMethods: {
            ...state.notificationsSettingData.availableMethods,
            partnerLevel: action.payload,
          },
        },
      }
    }

    case NotificationsActions.UPDATE_CHECKBOX: {
      const { value, index, methodId } = action.payload
      const tempObject = { ...state.notificationsSettingData }
      const notificationType = { ...tempObject.productNotificationTypes[index] }
      const selectedObjectIndex =
        notificationType.productNotificationTypeMethods.findIndex(
          (method: NotificationsTypeMethods) => method.methodId === methodId
        )
      const methodList = [...notificationType.productNotificationTypeMethods]
      methodList[selectedObjectIndex].isEnabled = value
      notificationType.productNotificationTypeMethods = methodList
      tempObject.productNotificationTypes[index] = notificationType
      return {
        ...state,
        isFormDirty: true,
        notificationsSettingData: tempObject,
      }
    }
    case NotificationsActions.FORM_DIRTY: {
      const val = action.payload
      return {
        ...state,
        isFormDirty: val,
      }
    }
    case NotificationsActions.CLEAR_NOTIFICATIONS_DATA: {
      return {
        ...initialState,
      }
    }
    default: {
      return state
    }
  }
}
