import { AppAlertState } from './reducer'
export enum AppAlertActionTypes {
  UPDATE_APP_ALERT = 'UPDATE_APP_ALERT',
}

export type ErrorActions = {
  type: AppAlertActionTypes.UPDATE_APP_ALERT
}

export const updateAppAlert = (error: AppAlertState) => ({
  type: AppAlertActionTypes.UPDATE_APP_ALERT,
  error,
})
