import { useDispatch, useSelector } from '../../../store'
import { OnboardingState } from '../../modules/onboarding/reducer'
import { useEffect } from 'react'
import { getOnboarding } from '../../modules/onboarding/actions'
import { PartnerData, PartnerType } from '../../modules/partner/action'

// Here we assume that we already have the partner Data or that it is at least loading
// make sure to use usePartnerUser beforehand
export const useOnboarding = () => {
  const { onboarding, partnerData } = useSelector<{
    onboarding: OnboardingState
    partnerData: PartnerData | undefined
  }>(state => ({
    onboarding: state.onboarding.user,
    partnerData: state.PartnerData.user.partnerData || undefined,
  }))
  const dispatch = useDispatch()
  const isOnboardingEmpty =
    !onboarding.awsSettingStatus ||
    !onboarding.crmSettingStatus ||
    !onboarding.uatSettingStatus
  const loading = Boolean(isOnboardingEmpty || !partnerData)

  useEffect(() => {
    if (isOnboardingEmpty) {
      if (partnerData?.partnerId) {
        dispatch(getOnboarding(partnerData.partnerId, PartnerType.User))
      }
    }
  }, [partnerData?.partnerId])
  return { loading, onboarding }
}
