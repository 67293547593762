import { API } from '../../../common/api'
import { gcpMarketplaceServiceUrl } from '../../../common/utils/constants'
export const fetchGcpProductsList = (partnerId: string) => {
  return API.get(`${gcpMarketplaceServiceUrl}/partners/${partnerId}/products`, {
    params: {
      include: 'plans',
    },
  })
}

export const getGcpSinglePrivateOffer = (
  partnerId: string,
  privateOfferId: string,
  crmOpportunityId?: string,
  productId?: string
) => {
  return API.get(
    `${gcpMarketplaceServiceUrl}/partners/${partnerId}/private_offers/${privateOfferId}`,
    {
      params: {
        product_id: productId,
        crm_opportunity_id: crmOpportunityId,
      },
    }
  )
}

export const getGCPPrivateOffersData = ({
  partnerId,
  pageSize,
  pageNumber,
  productId,
  crmOpportunityId,
}: {
  partnerId: string
  productId?: string
  pageSize?: number
  pageNumber?: number
  crmOpportunityId?: string
}) => {
  return API.get(
    `${gcpMarketplaceServiceUrl}/partners/${partnerId}/private_offers`,
    {
      params: {
        page_size: pageSize,
        page_number: pageNumber,
        crm_opportunity_id: crmOpportunityId,
        ...(productId ? { product_id: productId } : {}),
      },
    }
  )
}

export const patchGCPPrivateOffersData = (
  partnerId: string,
  privateOfferId: string,
  data: Record<string, unknown>
) => {
  return API.patch(
    `${gcpMarketplaceServiceUrl}/partners/${partnerId}/private_offers/${privateOfferId}`,
    data
  )
}

export const getGCPContractsData = (partnerId: string, productId: string) => {
  return API.get(
    `${gcpMarketplaceServiceUrl}/partners/${partnerId}/products/${productId}/contracts`,
    {
      params: {
        page_size: 100,
        include: 'plans',
      },
    }
  )
}

export const postGCPContractsData = (
  data: Record<string, unknown>,
  partnerId: string
) => {
  return API.post(
    `${gcpMarketplaceServiceUrl}/partners/${partnerId}/metering`,
    data
  )
}
export const putGCPPrivateOffersData = ({
  partnerId,
  data,
}: {
  partnerId: string
  data: Record<string, unknown>
  saveAsDraft?: boolean
}) => {
  return API.put(
    `${gcpMarketplaceServiceUrl}/partners/${partnerId}/private_offers`,
    data
  )
}
