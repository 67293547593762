import { makeStyles } from '@material-ui/core'
import { maxHeaderHeight } from '../../utils/constants'

export const useStyles = makeStyles(theme => ({
  appTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2.5, 4, 2.5, 4),
    alignItems: 'center',
    maxHeight: maxHeaderHeight,
    lineHeight: '0.8px',
  },
  appTitleHeading: {
    color: theme.palette.headerTitle.main,
    display: 'flex',
    flexDirection: 'row',
  },
  appTitleIcon: {
    marginRight: theme.spacing(1.12),
    color: theme.palette.icon.main,
  },
  appTitleHeadingText: {
    textAlign: 'left',
    fontWeight: 600,
  },
  appTitleActions: {},
  divider: {
    backgroundColor: theme.palette.dividers.main,
    height: '1px',
  },

  titleHeader: {
    position: 'relative',
    zIndex: 999,
    width: '100%',
    backgroundColor: theme.palette.common.white,
  },
  stickyContainer: {
    position: 'sticky',
  },
  fixedContainer: {
    zIndex: 999,
    height: 0,

    width: '100%',
  },
  sticky: {
    position: 'fixed',
    top: 0,
    width: '100%',
  },
}))
