import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  date: {
    fontSize: '13px',
    color: 'inherit',
  },
  time: {
    fontSize: '12px',
    color: theme.palette.check.light,
    marginLeft: theme.spacing(0.5),
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '25px',
  },
}))
