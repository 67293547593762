import { makeStyles } from '@material-ui/core'

export const useStyles = (modalHeight: string) => makeStyles(theme => ({
  actionButton: {
    fontWeight: 700,
    color: theme.palette.labraText.primary,
    borderRadius: theme.spacing(1,1,0,0),
    position: 'absolute',
    top: '50%',
    right: 0,
    zIndex: 1200,
    width: 'fit-content',
    fontSize: '10px',
    padding: theme.spacing(0.875, 1.25),
    backgroundColor: theme.palette.button.secondary,
    '&:hover': {
        backgroundColor: theme.palette.button.secondary,
    },
    '& -webkit-transform': 'rotate(90deg)',
    '& -webkit-transform-origin': '100% 100%',

    '& -moz-transform': 'rotate(90deg)',
    '& -moz-transform-origin': '100% 100%',

    '& ms-transform': 'rotate(90deg)',
    '& ms-transform-origin': '100% 100%',

    '& -o-transform': 'rotate(90deg)',
    '& -o-transform-origin': '100% 100%',

    transform: 'rotate(270deg)',
    transformOrigin: '100% 100%',
    marginTop: theme.spacing(-6.3),
    lineHeight: `${theme.spacing(1.75)}px`
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    gap: theme.spacing(1.25),
    fontSize: '16px',
    color: theme.palette.labraText.tertiary,
    textDecoration: 'none !important',
  },
  menuText:{
    lineHeight: `${theme.spacing(3)}px`,
  },
  loading: {
    height: modalHeight
  }
}))
