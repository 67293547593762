import { Reducer } from 'redux'
import { CloudType } from '../../../common/modules/types'
import { CRMConfigurationActionTypes, ReferralPipelinesType } from './actions'
import { ArrayReducerRow } from '../../../common/modules/arrayReducer'

export interface Stages {
  label: string
  id: string
}
export interface Pipelines {
  label: string
  id: string
  stages: Stages[]
}

export interface PartnerCentralError {
  firstNameHasError?: boolean
  firstNameError?: string
  lastNameHasError?: boolean
  lastNameError?: string
  emailHasError?: boolean
  emailError?: string
  usernameError?: string
  usernameHasError?: boolean
}
export interface PartnerCentralDetails {
  email: string
  username: string
  firstName: string
  lastName: string
}
export interface DefaultValues {
  defaultValueField: string
  value: string
}
export interface DefaultLabraHubspotFieldsMapping {
  defaultValueField: string
  value: unknown
}
export interface CRMSettingsFormData {
  biDirectionSync: boolean
  contacts: {
    customerEndUserContactDetails: boolean
    awsIsvSuccessManager: boolean
    awsPartnerSuccessManager: boolean
    partnerDevelopmentManager: boolean
    awsSalesRep: boolean
    awsAccountManager: boolean
    wwpsPartnerDevelopmentManager: boolean
  }
  syncFields: {
    revenue: boolean
    targetCloseDate: boolean
    nextSteps: boolean
  }
  createContact: boolean
  createCompany: boolean
  partnerCentralDetails: PartnerCentralDetails[]
  opportunityReferralPipeline: {
    pipelineId: string
    pipelineDealStageId: string
  }
  leadReferralPipeline: {
    pipelineId: string
    pipelineDealStageId: string
  }
  labraFieldsDefaultValues: Record<string, unknown>
  labraFieldsHubspotFieldsMapping: Record<string, unknown>
}
export type CRMConfigurationSchema = {
  AWS?: CRMSettingsFormData
  AZURE?: CRMSettingsFormData
  GCP?: CRMSettingsFormData
  REDHAT?: CRMSettingsFormData
}
export type CRMConfigurationState = {
  hubspot: {
    pipelines: Pipelines[]
    tablesFieldDetails: Record<string, unknown>[]
    CRMFormData: CRMConfigurationSchema
    crmConfigurationErros: Record<string, boolean>
  }
}

export type PartnerCentralDetailsWithCloudProviders = {
  AWS?: ArrayReducerRow<PartnerCentralDetails>[]
  AZURE?: ArrayReducerRow<PartnerCentralDetails>[]
  GCP?: ArrayReducerRow<PartnerCentralDetails>[]
  REDHAT?: ArrayReducerRow<PartnerCentralDetails>[]
}

export const zeroValuePartnerCentralDetails = {
  email: '',
  username: '',
  firstName: '',
  lastName: '',
}
export const zeroValueDefaultValues = { defaultValueField: '', value: '' }
const initialValuesFormData = {
  biDirectionSync: false,
  contacts: {
    customerEndUserContactDetails: false,
    awsIsvSuccessManager: false,
    awsPartnerSuccessManager: false,
    partnerDevelopmentManager: false,
    awsSalesRep: false,
    awsAccountManager: false,
    wwpsPartnerDevelopmentManager: false,
  },
  syncFields: {
    revenue: false,
    targetCloseDate: false,
    nextSteps: false,
  },
  createContact: false,
  createCompany: false,
  partnerCentralDetails: [zeroValuePartnerCentralDetails],
  opportunityReferralPipeline: {
    pipelineId: '',
    pipelineDealStageId: '',
  },
  leadReferralPipeline: {
    pipelineId: '',
    pipelineDealStageId: '',
  },
  labraFieldsDefaultValues: {},
  labraFieldsHubspotFieldsMapping: {},
}
export const initialState: CRMConfigurationState = {
  hubspot: {
    pipelines: [],
    tablesFieldDetails: [],
    CRMFormData: {
      AWS: initialValuesFormData,
      AZURE: initialValuesFormData,
      GCP: initialValuesFormData,
      REDHAT: initialValuesFormData,
    },
    crmConfigurationErros: {
      partnerCentralUsers: false,
      defaultValues: false,
    },
  },
}
export type CRMConfigurationActions =
  | {
      type: CRMConfigurationActionTypes.SET_CRM_CONFIGURATION_PIPELINES
      payload: Pipelines[]
    }
  | {
      type: CRMConfigurationActionTypes.SET_SELECTED_PIPELINES
      payload: {
        referralPipelinesType: ReferralPipelinesType
        key: string
        value: string
        cloudType?: CloudType
      }
    }
  | {
      type: CRMConfigurationActionTypes.SET_CRM_CONFIGURATION
      payload: CRMConfigurationSchema
    }
  | {
      type: CRMConfigurationActionTypes.SET_CRM_TABLES_FIELD_DETAILS
      payload: Record<string, unknown>[]
    }
  | {
      type: CRMConfigurationActionTypes.UPDATE_SYNC_FIELDS_VALUE_BY_KEY
      payload: { key: string; checked: boolean; cloudType: CloudType }
    }
  | {
      type: CRMConfigurationActionTypes.UPDATE_FROM_DATA_FIELDS_VALUE_BY_KEY
      payload: { key: string; value: unknown; cloudType: CloudType }
    }
  | {
      type: CRMConfigurationActionTypes.UPDATE_CONTACT_FIELDS_VALUE_BY_KEY
      payload: { key: string; checked: boolean; cloudType: CloudType }
    }
  | {
      type: CRMConfigurationActionTypes.UPDATE_CRM_CONFIGURATIONS_ERRORS
      payload: { key: string; hasErrors: boolean }
    }

export const reducer: Reducer<
  CRMConfigurationState,
  CRMConfigurationActions
> = (state = initialState, action) => {
  switch (action.type) {
    case CRMConfigurationActionTypes.SET_CRM_CONFIGURATION_PIPELINES: {
      return {
        ...state,
        hubspot: { ...state.hubspot, pipelines: action.payload },
      }
    }
    case CRMConfigurationActionTypes.SET_SELECTED_PIPELINES: {
      const { referralPipelinesType, key, value, cloudType } = action.payload
      const currentCloud = cloudType ? cloudType : CloudType.AWS
      return {
        ...state,
        hubspot: {
          ...state.hubspot,
          CRMFormData: {
            ...state.hubspot.CRMFormData,
            [currentCloud]: {
              ...state.hubspot.CRMFormData[currentCloud],
              [referralPipelinesType]: {
                ...(state.hubspot.CRMFormData[currentCloud]?.[
                  referralPipelinesType
                ] ?? {}),
                [key]: value,
              },
            },
          },
        },
      }
    }
    case CRMConfigurationActionTypes.SET_CRM_CONFIGURATION: {
      return {
        ...state,
        hubspot: {
          ...state.hubspot,
          CRMFormData: {
            ...state.hubspot.CRMFormData,
            ...action.payload,
          },
        },
      }
    }
    case CRMConfigurationActionTypes.SET_CRM_TABLES_FIELD_DETAILS: {
      return {
        ...state,
        hubspot: { ...state.hubspot, tablesFieldDetails: action.payload },
      }
    }

    case CRMConfigurationActionTypes.UPDATE_SYNC_FIELDS_VALUE_BY_KEY: {
      const { key, checked, cloudType } = action.payload
      return {
        ...state,
        hubspot: {
          ...state.hubspot,
          CRMFormData: {
            ...state.hubspot.CRMFormData,
            [cloudType]: {
              ...state.hubspot.CRMFormData[cloudType],
              syncFields: {
                ...(state.hubspot.CRMFormData[cloudType]?.syncFields ?? {}),
                [key]: checked,
              },
            },
          },
        },
      }
    }

    case CRMConfigurationActionTypes.UPDATE_FROM_DATA_FIELDS_VALUE_BY_KEY: {
      const { key, value, cloudType } = action.payload
      return {
        ...state,
        hubspot: {
          ...state.hubspot,
          CRMFormData: {
            ...state.hubspot.CRMFormData,
            [cloudType]: {
              ...state.hubspot.CRMFormData[cloudType],
              [key]: value,
            },
          },
        },
      }
    }

    case CRMConfigurationActionTypes.UPDATE_CONTACT_FIELDS_VALUE_BY_KEY: {
      const { key, checked, cloudType } = action.payload
      return {
        ...state,
        hubspot: {
          ...state.hubspot,
          CRMFormData: {
            ...state.hubspot.CRMFormData,
            [cloudType]: {
              ...state.hubspot.CRMFormData[cloudType],
              contacts: {
                ...(state.hubspot.CRMFormData[cloudType]?.contacts ?? {}),
                [key]: checked,
              },
            },
          },
        },
      }
    }
    case CRMConfigurationActionTypes.UPDATE_CRM_CONFIGURATIONS_ERRORS: {
      const { key, hasErrors } = action.payload
      return {
        ...state,
        hubspot: {
          ...state.hubspot,
          crmConfigurationErros: {
            ...state.hubspot.crmConfigurationErros,
            [key]: hasErrors,
          },
        },
      }
    }

    default: {
      return state
    }
  }
}
