import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles(theme => ({
  formContainer: {
    display: 'flex',
    gap: '24px',
    marginBottom: '24px',
    alignSelf: 'stretch',
  },

  description:{
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.25px',
    color: theme.palette.labraText.description,
    fontFamily: theme.typography.fontFamily,
  },

  note:{
    marginTop: theme.spacing(2),
  },

  deleteIcon: {
    '&.MuiIconButton-root': {
      padding: theme.spacing(0.4),
    },
    '& .MuiSvgIcon-root': {
      fontSize: '20px',
      color: theme.palette.textField.main,
      width: '24px',
      height: '24px',
    },
  },
  iconContainer: {
    flex: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'auto',
  },

  formControl: {
    width: '100%',
    textAlign: 'left',
    '& input[type=email]': {
      paddingTop: '7px !important',
      paddingBottom: '7px !important',
    },
  },
  fieldWidth: {
    width: '324px',
  },
  iconContainerErr: {
    paddingBottom: theme.spacing(2)
  }
}))
