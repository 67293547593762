import { Reducer } from 'redux'
import { environment } from '../../utils/constants'
import { GetOnboardingTypes } from './actions'

export enum UserOnboardingStatuses {
  INITIAL = 'INITIAL',
  CRM_SELECTION = 'CRM_SELECTION',
  CRM_MAPPING = 'CRM_MAPPING',
  CRM_MAPPING_SUBMITTED = 'CRM_MAPPING_SUBMITTED',
  ONBOARDING_COMPLETE = 'ONBOARDING_COMPLETE',
}

export enum EnvironmentStatus {
  staging = 'staging',
  production = 'production',
}

export enum SettingStatuses {
  NOT_INITIATED = 'NOT_INITIATED',
  IN_PROGRESS = 'IN_PROGRESS',
  REJECTED = 'REJECTED',
  COMPLETED = 'COMPLETED',
}

export enum CRMSettingStatuses {
  NOT_INITIATED = 'NOT_INITIATED',
  IN_PROGRESS = 'IN_PROGRESS',
  REJECTED = 'REJECTED',
  COMPLETED = 'COMPLETED',
  READY_FOR_REVIEW = 'READY_FOR_REVIEW',
}

export type Executive = {
  name: string
  image: string
  executiveCalendlyUrl?: string
}

export type OnboardingState = {
  executiveName: string
  executivePictureUrl: string
  awsSettingStatus?: SettingStatuses
  crmSettingStatus?: CRMSettingStatuses
  uatSettingStatus?: SettingStatuses
  notes?: string
  executiveCalendlyUrl?: string
  environment: environment
}

export type actionType = {
  type: GetOnboardingTypes
  payload: OnboardingState
}
export const initialState: OnboardingState = {
  executiveName: 'Lisa',
  executivePictureUrl: '',
  awsSettingStatus: undefined,
  crmSettingStatus: undefined,
  uatSettingStatus: undefined,
  notes: 'NOTES',
  environment: 'staging',
}

export const reducer: Reducer<OnboardingState, actionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case GetOnboardingTypes.GET_ONBOARDING: {
      return (state = action.payload)
    }
    case GetOnboardingTypes.SET_ONBOARDING: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case GetOnboardingTypes.ONBOARDING_CLEANUP:
      return initialState
    default:
      return state
  }
}
