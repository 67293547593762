import { Validate, ValidateActions } from './action'
export const initialState: Validate = {
  key: {
    touched: false,
    error: true,
  },
  table: {
    touched: false,
    error: true,
  },
  value: {
    touched: false,
    error: true,
  },
}
export type ValidateAction = {
  type: ValidateActions.SET_VALIDATIONS
  payload: { key: string; touched: boolean; error: boolean }
}
export const validateReducer = (
  state: Validate = initialState,
  action: ValidateAction
) => {
  switch (action.type) {
    case ValidateActions.SET_VALIDATIONS: {
      return {
        ...state,
        [action.payload.key]: {
          touched: action.payload.touched,
          error: action.payload.error,
        },
      }
    }
    default:
      return state
  }
}
