import { saveReferral, fetchReferral } from '../../../../oppsync/api/CoSell'

import { AppDispatch, RootState } from '../../../../store'
import { ReferralMetadata, ReferralReferredBy } from '../reducer.ts'
import { errorLogger } from '../../../../common/utils/errorLogger.ts'
import { AxiosError } from 'axios'
import { APIReferralError } from '../../../../externalApps/api/errorHandlers.ts'
import {
  assignNewKeys,
  removeEmptyValues,
} from '../../../../common/utils/CoSellHelpers/helperFunctions.ts'
import { isEmpty } from 'lodash'
import { ReferralMetadataType, ReferralUpdatedBy } from '../types.ts'
import { ActionType } from '../../../../externalApps/modules/referrals/actions.ts'
import {
  ReferralLeadSections,
  ReferralLeadFormSectionName,
  CustomerDetails,
  LeadDetails,
  PartialLeadObject,
  ReferralLeadAPI,
  ReferralLead,
  mapKeysLeadApiToLead,
} from './types.ts'
import {
  LeadStage,
  ReferralStage,
  ReferralStatus,
} from '../../../components/CoSell/types.ts'

export enum LeadFormActions {
  SET_LEAD_FORM = 'LEAD_SET_FORM',
  UPDATE_LEAD_FORM = 'LEAD_UPDATE_FORM',
  SET_LEAD = 'LEAD_SET',
  SET_FORM_LEAD_ERRORS = 'LEAD_SET_FORM_ERRORS',
  CLEAR_LEAD_FORM = 'LEAD_CLEAR_FORM',
}

export const setLeadCreationData = (
  onboardingFormData: Partial<ReferralLeadSections>
) => ({
  type: LeadFormActions.SET_LEAD_FORM,
  payload: onboardingFormData,
})

export const clearLeadForm = () => ({
  type: LeadFormActions.CLEAR_LEAD_FORM,
})

export type LeadChangeType = {
  sectionName: ReferralLeadFormSectionName
  data: Partial<CustomerDetails> | Partial<LeadDetails>
}

export const setLeadDetails = (referral: PartialLeadObject) => ({
  type: LeadFormActions.SET_LEAD,
  payload: referral,
})

export const updateLeadCreationData = (data: LeadChangeType) => ({
  type: LeadFormActions.UPDATE_LEAD_FORM,
  payload: data,
})

export const emptyLeadFormFieldErrors = {} as Record<keyof ReferralLead, string>

export const setLeadFormFieldErrors = (
  errors: Record<keyof ReferralLead, string>
) => ({
  type: LeadFormActions.SET_FORM_LEAD_ERRORS,
  payload: errors,
})

export const submitLeadForm =
  (
    referral: Partial<ReferralLeadSections>,
    status: ReferralMetadata['referralStatus'] = 'submitted',
    referralId?: string,
    actionType?: ActionType
  ) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const partnerData = getState().PartnerData.user.partnerData
    const referralFromBackend =
      getState().leadReferralForm.referral?.referralObject
    const { awsLeadStatus, acceptanceStatus, leadStatusReason } =
      getState().leadReferralForm.referralForm.leadDetails
    const dataTransformToApiFormat = mapLeadFormToApi(referral, {
      awsLeadStatus,
      acceptanceStatus,
      leadStatusReason,
    })
    const dataWithoutEmptyValues = removeEmptyValues(dataTransformToApiFormat)
    const referralFromForm = dataWithoutEmptyValues
    const referralPayload: Partial<ReferralLeadAPI> = {
      ...referralFromBackend,
      ...Object.fromEntries(
        Object.entries(referralFromForm).filter(
          ([key, value]) => value !== '' && value !== undefined
        )
      ),
      Labra__Last_Updated_By__c: ReferralUpdatedBy.LABRA_CRM,
    }
    const saveReferralParams = {
      referralObject: referralPayload,
      invokeSync: status === 'submitted',
      referralMetadata: {
        crmId: partnerData?.crmId || '',
        referralType: 'referral_lead' as ReferralMetadataType,
        referralStatus: status,
        crmParentTable: 'labra_referral',
        crmPrimaryKeyName: 'id',
      },
      labraSettingObject: {
        consultingPartner: false,
        technologyPartner: false,
        isv: partnerData?.isIsv ?? false,
        srrp: partnerData?.isSrrp ?? false,
        accelerateProgram: partnerData?.accelerateProgram ?? false,
        awsFieldsVersion: partnerData?.awsFieldsVersion || 'v14',
      },
    }
    try {
      const { data } = await saveReferral(
        saveReferralParams,
        referralId,
        actionType
      )

      const { referral_id: newReferralId } = data
      const lastReferralId = referralId ? referralId : newReferralId
      const newReferralObject = {
        ...referralPayload,
        ...(!isEmpty(lastReferralId) && { Id: newReferralId }),
      } as ReferralLeadAPI
      await dispatch(
        setLeadDetails({
          referralMetadata: {
            ...saveReferralParams.referralMetadata,
            ...(!isEmpty(lastReferralId) && { referralId: newReferralId }),
            ...(!isEmpty(lastReferralId) && {
              crmPrimaryKeyValue: newReferralId,
            }),
          },
          referralObject: newReferralObject,
          referralObjectTransformed: assignNewKeys<
            ReferralLeadAPI,
            ReferralLead
          >(newReferralObject, mapKeysLeadApiToLead),
        })
      )
    } catch (e: any) {
      const error = e as AxiosError<APIReferralError>

      const globalState = getState()
      errorLogger({ globalState })(error as Error)
      // intentionally returning error because redux does not bubble up the error
      return { error: e }
    }
  }

export const mapLeadFormToApi = (
  values: Partial<ReferralLeadSections>,
  extraData?: {
    awsLeadStatus?: LeadStage
    acceptanceStatus?: ReferralStatus
    leadStatusReason?: string
  }
): Partial<ReferralLeadAPI> => {
  const { customerDetails, leadDetails } = values
  return {
    Labra__Company_Name__c: customerDetails?.companyName,
    Labra__Website__c: customerDetails?.website,
    Labra__Industry__c: customerDetails?.industry,
    Labra__City__c: customerDetails?.city,
    Labra__State__c: customerDetails?.state,
    Labra__Country__c: customerDetails?.country,
    Labra__Postal_Code__c: customerDetails?.postalCode,
    Labra__First_Name__c: customerDetails?.firstName,
    Labra__Last_Name__c: customerDetails?.lastName,
    Labra__Title__c: customerDetails?.title,
    Labra__Email__c: customerDetails?.email,
    Labra__Phone__c: customerDetails?.phone,
    Labra__Street__c: customerDetails?.address,
    Labra__APN_CRM_Unique_Identifier__c: leadDetails?.partnerCRMLeadId,
    Labra__Company_Size__c: leadDetails?.segmentCompanySize,
    Labra__Use_Case_Workload__c: leadDetails?.useCaseWorkload,
    Labra__Description__c: leadDetails?.projectDescription,
    Labra__AWS_Lead_Status_Reason__c:
      extraData?.leadStatusReason || leadDetails?.leadStatusReason,
    Labra__AWS_Partner_Central_User_Name__c: leadDetails?.leadOwnerName,
    Labra__AWS_Partner_Central_User_Email__c: leadDetails?.leadOwnerEmail,
    Labra__Full_Name__c: customerDetails?.fullName,
    Labra__Referred_By__c: leadDetails?.referredBy,
    Labra__Acceptance_Status__c:
      extraData?.acceptanceStatus || leadDetails?.acceptanceStatus,
    Labra__AWS_Lead_Status__c:
      extraData?.awsLeadStatus || leadDetails?.awsLeadStatus,
    Labra__AWS_Campaign_Name__c: customerDetails?.campaignName,
  }
}

export const mapLeadApiToForm = (
  values: ReferralLeadAPI
): Partial<ReferralLeadSections> => {
  return {
    customerDetails: {
      companyName: values.Labra__Company_Name__c,
      website: values.Labra__Website__c,
      industry: values.Labra__Industry__c,
      city: values.Labra__City__c,
      state: values.Labra__State__c,
      country: values.Labra__Country__c,
      postalCode: values.Labra__Postal_Code__c,
      firstName: values.Labra__First_Name__c,
      lastName: values.Labra__Last_Name__c,
      title: values.Labra__Title__c,
      email: values.Labra__Email__c,
      phone: values.Labra__Phone__c,
      address: values.Labra__Street__c,
      fullName: values.Labra__Full_Name__c,
      campaignName: values.Labra__AWS_Campaign_Name__c,
    },
    leadDetails: {
      partnerCRMLeadId: values.Labra__APN_CRM_Unique_Identifier__c,
      segmentCompanySize: values.Labra__Company_Size__c,
      useCaseWorkload: values?.Labra__Use_Case_Workload__c,
      projectDescription: values.Labra__Description__c,
      leadStatusReason: values.Labra__AWS_Lead_Status_Reason__c,
      leadOwnerName: values.Labra__AWS_Partner_Central_User_Name__c,
      leadOwnerEmail: values.Labra__AWS_Partner_Central_User_Email__c,
      industry: values.Labra__Industry__c,
      referredBy: values.Labra__Referred_By__c as ReferralReferredBy,
      acceptanceStatus: values.Labra__Acceptance_Status__c as ReferralStatus,
      awsLeadStatus: values.Labra__AWS_Lead_Status__c as LeadStage,
    },
  }
}
