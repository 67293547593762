import { useEffect, useState } from 'react'
import {
  gethasAWSCustomCatalog,
  getPartner,
  PartnerData,
  PartnerType,
} from '../../modules/partner/action'
import { useDispatch, useSelector } from '../../../store'
import {
  getUserProfile,
  setUserProfile,
} from '../../modules/userProfile/action'
import { setIdentifyAndGroup } from '../CustomAnalytics'
import { newrelicErrLogger } from '../ErrorHandler'
import { useFlagValue, useUpdateConfigFlags } from '@labrav/flags'
import { useAuth0 } from '@auth0/auth0-react'
import { AuthProvider } from '../../../oppsync/modules/userList/action'

export const usePartnerUser = () => {
  const dispatch = useDispatch()
  const { user } = useAuth0()
  const { value: auth0Enabled } = useFlagValue('auth0')

  const { partnerData, partnerLoading } = useSelector<{
    partnerData: PartnerData | undefined
    partnerLoading: boolean
  }>(state => ({
    partnerData: state.PartnerData.user.partnerData || undefined,
    partnerLoading: state.PartnerData.user.isLoading,
  }))
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const { setConfig } = useUpdateConfigFlags()
  useEffect(() => {
    const setUp = async () => {
      if (user?.partner_id) {
        try {
          dispatch(getPartner(user.partner_id as string, PartnerType.User))
          dispatch(getUserProfile(user.partner_id, user.partner_user_id))
          setIdentifyAndGroup(user.sub || '', {
            partner_id: user.partner_id,
            roles: user.auth0_roles,
          })
          setConfig({
            user: {
              userId: user.sub,
              partnerId: user.partner_id as string,
            },
          })
        } catch (err: any) {
          newrelicErrLogger(err)
          setError(err)
        } finally {
          setLoading(false)
        }
      }
    }

    setUp()
  }, [auth0Enabled])

  return { loading, error, partnerData }
}
