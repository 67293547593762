import { UserProfile, UserProfileActionTypes } from './action'

export interface UserProfileState {
  userProfile: UserProfile
  errMess: any
}
export const initialState: UserProfileState = {
  userProfile: {},
  errMess: null,
}

type Action = { type: UserProfileActionTypes; payload: any }

export const UserProfileReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case UserProfileActionTypes.UPDATE_USER_PROFILE: {
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          ...action.payload,
        },
        errMess: null,
      }
    }

    case UserProfileActionTypes.SET_OKTA_USER_ID: {
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          id: action.payload,
        },
      }
    }
    case UserProfileActionTypes.UPDATE_USER_PROFILE_ERROR: {
      return {
        ...state,
        errMess: action.payload,
      }
    }
    case UserProfileActionTypes.SET_DEFAULT_TIMEZONE: {
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          defaultTimeZone: action.payload,
        },
      }
    }
    default:
      return state
  }
}
