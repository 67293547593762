import { RootState, AppDispatch } from '../../../../store'

import { updateAppAlert } from '../../appAlert/actions'

import { CRMTables } from './reducer'
import { isEmpty } from 'lodash'

import { fetchCRMTables } from '../../../../common/api'

import { RequestFailureMessage } from '../../../utils/messagesContants'

import { AxiosError } from 'axios'
import { errorLogger } from '../../../utils/errorLogger'
import { getErrorMessages } from '../../../utils/error'

export enum CrmTableActions {
  SET_CRM_TABLES = 'SET_CRM_TABLES',
  CLEAN_UP_CRM_TABLES = 'CLEAN_UP_CRM_TABLES',
}

export const setCrmTables = (payload: CRMTables) => ({
  type: CrmTableActions.SET_CRM_TABLES as CrmTableActions.SET_CRM_TABLES,
  payload,
})
export const cleanUpCRMTables = () => ({
  type: CrmTableActions.CLEAN_UP_CRM_TABLES as CrmTableActions.CLEAN_UP_CRM_TABLES,
})
export const getCRMTables =
  (crm_id: string) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState()
    if (!isEmpty(state.crmTables.tables)) {
      return void 0
    }

    try {
      const { data } = await fetchCRMTables(crm_id)

      dispatch(setCrmTables(data))
    } catch (error: unknown) {
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
      const errMess = getErrorMessages([RequestFailureMessage])(
        (error as AxiosError<ErrorResponse>).response
      )
      dispatch(
        updateAppAlert({
          message: errMess,
          messageType: 'ERROR',
          autoClose: true,
        })
      )
    }
  }
