/* eslint-disable @typescript-eslint/no-explicit-any */
import { Reducer, Action } from '@reduxjs/toolkit'

export const reducerTypeWrapper =
  (type: string, reducer: Reducer<any, any>): Reducer<any, any> =>
  (state, action: Action<string>) => {
    const actionTypeToken = `${type}-`
    if (action && action.type.match(actionTypeToken) !== null) {
      return reducer(state, {
        ...action,
        type: action.type.replace(actionTypeToken, ''),
      })
    }
    return reducer(state, {})
  }

export const actionTypeWrapper = <T = string>(type: T, action: any) => ({
  ...action,
  type: `${type}-${action.type}`,
})

export const actionTypeWrapperCurried =
  <T = string>(type: T) =>
  <Params = unknown, ActionReturn = Action<unknown>>(
    actionFn: (params: Params) => Action<ActionReturn>
  ) =>
  (params: Params) =>
    actionTypeWrapper(type, actionFn(params))
