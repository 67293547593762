import { makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.opaque.light,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    margin: theme.spacing(0),
  },
  heading: {
    fontSize: '80px',
    fontWeight: 500,
    color: theme.palette.black.main,
    lineHeight: 1,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '320px',
  },
  subtitle: {
    color: theme.palette.black.main,
    fontSize: '18px',
    fontWeight: 500,
  },
  button: {
    width: '240px',
    height: '48px',
  },
}))
