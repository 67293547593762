import { ThunkAction } from '@reduxjs/toolkit'
import { AnyAction } from 'redux'
import { RootState } from '../../store'

export type WebappThunkAction = ThunkAction<
  Promise<void>,
  RootState,
  unknown,
  AnyAction
>

export enum CloudType {
  AWS = 'AWS',
  AZURE = 'AZURE',
  GCP = 'GCP',
  REDHAT = 'REDHAT',
}

export function cloudTypeToApiValue(cloudType: CloudType) {
  if (cloudType === CloudType.AZURE) {
    return 'AZ'
  }

  return cloudType.toString()
}

export enum ServiceType {
  CRM = 'CRM',
  Marketplace = 'Marketplace',
}
