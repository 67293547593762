import { updateAppAlert } from '../../appAlert/actions'
import { postSupportData } from '../../../../common/api'
import {
  RequestFailureMessage,
  SupportRequestSuccess,
} from '../../../utils/messagesContants'
import { hideFaqSection } from '../../faqDrawer/actions'
import { AppDispatch, RootState } from '../../../../store'
import { errorLogger } from '../../../utils/errorLogger'

export enum SupportFormActionTypes {
  SHOW_SUPPORT_FORM = 'SHOW_SUPPORT_FORM',
  HIDE_SUPPORT_FORM = 'HIDE_SUPPORT_FORM',
}

export type BackDropActions =
  | {
      type: SupportFormActionTypes.SHOW_SUPPORT_FORM
    }
  | { type: SupportFormActionTypes.HIDE_SUPPORT_FORM }

export const showSupportForm = () => ({
  type: SupportFormActionTypes.SHOW_SUPPORT_FORM,
})

export const hideSupportForm = () => ({
  type: SupportFormActionTypes.HIDE_SUPPORT_FORM,
})

export const submitSupportData =
  (partnerId: string, data: unknown) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      await postSupportData(partnerId, data)
      dispatch(
        updateAppAlert({
          message: SupportRequestSuccess,
          messageType: 'SUCCESS',
          autoClose: true,
        })
      )
      dispatch(hideSupportForm())
      dispatch(hideFaqSection())
    } catch (error) {
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
      dispatch(
        updateAppAlert({
          message: RequestFailureMessage,
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      dispatch(hideSupportForm())
      dispatch(hideFaqSection())
    }
  }
