import {
  CRMSettingStatuses,
  EnvironmentStatus,
  OnboardingState,
  SettingStatuses,
} from '../../modules/onboarding/reducer'
import { useSelector } from '../../../store'

export const useOnboardingComplete = () => {
  const onboarding = useSelector<OnboardingState>(
    state => state.onboarding.user
  )

  const onboardingComplete =
    onboarding.awsSettingStatus === SettingStatuses.COMPLETED &&
    onboarding.crmSettingStatus === CRMSettingStatuses.COMPLETED &&
    onboarding.uatSettingStatus === SettingStatuses.COMPLETED &&
    onboarding.environment === EnvironmentStatus.production
  return {
    onboardingComplete,
  }
}
