import { Divider } from '@material-ui/core'

import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline'
import { ProductCardProps } from './ProductCard'
import { useStyles } from './ProductCard.styles'
import { Product } from '../../modules/partner/action'
import {
  Button,
  ModalConfirm,
  ModalSizeVariants,
} from '@labrav/react-components'
import { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import { getActiveCloudProviders } from '../../utils/Hooks/getActiveCloudProviders'

export type ProductCardUIProps = ProductCardProps & {
  activeProducts: Product[] | string[]
  selectedProduct?: string
}

export const ProductCardUI: React.FC<ProductCardUIProps> = ({
  product,
  handleCloudProviderClick,
  selectedProduct,
  activeProducts,
}) => {
  const {
    id,
    productName,
    productFullForm,
    productDescription,
    productAvailable,
    headerStripeColor,
    info,
  } = product
  const classes = useStyles({
    headerStripeColor,
    selected: selectedProduct === id,
    active: activeProducts.includes(id),
  })()

  const [openModal, setOpenModal] = useState<boolean>(false)
  const knowMore = useCallback(
    () => () => {
      setOpenModal(true)
    },
    []
  )
  const handleClose = useCallback(() => {
    setOpenModal(false)
  }, [open])

  const isProductSelected = selectedProduct === id
  const isProductActive = activeProducts.includes(id)

  const { cloudsConnected } = getActiveCloudProviders()

  return (
    <>
      <div className={classes.body} data-testid="card">
        <div className={classes.root} data-testid={`${productName}-card`}>
          <div className={classes.stripe}></div>
          <div className={classes.container}>
            <div
              className={classes.heading}
              data-testid={`${productName}-heading`}
            >
              {productName}
            </div>
            <div
              className={classes.subheading}
              data-testid={`${productName}-subheading`}
            >
              {productFullForm}
            </div>
            <div
              className={classes.description}
              data-testid={`${productName}-description`}
            >
              {productDescription}
            </div>

            <div
              onClick={info ? knowMore() : undefined}
              className={classes.knowMore}
              data-testid={`${productName}-info-button`}
            >
              Know more
            </div>
          </div>
          {!isProductSelected && !isProductActive && (
            <Divider className={classes.divider} />
          )}

          <div data-testid="actions" className={classes.actions}>
            {(isProductSelected && productAvailable) ||
            (isProductActive && productAvailable) ? (
              id === 'oppsync' && isProductActive ? (
                <div className={classes.licenseContainer}>
                  <div
                    className={classes.licenseDescription}
                    data-testid={`${productName}-description`}
                  >
                    {cloudsConnected.map(c => {
                      return (
                        <div>
                          {' '}
                          <CheckCircleOutline
                            className={classes.checkIconFilled}
                            data-testid={`${productName}-check-circle`}
                          />
                          {c}
                        </div>
                      )
                    })}
                  </div>

                  <Link
                    to={{
                      pathname: '/settings/oppsync/cloud',
                    }}
                    className={classes.cloudLink}
                  >
                    <Button
                      className={classes.oppsynButton}
                      variant="contained"
                      styleType={'primary'}
                      disableElevation
                      buttonWeight={200}
                      style={{ fontSize: '10px', fontWeight: 'normal' }}
                    >
                      {'Click here to configure new cloud provider'}
                    </Button>
                  </Link>
                </div>
              ) : (
                <CheckCircleOutline
                  className={classes.checkIcon}
                  data-testid={`${productName}-check-circle`}
                />
              )
            ) : (
              <Button
                data-testid={`${productName}-product-card-button`}
                variant="contained"
                styleType={!productAvailable ? 'disabled' : 'primary'}
                disableElevation
                disabled={!productAvailable}
                buttonWeight={148}
                onClick={handleCloudProviderClick}
              >
                {productAvailable ? 'Select' : 'Coming soon'}
              </Button>
            )}
          </div>
        </div>
        {isProductSelected && !isProductActive && (
          <div>
            <div className={classes.arrowContainer}></div>
            <div className={classes.arrowDown}></div>
          </div>
        )}
      </div>
      <ModalConfirm
        title={productName}
        content={info || ''}
        open={openModal}
        variant={ModalSizeVariants.medium}
        titleAcceptButton={'OK'}
        onAccept={handleClose}
        onDecline={handleClose}
        hideAcceptButton={true}
        hideCancelButton={true}
        align={'justify'}
      />
    </>
  )
}
