import { Reducer } from 'redux'
import { RevenueMiscellaneousActionTypes } from './actions'

export interface DateRange {
  startDate: string
  endDate: string
}
export interface RevenueDisbursementSidePanel {
  startDateOfMonth: string
  endDateOfMonth: string
  settlementId: string
  requestedForDatetime: string
  disbursementSidePanelLoading: number
}

type actionType =
  | {
      type: RevenueMiscellaneousActionTypes.SET_DISBURSEMENT_START_END_DATE
      payload: DateRange
    }
  | {
      type: RevenueMiscellaneousActionTypes.SET_SETTLEMENT_ID
      payload: string
    }
  | {
      type: RevenueMiscellaneousActionTypes.SET_REQUEST_PROCESSED_DATE
      payload: string
    }
  | {
      type: RevenueMiscellaneousActionTypes.SET_DISBURSEMENT_SIDE_PANEL_LOADING
      payload: boolean
    }
export const initialState: RevenueDisbursementSidePanel = {
  startDateOfMonth: '',
  endDateOfMonth: '',
  settlementId: '',
  requestedForDatetime: '',
  disbursementSidePanelLoading: 0,
}
export const reducer: Reducer<RevenueDisbursementSidePanel, actionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case RevenueMiscellaneousActionTypes.SET_DISBURSEMENT_START_END_DATE: {
      return {
        ...state,
        startDateOfMonth: action.payload.startDate,
        endDateOfMonth: action.payload.endDate,
      }
    }
    case RevenueMiscellaneousActionTypes.SET_REQUEST_PROCESSED_DATE: {
      return {
        ...state,
        requestedForDatetime: action.payload,
      }
    }
    case RevenueMiscellaneousActionTypes.SET_SETTLEMENT_ID: {
      return {
        ...state,
        settlementId: action.payload,
      }
    }
    case RevenueMiscellaneousActionTypes.SET_DISBURSEMENT_SIDE_PANEL_LOADING: {
      return {
        ...state,
        disbursementSidePanelLoading: action.payload
          ? state.disbursementSidePanelLoading + 1
          : state.disbursementSidePanelLoading - 1,
      }
    }
    default: {
      return state
    }
  }
}
