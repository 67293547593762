import { Dispatch } from 'redux'
import { updateAppAlert } from '../../../../common/modules/appAlert/actions'
import { SomeThingWentWrong } from '../../../../common/utils/messagesContants'
import { fetchPartnerObjectsStatistics } from '../../../../common/api'
import { camelize } from 'casing'
import {
  startLoading,
  stopLoading,
} from '../../../../common/modules/loading/actions'
import { LoadingTypes } from '../../../../common/modules/loading/reducer'
import { errorLogger } from '../../../../common/utils/errorLogger'
import { RootState } from '../../../../store'

export enum PartnerObjectsStatsTypes {
  GET_PARTNER_OBJS_STATS = 'GET_PARTNER_OBJS_STATS',
  SET_PARTNER_OBJS_STATS_ERROR = 'SET_PARTNER_OBJS_STATS_ERROR',
}

export const getPartnerStats =
  (partnerId: string) => (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.GENERAL))
    return fetchPartnerObjectsStatistics(partnerId)
      .then(res => {
        dispatch({
          type: PartnerObjectsStatsTypes.GET_PARTNER_OBJS_STATS,
          payload: camelize(res.data),
        })
      })
      .catch(error => {
        const errMessage = error?.response?.data?.message || SomeThingWentWrong
        setPartnerObjectsError(errMessage)
        dispatch(updateAppAlert(errMessage))
        const globalState = getState()
        errorLogger({ globalState })(error as Error)
      })
      .finally(() => {
        dispatch(stopLoading(LoadingTypes.GENERAL))
      })
  }

export const setPartnerObjectsError = (error: unknown) => ({
  type: PartnerObjectsStatsTypes.SET_PARTNER_OBJS_STATS_ERROR,
  payload: error,
})
