import { SubscriptionActionTypes } from './action'

export type SubscriptionState = {
  plan: string
  subscribed: boolean
  errMess: string
}

const initialState = {
  plan: '',
  subscribed: false,
  errMess: '',
}
type Action =
  | { type: SubscriptionActionTypes.SET_ERROR; payload: string }
  | {
      type: SubscriptionActionTypes.SET_SUBSCRIPTION
      payload: { plan: string; subscribed: boolean }
    }

export const SubscriptionReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case SubscriptionActionTypes.SET_SUBSCRIPTION: {
      return {
        ...state,
        plan: action.payload.plan,
        subscribed: action.payload.subscribed,
        errMess: null,
      }
    }
    case SubscriptionActionTypes.SET_ERROR: {
      return {
        ...state,
        plan: '',
        subscribed: false,
        errMess: action.payload,
      }
    }
    default:
      return state
  }
}
