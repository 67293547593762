import React, { useState } from 'react'
import {
  createStyles,
  Theme,
  makeStyles,
  withStyles,
} from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import { copyMsgTimeout } from '../../utils/constants'

const useStyles = (fontWeight: number) =>
  makeStyles((theme: Theme) =>
    createStyles({
      title: {
        color: theme.palette.black.dark,
        fontWeight: fontWeight,
      },
      elements: {
        margin: 15,
        fontSize: theme.typography.pxToRem(10),
      },
    })
  )

const LightHtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: '300px',
    fontSize: theme.typography.pxToRem(10),
    border: '1px solid #dadde9',
  },
}))(Tooltip)

interface LightToolTipProps {
  label: string
  description?: string
  children?: React.ReactNode
  fontWeight: number
  isCopyable: boolean
  textToCopy?: string
}

export const renderLabel = (
  label: string,
  isCopied: boolean,
  isCopyable: boolean
) => {
  if (isCopyable) {
    return isCopied ? 'Copied!' : label
  } else {
    return label
  }
}

export const LightToolTip: React.FC<LightToolTipProps> = (
  props: LightToolTipProps
) => {
  const { label, description, fontWeight, isCopyable, textToCopy, children } =
    props
  const [isCopied, setIsCopied] = useState(false)
  const classes = useStyles(fontWeight)()

  const onCopyText = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setIsCopied(true)
        setTimeout(() => {
          setIsCopied(false)
        }, copyMsgTimeout)
      })
      .catch(e => alert(e))
  }
  return (
    <LightHtmlTooltip
      onClick={() => (textToCopy ? onCopyText(textToCopy) : null)}
      arrow
      placement="right"
      data-testid="lightToolTip"
      title={
        <div>
          <div className={classes.elements}>
            <Typography
              color="inherit"
              className={classes.title}
              data-testid="label"
            >
              {renderLabel(label, isCopied, isCopyable)}
            </Typography>
          </div>
          <div className={classes.elements}>
            <Typography color="inherit" data-testid="description">
              {description}
            </Typography>
          </div>
        </div>
      }
    >
      <div>{children}</div>
    </LightHtmlTooltip>
  )
}

export interface LightToolTipDetermine {
  truncatedString: string
  originalString: string
}

export const LightToolTipDetermine = ({
  truncatedString,
  originalString,
}: LightToolTipDetermine) => {
  return truncatedString === originalString ? (
    <div data-testid="original-string">{originalString}</div>
  ) : (
    <LightToolTip isCopyable label={originalString} fontWeight={400}>
      {truncatedString}
    </LightToolTip>
  )
}
