import { useResolvedPath } from 'react-router-dom'
import { useStyles } from './AuthWrapper.styles'
import { useAuth0 } from '@auth0/auth0-react'
import { LoadingLogo } from '@labrav/react-components'
import { useFlagValue } from '@labrav/flags'

const AuthWrapper = () => {
  const { value: isLabraCustomerOnboarding, loading: loadingOnboardingFlag } =
    useFlagValue('labraCustomerOnboarding')
  const classes = useStyles(isLabraCustomerOnboarding)()
  const path = useResolvedPath('').pathname
  const { loginWithRedirect, isLoading, isAuthenticated } = useAuth0()
  const { value: isAuth0, loading: loadingFlag } = useFlagValue('auth0')
  const isLoadingAuth0 = isAuth0 && isLoading
  if (isLoadingAuth0 || loadingFlag || loadingOnboardingFlag) {
    return <LoadingLogo />
  }
  if (!isAuthenticated && isAuth0) {
    loginWithRedirect()
  }

  return <LoadingLogo />
}

export default AuthWrapper
