import { Reducer } from 'redux'
import { Creds } from '../onboarding/crmAuth/action'
import { environment } from '../../utils/constants'
import { PartnerActionTypes } from './action'

export interface PartnerStatitics {
  count: number
  pendingAwsSetting: number
  pendingCrmSetting: number
  pendingAwsUat: number
}

export interface partnerOnboardings {
  executiveName: string
  executivePictureUrl?: string
  notes?: string
  awsSettingStatus: string
  crmSettingStatus: string
  uatSettingStatus: string
  environment: environment
}

export interface PartnerOwner {
  email?: string
  firstName?: string
  lastName?: string
  login?: string
  name?: string
}

export interface CrmAppCreds {
  appLink: string
  appName: string
  clientId: string
  secretKey: string
  secretKeyUpdatedAt: string
  version?: string
}
export interface CrmDetails {
  creds?: Creds
  crmAppCreds?: CrmAppCreds
  crmId: string
  crmType: string
  environmentType: string
  status: string
  updatedAt: string
}
export interface AllPartnertData {
  partnerId: string
  iamArn?: string
  crmDetails?: CrmDetails
  crmId?: string
  awsAccountId?: string
  awsRegion?: string
  aceS3BucketPath?: string
  oppsyncS3BucketPath?: string
  companyName?: string
  syncPaused?: boolean
  awsSecurityPolicy?: string
  partnershipTier?: string
  partnershipType?: string
  isIsv?: boolean
  accelerateProgram?: boolean
  industry?: null
  type?: string
  address1?: string
  address2?: string
  city?: string
  state?: string
  zipcode?: string
  country?: string
  status?: string
  websiteUrl?: string
  contactEmail?: string
  contactPhone?: string
  acceptedTermsAndConditions?: boolean
  partnerOnboardings: partnerOnboardings
  partnerOwner?: PartnerOwner
  createdAt?: string
  updatedAt?: string
  firstSyncDate?: string
  isInternal?: boolean
}

export interface PartnerList {
  partners: AllPartnertData[]
  pageSize: number
  noOfPage: number | null
}

export interface AllPartnerState {
  partnerStatitics: PartnerStatitics
  partnerList: PartnerList
}
export const initialState: AllPartnerState = {
  partnerStatitics: {
    count: 0,
    pendingAwsSetting: 0,
    pendingCrmSetting: 0,
    pendingAwsUat: 0,
  },
  partnerList: {
    partners: [],
    pageSize: 10,
    noOfPage: null,
  },
}

type Action =
  | {
      type: PartnerActionTypes.SET_PARTNER_STATITICS
      payload: PartnerStatitics
    }
  | { type: PartnerActionTypes.SET_PARTNER_LIST; payload: any }
  | { type: PartnerActionTypes.SET_LIMIT; payload: any }
  | { type: PartnerActionTypes.SEARCH; payload: any }
  | { type: PartnerActionTypes.CLEAR_PARTNERS; payload: any }

export const reducer: Reducer<AllPartnerState, Action> = (
  state: AllPartnerState = initialState,
  action: Action
) => {
  switch (action.type) {
    case PartnerActionTypes.SET_PARTNER_STATITICS: {
      return {
        ...state,
        partnerStatitics: action.payload,
      }
    }

    case PartnerActionTypes.SET_PARTNER_LIST: {
      return {
        ...state,
        partnerList: {
          partners: action.payload.partners.partners,
          pageSize: action.payload.pageSize,
          noOfPage: Math.ceil(
            action.payload.partners.count / action.payload.pageSize
          ),
        },
      }
    }
    case PartnerActionTypes.CLEAR_PARTNERS: {
      return {
        ...state,
        partnerList: {
          partners: [],
          pageSize: 10,
          noOfPage: 0,
        } as PartnerList,
      }
    }
    case PartnerActionTypes.SET_LIMIT: {
      return {
        ...state,
        partnerList: {
          ...state.partnerList,
          pageSize: action.payload,
        },
      }
    }

    default:
      return state
  }
}
