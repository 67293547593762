import React from 'react'

import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import { useStyles } from './RoleStatusInfo.styles'
import { UserStatusEntries } from '../../../../common/utils/statusMapping'
import { UserInfoProps } from '../UserBasicInfo/UserBasicInfo'
import { Role, UserRole } from '../../../../common/utils/roles'
import { useDispatch, useSelector } from '../../../../store'
import { UserListState } from '../../../modules/userList/reducer'
import { setUserForBE } from '../../../modules/userList/action'
import { userStatus } from '../../../../common/utils/constants'
import { useUserType } from '../../../../common/utils/Hooks/usePartnerData'

export const RoleStatusInfo: React.FC<UserInfoProps> = ({ user }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const partnerType = useUserType()
  const { userStateForBE } = useSelector<UserListState>(
    state => state.userList[partnerType]
  )

  const [updateUser, setUpdateUser] = React.useState({
    status: user.status,
    role: user.roles[0],
  })

  const handleRoleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setUpdateUser({ ...updateUser, role: value as Role })
    dispatch(
      setUserForBE(
        { ...userStateForBE, roles: new Array(value) as Role[] },
        partnerType
      )
    )
  }

  const handleStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setUpdateUser({ ...updateUser, status: value as userStatus })
    dispatch(
      setUserForBE(
        { ...userStateForBE, status: value as userStatus },
        partnerType
      )
    )
  }

  const renderRoles = Object.entries(UserRole).map(([key, value]) => (
    <MenuItem className={classes.menuItem} value={key}>
      {value}
    </MenuItem>
  ))
  const statusMapping = UserStatusEntries(
    user?.status || null,
    user?.lastActivity || null
  )
  const renderStatuses = Object.entries(statusMapping as never).map(
    ([key, value]) => (
      <MenuItem className={classes.menuItem} value={key}>
        {value as string}
      </MenuItem>
    )
  )

  return (
    <div data-testid="roleStatus">
      <p className={classes.header}>Role & status</p>
      <div className={classes.selectContainer}>
        <TextField
          inputProps={{ 'data-testid': 'role', className: classes.select }}
          id="select-role"
          select
          name="role"
          label="Select role"
          value={updateUser.role}
          onChange={handleRoleChange}
          variant="standard"
        >
          {renderRoles}
        </TextField>
      </div>
      <div className={classes.selectContainer}>
        <TextField
          inputProps={{ 'data-testid': 'status', className: classes.select }}
          id="select-status"
          name="status"
          select
          label="Select status"
          value={updateUser.status}
          onChange={handleStatusChange}
          variant="standard"
        >
          {renderStatuses}
        </TextField>
      </div>
    </div>
  )
}
