export const snakeToTitleCase = (str: string): string =>
  str
    .split('_')
    .filter(x => x.length > 0)
    .map(x => x.charAt(0).toUpperCase() + x.slice(1).toLocaleLowerCase())
    .join(' ')

export const snakeToSentenceCase = (str: string): string => {
  const sentence = str
    .split('_')
    .filter(x => x.length > 0)
    .join(' ')
  return (
    sentence.charAt(0).toUpperCase() + sentence.slice(1).toLocaleLowerCase()
  )
}

export const generateRandomString = (length: number): string => {
  const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  let result = ''
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length))
  }
  return result
}
