import { calculatePercentageFromObj } from '../../../common/utils/calculatePercentage'
import { Reducer } from 'redux'
import { PieSvgProps } from '@nivo/pie'
import {
  leadsConfig,
  oppsConfig,
  oppsInitAtConfig,
  errorCausesConfig,
  leadsChartDetails,
  oppsChartDetails,
  oppsInitAtChartDetails,
  errorCausesChartDetails,
} from '../../../mocks/RadioIndicatorData'
import { dashboardChartsActionTypes } from './actions'
import {
  ChartDetailsProps,
  dataProps,
} from '../../components/RadioIndicator/RadioIndicator'

export interface DashBoardChartsState {
  oppsConfig: PieSvgProps<dataProps>
  oppsChartDetails: ChartDetailsProps
  leadsConfig: PieSvgProps<dataProps>
  leadsChartDetails: ChartDetailsProps
  oppsInitAtConfig: PieSvgProps<dataProps>
  oppsInitAtChartDetails: ChartDetailsProps
  errorCausesConfig: PieSvgProps<dataProps>
  errorCausesChartDetails: ChartDetailsProps
}

export const initialState: DashBoardChartsState = {
  oppsConfig: oppsConfig,
  oppsChartDetails: oppsChartDetails,
  leadsConfig: leadsConfig,
  leadsChartDetails: leadsChartDetails,
  oppsInitAtConfig: oppsInitAtConfig,
  oppsInitAtChartDetails: oppsInitAtChartDetails,
  errorCausesConfig: errorCausesConfig,
  errorCausesChartDetails: errorCausesChartDetails,
}

export type actionType = {
  type: dashboardChartsActionTypes
  payload: any
}

export const reducer: Reducer<DashBoardChartsState, actionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case dashboardChartsActionTypes.SET_CHARTS_DATA: {
      const { leads, opportunities, errorCauses } = action.payload
      const { errorCausesWithPercentage, totalCounts } =
        calculatePercentageFromObj(errorCauses)
      const { dataError, systemError, warnings } = errorCausesWithPercentage

      return {
        ...state,
        oppsConfig: {
          ...state.oppsConfig,
          data: [
            {
              ...state.oppsConfig.data[0],
              value: opportunities.synced,
            },
            {
              ...state.oppsConfig.data[1],
              value: opportunities.failed,
            },
            {
              ...state.oppsConfig.data[2],
              value: opportunities.inProgress,
            },
            {
              ...state.oppsConfig.data[3],
              value: opportunities.warning || 0,
            },
          ],
        },
        oppsChartDetails: {
          ...state.oppsChartDetails,
          total: opportunities.count,
          chartLabel:
            opportunities.count === 1 ? 'Opportunity' : 'Opportunities',
          legendDetails: {
            ...state.oppsChartDetails.legendDetails,
            data: [
              {
                ...state.oppsConfig.data[0],
                value: opportunities.synced,
              },
              {
                ...state.oppsConfig.data[1],
                value: opportunities.failed,
              },
              {
                ...state.oppsConfig.data[2],
                value: opportunities.inProgress,
              },
              {
                ...state.oppsConfig.data[3],
                value: opportunities.warning,
              },
            ],
          },
        },
        leadsConfig: {
          ...state.leadsConfig,
          data: [
            {
              ...state.leadsConfig.data[0],
              value: leads.synced,
            },
            {
              ...state.leadsConfig.data[1],
              value: leads.failed,
            },
            {
              ...state.leadsConfig.data[2],
              value: leads.inProgress,
            },
            {
              ...state.leadsConfig.data[3],
              value: leads.warning,
            },
          ],
        },
        leadsChartDetails: {
          ...state.leadsChartDetails,
          total: leads.count,
          chartLabel: leads.count === 1 ? 'Lead' : 'Leads',
          legendDetails: {
            ...state.leadsChartDetails.legendDetails,
            data: [
              {
                ...state.leadsConfig.data[0],
                value: leads.synced,
              },
              {
                ...state.leadsConfig.data[1],
                value: leads.failed,
              },
              {
                ...state.leadsConfig.data[2],
                value: leads.inProgress,
              },
              {
                ...state.leadsConfig.data[3],
                value: leads.warning,
              },
            ],
          },
        },

        oppsInitAtConfig: {
          ...state.oppsInitAtConfig,
          data: [
            {
              ...state.oppsInitAtConfig.data[0],
              value: opportunities.initiatedAtCrm,
            },
            {
              ...state.oppsInitAtConfig.data[1],
              value: opportunities.initiatedAtAce,
            },
          ],
        },
        oppsInitAtChartDetails: {
          ...state.oppsInitAtChartDetails,
          total: opportunities.count,
          chartLabel:
            opportunities.count === 1 ? 'Opportunity' : 'Opportunities',
          legendDetails: {
            ...state.oppsInitAtChartDetails.legendDetails,
            data: [
              {
                ...state.oppsInitAtConfig.data[0],
                value: opportunities.initiatedAtCrm,
              },
              {
                ...state.oppsInitAtConfig.data[1],
                value: opportunities.initiatedAtAce,
              },
            ],
          },
        },
        errorCausesConfig: {
          ...state.errorCausesConfig,
          data: [
            {
              ...state.errorCausesConfig.data[0],
              value: dataError,
            },
            {
              ...state.errorCausesConfig.data[1],
              value: systemError,
            },
            {
              ...state.errorCausesConfig.data[2],
              value: warnings,
            },
          ],
        },

        errorCausesChartDetails: {
          ...state.errorCausesChartDetails,
          total: totalCounts,
          legendDetails: {
            ...state.errorCausesChartDetails.legendDetails,
            data: [
              {
                ...state.errorCausesConfig.data[0],
                value: dataError,
              },
              {
                ...state.errorCausesConfig.data[1],
                value: systemError,
              },
              {
                ...state.errorCausesConfig.data[2],
                value: warnings,
              },
            ],
          },
        },
      }
    }

    default:
      return state
  }
}
