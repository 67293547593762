import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { IconButton, Typography } from '@material-ui/core'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import CloseIcon from '@material-ui/icons/Close'

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 20,
    },
    mainTitle: {
      display: 'flex',
    },
    titleText: {
      fontWeight: 400,
    },
    subtitle: {
      display: 'block',
    },
    checkIcon: {
      marginBottom: 2,
      paddingRight: 15,
      color: theme.palette.labraCheckbox.main,

      fontSize: 30,
    },
    actions: {
      paddingLeft: 25,
      display: 'inherit',
    },
    buttonAccept: {
      backgroundColor: theme.palette.opaque.dark,

      color: 'white',
      '&:hover': {
        backgroundColor: theme.palette.opaque.main,
      },
      borderRadius: theme.spacing(0.5),
      fontFamily: theme.typography.fontFamily,
      fontWeight: 500,
      textTransform: 'inherit',
      marginRight: 15,
      paddingLeft: 18,
      paddingRight: 18,
    },
    buttonDanger: {
      backgroundColor: theme.palette.status.dark,
      color: theme.palette.secondary.main,
      padding: theme.spacing(0.875, 4),
      marginRight: theme.spacing(2),
      textTransform: 'none',
      '&:hover': {
        backgroundColor: theme.palette.alerts.dark,
      },
      '&.Mui-disabled': {
        color: theme.palette.primary.light,
        backgroundColor: theme.palette.opaque.light,
        fontWeight: 500,
      },
    },
    cancel: {
      width: '100px',

      borderRadius: theme.spacing(0.5),
      fontFamily: theme.typography.fontFamily,
      fontWeight: 500,
      textTransform: 'inherit',

      paddingLeft: 18,
      paddingRight: 18,
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.primary.light,
    },
  })
)

interface ModalConfirmProps {
  icon?: JSX.Element
  title: string
  subTitle: string | JSX.Element
  content: string
  open: boolean
  titleAcceptButton: string
  onAccept: () => void
  onDecline: () => void
  showIcon?: boolean
  btnDanger?: boolean
}

export const ModalConfirm: React.FC<ModalConfirmProps> = (
  props: ModalConfirmProps
) => {
  const {
    icon,
    title,
    subTitle,
    content,
    open,
    titleAcceptButton,
    showIcon,
    onAccept,
    onDecline,
    btnDanger,
    ...other
  } = props

  const classes = useStyles()

  return (
    <>
      <Dialog open={open} onClose={onDecline}>
        <div className={classes.root}>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onDecline}
          >
            <CloseIcon />
          </IconButton>

          <DialogTitle id="responsive-dialog-title">
            <div className={classes.mainTitle}>
              {showIcon &&
                (icon || (
                  <CheckCircleOutlineIcon
                    data-testid="icon"
                    className={classes.checkIcon}
                  />
                ))}
              <Typography
                className={classes.titleText}
                variant="h1"
                data-testid="title"
              >
                {title}
              </Typography>
            </div>
          </DialogTitle>
          <DialogContent>
            <Typography
              variant="inherit"
              className={classes.subtitle}
              data-testid="subtitle"
            >
              {subTitle}
            </Typography>
            <Typography variant="inherit">{content}</Typography>
          </DialogContent>
          <DialogActions className={classes.actions}>
            <Button
              onClick={onAccept}
              color="primary"
              autoFocus
              className={
                btnDanger ? classes.buttonDanger : classes.buttonAccept
              }
              data-testid="accept-button"
            >
              {titleAcceptButton}
            </Button>
            <Button
              autoFocus
              variant="outlined"
              onClick={onDecline}
              className={classes.cancel}
              data-testid="cancel-button"
            >
              Cancel
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  )
}
