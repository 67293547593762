import { DateTime } from 'luxon'
import { useSelector } from '../../../store'
import { isEmpty } from 'lodash'
import { labraV2OnboardingReleaseDate } from '../../../common/utils/constants'
import { useFlagValue } from '@labrav/flags'
import { crmAuth } from '../../../common/utils/Hooks/useCrmAuthInfo'
import { customerFormData } from '../../../common/utils/Hooks/usePartnerData'

export type OppSyncOnboardingStatus = {
  hasCompanyInfo: boolean
  hasConnectedCrmAtLeastOnce: boolean
}

export const useOppSyncOnboardingStatus = (
  isCosellEnabled: boolean = false
) => {
  const { crmType } = customerFormData()
   const isCrmLabra = crmType === 'labra'
  const hasCompanyInfo = useSelector<boolean>(state => {
    const partnerData = state.PartnerData.user.partnerData
    return (
      !isEmpty(partnerData?.companyName) &&
      !isEmpty(partnerData?.websiteUrl) &&
      !isEmpty(partnerData?.industry) &&
      !isEmpty(partnerData?.partnershipType)
    )
  })

  const hasConnectedCrmAtLeastOnce = useSelector<boolean>(state => {
    return (
      !isEmpty(state.CRMAuth.crmFormData) &&
      !isEmpty(state.PartnerData.user?.partnerData?.crmId) &&
      (state.CRMAuth.crmFormData?.creds || []).length > 0 &&
      isEmpty(state.CRMAuth.crmFormData?.deleted_at)
    )
  })

  const hasArnRoleSetup = useSelector<boolean>(state => {
    const hasArnRoleSetup =
      (state.accounts.oppsync || [])[0]?.accountDetails?.roles?.rows?.length >
        1 ||
      !isEmpty(
        state.accountForm.oppsync?.accountDetails?.AWS?.roles?.rows[0]?.inputsFields.find(
          i => i.keyName === 'iamRoleArn'
        )?.value
      )
    return hasArnRoleSetup
  })

  const hasPolicySetup = useSelector<boolean>(state => {
    const partnerData = state.PartnerData.user.partnerData
    return (
      !isEmpty(partnerData?.iamPolicyProduction) &&
      !isEmpty(partnerData?.iamPolicyStaging)
    )
  })

  const hasSolutionsAndServices = useSelector<boolean>(
    state => state.PartnerData.user.partnerData?.hasAWSCustomCatalog ?? false
  )

  const loading = useSelector<boolean>(state => {
    const partnerLoading = !state.PartnerData.user.partnerData
    const arnLoading = state.accounts.oppsync === null
    const hasAWSCustomCatalog =
      state.PartnerData.user.partnerData?.hasAWSCustomCatalog
    const hasAWSCustomCatalogLoading =
      hasAWSCustomCatalog === null || hasAWSCustomCatalog === undefined

    return partnerLoading || arnLoading || hasAWSCustomCatalogLoading
  })

  const isNewPartner = useSelector<boolean>(state => {
    const partnerCreatedAt = state.PartnerData.user.partnerData?.createdAt
    if (!partnerCreatedAt) {
      return true
    }
    return (
      DateTime.fromISO(labraV2OnboardingReleaseDate) <
      DateTime.fromISO(partnerCreatedAt)
    )
  })
  const onboardingComplete =
    (hasCompanyInfo &&
      (isCrmLabra || hasConnectedCrmAtLeastOnce) &&
      hasArnRoleSetup &&
      hasPolicySetup &&
      hasSolutionsAndServices) ||
    !isNewPartner
  return {
    loading,
    isNewPartner,
    onboardingComplete,
    hasCompanyInfo,
    hasConnectedCrmAtLeastOnce,
    hasArnRoleSetup,
    hasPolicySetup,
    hasSolutionsAndServices,
  }
}
