import {
  ActionMenuPopover,
  ColumnMenuOption,
  PopoverBgColors,
} from '@labrav/react-components'
import { useStyles } from './LabraHelpAndFeedback.styles'
import { Button, Link } from '@material-ui/core'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowUpRightFromSquare,
  faCircleInfo,
  faMonitorWaveform,
  faTicket,
} from '@fortawesome/pro-regular-svg-icons'
import {
  labraHelpCenter,
  labraSupportTicket,
  labraSystemStatus,
} from '../../utils/constants'
import {
  PartnerType,
} from '../../modules/partner/action'
import { useUserType } from '../../utils/Hooks/usePartnerData'
import { useSelector } from '../../../store'
import useScreenSize from '../../utils/Hooks/useScreenSize'
import { DateTime } from 'luxon'
import { G2ReviewModal } from '../G2ReviewModal/G2ReviewModal'

export const isMoreThanThreeMonths = (openedAtVal: string | number | Date) =>
  !openedAtVal ||
  DateTime.now() >
    DateTime.fromJSDate(new Date(openedAtVal)).plus({ months: 3 })

export const LabraHelpAndFeedback = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const isAdmin = useUserType() === PartnerType.Admin
  const isInternal = useSelector<boolean>(state => {
    const partnerType = isAdmin ? PartnerType.Admin : PartnerType.User
    return state.PartnerData?.[partnerType]?.partnerData?.isInternal || false
  })
  const { screenHeight } = useScreenSize()
  const modalHeight = `${
    (screenHeight || 500) * (screenHeight <= 1080 ? 0.5 : 0.65)
  }px`
  const classes = useStyles(modalHeight)()

  const handleSelectOption = (
    option: ColumnMenuOption,
    handleClose?: (() => void) | undefined
  ) => {
    if (option?.key === 'g2-review-from') {
      setOpenModal(true)
    }
  }

  const options = [
    {
      label: (
        <Link
          target="_blank"
          href={labraHelpCenter}
          className={classes.menuItem}
          data-testid={'help-center'}
        >
          <FontAwesomeIcon icon={faCircleInfo} width={'16px'} height={'16px'} />
          <span className={classes.menuText}>Help centre</span>
          <FontAwesomeIcon
            icon={faArrowUpRightFromSquare}
            width={'16px'}
            height={'16px'}
          />
        </Link>
      ),
      disable: false,
      key: 'help-center',
    },
    {
      label: (
        <Link
          target="_blank"
          href={labraSupportTicket}
          className={classes.menuItem}
          data-testid={'support-ticket'}
        >
          <FontAwesomeIcon icon={faTicket} width={'18px'} height={'16px'} />
          <span className={classes.menuText}>Submit a support ticket</span>
          <FontAwesomeIcon
            icon={faArrowUpRightFromSquare}
            width={'16px'}
            height={'16px'}
          />
        </Link>
      ),
      disable: false,
      key: 'support-ticket',
      borderBottom: true,
    },
    {
      label: (
        <div className={classes.menuItem}>
          <FontAwesomeIcon icon={faTicket} width={'18px'} height={'16px'} />
          <span className={classes.menuText}>Review us on G2</span>
          <FontAwesomeIcon
            icon={faArrowUpRightFromSquare}
            width={'16px'}
            height={'16px'}
          />
        </div>
      ),
      disable: !!isInternal,
      key: 'g2-review-from',
      borderBottom: true,
      disabledTooltip: 'G2 review is for external users only.',
    },
    {
      label: (
        <Link
          target="_blank"
          href={labraSystemStatus}
          className={classes.menuItem}
          data-testid={'labra-system-status'}
        >
          <FontAwesomeIcon
            icon={faMonitorWaveform}
            width={'18px'}
            height={'16px'}
          />
          <span className={classes.menuText}>Labra system status</span>
          <FontAwesomeIcon
            icon={faArrowUpRightFromSquare}
            width={'16px'}
            height={'16px'}
          />
        </Link>
      ),
      key: 'labra-system-status',
    },
  ]

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  return (
    <>
      <Button
        className={classes.actionButton}
        onClick={handleClick}
        data-testid={'labra-help-and-feedback'}
      >
        Help | Feedback
      </Button>
      <ActionMenuPopover
        bgColor={PopoverBgColors?.YELLOW}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        menuOptions={options}
        handleSelectOption={handleSelectOption}
        toolTipPlacement={'left-start'}
      />
      <G2ReviewModal openModal={openModal} setOpenModal={setOpenModal} />
    </>
  )
}
