import {
  startLoading,
  stopLoading,
} from '../../../../common/modules/loading/actions'
import { actionTypeWrapper } from '../../../../common/utils/actionTypeWrapper'
import { Dispatch } from 'redux'
import { fetchPartnerObjects } from '../../../../common/api'
import { camelize } from 'casing'
import { PartnerObjectType } from './reducer'
import { updateAppAlert } from '../../../../common/modules/appAlert/actions'
import { LoadingTypes } from '../../../../common/modules/loading/reducer'
import { AxiosError } from 'axios'
import { RequestFailureMessage } from '../../../../common/utils/messagesContants'
import { RootState } from '../../../../store'
import { errorLogger } from '../../../../common/utils/errorLogger'
import { getErrorMessages } from '../../../../common/utils/error'

export enum PartnerObjectActionTypes {
  SET_PARTNER_OBJECTS = 'SET_PARTNER_OBJECTS',
  SET_LIMIT = 'SET_LIMIT',
  SET_PAGE_NUMBER = 'SET_PAGE_NUMBER',
  SET_PARTNER_OBJECTS_ERROR = 'SET_PARTNER_OBJECTS_ERROR',
  SET_CLEAR_OBJECTS_DATA = 'SET_CLEAR_OBJECTS_DATA',
}

export interface partnerObjectResponse {
  objects: PartnerObjectType[]
  count: number
}
export interface GetPartnerObjectsParams {
  partnerId: string
  objectType: CRMObjectType
  pageNumber: number
  pageSize: number
  syncStatus?: string
  objectsIds?: string
}

export const getPartnerObjects =
  (args: GetPartnerObjectsParams) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.GENERAL))
    const { partnerId, objectType } = args
    let { pageNumber, pageSize } = args
    pageNumber = pageNumber || 1
    pageSize = pageSize || 10
    dispatch(setClearObjectsData(objectType))
    return fetchPartnerObjects({
      partnerId,
      objectType,
      pageNumber,
      pageSize,
      syncStatus: args.syncStatus,
      objectsIds: args.objectsIds ?? '',
    })
      .then(res => {
        dispatch(setPageNumber(objectType, pageNumber))
        dispatch(
          actionTypeWrapper(objectType, setPartnerObjects(res.data, pageSize))
        )
      })
      .catch(e => {
        dispatch(
          setPartnerObjectsError(
            objectType,
            e.response.data?.errors || 'Some error occured'
          )
        )
        const globalState = getState()
        errorLogger({ globalState })(e as Error)
        const errorMessage = getErrorMessages([RequestFailureMessage])(
          (e as AxiosError<ErrorResponse>).response
        )
        dispatch(
          updateAppAlert({
            message: errorMessage,
            messageType: 'ERROR',
            autoClose: true,
          })
        )
      })
      .finally(() => {
        dispatch(stopLoading(LoadingTypes.GENERAL))
      })
  }

export const setPartnerObjects = (
  data: partnerObjectResponse,
  pageSize: number
) => ({
  type: PartnerObjectActionTypes.SET_PARTNER_OBJECTS,
  payload: { data: camelize(data), pageSize: pageSize },
})

export const setPartnerObjectsError = (
  objectType: CRMObjectType,
  error: unknown
) => {
  return actionTypeWrapper(objectType, {
    type: PartnerObjectActionTypes.SET_PARTNER_OBJECTS_ERROR,
    payload: error,
  })
}

export const setPageSize = (objectType: CRMObjectType, pageSize: number) => {
  return actionTypeWrapper(objectType, {
    type: PartnerObjectActionTypes.SET_LIMIT,
    payload: pageSize,
  })
}

export const setPageNumber = (
  objectType: CRMObjectType,
  pageNumber: number
) => {
  return actionTypeWrapper(objectType, {
    type: PartnerObjectActionTypes.SET_PAGE_NUMBER,
    payload: pageNumber,
  })
}

export const updatePageSize =
  (args: GetPartnerObjectsParams) => async (dispatch: Dispatch) => {
    const { partnerId, objectType } = args
    let { pageNumber, pageSize } = args
    pageNumber = pageNumber || 1
    pageSize = pageSize || 10

    await dispatch(setPageSize(objectType, pageSize))
    await dispatch(
      getPartnerObjects({
        partnerId,
        objectType,
        pageSize,
        pageNumber,
        syncStatus: args.syncStatus,
      }) as never
    )
  }

export const setClearObjectsData = (objectType: CRMObjectType) => {
  return actionTypeWrapper(objectType, {
    type: PartnerObjectActionTypes.SET_CLEAR_OBJECTS_DATA,
  })
}
