import { isEmpty } from 'lodash'
import { notifcationSeviceUrl } from '../utils/constants'
import { API } from './index'
import { camelize } from 'casing'

export const fetchThirdPartyAppList = (partnerId: string) => {
  return API.get(`${notifcationSeviceUrl}/partners/${partnerId}/integrations`)
}

export const putNotificationsData = (
  productId: string,
  data: Record<string, unknown>,
  partnerId: string,
  idpUserId?: string
) => {
  if (!isEmpty(idpUserId)) {
    return API.put(
      `${notifcationSeviceUrl}/products/${productId}/notifications`,
      data
    )
  } else {
    return API.put(
      `${notifcationSeviceUrl}/partners/${partnerId}/products/${productId}/notifications`,
      data
    )
  }
}

export const fetchNotificationSettingsPageData = (
  productId: string,
  idpUserId?: string
) => {
  if (idpUserId) {
    return API.get(
      `${notifcationSeviceUrl}/products/${productId}/product_notification_type_methods?idp_user_id=${idpUserId}`
    )
  } else {
    return API.get(
      `${notifcationSeviceUrl}/products/${productId}/product_notification_type_methods`
    )
  }
}

export const fetchReciepients = (
  partnerId: string,
  productId: string,
  productNotificationTypeMethodId: string
) =>
  API.get(
    `${notifcationSeviceUrl}/products/${productId}/product_notification_type_methods/${productNotificationTypeMethodId}/recipients?partner_id=${partnerId}`
  )

export const putUpdateRecipients = (data: unknown, productId: string) =>
  API.put(
    `${notifcationSeviceUrl}/products/${productId}/product_notification_type_methods`,
    data
  )
export const slackConnection = (
  code: string,
  partnerId: string,
  notificationMethodId: string,
  productId?: string
) =>
  API.post(`${notifcationSeviceUrl}/recipients/auths`, {
    callback_response: code,
    partner_id: partnerId,
    notification_method_id: notificationMethodId,
    product_id: productId,
  })

export const slackDisconecction = (
  partnerId: string,
  notificationMethodId: string,
  productId?: string
) =>
  API.delete(`${notifcationSeviceUrl}/recipients/auths`, {
    data: {
      partner_id: partnerId,
      product_id: productId,
      notification_method_id: notificationMethodId,
    },
  })

export const fetchSlackChannelsData = (partnerId: string, productId: string) =>
  API.get(
    `${notifcationSeviceUrl}/partners/${partnerId}/products/${productId}?operations=get_slack_channels`
  ).then(({ data }) => ({ data: camelize(data) }))
