import { userStatus } from './constants'

export enum UserStatusMapping {
  ACTIVE = 'Active',
  PENDING = 'Pending',
  BLOCKED = 'Blocked',
}

export enum Status {
  ACTIVE = 'ACTIVE',
  BLOCKED = 'BLOCKED',
  PENDING = 'PENDING',
}

export const UserStatusEntries = (
  status: userStatus | null,
  lastActivity: string | null
) => {
  if (lastActivity == null && status === null) {
    return {
      PENDING: UserStatusMapping.PENDING,
      BLOCKED: UserStatusMapping.BLOCKED,
    }
  }
  if (lastActivity == null && status === Status.PENDING) {
    return {
      PENDING: UserStatusMapping.PENDING,
      BLOCKED: UserStatusMapping.BLOCKED,
    }
  }
  if (lastActivity == null && status === Status.BLOCKED) {
    return {
      BLOCKED: UserStatusMapping.BLOCKED,
      PENDING: UserStatusMapping.PENDING,
    }
  }
  if (status === Status.BLOCKED) {
    return {
      BLOCKED: UserStatusMapping.BLOCKED,
      ACTIVE: UserStatusMapping.ACTIVE,
    }
  }
  if (status === Status.ACTIVE) {
    return {
      ACTIVE: UserStatusMapping.ACTIVE,
      BLOCKED: UserStatusMapping.BLOCKED,
    }
  }
  if (status === Status.PENDING) {
    return {
      PENDING: UserStatusMapping.PENDING,
      BLOCKED: UserStatusMapping.BLOCKED,
    }
  }
}
