import { useEffect, useState } from 'react'
import { setDefaultTimeZone } from '../../modules/userProfile/action'
import { useDispatch, useSelector } from '../../../store'
import { getTimeZone } from '../timezones'

export const useTimeZone = () => {
  const dispatch = useDispatch()
  const timeZone = useSelector<string>(
    state => state.userProfile.userProfile.timeZone || 'UTC'
  )
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    getTimeZone()
      .then(data => {
        dispatch(setDefaultTimeZone(data))
      })
      .catch(() => dispatch(setDefaultTimeZone('UTC')))
      .finally(() => setLoading(false))
  }, [])
  return { loading, timeZone }
}
