import { Reducer } from 'redux'
import { IntegrationsType } from './action'

export interface IntegrationMetaDataFields {
  imageUrl?: string
  webhookUrl?: string
  channelName?: string
}

export interface IntegrationValue {
  isConfigured: boolean
  configurationUrl: string
  metaDataFields: IntegrationMetaDataFields
  hasReinstalled?: boolean
}

export interface IntegrationsState {
  integrationsObj: { [key: string]: IntegrationValue }
}

export const initialState: IntegrationsState = {
  integrationsObj: {},
}
type Action = {
  type: IntegrationsType.SET_INTEGRATIONS
  payload: IntegrationsState
}

export const reducer: Reducer<IntegrationsState, Action> = (
  state: IntegrationsState = initialState,
  action
) => {
  switch (action.type) {
    case IntegrationsType.SET_INTEGRATIONS: {
      return {
        ...state,
        integrationsObj: action.payload.integrationsObj,
      }
    }

    default:
      return state
  }
}
