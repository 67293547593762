export const awsOppFields = [
  {
    title: 'Sync to AWS',
    place_holder: 'Sync to Aws',
    id: 'Labra__Sync_to_AWS__c',
    default_value: true,
    data_type: 'bool',
    info: 'Sync to Aws',
  },
  {
    title: 'Labra Referral Name',
    place_holder: 'Labra Referral Name',
    id: 'Name',
    is_required: true,
    data_type: 'string',
    max_length: 255,
    info: 'Labra Referral Name',
  },
  {
    title: 'Company name',
    place_holder: 'Company name',
    id: 'Labra__Company_Name__c',
    is_required: true,
    data_type: 'string',
    max_length: 255,
    info: "Name of the customer's company",
    disable_in_update: true,
  },
  {
    title: 'Acceptance Status',
    place_holder: 'Acceptance Status',
    id: 'Labra__Acceptance_Status__c',
    is_required: true,
    data_type: 'enum',
    info: 'Acceptance status of the business opportunity in your organization',
    options: ['Accepted', 'Rejected'],
    default_value: 'Accepted',
  },
  {
    title: 'Country',
    place_holder: 'Country',
    id: 'Labra__Country__c',
    is_required: true,
    data_type: 'enum',
    max_length: 40,
    min_length: 4,
    info: "Country in which customer's company operate primarily.",
    options: [
      'United States',
      'Afghanistan',
      'Aland Islands',
      'Albania',
      'Algeria',
      'American Samoa',
      'Andorra',
      'Angola',
      'Anguilla',
      'Antarctica',
      'Antigua And Barbuda',
      'Argentina',
      'Armenia',
      'Aruba',
      'Australia',
      'Austria',
      'Azerbaijan',
      'Bahamas',
      'Bahrain',
      'Bangladesh',
      'Barbados',
      'Belarus',
      'Belgium',
      'Belize',
      'Benin',
      'Bermuda',
      'Bhutan',
      'Bolivia',
      'Bonaire, Sint Eustatius and Saba',
      'Bosnia and Herzegovina',
      'Botswana',
      'Bouvet Island',
      'Brazil',
      'British Indian Ocean Territory',
      'Brunei Darussalam',
      'Bulgaria',
      'Burkina Faso',
      'Burundi',
      'Cambodia',
      'Cameroon',
      'Canada',
      'Cape Verde',
      'Cayman Islands',
      'Central African Republic',
      'Chad',
      'Chile',
      'China',
      'Christmas Island',
      'Cocos (Keeling) Islands',
      'Colombia',
      'Comoros',
      'Congo',
      'Cook Islands',
      'Costa Rica',
      "Cote d'Ivoire",
      'Croatia',
      'Cuba',
      'Curacao',
      'Cyprus',
      'Czech Republic',
      'Democratic Republic Of The Congo',
      'Denmark',
      'Djibouti',
      'Dominica',
      'Dominican Republic',
      'Ecuador',
      'Egypt',
      'El Salvador',
      'Equatorial Guinea',
      'Eritrea',
      'Estonia',
      'Ethiopia',
      'Falkland Islands (Malvinas)',
      'Faroe Islands',
      'Fiji',
      'Finland',
      'France',
      'French Guiana',
      'French Polynesia',
      'French Southern Territories',
      'Gabon',
      'Gambia',
      'Georgia',
      'Germany',
      'Ghana',
      'Gibraltar',
      'Great Britain',
      'Greece',
      'Greenland',
      'Grenada',
      'Guadeloupe',
      'Guam',
      'Guatemala',
      'Guernsey',
      'Guinea',
      'Guinea-Bissau',
      'Guyana',
      'Haiti',
      'Heard Island and McDonald Islands',
      'Holy See (Vatican City State)',
      'Honduras',
      'Hong Kong',
      'Hungary',
      'Iceland',
      'India',
      'Indonesia',
      'Iran, Islamic Republic Of',
      'Iraq',
      'Ireland',
      'Isle of Man',
      'Israel',
      'Italy',
      'Jamaica',
      'Japan',
      'Jersey',
      'Jordan',
      'Kazakhstan',
      'Kenya',
      'Kiribati',
      'Korea',
      'Kuwait',
      'Kyrgyzstan',
      "Lao People's Democratic Republic",
      'Latvia',
      'Lebanon',
      'Lesotho',
      'Liberia',
      'Libyan Arab Jamahiriya',
      'Liechtenstein',
      'Lithuania',
      'Luxembourg',
      'MACAO',
      'Macedonia, The former Yugoslav Republic Of',
      'Madagascar',
      'Malawi',
      'Malaysia',
      'Maldives',
      'Mali',
      'Malta',
      'Marshall Islands',
      'Martinique',
      'Mauritania',
      'Mauritius',
      'Mayotte',
      'Mexico',
      'Micronesia, Federated States of',
      'Moldova, Republic of',
      'Monaco',
      'Mongolia',
      'Montenegro',
      'Montserrat',
      'Morocco',
      'Mozambique',
      'Myanmar',
      'Namibia',
      'Nauru',
      'Nepal',
      'Netherlands',
      'Netherlands Antilles',
      'New Caledonia',
      'New Zealand',
      'Nicaragua',
      'Niger',
      'Nigeria',
      'Niue',
      'Norfolk Island',
      'Northern Mariana Islands',
      'Norway',
      'Oman',
      'Pakistan',
      'Palau',
      'Palestinian Territory, Occupied',
      'Panama',
      'Papua New Guinea',
      'Paraguay',
      'Peru',
      'Philippines',
      'Pitcairn',
      'Poland',
      'Portugal',
      'Puerto Rico',
      'Qatar',
      'Reunion',
      'Romania',
      'Russia',
      'Rwanda',
      'Saint Barthelemy',
      'Saint Helena, Ascension and Tristan Da Cunha',
      'Saint Kitts and Nevis',
      'Saint Lucia',
      'Saint Martin (French Part)',
      'Saint Pierre and Miquelon',
      'Saint Vincent and the Grenadines',
      'Samoa',
      'San Marino',
      'Sao Tome and Principe',
      'Saudi Arabia',
      'Senegal',
      'Serbia',
      'Serbia and Montenegro',
      'Seychelles',
      'Sierra Leone',
      'Singapore',
      'Sint Maarten (Dutch Part)',
      'Slovakia',
      'Slovenia',
      'Solomon Islands',
      'Somalia',
      'South Africa',
      'South Georgia and the South Sandwich Islands',
      'South Sudan',
      'Spain',
      'Sri Lanka',
      'Sudan',
      'Suriname',
      'Svalbard and Jan Mayen',
      'Swaziland',
      'Sweden',
      'Switzerland',
      'Syrian Arab Republic',
      'Taiwan',
      'Tajikistan',
      'Tanzania',
      'Thailand',
      'Timor-Leste',
      'Togo',
      'Tokelau',
      'Tonga',
      'Trinidad and Tobago',
      'Tunisia',
      'Turkey',
      'Turkmenistan',
      'Turks and Caicos Islands',
      'Tuvalu',
      'Uganda',
      'Ukraine',
      'United Arab Emirates',
      'United Kingdom',
      'United States Minor Outlying Islands',
      'Uruguay',
      'Uzbekistan',
      'Vanuatu',
      'Venezuela',
      'Viet Nam',
      'VIRGIN ISLANDS, BRITISH',
      'VIRGIN ISLANDS, U.S.',
      'Wallis and Futuna',
      'Western Sahara',
      'Yemen',
      'Zambia',
      'Zimbabwe',
    ],
    disable_in_update: true,
  },
  {
    title: 'State',
    place_holder: 'State',
    id: 'Labra__State__c',
    data_type: 'enum',
    info: "State of the customer's company",
    options: [
      'Alabama',
      'Alaska',
      'American Samoa',
      'Arizona',
      'Arkansas',
      'California',
      'Colorado',
      'Connecticut',
      'Delaware',
      'Dist. of Columbia',
      'Federated States of Micronesia',
      'Florida',
      'Georgia',
      'Guam',
      'Hawaii',
      'Idaho',
      'Illinois',
      'Indiana',
      'Iowa',
      'Kansas',
      'Kentucky',
      'Louisiana',
      'Maine',
      'Marshall Islands',
      'Maryland',
      'Massachusetts',
      'Michigan',
      'Minnesota',
      'Mississippi',
      'Missouri',
      'Montana',
      'Nebraska',
      'Nevada',
      'New Hampshire',
      'New Jersey',
      'New Mexico',
      'New York',
      'North Carolina',
      'North Dakota',
      'Northern Mariana Islands',
      'Ohio',
      'Oklahoma',
      'Oregon',
      'Palau',
      'Pennsylvania',
      'Puerto Rico',
      'Rhode Island',
      'South Carolina',
      'South Dakota',
      'Tennessee',
      'Texas',
      'Utah',
      'Vermont',
      'Virginia',
      'Virgin Islands',
      'Washington',
      'West Virginia',
      'Wisconsin',
      'Wyoming',
      'APO/AE',
      'AFO/FPO',
      'FPO AP',
    ],
    disable_in_update: true,
  },
  {
    title: 'City',
    place_holder: 'City',
    id: 'Labra__City__c',
    data_type: 'string',
    max_length: 255,
    info: "City of the customer's company",
    hide_in_creation: true,
    disable_in_creation: true,
  },
  {
    title: 'Postal Code',
    place_holder: 'Postal Code',
    id: 'Labra__Postal_Code__c',
    is_required: true,
    data_type: 'string',
    max_length: 20,
    info: "Postal code of the customer's company",

    disable_in_update: true,
  },
  {
    title: 'Delivery Model',
    place_holder: 'Delivery Model',
    id: 'Labra__Delivery_Model__c',
    is_required: true,
    data_type: 'multi_enum',
    info: 'Most applicable usage model',
    options: [
      'SaaS or PaaS',
      'BYOL or AMI',
      'Managed Services',
      'Professional Services',
      'Resell',
      'Other',
    ],
  },
  {
    title: 'Description',
    place_holder: 'Description',
    id: 'Labra__Description__c',
    is_required: true,
    min_length: 20,
    data_type: 'textarea',
    info: 'Write details and role of your organization on it.',
  },
  {
    title: 'Expected Monthly AWS Revenue (USD)',
    place_holder: 'Expected Monthly AWS Revenue (USD)',
    id: 'Labra__Expected_Monthly_AWS_Revenue__c',
    is_required: true,
    min_length: 1,
    data_type: 'integer',
    info: 'Expected Monthly AWS Revenue (USD)',
  },
  {
    title: 'Industry',
    place_holder: 'Industry',
    id: 'Labra__Industry__c',
    is_required: true,
    data_type: 'enum',
    info: "Industry in which customer's company operate primarily",
    options: [
      'Aerospace',
      'Agriculture',
      'Automotive',
      'Computers & Electronics',
      'Consumer Goods',
      'Education',
      'Financial Services',
      'Gaming',
      'Government',
      'Healthcare',
      'Hospitality',
      'Life Sciences',
      'Manufacturing',
      'Marketing & Advertising',
      'Media & Entertainment',
      'Mining',
      'Non-Profit Organization',
      'Energy - Oil & Gas',
      'Other',
      'Energy - Power & Utilities',
      'Professional Services',
      'Real Estate & Construction',
      'Retail',
      'Software & Internet',
      'Telecommunications',
      'Transportation & Logistics',
      'Travel',
      'Wholesale & Distribution',
    ],
  },
  {
    title: 'Other Industry',
    place_holder: 'Other Industry',
    id: 'Labra__Other_Industry__c',
    data_type: 'string',
    max_length: 255,
    info: "Name of the industry of the customer's company. This is only applicable when you select 'other' in the industry field",
  },
  {
    title: 'Primary Need From AWS',
    place_holder: 'Primary Need From AWS',
    id: 'Labra__Primary_Need_From_AWS__c',
    is_required: true,
    data_type: 'multi_enum',
    info: 'Type of support your organization will need from AWS',
    options: [
      'Co-Sell - Architectural Validation',
      'Co-Sell - Business Presentation',
      'Co-Sell - Competitive Information',
      'Co-Sell - Pricing Assistance',
      'Co-Sell - Technical Consultation',
      'Co-Sell - Total Cost of Ownership Evaluation',
      'Co-Sell - Deal Support',
      'Do Not Need Support from AWS Sales Rep',
      'Co-Sell - Support for Public Tender / RFx',
    ],
  },
  {
    title: 'Target Close Date',
    place_holder: 'Target Close Date',
    id: 'Labra__Target_Close_Date__c',
    is_required: true,
    data_type: 'date',
    min_date: 'today',
    info: 'Expected launch date',
  },
  {
    title: 'Use Case',
    place_holder: 'Use Case',
    id: 'Labra__Use_Case__c',
    is_required: true,
    data_type: 'enum',
    info: 'Customer use case to be solved',
    options: [
      'AI Machine Learning and Analytics',
      'Big Data: Data Warehouse / Data Integration / ETL / Data Lake / BI',
      'Business Applications & Contact Center',
      'Cloud Management Tools',
      'Containers & Serverless',
      'Edge Computing / End User Computing',
      'Energy',
      'Financial Services',
      'Healthcare and Life Sciences',
      'Hybrid Application Platform',
      'Industrial Software',
      'IOT',
      'Media & High performance computing (HPC)',
      'Migration / Database Migration',
      'Networking',
      'Security & Compliance',
      'Storage & Backup',
      'Training',
      'Archiving',
      'Blockchain',
      'Business Applications & SAP Production',
      'Business Applications: Mainframe Modernization',
      'Centralized Operations Management',
      'Cloud Management Tools & DevOps with Continuous Integration & Continuous Delivery (CICD)',
      'Configuration, Compliance & Auditing',
      'Connected Services',
      'Content Delivery & Edge Services',
      'Database',
      'Enterprise Governance & Controls',
      'Enterprise Resource Planning',
      'High Performance Computing',
      'Manufacturing, Supply Chain and Operations',
      'Monitoring & Observability',
      'Monitoring, logging and performance',
      'Outpost',
      'SAP',
      'VMC',
      'VMWare',
      'Web development & DevOps',
    ],
  },
  {
    title: 'Website',
    place_holder: 'Website',
    id: 'Labra__Website__c',
    is_required: true,
    data_type: 'string',
    validation_type: 'url',
    max_length: 255,
    info: "Website of the customer's company",

    disable_in_update: true,
  },
  {
    title: 'AWS Partner Central User Email',
    place_holder: 'AWS Partner Central User Email',
    id: 'Labra__AWS_Partner_Central_User_Email__c',
    is_required: true,
    data_type: 'enum',
    validation_type: null,
    info: 'It represents the opportunity owner for this referral and this user must be a registered AWS Partner Central user.',
  },
  {
    title: 'AWS Partner Central User Name',
    place_holder: 'AWS Partner Central User Name',
    id: 'Labra__AWS_Partner_Central_User_Name__c',
    is_required: true,
    data_type: 'string',
    max_length: 255,
    info: 'It represents the opportunity owner for this referral and this user must be a registered AWS Partner Central user.',
    hide_in_creation: true,
    disable_in_creation: true,
    hide_in_update: true,
    disable_in_update: true,
  },
  {
    title: 'Is Opp From Marketing Activity?',
    place_holder: 'Is Opp From Marketing Activity?',
    id: 'Labra__IsOppFromMarketingActivity__c',
    is_required: true,
    data_type: 'enum',
    info: 'To check if the Opportunity or Contact resulted from a marketing activity, Note: It’s mandatory for the user to add this value while creating/updating referral from Salesforce.',
    options: ['Yes', 'No'],
  },
  {
    title: 'Is Marketing Development Funded?',
    place_holder: 'Is Marketing Development Funded?',
    id: 'Labra__Labra_Is_Marketing_Development_Funded__c',
    data_type: 'enum',
    info: 'Is the opportunity result of a marketing development funded activity',
    options: ['Yes', 'No'],
    hide_in_creation: true,
    disable_in_creation: true,
    disable_in_update: true,
  },
  {
    title: 'MDF Needed',
    place_holder: 'MDF Needed',
    id: 'Labra__MDF_Needed__c',
    data_type: 'enum',
    max_length: 255,
    info: 'Did you used marketing development funded?',
    options: ['Yes', 'No'],
  },
  {
    title: 'Additional Comments',
    place_holder: 'Additional Comments',
    id: 'Labra__Additional_Comments__c',
    data_type: 'textarea',
    max_length: 32788,
    info: 'Additional information or comments',
  },
  {
    title: 'AWS Account Id',
    place_holder: 'AWS Account Id',
    id: 'Labra__AWS_Account_Id__c',
    data_type: 'string',
    max_length: 12,
    info: '12 digit AWS account number',
    hide_in_creation: true,
    disable_in_creation: true,
  },
  {
    title: 'AWS Internal Stage',
    place_holder: 'AWS Internal Stage',
    id: 'Labra__AWS_Internal_Stage__c',
    data_type: 'string',
    max_length: 80,
    info: 'Stage in the lifecycle as tracked by AWS. This will be shared only if the referral is Launched or Closed Lost.',
    hide_in_creation: true,
    disable_in_creation: true,
    disable_in_update: true,
  },
  {
    title: 'AWS Sales Rep Email',
    place_holder: 'AWS Sales Rep Email',
    id: 'Labra__AWS_Sales_Rep_Email__c',
    data_type: 'string',
    info: 'Email of the AWS Sales rep who will be your primary contact point.',
    hide_in_creation: true,
    disable_in_creation: true,
    disable_in_update: true,
  },
  {
    title: 'AWS Sales Rep Name',
    place_holder: 'AWS Sales Rep Name',
    id: 'Labra__AWS_Sales_Rep_Name__c',
    data_type: 'string',
    max_length: 255,
    info: 'Name of the AWS Sales rep who will be your primary contact point.',
    hide_in_creation: true,
    disable_in_creation: true,
    disable_in_update: true,
  },
  {
    title: 'Campaign Name',
    place_holder: 'Campaign Name',
    id: 'Labra__Campaign_Name__c',
    data_type: 'multi_enum',
    info: 'AWS campaign name to track campaign performance.',
    options: [
      'APN Immersion Days',
      'APN Solution Space',
      'ATO (Authority to Operate)',
      'AWS Marketplace Campaign',
      'ISV Immersion Day SFID Program',
      'ISV Workload Migration',
      'Migration Acceleration Program',
      'Partner Launch Initiative',
      'Partner Opportunity Acceleration Funded',
      'The Next Smart',
      'VMware Cloud on AWS',
      'Well-Architected',
      'Windows',
      'Workspaces/AppStream Accelerator Program',
      'WWPS NDPP',
      'P3',
    ],
  },
  {
    title: 'Competitive Tracking',
    place_holder: 'Competitive Tracking',
    id: 'Labra__Competitive_Tracking__c',
    data_type: 'enum',
    info: 'Name of the cloud providers who can be a competitor.',
    options: [
      'Oracle Cloud',
      'On-Prem',
      'Co-Location',
      'Akamai',
      'AliCloud',
      'Google Cloud Platform',
      'IBM Softlayer',
      'Microsoft Azure',
      'Other- Cost Optimization',
      'No Competition',
      '*Other',
    ],
    hide_in_creation: true,
    disable_in_creation: true,
  },
  {
    title: 'Other Competitive Tracking',
    place_holder: 'Other Competitive Tracking',
    id: 'Labra__Other_Competitive_Tracking__c',
    data_type: 'string',
    max_length: 255,
    info: "Name of the cloud providers who can be a competitor . This is only applicable when you select 'other' in the cloud provider competitor field.",
    hide_in_creation: true,
    disable_in_creation: true,
  },
  {
    title: 'Customer Email',
    place_holder: 'Customer Email',
    id: 'Labra__Customer_Email__c',
    data_type: 'string',
    validation_type: 'email',
    info: 'Customer Email',
    hide_in_creation: true,
    disable_in_creation: true,
  },
  {
    title: 'Customer First Name',
    place_holder: 'Customer First Name',
    id: 'Labra__Customer_First_Name__c',
    data_type: 'string',
    validation_type: 'string',
    max_length: 80,
    info: 'First Name of the customer',
    hide_in_creation: true,
    disable_in_creation: true,
  },
  {
    title: 'Customer Last Name',
    place_holder: 'Customer Last Name',
    id: 'Labra__Customer_Last_Name__c',
    data_type: 'string',
    validation_type: 'string',
    max_length: 80,
    info: 'Last name of the customer',
    hide_in_creation: true,
    disable_in_creation: true,
  },
  {
    title: 'Customer Phone',
    place_holder: 'Customer Phone',
    id: 'Labra__Customer_Phone__c',
    data_type: 'string',
    validation_type: 'phone',
    max_length: 40,
    info: 'Customer Phone',
    hide_in_creation: true,
    disable_in_creation: true,
  },
  {
    title: 'Customer Title',
    place_holder: 'Customer Title',
    id: 'Labra__Customer_Title__c',
    data_type: 'string',
    max_length: 80,
    info: 'Customer Title',
    hide_in_creation: true,
    disable_in_creation: true,
  },
  {
    title: 'Next step',
    place_holder: 'Next step',
    id: 'Labra__Next_step__c',
    data_type: 'string',
    max_length: 255,
    info: 'Use next steps for communication with AWS.',
  },
  {
    title: 'Next Step History',
    place_holder: 'Next Step History',
    id: 'Labra__Next_Step_History__c',
    data_type: 'string',
    max_length: 32000,
    info: 'Next Step History',
    hide_in_creation: true,
    disable_in_creation: true,
    disable_in_update: true,
  },
  {
    title: 'ACE Reviewer Comments',
    place_holder: 'ACE Reviewer Comments',
    id: 'Labra__Labra_ACE_Reviewer_Comments__c',
    data_type: 'string',
    info: 'Comments from AWS for Action Required Status',
    hide_in_creation: true,
    disable_in_creation: true,
    disable_in_update: true,
  },
  {
    title: 'ACE CRM Lead UniqueIdentifier',
    place_holder: 'ACE CRM Lead UniqueIdentifier',
    id: 'Labra__Labra_ACE_CRM_Lead_UniqueIdentifier__c',
    data_type: 'string',
    info: 'Tag opportunity to existing lead',
  },
  {
    title: ' Accept By Date',
    place_holder: ' Accept By Date',
    id: 'Labra__Labra_Accept_By_Date__c',
    data_type: 'datetime',
    info: 'Last Date to take decision on acceptance for AWS referrals, After this date Opportunity will be rejected if acceptance status is not provided',
    hide_in_creation: true,
    disable_in_creation: true,
    disable_in_update: true,
  },
  {
    title: 'Street Address',
    place_holder: 'Street Address',
    id: 'Labra__Street_Address__c',
    data_type: 'string',
    max_length: 255,
    info: "Address of the customers's company",
    hide_in_creation: true,
    disable_in_creation: true,
  },
  {
    title: 'AWS Acceptance Status',
    place_holder: 'AWS Acceptance Status',
    id: 'Labra__AWS_Acceptance_Status__c',
    data_type: 'enum',
    max_length: 255,
    info: 'Acceptance status o by AWS for the business opportunity referred by your organization',
    options: [
      'Draft',
      'Action Required',
      'Approved',
      'Submitted',
      'In review',
      'Rejected',
    ],

    hide_in_creation: true,
    disable_in_creation: true,
    disable_in_update: true,
  },
  {
    title: 'ISV Manager Email',
    place_holder: 'ISV_Manager_Email',
    id: 'Labra__ISV_Manager_Email__c',
    data_type: 'string',
    info: 'Email of the AWS ISV success manager. This is only applicable for you if you are a technology partner.',
    hide_in_creation: true,
    disable_in_creation: true,
    disable_in_update: true,
  },
  {
    title: 'ISV Manager Name',
    place_holder: 'ISV Manager Name',
    id: 'Labra__ISV_Manager_Name__c',
    data_type: 'string',
    max_length: 255,
    info: 'Name of the AWS ISV success manager . This is only applicable for you if you are a technology partner.',
    hide_in_creation: true,
    disable_in_creation: true,
    disable_in_update: true,
  },
  {
    title: 'Other PDM',
    place_holder: 'Other PDM',
    id: 'Labra__Other_PDM__c',
    data_type: 'string',
    max_length: 255,
    info: 'Name of the other AWS Partner development manager',
    hide_in_creation: true,
    disable_in_creation: true,
    disable_in_update: true,
  },
  {
    title: 'Other PDM Email',
    place_holder: 'Other PDM Email',
    id: 'Labra__Other_PDM_Email__c',
    data_type: 'string',
    info: 'Email of the other AWS Partner development manager',
    hide_in_creation: true,
    disable_in_creation: true,
    disable_in_update: true,
  },
  {
    title: 'Referral Stage',
    place_holder: 'Referral Stage',
    id: 'Labra__Referral_Stage__c',
    data_type: 'enum',
    info: 'Stage in the business lifecycle',
    options: [
      'Prospect',
      'Business Validation',
      'Committed',
      'Launched',
      'Technical Validation',
      'Qualified',
      'Closed Lost',
    ],
    hide_in_creation: true,
  },
  {
    title: 'Success Manager Email',
    place_holder: 'Success Manager Email',
    id: 'Labra__Success_Manager_Email__c',
    data_type: 'string',
    info: 'Email of the AWS Partner success manager . This is only applicable for you if you are a consulting partner.',
    hide_in_creation: true,
    disable_in_creation: true,
    disable_in_update: true,
  },
  {
    title: 'Success Manager Name',
    place_holder: 'Success Manager Name',
    id: 'Labra__Success_Manager_Name__c',
    data_type: 'string',
    max_length: 255,
    info: 'Name of the AWS Partner success manager. This is only applicable for you if you are a consulting partner.',
    hide_in_creation: true,
    disable_in_creation: true,
    disable_in_update: true,
  },
  {
    title: 'Customer Segment',
    place_holder: 'Customer Segment',
    id: 'Labra__Customer_Segment__c',
    data_type: 'enum',
    options: ['Enterprise', 'SME', 'Startup'],
    info: 'Customer Segment',
    hide_in_creation: true,
    disable_in_creation: true,
  },
  {
    title: 'Sales Territory',
    place_holder: 'Sales Territory',
    id: 'Labra__Sales_Territory__c',
    data_type: 'enum',
    options: [
      'EMEA',
      'APAC',
      'LATAM',
      'NA-Alaska',
      'NA-Midwest',
      'NA-Northwest',
      'NA-North Central',
      'NA-Northeast',
      'NA-Pacific Islands',
      'NA-Southwest',
      'NA-South Central',
      'NA-Southeast',
    ],
    info: 'Sales Territory',
    hide_in_creation: true,
    disable_in_creation: true,
  },
  {
    title: 'AWS SF Campaign Name',
    place_holder: 'AWS SF Campaign Name',
    id: 'Labra__awsSFCampaignName__c',
    data_type: 'string',
    max_length: 255,
    info: 'Marketing campaign name as tracked by AWS.',
  },
  {
    title: 'Marketing Activity Channel',
    place_holder: 'Marketing Activity Channel',
    id: 'Labra__marketingActivityChannel__c',
    data_type: 'enum',
    info: 'Marketing campaign channel that resulted in this referral.',
    options: [
      'AWS Marketing Central',
      'Content Syndication',
      'Display',
      'Email',
      'Live Event',
      'Out Of Home (OOH)',
      'Print',
      'Search',
      'Social',
      'Telemarketing',
      'TV',
      'Video',
      'Virtual Event',
    ],
  },
  {
    title: 'Marketing Activity Usecase',
    place_holder: 'Marketing Activity Usecase',
    id: 'Labra__marketingActivityUsecase__c',
    data_type: 'enum',
    info: 'Marketing campaign usecase that resulted in this referral.',
    options: [
      'AI/ML',
      'Analytics',
      'Application Integration',
      'Blockchain',
      'Business Applications',
      'Cloud Financial Management',
      'Compute',
      'Containers',
      'Customer Engagement',
      'Databases',
      'Developer Tools',
      'End User Computing',
      'Front End Web & Mobile',
      'Game Tech, IoT',
      'Management & Governance',
      'Media Services',
      'Migration & Transfer',
      'Networking & Content Delivery',
      'Quantum Technologies',
      'Robotics',
      'Satellite',
      'Security',
      'Serverless',
      'Storage',
      'VR & AR',
    ],
  },
  {
    title: 'Procurement Type',
    place_holder: 'Procurement Type',
    id: 'Labra__procurementType__c',
    data_type: 'enum',
    info: 'Payment terms agreed upon with the customer.',
    options: ['Contract', 'Pay-as-you-go', 'Subscription'],
    hide_in_creation: true,
    disable_in_creation: true,
  },
  {
    title: 'Contract End date',
    place_holder: 'Contract End date',
    id: 'Labra__contractEndDate__c',
    data_type: 'date',
    info: 'Contract End date',
    hide_in_creation: true,
    disable_in_creation: true,
  },
  {
    title: 'Contract Start date',
    place_holder: 'Contract Start date',
    id: 'Labra__contractStartDate__c',
    data_type: 'date',
    info: 'Start date of the contract',
    hide_in_creation: true,
    disable_in_creation: true,
  },
  {
    title: 'Contract Value',
    place_holder: 'Contract Value',
    id: 'Labra__customerSoftwareValue__c',
    data_type: 'integer',
    info: 'Total contract value as documented in the contract.',
    hide_in_creation: true,
    disable_in_creation: true,
  },
  {
    title: 'Primary Sales Rep Phone',
    place_holder: 'Primary Sales Rep Phone',
    id: 'Labra__Primary_Sales_Rep_Phone__c',
    data_type: 'string',
    validation_type: 'phone',
    info: 'Phone no of your primary sales representative',
  },
  {
    title: 'Currency',
    place_holder: 'Currency',
    id: 'Labra__customerSoftwareValueCurrency__c',
    data_type: 'enum',
    info: 'Currency of the documented contract',
    options: [
      'USD',
      'EUR',
      'GBP',
      'AUD',
      'CAD',
      'CNY',
      'NZD',
      'INR',
      'JPY',
      'CHF',
      'SEK',
      'AED',
      'AFN',
      'ALL',
      'AMD',
      'ANG',
      'AOA',
      'ARS',
      'AWG',
      'AZN',
      'BAM',
      'BBD',
      'BDT',
      'BGN',
      'BHD',
      'BIF',
      'BMD',
      'BND',
      'BOB',
      'BOV',
      'BRL',
      'BSD',
      'BTN',
      'BWP',
      'BYN',
      'BZD',
      'CDF',
      'CHE',
      'CHW',
      'CLF',
      'CLP',
      'COP',
      'COU',
      'CRC',
      'CUC',
      'CUP',
      'CVE',
      'CZK',
      'DJF',
      'DKK',
      'DOP',
      'DZD',
      'EGP',
      'ERN',
      'ETB',
      'FJD',
      'FKP',
      'GEL',
      'GHS',
      'GIP',
      'GMD',
      'GNF',
      'GTQ',
      'GYD',
      'HKD',
      'HNL',
      'HRK',
      'HTG',
      'HUF',
      'IDR',
      'ILS',
      'IQD',
      'IRR',
      'ISK',
      'JMD',
      'JOD',
      'KES',
      'KGS',
      'KHR',
      'KMF',
      'KPW',
      'KRW',
      'KWD',
      'KYD',
      'KZT',
      'LAK',
      'LBP',
      'LKR',
      'LRD',
      'LSL',
      'LYD',
      'MAD',
      'MDL',
      'MGA',
      'MKD',
      'MMK',
      'MNT',
      'MOP',
      'MRU',
      'MUR',
      'MVR',
      'MWK',
      'MXN',
      'MXV',
      'MYR',
      'MZN',
      'NAD',
      'NGN',
      'NIO',
      'NOK',
      'NPR',
      'OMR',
      'PAB',
      'PEN',
      'PGK',
      'PHP',
      'PKR',
      'PLN',
      'PYG',
      'QAR',
      'RON',
      'RSD',
      'RUB',
      'RWF',
      'SAR',
      'SBD',
      'SCR',
      'SDG',
      'SGD',
      'SHP',
      'SLL',
      'SOS',
      'SRD',
      'SSP',
      'STN',
      'SVC',
      'SYP',
      'SZL',
      'THB',
      'TJS',
      'TMT',
      'TND',
      'TOP',
      'TRY',
      'TTD',
      'TWD',
      'TZS',
      'UAH',
      'UGX',
      'USN',
      'UYI',
      'UYU',
      'UZS',
      'VEF',
      'VND',
      'VUV',
      'WST',
      'XAF',
      'XCD',
      'XDR',
      'XOF',
      'XPF',
      'XSU',
      'XUA',
      'YER',
      'ZAR',
      'ZMW',
      'ZWL',
    ],
    hide_in_creation: true,
    disable_in_creation: true,
  },
  {
    title: 'Last Modified By',
    place_holder: 'Last Modified By',
    id: 'Labra__Last_Modified_By__c',
    data_type: 'string',
    max_length: 250,
    info: 'Last Modified By',
    hide_in_creation: true,
    hide_in_update: true,
    disable_in_creation: true,
    disable_in_update: true,
  },
  {
    title: 'PDM',
    place_holder: 'PDM',
    id: 'Labra__PDM__c',
    data_type: 'string',
    max_length: 255,
    info: 'Name of the AWS Partner development manager',
    hide_in_creation: true,
    disable_in_creation: true,
    disable_in_update: true,
  },
  {
    title: 'PDM Email',
    place_holder: 'PDM Email',
    id: 'Labra__PDM_Email__c',
    data_type: 'string',
    info: 'Email of the AWS Partner development manager',
    hide_in_creation: true,
    disable_in_creation: true,
    disable_in_update: true,
  },
  {
    title: 'Primary Sales Rep Email',
    place_holder: 'Primary Sales Rep Email',
    id: 'Labra__Primary_Sales_Rep_Email__c',
    data_type: 'string',
    validation_type: 'email',
    info: 'Email of your primary sales representative',
  },
  {
    title: 'Primary Sales Rep First Name',
    place_holder: 'Primary Sales Rep First Name',
    id: 'Labra__Primary_Sales_Rep_First_Name__c',
    data_type: 'string',
    max_length: 255,
    info: 'First name of your primary sales representative',
  },
  {
    title: 'Primary Sales Rep Last Name',
    place_holder: 'Primary Sales Rep Last Name',
    id: 'Labra__Primary_Sales_Rep_Last_Name__c',
    data_type: 'string',
    max_length: 255,
    info: 'Last name of your primary sales representative',
  },
  {
    title: 'AWS Closed Lost Reason',
    place_holder: 'AWS Closed Lost Reason',
    id: 'Labra__AWS_Closed_Lost_Reason__c',
    data_type: 'enum',
    info: 'Reason code for referral being Closed by AWS. This will be shared only if the referral is Launched or Closed Lost.',
    options: [
      'Customer Deficiency',
      'Delay / Cancellation of Project',
      'Legal / Tax / Regulatory',
      'Lost to Competitor – Google',
      'Lost to Competitor – Microsoft',
      'Lost to Competitor – SoftLayer',
      'Lost to Competitor – VMWare',
      'Lost to Competitor – Other',
      'No Opportunity',
      'On Premises Deployment',
      'Partner Gap',
      'Price',
      'Security / Compliance',
      'Technical Limitations',
      'Customer Experience',
      'Other',
      'People/Relationship/Governance',
      'Product/Technology',
      'Financial/Commercial',
    ],
    hide_in_creation: true,
    disable_in_creation: true,
  },
  {
    title: 'ACE CRM Unique Identifier',
    place_holder: 'ACE CRM Unique Identifier',
    id: 'Labra__APN_CRM_Unique_Identifier__c',
    data_type: 'string',
    max_length: 18,
    info: 'Unique ID of the business opportunity in AWS ACE system.',
    hide_in_creation: true,
    disable_in_creation: true,
    disable_in_update: true,
  },
  {
    title: 'AWS Account Owner Email',
    place_holder: 'AWS Account Owner Email',
    id: 'Labra__AWS_Account_Owner_Email__c',
    data_type: 'string',
    info: 'Email of the AWS account owner',
    hide_in_creation: true,
    disable_in_creation: true,
    disable_in_update: true,
  },
  {
    title: 'AWS Account Owner Name',
    place_holder: 'AWS Account Owner Name',
    id: 'Labra__AWS_Account_Owner_Name__c',
    data_type: 'string',
    max_length: 255,
    info: 'Name of AWS Account Owner',
    hide_in_creation: true,
    disable_in_creation: true,
    disable_in_update: true,
  },
  {
    title: 'AWS Close Date',
    place_holder: 'AWS Close Date',
    id: 'Labra__AWS_Close_Date__c',
    data_type: 'date',
    info: 'Expected launch date according to AWS. This will be shared only if the referral is Launched or Closed Lost.',
    hide_in_creation: true,
    disable_in_creation: true,
    disable_in_update: true,
  },
  {
    title: 'ACE Created Date',
    place_holder: 'ACE Created Date',
    id: 'Labra__ACE_Created_Date__c',
    data_type: 'datetime',
    info: 'ACE Created Date',
    hide_in_creation: true,
    disable_in_creation: true,
    disable_in_update: true,
  },
  {
    title: 'ACE Last Modified Date',
    place_holder: 'ACE Last Modified Date',
    id: 'Labra__ACE_Last_Modified_Date__c',
    data_type: 'datetime',
    info: 'ACE Last Modified Date',
    hide_in_creation: true,
    disable_in_creation: true,
    disable_in_update: true,
  },
  {
    title: 'Referred by',
    place_holder: 'Referred by',
    id: 'Labra__Referred_By__c',
    default_value: 'Partner Referral',
    is_required: true,
    data_type: 'enum',
    info: 'Referred by',
    max_length: null,
    options: ['AWS Referral', 'Partner Referral'],
    disable_in_creation: true,
    disable_in_update: true,
  },
  {
    title:
      'Does this opportunity contain classified National Security information?',
    id: 'Labra__doesOppBelongToNatSec__c',
    options: ['Yes', 'No'],
    data_type: 'enum',
    info: 'Indicates whether the opportunity belongs to the national security category',
    disable_in_update: true,
  },
  {
    title: 'Customer Data Universal Number System (DUNS)',
    id: 'Labra__customerDuns__c',
    data_type: 'integer',
    info: 'Specifies the Data Universal Numbering System (DUNS) to uniquely identify a customer. The DUNS is a nine-digit identification number issued by Dun & Bradstreet (D&B). Each DUNS Number is specific to a particular site, meaning that separate DUNS numbers may be assigned to distinct physical locations of an entity, including branches, divisions, and headquarters.',
    min_length: 0,
    max_length: 999999999,
  },
  {
    title: 'AWS products',
    id: 'Labra__awsProducts__c',
    data_type: 'string',
    info: "Enables the association of specific AWS products with the opportunity. Partners can indicate which AWS products are relevant to the opportunity's solution and align with the customer's needs.",
  },
  {
    title: 'Opportunity Type',
    id: 'Labra__opportunityType__c',
    data_type: 'enum',
    info: 'Specifies the type of opportunity, categorizing it as a renewal opportunity, new opportunity, or expansion opportunity.',
    options: ['Flat Renewal', 'Expansion', 'Net New Business'],
    is_required: false,
  },
  {
    title: 'Other Solution Offered',
    id: 'Labra__otherSolutionOffered__c',
    data_type: 'string',
    info: 'Specifies the Solution offered for solving the customer\'s business problem when Solution Offered field takes the value "Other"',
    max_length: 255,
  },
  {
    title: 'Parent Opportunity ID',
    id: 'Labra__parentOppId__c',
    data_type: 'string',
    info: 'Specifies the apnCrmUniqueIdentifier of the parent opportunity associated with the current opportunity.',
    max_length: 20,
  },
  {
    title: 'Sales Activities',
    id: 'Labra__salesActivities__c',
    data_type: 'multi_enum',
    info: 'Specifies the sales activities conducted with the end customer that are related to the opportunity.',
    options: [
      'Initialized discussions with customer',
      'Customer has shown interest in solution',
      'Conducted POC / Demo',
      'In evaluation / planning stage',
      'Agreed on solution to Business Problem',
      'Completed Action Plan',
      'Finalized Deployment Needs',
      'SOW Signed',
    ],
    is_required: true,
  },
  {
    title: 'Solution offered',
    id: 'Labra__solutionOffered__c',
    data_type: 'string',
    info: 'Enables the linkage of solutions or offerings to opportunities. Insert the Offering IDs associated with the solutions being evaluated for addressing the customer\'s business problem. When an Offering ID isn\'t accessible, input "Other" and provide details in the "otherSolutionOffered" field. Offering IDs can be found AWS Partner Central > Build > Offering > Offering Detail Page. The ID\'s format follows S-1234567 pattern.',
    max_length: 20,
    is_required: true,
  },
  {
    title: 'Partner Contact Title',
    id: 'Labra__primaryContactTitle__c',
    data_type: 'string',
    info: "Job title or role of the partner's sales contact person associated with the opportunity.",
  },
  {
    title: 'AWS Marketplace Engagement Score',
    id: 'Labra__engagementScore__c',
    data_type: 'enum',
    info: 'Indicates the prioritization value that displays an estimate (High, Medium, or Low) of how likely the customer in an opportunity is to procure a solution through AWS Marketplace. For opportunities that do not have a score displayed, we were not able to determine their Co-Sell score given the current available customer information. The scores will be surfaced once enough information is available.',
    options: ['Low', 'Medium', 'High'],
    hide_in_creation: true,
    disable_in_creation: true,
    disable_in_update: true,
  },
  {
    title: 'AWS Recommended Action',
    id: 'Labra__recommendedNextBestActions__c',
    data_type: 'string',
    info: 'Indicates the recommendations for the next best actions to be taken on the opportunity. It offers insights and guidance on the optimal steps to progress the opportunity further. These recommendations are based on the current status, context, and available data associated with the opportunity.',
    hide_in_creation: true,
    disable_in_creation: true,
    disable_in_update: true,
  },
  {
    title: 'Offer ID',
    id: 'Labra__offers__c',
    data_type: 'string',
    info: 'Specifies the identifier of the AWS marketplace private offer that is associated with this opportunity.',
    hide_in_creation: true,
    disable_in_creation: true,
  },
  {
    title: 'Rejection reason',
    id: 'Labra__rejectionReason__c',
    data_type: 'enum',
    info: 'Specifies the reason for rejecting an AWS referral. Used by AWS for deriving insights as to why the opportunity was rejected.',
    options: [
      'Customer problem unclear',
      'Next steps unclear',
      'Unable to support',
      'Duplicate of Partner Referral',
      'Renewal opportunity',
      'Other',
    ],
    disable_in_creation: true,
  },
  {
    title: 'Co-sell Convert reason',
    id: 'Labra__cosellConvertReason__c',
    data_type: 'enum',
    info: 'Read only field to notify partner the reason why the opportunity converted to co-sell.',
    options: [
      'Expansion Opportunity',
      'Change in Deal Information',
      'Customer Requested',
      'Technical Complexity',
      'Risk Mitigation',
    ],
    hide_in_creation: true,
    disable_in_creation: true,
    disable_in_update: true,
  },
]
