import { PlanType } from '@labrav/react-components'
import { Reducer } from 'redux'
import { CloudMarketplace, links } from '../productsListing/reducer'
import { ContractsListingActionTypes } from './actions'
import { get } from 'lodash'

export type ListingStatus = 'EXPIRING_SOON' | 'EXPIRED'
export type Status = 'ACTIVE' | 'DEACTIVATED' | 'ARCHIVED' | 'PENDING'
export type OfferType = 'PUBLIC' | 'PRIVATE'

export interface ContractListingDimensionFields {
  dimensionId: string
  dimensionName: string
  dimensionLabel: string
  dimensionValueDataType: number | string | boolean
  meteringTimestamp?: string
  meteringId?: string
  meteringQuantityConsumed?: number
  unit?: string
  meteringLastUpdatedAt?: string
  meteringStatus?: Status
  id?: string
  label?: string
  name?: string
}
export interface Entitlement extends ContractListingDimensionFields {
  entitlementId: string
  entitlementValue?: string
  entitlementExpirationDate: string
  dimensionLabelDescription: string
  dimensionIsAdditionalUsageAllowed: boolean
  dimensionAdditionalUsageDescription?: string
}

export interface Entitlements {
  rows: Entitlement[]
}

export type ContractListingDimension = ContractListingDimensionFields

export interface ContractListingDimensions {
  rows: ContractListingDimension[]
}
export interface Plan {
  dimensions: ContractListingDimensionFields[]
  name: string
  type: string
  id: string
}
export interface Contract {
  id: string
  cloudMarketplaceContractId: string
  buyerCompanyName: string
  buyerId?: string
  buyerLogoUrl: string
  lastMeteringInfoSentAt?: string
  contractStartDate: string
  contractExpirationDate: string
  subscriptionEndDate: string
  listingStatus?: ListingStatus
  status?: Status
  createdAt?: string
  updatedAT?: string
  offerType?: OfferType
  cloudMarketplaceProductId: string
  productId: string
  productName?: string
  planId?: string
  cloudMarketplace?: string
  productType?: PlanType
  links?: links
  dimensions?: ContractListingDimensions
  entitlements?: Entitlements
  plan?: Plan
  cloudMarketplaceBuyerAccountId?: string
}

export interface CloudMarketPlaceContract {
  dimensionId: string
  meteringQuantityConsumed: number
  meteringTimestamp?: string
}

export interface ContractsListingResponse {
  contracts: {
    rows: Contract[]
  }
  pageNumber: number
  pageSize: number
  totalCount: number
  sortBy: string
  sortOrder: string
  links: links & {
    nextPage: string
    previousPage: string
  }
}

export interface MeteringsResponse {
  productId: string
  cloudMarketPlace: CloudMarketplace
  cloudMarketPlaceProductId: string
  usage: {
    [cloudMarketPlaceContractId: string]: CloudMarketPlaceContract[]
  }
}

export interface ContractsListingState {
  contractsData: ContractsListingResponse
}

export const initialState: ContractsListingState = {
  contractsData: {
    contracts: {
      rows: [],
    },
    pageNumber: 0,
    pageSize: 0,
    totalCount: 0,
    sortBy: '',
    sortOrder: '',
    links: {
      nextPage: '',
      previousPage: '',
      self: '',
    },
  },
}

const getUpdatedMeteringInfo = (
  keyName: 'entitlements' | 'dimensions' | 'plan',
  meteringId: string,
  values: string,
  contract: Contract
) => {
  const meteringQuantityConsumed = parseInt(values)
  if (keyName === 'plan') {
    return {
      ...contract.plan,
      dimensions: contract.plan?.dimensions.map(dimension => ({
        ...dimension,
        meteringQuantityConsumed:
          dimension.id === meteringId
            ? meteringQuantityConsumed
            : dimension.meteringQuantityConsumed,
      })),
    }
  }
  const updatedRows = get(contract, `${keyName}`)
  return {
    rows: updatedRows
      ? updatedRows.rows?.map(row => {
          const toCheckId =
            keyName === 'entitlements'
              ? (row as Entitlement).entitlementId
              : row.dimensionId
          return toCheckId === meteringId
            ? { ...row, meteringQuantityConsumed }
            : row
        })
      : [],
  }
}

type actionType =
  | {
      type: ContractsListingActionTypes.SET_CONTRACTS
      payload: ContractsListingResponse
    }
  | {
      type: ContractsListingActionTypes.UPDATE_ENTITLEMENTS_OR_DIMENSIONS
      payload: {
        contractId: string
        keyName: 'entitlements' | 'dimensions' | 'plan'
        values: string
        meteringId: string
      }
    }
  | {
      type: ContractsListingActionTypes.CLEAR_CONTRACTS
    }

export const reducer: Reducer<ContractsListingState, actionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ContractsListingActionTypes.SET_CONTRACTS: {
      return {
        ...state,
        contractsData: action.payload,
      }
    }
    case ContractsListingActionTypes.UPDATE_ENTITLEMENTS_OR_DIMENSIONS: {
      const { contractId, values, keyName, meteringId } = action.payload
      const { contractsData } = state
      const { rows } = contractsData.contracts
      const newContractRows = rows.map(contract => {
        if (contract.id === contractId) {
          const updatedContract = {
            ...contract,
            [keyName]: getUpdatedMeteringInfo(
              keyName,
              meteringId,
              values,
              contract
            ),
          }
          return updatedContract
        } else {
          return contract
        }
      }) as unknown as Contract[]
      return {
        ...state,
        contractsData: {
          ...state.contractsData,
          contracts: {
            ...state.contractsData.contracts,
            rows: newContractRows,
          },
        },
      }
    }
    case ContractsListingActionTypes.CLEAR_CONTRACTS: {
      return {
        ...initialState,
      }
    }
    default: {
      return state
    }
  }
}
