import { isEmpty } from 'lodash'
import { Reducer } from 'redux'
import { removeExtraSpaces } from '../../../utils/RemoveExtraSpaces'
import { CrmFieldReducerState } from '../CrmFields/reducer'
import { CrmTableState } from '../crmTables/reducer'
import { OverridingRules } from '../ruleDefinitions/reducer'
import { CrmAceMappingActions, validateMandatory } from './action'

export interface ACEField {
  aceKey: string
  label: string
  helpText: string
  conditionText: string
  requiredForCreation: boolean
  dataType: string
  maxLength?: number
  minLength?: number
  maxValue?: number
  minValue?: number
  dateFormat?: string
  standardValues?: string[]
  isHidden: boolean
  mappedDataType?: string
}

export interface CRMField {
  name: string
  label: string
  type: string
  is_mandatory?: boolean
  is_primary_key?: boolean
  group_name?: string
  mapped_data_type?: string
  max_length?: number
}

export interface FieldCrmToAce {
  aceKey: string
  aceLabel?: string
  crmKey?: string
  defaultValue?: string
  standardValuesMap?: StandardValuesMap[]
  crmLabel?: string
  crmMappedDataType?: string
  crmDataType?: string
  hasRules?: boolean
  objectType?: CRMObjectType
  overridingRules?: OverridingRules
  overridingRulesType?: string
  parentTable?: string
  isPrimaryKey?: boolean
  defaultValueFromAwsKey?: string
  hardcodedValue?: string
  maxLength?: number
  isMandatory?: boolean
}
export interface StandardValuesMap {
  aceValue: string
  crmValue?: string
}
export type FieldsMap = {
  [x: string]: Omit<FieldCrmToAce, 'aceKey'>
}

export type MandatoryField = {
  crmKey: string
  parentTable: string
  isPrimaryKey: boolean
  defaultValueFromAwsKey: string
  hardcodedValue: string
  defaultValue: string
  crmLabel: string
  crmDataType: string
  crmMappedDataType: string
  isMandatory?: boolean
}

export interface MappingKeys {
  key: string
  value: string
  dataType: string
  parentTable: string
  crmMandatoryFields: MandatoryField[]
  fields: FieldsMap
}
export interface CrmAceMappingState {
  aceFields: ACEField[]
  submitEnabled: boolean
  completeData: boolean
  mappingKeys: MappingKeys
}

export type CrmAceMappingAction =
  | {
      type: CrmAceMappingActions.SET_ACE_FIELDS
      payload: ACEField[]
    }
  | {
      type: CrmAceMappingActions.SET_MAPPING_FIELD
      payload: FieldCrmToAce
    }
  | {
      type: CrmAceMappingActions.SET_MAPPING_KEY_VALUE
      payload: Partial<MappingKeys>
    }
  | {
      type: CrmAceMappingActions.SET_MAPPING_PARENT_TABLE
      payload: { value: string }
    }
  | {
      type: CrmAceMappingActions.SET_MANDATORY_FIELDS
      payload: { index: number; field: MandatoryField }
    }
  | {
      type: CrmAceMappingActions.SET_GET_MAPPED_DATA
      payload: any
    }
  | {
      type: CrmAceMappingActions.ADD_EMPTY_MANDATORY_FIELD_ROW
      payload: { crmFields: CrmFieldReducerState; crmTables: CrmTableState }
    }
  | {
      type: CrmAceMappingActions.MAPPING_CLEANUP
    }
  | {
      type: CrmAceMappingActions.REMOVE_MANDATORY_FIELD_ROW
      payload: number
    }
  | {
      type: CrmAceMappingActions.SET_HAS_RULES
      payload: {
        aceKey: string
        value: boolean
      }
    }

const emptyMandatoryField = {
  crmKey: '',
  parentTable: '',
  isPrimaryKey: false,
  defaultValueFromAwsKey: '',
  hardcodedValue: '',
  defaultValue: '',
  crmLabel: '',
  crmDataType: '',
  crmMappedDataType: '',
  isMandatory: false,
}

export const initialState = {
  aceFields: [],
  submitEnabled: false,
  completeData: false,
  mappingKeys: {
    key: '',
    value: '',
    dataType: '',
    parentTable: '',
    fields: {},
    crmMandatoryFields: [emptyMandatoryField],
  },
}

const initializeMappings = (fields: ACEField[]): MappingKeys => ({
  key: '',
  value: '',
  dataType: '',
  parentTable: '',
  crmMandatoryFields: [emptyMandatoryField],
  fields: fields.reduce(
    (acc, { aceKey, standardValues }) => ({
      ...acc,
      [aceKey]: {
        crmKey: '',
        standardValuesMap: standardValuesMapping(standardValues),
      },
    }),
    {}
  ),
})

const getKeyTable = (key?: string, table?: string) => ({
  key: key || '',
  table: table || '',
})

const getListOfSelectedFromMapping = (fields: FieldsMap) =>
  (Object.entries(fields) || []).map(([, { crmKey, parentTable }]) =>
    getKeyTable(crmKey, parentTable)
  )

export const getSelectedAndAvailableCrmFields = (
  state: CrmAceMappingState,
  crmFieldState: CrmFieldReducerState,
  allTables: CrmTableState
): {
  selectedFields: Record<string, string[]>
  availableFields: CRMField[]
  availableFieldsCount: number
} => {
  const {
    mappingKeys: { fields, key, parentTable },
  } = state

  const listObject =
    getListOfSelectedFromMapping(fields)
      .concat(
        state.mappingKeys.crmMandatoryFields?.map(({ parentTable, crmKey }) =>
          getKeyTable(crmKey, parentTable)
        )
      )
      .concat(getKeyTable(key, parentTable)) || []
  let selectedFields: Record<string, string[]> = {}
  Object.keys(allTables.tables).forEach((table: string) => {
    selectedFields = { ...selectedFields, [table]: [] }
  })
  for (const obj of listObject) {
    const { key, table } = obj

    if (Object.keys(selectedFields)?.includes(table)) {
      const tempList = selectedFields[table]
      tempList.push(key)
      selectedFields[table] = tempList
    }
  }
  const availableFields: CRMField[] = []

  for (const table in crmFieldState) {
    availableFields.push(
      ...crmFieldState[table].filter(
        ({ name, is_primary_key }) =>
          !selectedFields[table]?.includes(name) && is_primary_key === false
      )
    )
  }
  const availableFieldsCount = availableFields.length + 1
  return { selectedFields, availableFields, availableFieldsCount }
}
export const getNonHiddenAceFields = (aceFields: ACEField[]) =>
  aceFields.filter(field => field.isHidden === false)
export const getAvailableCrmFields = (
  state: CrmAceMappingState,
  crmFields: CrmFieldReducerState,
  allTables: CrmTableState
): number =>
  getSelectedAndAvailableCrmFields(state, crmFields, allTables)
    .availableFieldsCount

const standardValuesMapping = (standardValue: any) => {
  if (standardValue === undefined) {
    return []
  }
  const standardValues: StandardValuesMap[] = []
  standardValue.sort()
  standardValue.map((m: string) =>
    standardValues.push({
      aceValue: m,
      crmValue: undefined,
    })
  )
  return standardValues
}

export const reducer: Reducer<CrmAceMappingState, CrmAceMappingAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case CrmAceMappingActions.SET_ACE_FIELDS: {
      const { payload: aceFields } = action

      return {
        ...state,
        aceFields,
        mappingKeys: initializeMappings(aceFields),
      }
    }
    case CrmAceMappingActions.SET_MAPPING_FIELD: {
      const { aceKey, ...data } = action.payload
      const newState = {
        ...state,
        mappingKeys: {
          ...state.mappingKeys,
          fields: {
            ...state.mappingKeys.fields,
            [aceKey]: {
              ...state.mappingKeys.fields[aceKey],
              ...data,
            },
          },
        },
      }
      const crmMandatoryFields =
        !isEmpty(data.crmKey) &&
        !isEmpty(state.mappingKeys.fields[aceKey]?.parentTable)
          ? state.mappingKeys.crmMandatoryFields.filter(
              ({ crmKey, parentTable }) =>
                `${parentTable}.${crmKey}` !==
                `${data.parentTable}.${data.crmKey}`
            )
          : state.mappingKeys.crmMandatoryFields
      const filledMandatoryFields = crmMandatoryFields.filter(
        ({ parentTable, crmKey }) => `${parentTable}${crmKey}` !== ''
      )
      const newMandatoryFields =
        filledMandatoryFields.length === 0
          ? filledMandatoryFields.concat(emptyMandatoryField)
          : filledMandatoryFields

      const submitEnabled = validateMandatory(newState)
      return {
        ...newState,
        submitEnabled,
        mappingKeys: {
          ...newState.mappingKeys,
          crmMandatoryFields: newMandatoryFields,
        },
      }
    }

    case CrmAceMappingActions.SET_MAPPING_KEY_VALUE: {
      const { key, value, dataType, parentTable } = action.payload
      const newState = {
        ...state,
        mappingKeys: {
          ...state.mappingKeys,
          ...action.payload,
        },
      }
      let newMandatoryFields = state.mappingKeys.crmMandatoryFields
      if (
        state.mappingKeys.key !== newState.mappingKeys.key ||
        state.mappingKeys.value !== newState.mappingKeys.value
      ) {
        const crmMandatoryFields =
          !isEmpty(key) && !isEmpty(state.mappingKeys.parentTable)
            ? state.mappingKeys.crmMandatoryFields.filter(
                ({ crmKey, parentTable }) =>
                  `${parentTable}.${crmKey}` !==
                  `${action.payload.parentTable}.${action.payload.key}`
              )
            : state.mappingKeys.crmMandatoryFields
        const filledMandatoryFields = crmMandatoryFields.filter(
          ({ crmKey, parentTable }) => `${crmKey}${parentTable}` !== ''
        )
        newMandatoryFields =
          filledMandatoryFields.length === 0
            ? filledMandatoryFields.concat(emptyMandatoryField)
            : filledMandatoryFields
      }
      const submitEnabled = validateMandatory(newState)
      return {
        ...newState,
        submitEnabled,
        mappingKeys: {
          ...newState.mappingKeys,
          crmMandatoryFields: newMandatoryFields,
        },
      }
    }

    case CrmAceMappingActions.SET_MAPPING_PARENT_TABLE: {
      const { value } = action.payload
      const newState = {
        ...state,
        mappingKeys: {
          ...state.mappingKeys,
          parentTable: removeExtraSpaces(value),
        },
      }

      const submitEnabled = validateMandatory(newState)
      return {
        ...newState,
        submitEnabled,
        mappingKeys: {
          ...newState.mappingKeys,
        },
      }
    }
    case CrmAceMappingActions.SET_MANDATORY_FIELDS: {
      const { index, field } = action.payload
      const buffer = [...state.mappingKeys.crmMandatoryFields]
      buffer[index] = { ...buffer[index], ...field }
      const newState = {
        ...state,
        mappingKeys: {
          ...state.mappingKeys,
          crmMandatoryFields: buffer,
        },
      }
      const submitEnabled = validateMandatory(newState)

      return {
        ...newState,
        submitEnabled,
      }
    }
    case CrmAceMappingActions.ADD_EMPTY_MANDATORY_FIELD_ROW: {
      const mandatoryFieldFormsDisplayed =
        state.mappingKeys.crmMandatoryFields.length
      const availableFieldsCount = getAvailableCrmFields(
        state,
        action.payload.crmFields,
        action.payload.crmTables
      )
      return availableFieldsCount - mandatoryFieldFormsDisplayed === 0
        ? state
        : {
            ...state,
            mappingKeys: {
              ...state.mappingKeys,
              crmMandatoryFields:
                state.mappingKeys.crmMandatoryFields.concat(
                  emptyMandatoryField
                ),
            },
          }
    }
    case CrmAceMappingActions.SET_GET_MAPPED_DATA: {
      const fields1: FieldsMap = {}
      ;(action.payload.fields || []).forEach((e: any) => {
        const { aceKey, ...data } = e
        fields1[aceKey] = data
      })

      const newState = {
        ...state,
        mappingKeys: {
          ...state.mappingKeys,
          fields: fields1,
          crmMandatoryFields: action.payload.crmMandatoryFields || [
            emptyMandatoryField,
          ],
        },
      }

      const submitEnabled = validateMandatory(newState)
      return {
        ...newState,
        submitEnabled: submitEnabled,
        completeData: true,
      }
      //return state
    }
    case CrmAceMappingActions.MAPPING_CLEANUP:
      return initialState
    case CrmAceMappingActions.REMOVE_MANDATORY_FIELD_ROW: {
      const mandatoryFields = state.mappingKeys.crmMandatoryFields
      const newMandatoryFields = mandatoryFields.filter(
        (_, i) => i !== action.payload
      )
      return {
        ...state,
        mappingKeys: {
          ...state.mappingKeys,
          crmMandatoryFields: newMandatoryFields,
        },
      }
    }
    case CrmAceMappingActions.SET_HAS_RULES: {
      const { aceKey, value } = action.payload
      return {
        ...state,
        mappingKeys: {
          ...state.mappingKeys,
          fields: {
            ...state.mappingKeys.fields,
            [aceKey]: {
              ...state.mappingKeys.fields[aceKey],
              hasRules: value,
            },
          },
        },
      }
    }

    default:
      return state
  }
}
