import {
  checkDefaultValueError,
  DefaultValueState,
  DefaultValueValidationActionTypes,
} from './action'
export const initialState: DefaultValueState = { errors: {}, valid: true }
export type DefaultValueValidationAction = {
  type: DefaultValueValidationActionTypes
  payload: { aceKey: string; error: boolean; errorMess: string }
}
export const defaultValueValidationReducer = (
  state: DefaultValueState = initialState,
  action: DefaultValueValidationAction
) => {
  switch (action.type) {
    case DefaultValueValidationActionTypes.SET_DEFAULT_VALIDATIONS: {
      const newState = {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.aceKey]: {
            error: action.payload.error,
            errMess: action.payload.errorMess,
          },
        },
      }
      const checkValidity = checkDefaultValueError(newState)
      return { ...newState, valid: !checkValidity }
    }
    default:
      return state
  }
}
