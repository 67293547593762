interface TermsProps {
  classes?: any
}

export const OnboardingTerms: React.FC<TermsProps> = ({ classes }) => (
  <>
    <div id="terms">
      <h3 className={classes ? classes.termsSubHeading : ''}>
        Last updated September 22, 2021
      </h3>
      <h3 className={classes ? classes.termsSubHeading : ''}>
        <strong>1. SCOPE; DEFINITIONS</strong>
      </h3>
      <p className={classes ? classes.termsParagraph : ''}>
        <strong>1.1 Security Policy.</strong> Supplier will comply in all
        respects with Amazon’s information security requirements set forth in
        this Appendix (the “Security Policy”). This Security Policy applies to
        Supplier’s performance under the Agreement and all Supplier’s access,
        collection, use, storage, transmission, disclosure, destruction or
        deletion of, and security incidents regarding, Amazon Information. This
        Security Policy does not limit any of Supplier’s other obligations,
        including under any other agreements with Amazon or any other laws that
        apply to Supplier, Supplier’s performance under any other agreements
        with Amazon, the Amazon Information, or the Permitted Purpose. If this
        Security Policy conflicts with any non-disclosure agreement between the
        parties, or any other agreement applicable to the parties, Supplier will
        promptly notify Amazon of the conflict and comply with the requirements
        that are more protective of Amazon Information unless the
        less-protective requirements are expressly stated as overriding the
        more-protective requirements (which may be designated by Amazon). Amazon
        may change this Security Policy from time to time at its sole discretion
        upon providing written notice to Supplier, provided that if such changes
        are not commercially reasonable, the parties will meet and agree on
        appropriate additional fees.
      </p>
      <p className={classes ? classes.termsParagraph : ''}>
        <strong>1.2 Definitions.</strong>
      </p>
      <p className={classes ? classes.termsParagraph : ''}>
        1.2.1 <strong>"Aggregate"</strong> means to combine or store Amazon
        Information with any data or information of Supplier or any third party.
      </p>
      <p className={classes ? classes.termsParagraph : ''}>
        1.2.2 <strong>"Anonymize"</strong> means to use, collect, store,
        transmit, or transform any data or information (including Amazon
        Information) in a manner or form that does not identify, permit
        identification of, and is not otherwise attributable to Amazon or its
        affiliates, or any user, device identifier, source, product, service,
        context, or brand thereof.
      </p>
      <p className={classes ? classes.termsParagraph : ''}>
        1.2.3 <strong>"Amazon Information"</strong> means, individually and
        collectively: (a) all Amazon Confidential Information (as defined in a
        non-disclosure agreement or any other agreement between the parties);
        (b) all other data, records, files, content, or information, in any form
        or format, acquired, accessed, collected, received, stored, or
        maintained by Supplier or its affiliates, from or on behalf of Amazon or
        its affiliates, or otherwise in connection with this Security Policy or
        the services, or the parties’ performance or exercise of rights under or
        in connection with the Agreement; and (c) information derived from (a)
        or (b), even if Anonymized.
      </p>
      <p className={classes ? classes.termsParagraph : ''}>
        1.3 Permitted Purpose. Supplier may access, collect, use, store, and
        transmit only the Amazon Information expressly authorized under the
        Agreement and solely for the purpose of providing the products or
        services under the Agreement, consistent with the licenses (if any)
        granted under this Security Policy (the “Permitted Purpose”). Except as
        expressly authorized under the Agreement, Supplier will not access,
        collect, use, store, or transmit any Amazon Information and will not
        Aggregate Amazon Information, even if Anonymized. Except with Amazon’s
        prior express written consent, Supplier will not (a) transfer, rent,
        barter, trade, sell, loan, or lease, or otherwise distribute or make
        available to any third-party, any Amazon Information or (b) Aggregate
        Amazon Information with any other information or data, even if
        Anonymized.
      </p>

      <h3 className={classes ? classes.termsSubHeading : ''}>
        <strong>2. AMAZON SECURITY POLICY.</strong>
      </h3>
      <p className={classes ? classes.termsParagraph : ''}>
        <strong>2.1 Basic Security Requirements.</strong> Supplier will,
        consistent with current best industry standards and such other
        requirements specified by Amazon based on the classification and
        sensitivity of Amazon Information, maintain physical, administrative,
        and technical safeguards and other security measures to (a) maintain the
        security and confidentiality of Amazon Information accessed, collected,
        used, stored, or transmitted by Supplier, and (b) protect that
        information from known or reasonably anticipated threats or hazards to
        its security and integrity, accidental loss, alteration, and disclosure
        and all other unlawful forms of processing. Without limitation, Supplier
        will comply with the following requirements for any systems that
        Supplier uses to process Amazon Information or that have access to
        Amazon Information (“Amazon Information Systems”):
      </p>
      <p className={classes ? classes.termsParagraph : ''}>
        2.1.1 Network Security. Supplier will protect any Amazon Information
        Systems by restricting unauthorized network access, especially from the
        external Internet. Supplier will install and maintain an effective
        network security solution, such as a firewall, to protect Amazon
        Information at all times.
      </p>
      <p className={classes ? classes.termsParagraph : ''}>
        2.1.2 Updates. As outlined in National Institute of Standards and
        Technology (“NIST”) Special Publication (“SP”) 800-40 Revision 3,
        Supplier will keep Amazon Information Systems up-to-date with the latest
        upgrades, updates, bug fixes, and new versions and with any other
        modifications necessary to ensure security of Amazon Information.
      </p>
      <p className={classes ? classes.termsParagraph : ''}>
        2.1.3 Anti-malware. Supplier will at all times use anti-malware software
        or an equivalent security control to mitigate the risk of malware
        compromise and spread. If used, Supplier will keep anti-malware software
        up to date.
      </p>
      <p className={classes ? classes.termsParagraph : ''}>
        2.1.4 Encryption. Supplier will encrypt data at rest and data sent
        across open networks in accordance with industry best practices..
      </p>
      <p className={classes ? classes.termsParagraph : ''}>
        2.1.5 Testing. Supplier will regularly test its security systems and
        processes to ensure they meet the requirements of this Security Policy
        or the security policy last agreed to by Supplier and Amazon. To the
        extent there are changes to the Security Policy pursuant to Section 1.1,
        those changes will not take effect for purposes of this Section until
        agreement between Supplier and Amazon (email to suffice).
      </p>
      <p className={classes ? classes.termsParagraph : ''}>
        2.1.6 Access Controls. Supplier will secure Amazon Information,
        including by complying with the following requirements:
      </p>
      <ol type="i">
        <li className={classes ? classes.termsParagraph : ''}>
          Supplier will assign a unique ID to each person with computer access
          to Amazon Information or Amazon Information Systems.
        </li>
        <li className={classes ? classes.termsParagraph : ''}>
          Supplier will restrict access to Amazon Information to only those
          people with a “need-to-know” for a Permitted Purpose.
        </li>
        <li className={classes ? classes.termsParagraph : ''}>
          Supplier will regularly review the list of people and services with
          access to Amazon Information, and remove accounts that no longer
          require access to Amazon Information Systems. This review must be
          performed at least once every 90 days.
        </li>
        <li className={classes ? classes.termsParagraph : ''}>
          Supplier will not use manufacturer-supplied defaults for system
          passwords and other security parameters on any Amazon Information
          Systems. Supplier will mandate and ensure the use of system-enforced
          “strong passwords” in accordance with the best practices described in
          NIST SP 800-63B on all Amazon Information Systems. Supplier will
          require that all passwords and access credentials are kept
          confidential and not shared among personnel.
        </li>
        <li className={classes ? classes.termsParagraph : ''}>
          Supplier will maintain and enforce “account lockout” by disabling
          accounts with access to Amazon Information or Amazon Information
          Systems when an account exceeds more than ten (10) consecutive
          incorrect password attempts.
        </li>
        <li className={classes ? classes.termsParagraph : ''}>
          Except where expressly authorized by Amazon in writing, Supplier will
          physically or logically segregate Amazon Information at all times
          (including in storage, processing, or transmission), from Supplier’s
          and any third-party information.
        </li>
        <li className={classes ? classes.termsParagraph : ''}>
          If additional physical access controls are requested in writing by
          Amazon, Supplier will implement and use those secure physical access
          control measures.
        </li>
        <li className={classes ? classes.termsParagraph : ''}>
          Supplier will provide to Amazon, upon Amazon’s reasonable request, (a)
          log data about all use (both authorized and unauthorized) of Amazon’s
          accounts or credentials provided to Supplier for a Permitted Purpose
          (e.g., social medial account credentials), and (b) detailed log data
          about any impersonation of, or attempt to impersonate, Amazon
          personnel or Supplier’s personnel that have access to Amazon
          Information.
        </li>
        <li className={classes ? classes.termsParagraph : ''}>
          Supplier will regularly review access logs for signs of malicious
          behavior or unauthorized access.
        </li>
      </ol>
      <p className={classes ? classes.termsParagraph : ''}>
        2.1.7 Supplier’s Policy. Supplier will maintain and enforce an
        information and network security policy for employees, subcontractors,
        agents, and suppliers that meets the standards set out in this Security
        Policy, including methods to detect and log policy violations. Upon
        request by Amazon, Supplier will provide Amazon with information on
        violations of Supplier’s information and network security policy if
        either Supplier or Amazon have a reasonable suspicion that it may
        constitute a Security Incident (defined below).
      </p>
      <p className={classes ? classes.termsParagraph : ''}>
        2.1.8 Subcontract. Supplier will not subcontract or delegate any of its
        obligations under this Security Policy to any subcontractors or
        delegates (collectively, “Subcontractors”) without Amazon’s prior
        written consent. Notwithstanding the existence or terms of any
        subcontract or delegation, Supplier will remain responsible for the full
        performance of its obligations under this Security Policy. The terms and
        conditions of this Security Policy will be binding upon Supplier’s
        Subcontractors and personnel. Supplier will (a) ensure that its
        Subcontractors and personnel comply with this Security Policy, and (b)
        be responsible for all acts, omissions, negligence, and misconduct of
        Supplier’s Subcontractors and personnel.
      </p>
      <p className={classes ? classes.termsParagraph : ''}>
        2.1.9 Remote Access. Supplier will ensure that any access to Amazon
        Information Systems requires multi-factor authentication (e.g., requires
        at least two separate factors for identifying users).
      </p>
      <p className={classes ? classes.termsParagraph : ''}>
        2.1.10 “In Bulk” Access. For purposes of this section, “in bulk” access
        means accessing data by means of database query, report generation, or
        any other mass transfer of data. Except where expressly authorized by
        Amazon in writing, Supplier will not access, and will not permit access
        to, Amazon Information “in bulk” whether the Amazon Information is in a
        database controlled by Supplier or Amazon or stored in any other manner,
        including storage in file-based archives (e.g., flat files), etc.
        Specifically, this section prohibits any access to Amazon Information
        except for access to individual records as needed for the Permitted
        Purpose. Supplier will preserve detailed log data on attempted or
        successful “in bulk” access to Amazon Information and will provide
        reports from these logs as part of Supplier’s obligations under Section
        2.5 (Security Review). If Amazon provides Supplier written authorization
        for “in bulk” access to Amazon Information, Supplier will (a) limit such
        access only to specified employees with a ”need to know,” and (b) use
        tools that limit access and require explicit authorization and logging
        of all access.
      </p>
      <p className={classes ? classes.termsParagraph : ''}>
        2.1.11 Supplier Personnel. Amazon may condition Supplier personnel’s
        access to Amazon Information on their execution and delivery to Amazon
        of individual nondisclosure agreements, the form of which is specified
        by Amazon. Supplier personnel will execute the individual nondisclosure
        agreement if Amazon so requires. Supplier will obtain and deliver to
        Amazon signed individual nondisclosure agreements from Supplier
        personnel that will have access to the Amazon Information (prior to
        granting access or providing information to Supplier personnel).
        Supplier will also maintain a list of all Supplier personnel who have
        accessed or received the Amazon Information on Amazon Information
        Systems and promptly provide that list to Amazon upon request. For any
        of Supplier’s personnel that (a) no longer needs access to Amazon
        Information or (b) no longer qualifies as Supplier personnel (e.g., the
        individual leaves Supplier’s employment), Supplier will immediately
        (within a maximum of 24 hours) terminate access to Amazon Information
        and Amazon Information Systems. If any such personnel are authorized to
        access Amazon Information on Amazon Information Systems, Supplier will
        also notify Amazon within 24 hours.
      </p>

      <p className={classes ? classes.termsParagraph : ''}>
        <strong>2.2 Access to Amazon Extranet and Supplier Portals.</strong>
      </p>
      <p className={classes ? classes.termsParagraph : ''}>
        Amazon may grant Supplier access to Amazon Information via web portals
        or other non-public websites or extranet services on Amazon’s or a
        third-party’s website or system (each, an “Extranet”) for the Permitted
        Purpose. If Amazon permits Supplier to access any Amazon Information
        using an Extranet, Supplier must comply with the following requirements:
      </p>
      <p className={classes ? classes.termsParagraph : ''}>
        2.2.1 Permitted Purpose. Supplier and Supplier personnel will access the
        Extranet and access, collect, use, view, retrieve, download or store
        Amazon Information from the Extranet solely for the Permitted Purpose.
      </p>
      <p className={classes ? classes.termsParagraph : ''}>
        2.2.2 Accounts. Supplier will ensure that Supplier personnel use only
        the Extranet account(s) Amazon designated for each individual and will
        require Supplier personnel to keep their access credentials
        confidential.
      </p>
      <p className={classes ? classes.termsParagraph : ''}>
        2.2.3 Systems. Supplier will access the Extranet only through computing
        or processing systems or applications running operating systems managed
        by Supplier and that include: (a) system network firewalls in accordance
        with Section 2.1.1 (Network Security); (b) centralized patch management
        in compliance with Section 2.1.2 (Updates); (c) operating system
        appropriate anti-malware software in accordance with Section 2.1.3
        (Anti-malware); and (d) full disk encryption for portable devices.
      </p>
      <p className={classes ? classes.termsParagraph : ''}>
        2.2.4 Restrictions. Except if approved in advance in writing by Amazon,
        Supplier will not download, mirror, or permanently store any Amazon
        Information from any Extranet on any medium, including any machines,
        devices, or servers.
      </p>
      <p className={classes ? classes.termsParagraph : ''}>
        2.2.5 Account Termination. Supplier will terminate the account of, and
        notify Amazon no later than 24 hours after, any of Supplier personnel
        who is authorized to access any Extranet: (a) no longer needs access to
        Amazon Information or (b) no longer qualifies as Supplier personnel
        (e.g., the personnel leave Supplier’s employment).
      </p>
      <p className={classes ? classes.termsParagraph : ''}>
        2.2.6 Third Party Systems.
      </p>
      <ol type="i">
        <li className={classes ? classes.termsParagraph : ''}>
          Supplier will give Amazon prior notice and obtain Amazon’s prior
          written approval before it uses any third-party system that stores or
          may otherwise have access to Amazon Information, unless (a) the data
          is encrypted in accordance with this Security Policy, and (b) the
          third-party system will not have access to the decryption key or
          unencrypted “plain text” versions of the data. Amazon reserves the
          right to require an Amazon security review (in accordance with Section
          2.5 (Security Review) of the third-party system before giving
          approval.
        </li>
        <li className={classes ? classes.termsParagraph : ''}>
          {' '}
          If Supplier uses any third-party systems that store unencrypted Amazon
          Information or otherwise may access unencrypted Amazon Information,
          Supplier will perform a security review of the third-party systems and
          their security controls and will provide Amazon periodic reporting
          about the third-party system’s security controls in the format
          requested by Amazon (e.g., SAS 70 or its successor report, or other
          recognized industry-standard report approved by Amazon).
        </li>
      </ol>
      <p className={classes ? classes.termsParagraph : ''}>
        <strong>2.3 Data Retention and Destruction.</strong>
      </p>
      <p className={classes ? classes.termsParagraph : ''}>
        2.3.1 Retention. Supplier will retain Amazon Information only for the
        purpose of, and as long as is necessary for, the Permitted Purpose.
      </p>
      <p className={classes ? classes.termsParagraph : ''}>
        2.3.2 Return or Deletion. Upon Amazon’s request, Supplier will promptly
        (but within no more than 72 hours) return to Amazon and permanently and
        securely delete all Amazon Information in accordance with Amazon’s
        notice requiring return and/or deletion. Supplier will also permanently
        and securely delete all live (online or network accessible) instances of
        the Amazon Information within 30 days after the earlier completion of
        the Permitted Purpose or termination or expiration of this Security
        Policy. If requested by Amazon, Supplier will certify in writing that
        all Amazon Information has been destroyed. For clarity, this section
        will not apply to Archival Copies pursuant to Section 2.3.3
      </p>
      <p className={classes ? classes.termsParagraph : ''}>
        2.3.3 Archival Copies. If Supplier is required by law to retain archival
        copies of Amazon Information for tax or similar regulatory purposes,
        this archived Amazon Information must be stored in one of the following
        ways:
      </p>
      <ol type="i">
        <li className={classes ? classes.termsParagraph : ''}>
          As a “cold” or offline (i.e., not available for immediate or
          interactive use) backup stored in a physically secure facility; or{' '}
        </li>
        <li className={classes ? classes.termsParagraph : ''}>
          Encrypted, where the system hosting or storing the encrypted file(s)
          does not have access to a copy of the key(s) used for encryption.
        </li>
      </ol>
      <p className={classes ? classes.termsParagraph : ''}>
        2.3.4 Recovery. If Supplier performs a “recovery” (i.e., reverting to a
        backup) for the purpose of disaster recovery, Supplier will have and
        maintain a process that ensures that all Amazon Information that is
        required to be deleted pursuant to the Agreement or this Security Policy
        or any other agreement with Amazon will be re-deleted or overwritten
        from the recovered data in accordance with this Section 2.3 within 24
        hours after recovery occurs. If Supplier performs a recovery for any
        purpose, no Amazon Information may be recovered to any third-party
        system or network without Amazon’s prior written approval. Amazon
        reserves the right to require an Amazon security review (in accordance
        with Section 2.5 (Security Review) of the third-party system or network
        before permitting recovery of any Amazon Information to any third-party
        system or network.
      </p>
      <p className={classes ? classes.termsParagraph : ''}>
        2.3.5 Data Sanitization Standards. All Amazon Information deleted by
        Supplier will be deleted in accordance with the Minimum Sanitization
        Recommendations contained in NIST SP 800-88 Revision 1, Guidelines for
        Media Sanitation, December 18, 2014 (available at{' '}
        <a
          target="_blank"
          href="http://nvlpubs.nist.gov/nistpubs/SpecialPublications/NIST.SP.800-88r1.pdf)"
        >
          http://nvlpubs.nist.gov/nistpubs/SpecialPublications/NIST.SP.800-88r1.pdf
        </a>
        ), Appendix A, for purging the relevant type of device. In the absence
        of relevant guidance in NIST SP 800-88, Appendix A, the device
        containing Amazon Information shall be destroyed in one of the following
        ways: (a) through degaussing of magnetic media in an electromagnetic
        flux field of 10,000+ Gauss, (b) by shredding or mechanical
        disintegration that results in particles smaller than 2x2 mm, or (c)
        through such other standards Amazon may require based on the
        classification and sensitivity of the Amazon Information.
      </p>
      <p className={classes ? classes.termsParagraph : ''}>
        <strong>2.4 Forensic Destruction.</strong>
      </p>
      <p className={classes ? classes.termsParagraph : ''}>
        Before disposing (in any manner) of any hardware, software, or any other
        media that contains, or has at any time contained, Amazon Information,
        Supplier will perform a complete forensic destruction of the hardware,
        software, or other media such that retrieval of the Amazon Information
        in any form is infeasible. Supplier will perform forensic destruction in
        accordance with the Minimum Sanitization Recommendations contained in
        NIST SP 800-88, Appendix A, for destroying the relevant type of device:
      </p>
      <ol type="i">
        <li className={classes ? classes.termsParagraph : ''}>
          Supplier will not sell, resell, donate, refurbish, or otherwise
          transfer (including any sale or transfer of any such hardware,
          software, or other media, any disposition in connection with any
          liquidation of Supplier’s business, or any other disposition) any
          hardware, software, or other media that contains Amazon Information
          that has not been forensically destroyed by Supplier as required by
          this Section 2.4.
        </li>
      </ol>
      <p className={classes ? classes.termsParagraph : ''}>
        <strong>2.5 Security Review.</strong>
      </p>
      <p className={classes ? classes.termsParagraph : ''}>
        2.5.1 Amazon reserves the right to periodically request supplier to
        participate in an Amazon risk assessment.
      </p>
      <p className={classes ? classes.termsParagraph : ''}>
        2.5.2 Certification. Upon Amazon’s written request, Supplier will
        certify in writing to Amazon that information provided as part of risk
        assessment is in compliance with this Security Policy last agreed to by
        Supplier and Amazon. To the extent there are changes to the Security
        Policy pursuant to Section 1.1, those changes will not take effect for
        purposes of this Section until agreement between Supplier and Amazon
        (email to suffice).
      </p>
      <p className={classes ? classes.termsParagraph : ''}>
        2.5.3 Other Reviews. Amazon reserves the right to periodically review
        the security of Amazon Information Systems, but not more than once
        yearly unless (a) a prior substantial deficiency was identified within
        the calendar year or (b) Amazon is obligated by a government agency or
        other regulatory body to perform such review. Supplier will cooperate
        and provide Amazon with all required information within a reasonable
        time frame but no more than 20 calendar days from the date of Amazon’s
        request.
      </p>
      <p className={classes ? classes.termsParagraph : ''}>
        2.5.4 Remediation. If any security review identifies any deficiencies,
        Supplier will, at Supplier’s sole cost and expense, take all reasonable
        actions necessary to remediate those deficiencies within an agreed upon
        timeframe.
      </p>
      <p className={classes ? classes.termsParagraph : ''}>
        <strong>2.6 Security Incidents</strong>
      </p>
      <p className={classes ? classes.termsParagraph : ''}>
        2.6.1 Supplier will inform Amazon as soon as possible, but no later than
        24 hours after Supplier knows of any actual or suspected unauthorized
        access, collection, acquisition, use, transmission, disclosure,
        corruption, or loss of Amazon Information or breach of any Amazon
        Information Systems (a “Security Incident”). Supplier will remedy each
        Security Incident in a timely manner and provide Amazon written details
        regarding Supplier’s internal investigation regarding each Security
        Incident. When permitted by applicable laws Supplier agrees not to
        notify any regulatory authority or customer on behalf of Amazon, unless
        Amazon specifically requests in writing that Supplier does so, and
        Amazon reserves the right to review and approve the form and content of
        any notification before it is provided to any party. Supplier will
        cooperate and work together with Amazon to formulate and execute a plan
        to rectify all confirmed Security Incidents.
      </p>
      <p className={classes ? classes.termsParagraph : ''}>
        2.6.2 To the extent permitted by applicable law, in the event Supplier
        receives a request or order from a governmental body (such as a
        subpoena, court order, or search warrant) seeking data that includes any
        Amazon Information, Supplier will provide sufficient notice to Amazon to
        enable Amazon to seek a protective order or other appropriate remedy.
      </p>
      <p className={classes ? classes.termsParagraph : ''}>
        <strong>2.7 General</strong> Amazon retains sole discretion to make all
        applicable decisions under this Security Policy. Any list of examples
        following “including” or “e.g.” is illustrative and not exhaustive. All
        references to standards for security requirements under this Security
        Policy refer to the specified standards and their respective successor
        versions or equivalent versions, as they may be updated, unless Amazon
        specifies otherwise
      </p>
    </div>
  </>
)
