import { AxiosResponse } from 'axios'
import { AppDispatch, RootState } from '../../../store'
import { fetchCppoAgreementData } from '../../api/cppoAgreement'
import { getErrorMessages } from '../../../common/utils/error'
import { updateAppAlert } from '../../../common/modules/appAlert/actions'
import {
  startLoading,
  stopLoading,
} from '../../../common/modules/loading/actions'
import { LoadingTypes } from '../../../common/modules/loading/reducer'
import { errorLogger } from '../../../common/utils/errorLogger'
import { CPPOResellerAgreementState } from './reducer'
import { camelize } from 'casing'

export enum CPPOAgreementStatusTypes {
  COMPLETED = 'completed',
  IN_PROGRESS = 'in_progress',
  EXPIRED = 'expired',
  RESTRICTED = 'restricted',
  REJECTED = 'rejected',
  ACTIVE = 'active',
}

export enum CPPOAgreementPricingTypes {
  DISCOUNT = 'discount',
  INDIVIDUAL_PRICING = 'individual_pricing',
  FLEXIBLE_PAYMENT_TERMS = 'flexible_payment_terms',
}

export enum cppoAgreementActionTypes {
  SET_CPPO_AGREENEMENT_DATA = 'SET_CPPO_AGREENEMENT_DATA',
}

export const setCppoAgreementData = (data: unknown) => ({
  type: cppoAgreementActionTypes.SET_CPPO_AGREENEMENT_DATA,
  payload: data,
})

export const setCppoResellerAgreementState = (
  data: Partial<CPPOResellerAgreementState>
) => ({
  type: cppoAgreementActionTypes.SET_CPPO_AGREENEMENT_DATA,
  payload: data,
})

export const getCppoAgreementData =
  (
    partnerId: string,
    crmAwsResellerAgreementId: string,
    secretKey: string,
    email?: string
  ) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.GENERAL))

    try {
      const { data }: CPPOResellerAgreementState | any =
        await fetchCppoAgreementData(
          partnerId,
          crmAwsResellerAgreementId,
          secretKey,
          email
        )

      dispatch(setCppoResellerAgreementState(camelize(data)))
    } catch (e) {
      const errorMessage = getErrorMessages([
        'Unable to fetch agreement information',
      ])(e as AxiosResponse<ErrorResponse>)
      dispatch(
        updateAppAlert({
          message: errorMessage,
          messageType: 'ERROR',
        })
      )

      const globalState = getState()
      errorLogger({ globalState })(e as Error)

      throw new Error(errorMessage)
    } finally {
      dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }
