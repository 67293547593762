import { Reducer } from 'redux'
import {
  CommonDimensionField,
  ContainerPricingModel,
  links,
} from '../productsListing/reducer'
import { PrivateOfferActionTypes } from './actions'

export enum OfferStatus {
  PRIVATE_OFFER_CREATION_IN_PROGRESS = 'OFFER_CREATION_IN_PROGRESS',
  PRIVATE_OFFER_EMAIL_SENT = 'OFFER_EMAIL_SENT',
  PRIVATE_OFFER_VIEWED = 'OFFER_VIEWED',
  PRIVATE_OFFER_ACCEPTED = 'OFFER_ACCEPTED',
  PRIVATE_OFFER_EXPIRED = 'OFFER_EXPIRED',
  PRIVATE_OFFER_FAILED = 'OFFER_CREATION_FAILED',
  PRIVATE_OFFER_RESTRCITED = 'OFFER_RESTRICTED',
  PRIVATE_OFFER_IN_DRAFT = 'OFFER_IN_DRAFT',
  PRIVATE_OFFER_RESTRICTION_IN_PROGRESS = 'OFFER_RESTRICTION_IN_PROGRESS',
}
export const offerStatusMap = {
  'In Progress': OfferStatus.PRIVATE_OFFER_CREATION_IN_PROGRESS,
  Sent: OfferStatus.PRIVATE_OFFER_EMAIL_SENT,
  Accepted: OfferStatus.PRIVATE_OFFER_ACCEPTED,
  Expired: OfferStatus.PRIVATE_OFFER_EXPIRED,
  Viewed: OfferStatus.PRIVATE_OFFER_VIEWED,
  Failed: OfferStatus.PRIVATE_OFFER_FAILED,
  Cancelled: OfferStatus.PRIVATE_OFFER_RESTRCITED,
  Draft: OfferStatus.PRIVATE_OFFER_IN_DRAFT,
  'Cancellation in Progress': OfferStatus.PRIVATE_OFFER_RESTRICTION_IN_PROGRESS,
}

export enum OfferTypeRenew {
  NEW = 'NEW',
  RENEWAL = 'RENEWAL',
}

export enum AzurePricingType {
  LABRA_PERCENTAGE = 'labra_percentage',
  ABSOLUTE = 'absolute',
}

export enum EntitlementOption {
  SINGLE = 'SINGLE',
  MANY = 'MANY',
}

export interface EntitlementOptionMap {
  SINGLE: EntitlementOption.SINGLE
  MANY: EntitlementOption.MANY
}

export enum EulaType {
  STANDARD = 'STANDARD',
  ENTERPRISE = 'ENTERPRISE',
  CUSTOM = 'CUSTOM',
  PUBLIC = 'PUBLIC',
}

export enum PaymentType {
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  ANNUALLY = 'ANNUALLY',
  CUSTOM = 'CUSTOM',
}

export const GcpPostPaymentTypes = [
  PaymentType.MONTHLY,
  PaymentType.QUARTERLY,
  PaymentType.ANNUALLY,
]

export enum PlanTypeGCP {
  USAGE = 'USAGE',
  FLAT_FEE = 'FLAT_FEE',
  FLAT_FEE_USAGE = 'FLAT_FEE_USAGE',
}

export const FeatureStatus = {
  INCLUDED: 'INCLUDED',
  NOT_INCLUDED: 'NOT_INCLUDED',
  CUSTOM_VALUE: 'CUSTOM_VALUE',
  NOT_APPLICABLE: 'NOT_APPLICABLE',
}

export interface PrivateOfferDetials {
  companyName?: string
  awsAccountId?: string
  emailCustomMessage?: string
  privateOfferName?: string
  serviceLength?: number
}
export interface EulaTypeMap {
  STANDARD: string
  ENTERPRISE: string
  CUSTOM: string
}

export interface OfferRecipients {
  firstName: string
  lastName: string
  title: string
  email: string
  sentAt?: string
  lastViewedAt?: string
  firstViewedAt?: string
  crmObjectId?: string
  name?: string
}
export interface OfferStakeholders {
  firstName: string
  lastName: string
  email: string
}

export interface creatorData {
  creatorName?: string
  creatorEmail?: string
}

export interface Installments {
  paymentDate: string
  amount?: number | null
  currency: string
  flatFee?: number
}

export interface MetaData {
  crmObjectId?: string
  type?: string
  key: string
  value?: string | null
}
export interface PriceTypes {
  price1m: number | null
  price12m: number | null
  price24m: number | null
  price36m: number | null
  additionalUsagePrice: number | null
  subscriptionPrice: number | null
  price?: number
}
export type DimensionType = 'standard' | 'custom' | 'additional_usage'

export type Price = number | PriceTypes
export interface Dimension {
  id?: string
  dimensionId?: string
  type: DimensionType
  price?: Price
  prices?: Price
  currency: string
  quantity?: number
  label?: string
  labelDescription?: string
  name?: string
  productName?: string
  awsProductId?: string
  crmObjectId?: string
  isAdditionalUsageAllowed?: boolean
  createdAt?: string
  additionalUsageDescription?: string
}

export const containerProductHourlyAndUsage = [
  ContainerPricingModel.HOURLY,
  ContainerPricingModel.USAGE,
]

export const PaymentTypeGCP = {
  MONTHLY: 'Monthly',
  QUARTERLY: 'Quarterly',
  ANNUALLY: 'Annually',
  CUSTOM: 'Custom',
}

export interface BillingTerm {
  type: string
  value: number
}

export interface PaymentOption {
  type: string
  value: number
}

export interface AzureRecurrentPriceField {
  billingTerm: BillingTerm
  paymentOption: PaymentOption
  pricePerPaymentInUsd?: number
}

export interface UserLimits {
  min?: number
  max?: number
  minEnable?: boolean
  maxEnable?: boolean
}

export enum OfferRecurrentPricingType {
  EDITEXISTINGOFFERPRICINGONLY = 'editExistingOfferPricingOnly',
  SAASNEWCUSTOMIZEDPLANS = 'saasNewCustomizedPlans',
  VMSOFTWARERESERVATIONS = 'vmSoftwareReservations',
}

export enum AzurePriceInputOption {
  PERMARKET = 'perMarket',
  USD = 'usd',
}

export enum RecurrentPriceModeType {
  FLATRATE = 'flatRate',
  PERUSER = 'perUser',
}

export interface RecurrentPrice {
  recurrentPriceMode: RecurrentPriceModeType
  priceInputOption: AzurePriceInputOption
  prices: AzureRecurrentPriceField[]
  userLimits: UserLimits
}

export interface IncludedQuantity {
  billingTerm: BillingTerm
  isInfinite: boolean
  quantity: number
}

export interface AdditionalMeter {
  includedQuantities: IncludedQuantity[]
  pricePerPaymentInUsd: number
  unitOfMeasure: string
  meteringDimensionName: string
}

export interface CustomMeters {
  priceInputOption: AzurePriceInputOption
  meters: {
    [x: string]: AdditionalMeter
  }
}

export interface AzurePOPricing {
  recurrentPrice: RecurrentPrice
  customMeters?: CustomMeters
}

export interface AzureOfferPlan {
  planId?: string
  azurePlanId?: string
  discountPercentage?: number
  description: string
  displayName: string
  labraPlanId: string
  offerPricingType: string
  pricing: AzurePOPricing
  originalPricing?: AzurePOPricing
}
export type InstallmentSchedule = 'monthly' | 'quarterly' | 'yearly' | 'custom'

export interface GCPOfferPlanDimesnion extends CommonDimensionField {
  discount?: number
}

export interface GCPInstallments {
  id?: string
  flatFeePrice?: number
  installmentDate?: string
  metricPricing?: GCPOfferPlanDimesnion[]
}

export interface GCPOfferPlan {
  serviceLength?: number
  paymentType?: PaymentType
  flatFeePrice?: number
  installmentSchedule?: InstallmentSchedule
  tcv?: number
  installmentInfo: GCPInstallments[]
  planId: string
  description: string
  planType: string
  displayName: string
  discount?: number
  individualDiscount?: boolean
  metricPricing?: GCPOfferPlanDimesnion[]
}

export interface Features {
  label: string
  status: string
  value: string
}
export interface Subscription {
  expectedExpirationDate?: string | null
}

export type ChannelType = 'EXTERNAL' | 'AWS_MARKETPLACE'
export interface PrivateOffer {
  privateOfferId: string
  labraProductId?: string
  crmOrderObjectId: string
  awsOfferId?: string
  cloudOfferUrl: string
  labraOfferUrl: string | null
  awsAgreementId?: string
  productName: string
  awsServiceStartDate?: string
  awsOfferLinkCreationDatetime?: string
  offerStatus: OfferStatus
  awsAccountId?: string
  privateOfferName: string
  flexiblePaymentTerms?: boolean
  serviceLength?: number
  entitlementOption?: EntitlementOption
  eulaType: EulaType
  eulaFile?: string[]
  renewal?: boolean
  renewalCount?: number
  renewalChannel?: ChannelType
  autoRenew: boolean
  offerExpirationDate: string
  agreementStartDate?: string
  zeroDollarPricing?: boolean
  offerRecipients: OfferRecipients[]
  companyName: string
  installments?: Installments[]
  subscriptionEndDate?: string | null
  emailCustomMessage?: string | null
  awsProductId?: string
  metaData: MetaData[]
  dimensions?: Dimension[]
  createdAt: string
  draft?: boolean
  creatorData?: creatorData
  links: links
  billingAccountId?: string
  variableStartDate?: boolean
  offerStartDate?: string
  acceptBy?: string
  azurePricingType?: AzurePricingType
  plans?: AzureOfferPlan[]
  azurePrivateOfferId?: string
  acceptanceLink?: string
  preparedBy?: string
  crmOpportunityId?: string
  cloudOfferId?: string
  serviceStartDate?: string
  labraOfferId?: string
  buyerCloudBillingId?: string
  offerStakeholders?: OfferStakeholders[]
  plan?: GCPOfferPlan
  updatedAt?: string
  labraPlanFeatures?: Features[]
  notes?: string
  offerMetadata?: object
  type?: OfferTypeRenew
  labraSubscriptionId?: string
  containerOfferServiceLength?: string
  usageDuration?: number
  customOfferStakeholders?: OfferStakeholders[]
  subscription?: Subscription
}

export type ApiResponse = {
  pageNumber: number
  pageSize: number
  totalCount: number
  sortBy?: string
  sortOrder?: string
  links?: links
}
export interface PrivateOfferObject {
  [x: string]: PrivateOffer
}
export interface PrivateOfferFreeTierInfo {
  additionalAllowedPrivateOffers?: number
}
export type PrivateOffersState = {
  privateOffers: PrivateOfferObject
  noOfPage: number | null
  currentPrivateOffer: { [x: string]: PrivateOffer }
  freeTierInfo?: PrivateOfferFreeTierInfo
} & ApiResponse

const initialState: PrivateOffersState = {
  privateOffers: {},
  pageNumber: 0,
  noOfPage: null,
  pageSize: 10,
  totalCount: 0,
  sortBy: '',
  sortOrder: 'desc',
  links: { self: '' },
  currentPrivateOffer: {},
}

export const initializeOffers = (offers: PrivateOffer[]): PrivateOfferObject =>
  offers.reduce(
    (acc, { privateOfferId, ...offer }) => ({
      ...acc,
      [privateOfferId]: {
        privateOfferId: privateOfferId,
        ...offer,
      },
    }),
    {}
  )
type actionType =
  | {
      type: PrivateOfferActionTypes.SET_PRIVATE_OFFERS_DATA
      payload: {
        data: PrivateOffer[]
        freeTierInfo: PrivateOfferFreeTierInfo
      } & ApiResponse
    }
  | {
      type: PrivateOfferActionTypes.PRIVATE_OFFERS_SET_PAGE_NUMBER
      payload: number
    }
  | {
      type: PrivateOfferActionTypes.PRIVATE_OFFERS_SET_LIMIT
      payload: number
    }
  | {
      type: PrivateOfferActionTypes.UPDATE_PRIVATE_OFFERS_DATA
      payload: Record<string, string>
    }
  | {
      type: PrivateOfferActionTypes.CLEAR_PRIVATE_OFFERS_DATA
    }
  | {
      type: PrivateOfferActionTypes.SET_CURRENT_PRIVATE_OFFER
      payload: PrivateOffer
    }
  | {
      type: PrivateOfferActionTypes.CLEAR_CURRENT_PRIVATE_OFFER
    }

export const reducer: Reducer<PrivateOffersState, actionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case PrivateOfferActionTypes.SET_PRIVATE_OFFERS_DATA: {
      return {
        ...state,
        privateOffers: {
          ...state.privateOffers,
          ...initializeOffers(action.payload.data as PrivateOffer[]),
        },
        pageNumber: action.payload.pageNumber,
        pageSize: action.payload.pageSize,
        totalCount: action.payload.totalCount,
        noOfPage: Math.ceil(
          (action.payload.totalCount ?? 0) / action.payload.pageSize
        ),
        sortBy: action.payload.sortBy,
        sortOrder: action.payload.sortOrder,
        links: action.payload.links,
        freeTierInfo: action.payload.freeTierInfo,
      }
    }
    case PrivateOfferActionTypes.UPDATE_PRIVATE_OFFERS_DATA: {
      return {
        ...state,
        privateOffers: {
          ...state.privateOffers,
          [action.payload.privateOfferId]: {
            ...state.privateOffers[action.payload.privateOfferId],
            [action.payload.key]: action.payload.value,
          },
        },
      }
    }
    case PrivateOfferActionTypes.PRIVATE_OFFERS_SET_LIMIT: {
      return {
        ...state,
        pageSize: action.payload,
      }
    }
    case PrivateOfferActionTypes.PRIVATE_OFFERS_SET_PAGE_NUMBER: {
      return {
        ...state,
        pageNumber: action.payload,
      }
    }
    case PrivateOfferActionTypes.CLEAR_PRIVATE_OFFERS_DATA: {
      return {
        ...state,
        privateOffers: {},
        noOfPage: null,
        totalCount: 0,
        links: { self: '' },
      }
    }
    case PrivateOfferActionTypes.SET_CURRENT_PRIVATE_OFFER: {
      return {
        ...state,
        currentPrivateOffer: {
          [action.payload.privateOfferId]: action.payload as PrivateOffer,
        },
      }
    }
    case PrivateOfferActionTypes.CLEAR_CURRENT_PRIVATE_OFFER: {
      return {
        ...state,
        currentPrivateOffer: {},
      }
    }
    default:
      return state
  }
}
