import { Product } from '../partner/action'

export enum PlatformSettingsActionTypes {
  SET_SELECTED_PRODUCT_ON_SETTINGS = 'SET_SELECTED_PRODUCT_ON_SETTINGS',
}

export const setSelectedProductOnSettings = (product: Product) => ({
  type: PlatformSettingsActionTypes.SET_SELECTED_PRODUCT_ON_SETTINGS,
  payload: product,
})
