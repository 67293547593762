import { Reducer } from 'redux'
import { UserListActionType } from './action'
import { userStatus } from '../../../common/utils/constants'
import { InvitationStatus, Role } from '../../../common/utils/roles'

export interface UserState {
  id: string
  name: string
  lastActivity: string | null
  status: userStatus
  email: string
  password?: string
  idpUserId: string
  phone?: string
  roles: Role[]
  invitationStatus?: InvitationStatus
  createdAt: string
}

export interface UserList {
  users: UserState[]
  count: number
}
export interface UserListState {
  userList: UserList
  user: UserState | null
  userStateForBE: Partial<UserState>
  pageNumber: number
  pageSize: number
  noOfPage: number
  errMess: string
  isUserSidePanelOpen: boolean
  userLoader: boolean
}

export interface UserListLocalState {
  userList: UserList
  pageSize: number
  pageNumber: number
}

export const initialState: UserListState = {
  userList: {
    users: [],
    count: 0,
  },
  userStateForBE: {},
  user: null,
  pageNumber: 1,
  pageSize: 10,
  noOfPage: 0,
  errMess: '',
  isUserSidePanelOpen: false,
  userLoader: false,
}
type Action =
  | {
      type: UserListActionType.SET_USERS
      payload: UserListLocalState
    }
  | {
      type: UserListActionType.SET_USER
      payload: string
    }
  | {
      type: UserListActionType.SET_USER_FOR_BE
      payload: Partial<UserState>
    }
  | {
      type: UserListActionType.SET_ERROR_MESS
      payload: string
    }
  | {
      type: UserListActionType.SET_USERS_PAGE_NUMBER
      payload: number
    }
  | {
      type: UserListActionType.SET_USERS_LIMIT
      payload: number
    }
  | {
      type: UserListActionType.SET_OPEN_USER_PANEL
    }
  | {
      type: UserListActionType.SET_CLOSE_USER_PANEL
    }
  | {
      type: UserListActionType.SET_START_USER_LOADING
    }
  | {
      type: UserListActionType.SET_STOP_USER_LOADING
    }
  | {
      type: UserListActionType.SET_CLEAR_USER_BE
    }

export const reducer: Reducer<UserListState, Action> = (
  state: UserListState = initialState,
  action: Action
) => {
  switch (action.type) {
    case UserListActionType.SET_USERS: {
      return {
        ...state,
        userList: action.payload.userList,
        noOfPage: Math.ceil(
          action.payload.userList.count / action.payload.pageSize
        ),
        pageNumber: action.payload.pageNumber,
      }
    }
    case UserListActionType.SET_USERS_LIMIT: {
      return {
        ...state,
        pageSize: action.payload,
        errMess: '',
      }
    }
    case UserListActionType.SET_USERS_PAGE_NUMBER: {
      return {
        ...state,
        pageNumber: action.payload,
        errMess: '',
      }
    }
    case UserListActionType.SET_USER: {
      return {
        ...state,
        isUserSidePanelOpen: true,
        user:
          state.userList.users.find(user => user.id === action.payload) || null,
      }
    }
    case UserListActionType.SET_USER_FOR_BE: {
      return {
        ...state,
        userStateForBE: action.payload,
      }
    }
    case UserListActionType.SET_ERROR_MESS: {
      return {
        ...state,
        errMess: action.payload,
      }
    }
    case UserListActionType.SET_OPEN_USER_PANEL: {
      return {
        ...state,
        isUserSidePanelOpen: true,
      }
    }
    case UserListActionType.SET_CLOSE_USER_PANEL: {
      return {
        ...state,
        isUserSidePanelOpen: false,
      }
    }
    case UserListActionType.SET_START_USER_LOADING: {
      return {
        ...state,
        userLoader: true,
      }
    }
    case UserListActionType.SET_STOP_USER_LOADING: {
      return {
        ...state,
        userLoader: false,
      }
    }
    case UserListActionType.SET_CLEAR_USER_BE: {
      return {
        ...state,
        userStateForBE: {},
      }
    }
    default:
      return state
  }
}
