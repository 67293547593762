import { camelize, snakeize } from 'casing'
import { AnyAction, Dispatch } from 'redux'
import {
  fetchSellerData,
  patchSellersData,
  postMigrationSyncData,
} from '../../../flyout/api/markeplace'
import { getErrorMessages } from '../../../common/utils/error'
import {
  DefaultSuccesrOnSubmitData,
  ListingTypeUpdationUnsuccessful,
  RequestFailureMessage,
  WaitForMigrationSync,
} from '../../../common/utils/messagesContants'
import { updateAppAlert } from '../../../common/modules/appAlert/actions'
import {
  startLoading,
  stopLoading,
} from '../../../common/modules/loading/actions'
import { LoadingTypes } from '../../../common/modules/loading/reducer'
import { errorLogger } from '../../../common/utils/errorLogger'
import { FlyOutListingData, FlyOutListingUserType } from './reducer'
import { capitalize } from 'lodash'
import { AppDispatch, RootState } from '../../../store'

export enum FlyOutSyncsActionTypes {
  SET_FLYOUT_LISTING_DATA = 'SET_FLYOUT_LISTING_DATA',
}

export const getFlyoutListingData =
  ({
    partnerId,
    metadata = false,
  }: {
    partnerId: string
    metadata?: boolean
  }) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.MARKETPLACE_INFO))
    try {
      const { data } = await fetchSellerData({
        partnerId,
        metadata: { metadata },
      })
      await dispatch(setListingData(camelize(data)))
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(error?.response),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.MARKETPLACE_INFO))
    }
  }

export const sendListingType =
  (
    partnerId: string,
    listingType: FlyOutListingUserType,
    cloudMarketplace = 'aws',
    operations = 'update_partner_details'
  ) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.GENERAL))
    try {
      const { data } = await patchSellersData(
        partnerId,
        { origin: listingType },
        cloudMarketplace,
        operations
      )
      const camelizedData = camelize(data)
      if (camelizedData.updatePartnerDetails.updated) {
        await dispatch(setListingData({ origin: listingType }))
      } else {
        await dispatch(
          updateAppAlert({
            message: ListingTypeUpdationUnsuccessful,
            messageType: 'ERROR',
            autoClose: true,
          })
        )
        await dispatch(
          getFlyoutListingData({
            partnerId,
            metadata: false,
          }) as unknown as AnyAction
        )
      }
    } catch (error: any) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(error?.response),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }

export const patchSellerData =
  (
    partnerId: string,
    sellerData: FlyOutListingData,
    cloudMarketplace = 'aws',
    operations = 'update_partner_details'
  ) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.GENERAL))
    try {
      const { data } = await patchSellersData(
        partnerId,
        snakeize(sellerData),
        cloudMarketplace,
        operations
      )
      const camelizedData = camelize(data)
      if (camelizedData.updatePartnerDetails.updated) {
        await dispatch(setListingData(sellerData))
        await dispatch(
          updateAppAlert({
            message: DefaultSuccesrOnSubmitData,
            messageType: 'SUCCESS',
            autoClose: true,
          })
        )
      } else {
        await dispatch(
          updateAppAlert({
            message: ListingTypeUpdationUnsuccessful,
            messageType: 'ERROR',
            autoClose: true,
          })
        )
        await dispatch(
          getFlyoutListingData({
            partnerId,
            metadata: false,
          }) as unknown as AnyAction
        )
      }
    } catch (error: any) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(error?.response),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }
export const setListingData = (
  listingData: Record<string, unknown> | FlyOutListingData
) => ({
  type: FlyOutSyncsActionTypes.SET_FLYOUT_LISTING_DATA,
  payload: listingData,
})
export type EntityType = 'offers' | 'agreements'
export const sendSync =
  (partnerId: string, entity: EntityType) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.GENERAL))
    await dispatch(
      updateAppAlert({
        message: WaitForMigrationSync,
        messageType: 'WARNING',
        autoClose: false,
      })
    )
    try {
      await postMigrationSyncData(partnerId, { entity })
      await dispatch(
        updateAppAlert({
          message: `${capitalize(entity)} have been synced successfully.`,
          messageType: 'SUCCESS',
          autoClose: true,
        })
      )
    } catch (error: any) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(error?.response),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }
