import { useFlagValue } from '@labrav/flags'
import { Auth0SecuredApp } from './Auth0SecureApp'
import { LoadingLogo } from '@labrav/react-components'

const OktaAndAuth0SecureApp = () => {
  const { loading, value: auth0Enabled } = useFlagValue('auth0')
  if (loading) {
    return <LoadingLogo />
  }
  return <Auth0SecuredApp />
}

export default OktaAndAuth0SecureApp
