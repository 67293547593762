import {
  SyncReportData,
  SyncReportActionTypes,
  syncReportResponse,
} from './action'

export interface SyncReportState {
  syncReport: SyncReportData[]
  pageSize: number
  noOfPage: number | null
  pageNumber: number
  errMess: unknown
}
export const initialState: SyncReportState = {
  syncReport: [],
  pageSize: 100,
  noOfPage: null,
  pageNumber: 1,
  errMess: null,
}
export interface syncReportPayload {
  data: syncReportResponse
  pageSize: number
}
type Action =
  | {
      type: SyncReportActionTypes.SET_SYNC_REPORT
      payload: syncReportPayload
    }
  | {
      type: SyncReportActionTypes.SET_SYNC_REPORT_LIMIT
      payload: number
    }
  | {
      type: SyncReportActionTypes.SET_SYNC_REPORT_PAGE_NUMBER
      payload: number
    }
  | {
      type: SyncReportActionTypes.SET_SYNC_REPORT_ERROR
      payload: unknown
    }
  | {
      type: SyncReportActionTypes.CLEAR_SYNC_REPORT
    }

export const SyncReportReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case SyncReportActionTypes.SET_SYNC_REPORT: {
      return {
        ...state,
        syncReport: action.payload.data.syncsData,
        noOfPage: Math.ceil(
          action.payload.data.totalCount / action.payload.pageSize
        ),
        errMess: null,
      }
    }
    case SyncReportActionTypes.SET_SYNC_REPORT_LIMIT: {
      return {
        ...state,
        pageSize: action.payload,
        errMess: null,
      }
    }
    case SyncReportActionTypes.SET_SYNC_REPORT_PAGE_NUMBER: {
      return {
        ...state,
        pageNumber: action.payload,
      }
    }
    case SyncReportActionTypes.SET_SYNC_REPORT_ERROR: {
      return {
        ...state,
        errMess: action.payload,
      }
    }
    case SyncReportActionTypes.CLEAR_SYNC_REPORT: {
      return initialState
    }
    default:
      return state
  }
}
