import { FlyoutProductAddEditSectionName } from '../../flyout/modules/addEditProduct/reducer'
import { FlyoutOnboardingSectionName } from '../../flyout/modules/flyOutOnboarding/reducer'

export const getPageMarkerMapping = (
): Record<string, string> => {
  return {
    [FlyoutOnboardingSectionName.LABRA_PARTNER_DETAILS]: 'page1',
    [FlyoutOnboardingSectionName.CLOUD_SETTINGS]: 'page2',
  }
}

export const getProductPageMarkerMapping = (
  isMigrated: boolean
): Record<string, string> => {
  return {
    [isMigrated
      ? FlyoutProductAddEditSectionName.MIGRATION_DETAILS
      : FlyoutProductAddEditSectionName.GENERAL_INFORMATION]: 'page1',
    [FlyoutProductAddEditSectionName.PRODUCT_DIMENSIONS]: 'page2',
    [FlyoutProductAddEditSectionName.LANDING_PAGE_DETAILS]: 'page3',
    [FlyoutProductAddEditSectionName.NOTIFICATIONS_RECIPIENTS]: 'page4',
  }
}
