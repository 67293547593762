import { useAuth0 } from '@auth0/auth0-react'
import { LoadingLogo } from '@labrav/react-components'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

const Auth0Register = () => {
  const { loginWithRedirect } = useAuth0()
  const [searchParams,] = useSearchParams();
  searchParams.get("email")

  useEffect(() => {
    loginWithRedirect({
      authorizationParams: {
        screen_hint: 'signup',
        email: searchParams.get("email"),
        prompt: 'login'
      },
    })
  }, [])

  return <LoadingLogo />
}

export default Auth0Register
