import React from 'react'
import ReactDOM from 'react-dom'
// import { createRoot } from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import {
  mockServerEnabled,
  newrelicAppId,
  newrelicLicenseKey,
  segmentID,
} from './common/utils/constants'
import { newrelicErrLogger } from './common/utils/ErrorHandler'
import './common/utils/globalValidations'
import { SetupWorker } from 'msw/browser'

declare global {
  interface Window {
    stripe: unknown
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Calendly: any
    newrelic: {
      noticeError: (e: Error, customObj?: Record<string, unknown>) => void
    }
    NREUM: {
      loader_config: unknown
      info: unknown
    }
    segmentScriptRun: (writeKey: string, environment: string) => void
  }
}
if (
  typeof window !== 'undefined' &&
  Object.prototype.hasOwnProperty.call(window, 'NREUM')
) {
  window.NREUM.loader_config = {
    accountID: '3066873',
    trustKey: '3066873',
    agentID: newrelicAppId,
    licenseKey: newrelicLicenseKey,
    applicationID: newrelicAppId,
  }
  window.NREUM.info = {
    beacon: 'bam.nr-data.net',
    errorBeacon: 'bam.nr-data.net',
    licenseKey: newrelicLicenseKey,
    applicationID: newrelicAppId,
    sa: 1,
  }
} else {
  console.error('unable to load newrelic')
}

if (process.env.NODE_ENV !== 'production' && mockServerEnabled) {
  import('./mocks/browser')
    .then(({ getWorker }) => getWorker())
    .then((worker: SetupWorker) => {
      worker.start()
    })
    .catch((e: Error) => {
      console.error(e)
      newrelicErrLogger(e as Error, {
        message: e,
      })
    })
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
