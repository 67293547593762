import { API } from '../../../common/api'
import { marketplaceServiceUrl } from '../../../common/utils/constants'

export const fetchCppoAgreementData = (
  parnterId: string,
  crmAwsResellerAgreementId: string,
  secureKey: string,
  email?: string
) => {
  return API.get(
    `${marketplaceServiceUrl}/partners/${parnterId}/aws_reseller_agreements/${crmAwsResellerAgreementId}`,
    {
      params: {
        email,
        s: secureKey,
      },
    }
  )
}
