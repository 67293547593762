import { makeStyles } from '@material-ui/core'

export const useStyles = (
  shouldShowCustomeNavButton: boolean,
  isExternal?: boolean,
  isRevampEnable?: boolean
) =>
  makeStyles(theme => ({
    appTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: 'auto',
    },
    appTitleHeading: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    appTitleHeadingText: {
      fontSize: isRevampEnable ? '24px' : '28px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: isRevampEnable ? '32px' : '36px',
      color: theme.palette.labraText.primary,
    },
    stickyContainer: {
      position: 'sticky',
      ...(isRevampEnable && !isExternal
        ? {
            top: 0,
            zIndex: 99,
          }
        : {}),
    },
    action: {
      display: 'flex',
      justifyContent: 'row',
      paddingTop: shouldShowCustomeNavButton
        ? theme.spacing(0)
        : theme.spacing(1.125),
      alignItems: 'center',
      gap: '32px',
      height: theme.spacing(isRevampEnable ? 4 : 5.5),
      boxSizing: 'border-box',
    },
    headerContainer: {
      display: 'inline-flex',
      flexDirection: 'column',
      alignItems: 'flexStart',
      gap: '32px',
      ...(isRevampEnable
        ? {
            padding: theme.spacing(2, 5),
            backgroundColor: theme.palette.text.white,
            boxShadow: `0px -1px 0px 0px ${theme.palette.labraV2BoxShadow.dark} inset`,
          }
        : {
            padding: theme.spacing(4, 5),
          }),
      zIndex: 105,
    },
    subTitleText: {
      fontSize: '14px !important',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px !important',
      letterSpacing: '0.25px',
      color: theme.palette.labraText.description,
      fontFamily: theme.typography.fontFamily,
      textAlign: 'left',
    },
    appBackIcon: {
      display: 'flex',
      margin: 'auto',
      marginRight: theme.spacing(1.12),
      cursor: 'pointer',
    },
    customNavButton: {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
    },

    stickyHeaders: {
      position: 'relative',
      height: '23px',
      zIndex: 999,
    },
  }))
