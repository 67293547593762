import { Reducer } from 'redux'
import { RevenueTrackingActionTypes } from './actions'
import { DateTime } from 'luxon'

export enum ReportType {
  DAILY = 'daily',
  MONTHLY = 'monthly',
  CLOUD_DISBURSEMENT = 'cloudDisbursement',
  DISBURSEMENT = 'disbursement',
}
export interface GraphData {
  date: string
  totalAmountActivated?: number
  totalAmountBilled?: number
  totalAmountDisbursed?: number
}
export interface DateRange {
  startDate: string
  endDate: string
}
export interface ReportsRow {
  date: string
  productTitle: string
  labraProductId: string
  cloudMarketplaceProductCode: string
  labraBuyerName: string
  offerId: string
  buyerId: string
  labraBuyerLogoUrl: string
  awsAccountId: string
  activatedAmount?: string
  billedAmount?: string
  revenue?: string
  deductions?: string
  refund?: string
  disbursedAmount?: string
}

export interface CloudDisbursementReportsRow {
  settlementId: string
  settlementPeriodStartDate: string
  settlementPeriodEndDate: string
  depositDate: string
  disbursedAmount: string
  requestedForDatetime: string
}

export interface ReportsData {
  rows: ReportsRow[] | CloudDisbursementReportsRow[]
  totalAmountActivated?: string
  amountChange?: string
  percentageChange?: string
  totalAmountBilled?: string
  totalAmountDisbursed?: string
  pageSize: number
  pageNumber: number
  noOfPage: number | null
  graphData: GraphData[]
  dateRange: DateRange
}
export interface ReportsPayload {
  rows: ReportsRow[]
  totalAmountActivated?: string
  totalAmountBilled?: string
  totalAmountDisbursed?: string
  count: number
  pageSize: number
}
type actionType =
  | {
      type: RevenueTrackingActionTypes.SET_REPORTS_DATA
      payload: ReportsPayload
    }
  | {
      type: RevenueTrackingActionTypes.REVENUE_SET_PAGE_NUMBER
      payload: number
    }
  | {
      type: RevenueTrackingActionTypes.REVENUE_SET_LIMIT
      payload: number
    }
  | {
      type: RevenueTrackingActionTypes.CLEAR_REVENUE_REPORTS
    }
  | {
      type: RevenueTrackingActionTypes.SET_DATE_RANGE
      payload: DateRange
    }

export const initialState: ReportsData = {
  rows: [],
  totalAmountActivated: '',
  totalAmountBilled: '',
  totalAmountDisbursed: '',
  pageSize: 10,
  pageNumber: 1,
  noOfPage: null,
  graphData: [],
  dateRange: {
    startDate: DateTime.now().toISODate() as string,
    endDate: DateTime.now().toISODate() as string,
  },
}
export const reducer: Reducer<ReportsData, actionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case RevenueTrackingActionTypes.SET_REPORTS_DATA: {
      return {
        ...state,
        ...action.payload,
        noOfPage: Math.ceil(action.payload.count / action.payload.pageSize),
      }
    }
    case RevenueTrackingActionTypes.REVENUE_SET_LIMIT: {
      return {
        ...state,
        pageSize: action.payload,
      }
    }
    case RevenueTrackingActionTypes.REVENUE_SET_PAGE_NUMBER: {
      return {
        ...state,
        pageNumber: action.payload,
      }
    }
    case RevenueTrackingActionTypes.SET_DATE_RANGE: {
      return {
        ...state,
        dateRange: action.payload,
      }
    }
    case RevenueTrackingActionTypes.CLEAR_REVENUE_REPORTS: {
      return {
        ...state,
        rows: [],
        graphData: [],
        totalAmountActivated: '',
        amountChange: '',
        percentageChange: '',
        totalAmountBilled: '',
        totalAmountDisbursed: '',
      }
    }
    default: {
      return state
    }
  }
}
