import { AppDispatch, RootState } from '../../../store'
import {
  startLoading,
  stopLoading,
} from '../../../common/modules/loading/actions'
import { AnyAction } from 'redux'
import { LoadingTypes } from '../../../common/modules/loading/reducer'
import { updateAppAlert } from '../../../common/modules/appAlert/actions'
import { getErrorMessages } from '../../../common/utils/error'
import { RequestFailureMessage } from '../../../common/utils/messagesContants'
import { AxiosResponse } from 'axios'
import { errorLogger } from '../../../common/utils/errorLogger'
import { getOverviewData } from '../../api/markeplace'
import { camelize } from 'casing'
import { OverviewState, RevenueData } from './reducer'
import { getMonthAbbreviation } from '../../../common/utils/getGraphDateLabel'

export enum OverviewActionTypes {
  SET_OVERVIEW = 'SET_OVERVIEW',
}

export const getFlyoutOverview =
  ({ partnerId }: { partnerId: string }) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    await dispatch(startLoading(LoadingTypes.FLYOUT_OVERVIEW))
    try {
      const response = await getOverviewData({ partnerId })
      const overviewData = camelize(response?.data ?? {})
      if (
        !overviewData.overview?.revenue ||
        Object.keys(overviewData.overview.revenue).length === 0
      ) {
        await dispatch(setOverviewData(overviewData))
      } else {
        const transformedOverviewData = {
          ...overviewData,
          overview: {
            ...overviewData.overview,
            revenue: {
              ...overviewData.overview.revenue,
              revenueData: overviewData.overview.revenue.revenueData.map(
                (item: RevenueData) => ({
                  ...item,
                  data: item.data.map(({ date, value }) => ({
                    x: getMonthAbbreviation(date),
                    y: value,
                  })),
                })
              ),
            },
          },
        }
        await dispatch(setOverviewData(transformedOverviewData))
      }
    } catch (error: any) {
      await dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      await dispatch(stopLoading(LoadingTypes.FLYOUT_OVERVIEW))
    }
  }

export const setOverviewData = (overviewData: OverviewState) => ({
  type: OverviewActionTypes.SET_OVERVIEW,
  payload: overviewData,
})
