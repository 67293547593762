import { makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = (width: string, height?: string) =>
  makeStyles((theme: Theme) => ({
    root: {
      width: width,
      height: height,
    },

    barColorPrimary: {
      backgroundColor: theme.palette.loading.main,
    },
    barColorLight: {
      backgroundColor: theme.palette.status.light,
    },
  }))
