import { ArrayAction } from '.'
import { actionTypeWrapper } from '../../utils/actionTypeWrapper'

export enum ArrayActionType {
  ADD = 'ADD',
  REPLACE_ALL = 'REPLACE_ALL',
  REMOVE_BY_INDEX = 'REMOVE_BY_INDEX',
  REMOVE = 'REMOVE',
  UPDATE = 'UPDATE',
  UPDATE_BY_INDEX = 'UPDATED_BY_INDEX',
}

const addRow = (): ArrayAction => ({ type: ArrayActionType.ADD })

const replaceAllRows = (data: unknown[]): ArrayAction => ({
  type: ArrayActionType.REPLACE_ALL,
  payload: data,
})

const removeRow = (id: string): ArrayAction => ({
  type: ArrayActionType.REMOVE,
  payload: id,
})

const removeRowByIndex = (index?: number): ArrayAction => ({
  type: ArrayActionType.REMOVE_BY_INDEX,
  payload: index,
})

const updateRow = (id: string, data: unknown): ArrayAction => ({
  type: ArrayActionType.UPDATE,
  payload: { id, data },
})

const updateRowByIndex = (index: number, data: unknown): ArrayAction => ({
  type: ArrayActionType.UPDATE_BY_INDEX,
  payload: { index, data },
})

// we can avoid repetition here, but as far as I'm able to do so, we would lose typings, so I'd rather have it like this
// util a better solution is suggested.
export const arrayReducerActionWrapper = <T>(label: string) => ({
  addRow: () => actionTypeWrapper(label, addRow()),
  replaceAllRows: (data: T[]) => actionTypeWrapper(label, replaceAllRows(data)),
  removeRow: (id: string) => actionTypeWrapper(label, removeRow(id)),
  updateRow: (id: string, data: T) =>
    actionTypeWrapper(label, updateRow(id, data)),
  removeRowByIndex: (index?: number) =>
    actionTypeWrapper(label, removeRowByIndex(index)),
  updateRowByIndex: (index: number, data: T) =>
    actionTypeWrapper(label, updateRowByIndex(index, data)),
})
