import React, { useEffect } from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { useStyles } from './HeaderAlert.styles'
import Typography from '@material-ui/core/Typography'
import { useDispatch, useSelector } from '../../../store'
import { AppAlertState } from '../../modules/appAlert/reducer'
import { updateAppAlert } from '../../modules/appAlert/actions'
import CloseIcon from '@material-ui/icons/Close'
import clsx from 'clsx'
import { CRMAuthSuccessMessage } from '../../utils/messagesContants'

export const HeaderAlertComponent: React.FC<{ trigger?: boolean }> = ({
  trigger,
}) => {
  const classes = useStyles()

  const errorState = useSelector<AppAlertState>(state => state.appAlert)

  const dispatch = useDispatch()

  const close = () => {
    const resetError: AppAlertState = {
      message: undefined,
      messageType: 'NONE',
    }
    dispatch(updateAppAlert(resetError))
  }
  useEffect(() => {
    if (errorState !== undefined) {
      if (errorState.autoClose && errorState.message !== CRMAuthSuccessMessage)
        setTimeout(close, 10000)
    }
  }, [errorState])

  if (!errorState.message) {
    return <></>
  }

  return (
    <div
      className={clsx({
        [classes.root]: !!errorState.message,
      })}
      data-testid="header-alert"
      data-test-status={errorState.messageType}
    >
      <AppBar
        elevation={0}
        position="static"
        className={clsx(classes.appbar, {
          [classes.appbarSuccess]: errorState.messageType === 'SUCCESS',
          [classes.appbarError]: errorState.messageType === 'ERROR',
          [classes.appbarInfo]: errorState.messageType === 'INFO',
          [classes.appbarWarning]: errorState.messageType === 'WARNING',
        })}
      >
        <Toolbar>
          <Typography className={classes.toolbarContent}>
            {errorState.message}
          </Typography>

          <CloseIcon
            data-testid="close"
            onClick={close}
            className={classes.close}
          />
        </Toolbar>
      </AppBar>
    </div>
  )
}
