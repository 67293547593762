import { Reducer } from 'redux'
import { SavedActionTypes } from './actions'

export type SavedStatus = {
  alertType: 'SUCCESS' | 'LOADING' | 'ERROR'
  autoClose?: boolean
}
export const initialState: SavedStatus = {
  alertType: 'SUCCESS',
  autoClose: true,
}
type actionType = {
  type: SavedActionTypes
  saved: SavedStatus
}

export const reducer: Reducer<SavedStatus, actionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SavedActionTypes.UPDATE_SAVED: {
      return action.saved
    }
    default: {
      return state
    }
  }
}
