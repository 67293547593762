import { Reducer } from 'redux'
import { WarningStatusState } from './action'

export type WarningStatusAction =
  | {
      type: WarningStatusState.SET_WARNING_STATUS
      payload: { aceKey: string; mappingWarningObj: WarningMessageObj }
    }
  | {
      type: WarningStatusState.IS_COMPLETELY_LOADED
      payload: boolean
    }
  | {
      type: WarningStatusState.SET_MANDATORY_WARNING_STATUS
      payload: { index: number; mandatoryWarningObj: WarningMessageObj }
    }
  | {
      type: WarningStatusState.REMOVE_MANDATORY_WARNING_STATUS
      payload: { index: number }
    }
  | {
      type: WarningStatusState.CLEAR_WARNING_STATUS
    }

export type WarningMessageObj = {
  dataTypeWarningMessage?: string
  maxLengthWarningMessage?: string
  mandatoryWarningMessage?: string
  table: string
  crmLabel: string
  crmKey: string
}

export type WarningsForMapping = {
  [x: string]: WarningMessageObj
}
export type WarningForMandatoryFields = WarningMessageObj[]
export type WarningState = {
  mappingWarningMessages: WarningsForMapping
  mandatoryWarningMessages: WarningForMandatoryFields
  isCompletelyLoaded: boolean
}

export const initialState = {
  mappingWarningMessages: {},
  mandatoryWarningMessages: [],
  isCompletelyLoaded: false,
}
export const reducer: Reducer<WarningState, WarningStatusAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case WarningStatusState.SET_WARNING_STATUS: {
      const { aceKey, mappingWarningObj } = action.payload
      return {
        ...state,
        mappingWarningMessages: {
          ...state.mappingWarningMessages,
          [aceKey]: mappingWarningObj,
        },
      }
    }
    case WarningStatusState.IS_COMPLETELY_LOADED: {
      return {
        ...state,
        isCompletelyLoaded: action.payload,
      }
    }
    case WarningStatusState.SET_MANDATORY_WARNING_STATUS: {
      const { index, mandatoryWarningObj } = action.payload
      const newMandatoryWarningMessages = [...state.mandatoryWarningMessages]
      newMandatoryWarningMessages[index] = mandatoryWarningObj

      return {
        ...state,
        mandatoryWarningMessages: newMandatoryWarningMessages,
      }
    }
    case WarningStatusState.REMOVE_MANDATORY_WARNING_STATUS: {
      const { index } = action.payload
      const newMandatoryWarningMessages = [
        ...state.mandatoryWarningMessages,
      ].filter((_, i) => i !== index)

      return {
        ...state,
        mandatoryWarningMessages: newMandatoryWarningMessages,
      }
    }
    case WarningStatusState.CLEAR_WARNING_STATUS: {
      return initialState
    }
    default:
      return state
  }
}
