import {
  startLoading,
  stopLoading,
} from '../../../common/modules/loading/actions'
import { LoadingTypes } from '../../../common/modules/loading/reducer'
import { AnyAction, Dispatch } from 'redux'
import { camelize, snakeize } from 'casing'
import { updateAppAlert } from '../../../common/modules/appAlert/actions'
import { AxiosError, AxiosResponse } from 'axios'
import {
  fetchFlyoutAccountDetails,
  fetchOrganizationAccounts,
  getUrlFromCloudAccount,
  patchAccount,
  postFlyoutAccountDetails,
  sendAzureData,
  sendAzurePartnerData,
  testTemplateEndpoint,
} from '../../../flyout/api/cmcm'
import {
  AccountUpdateSuccessMessage,
  FlyOutSettingsSuccessMessage,
  RequestFailureMessage,
} from '../../../common/utils/messagesContants'
import { RootState } from '../../../store'

import { ProductType } from '../../../common/modules/partner/reducer'
import { actionTypeWrapper } from '../../../common/utils/actionTypeWrapper'
import { Account } from '../../../common/modules/cmcm/types'
import { get, isEmpty, snakeCase } from 'lodash'
import {
  fetchAccounts,
  setAccounts,
} from '../../../common/modules/cmcm/accounts/actions'
import {
  awsTemplateNameCMCM,
  azureScopesUrls,
  oppSyncProductPrd,
  temporaryArn,
} from '../../../common/utils/constants'
import { CloudType, cloudTypeToApiValue } from '../../../common/modules/types'
import { TestButtonStatus } from '@labrav/react-components/lib/@types/components/TestButtonComponent'
import { errorLogger } from '../../../common/utils/errorLogger'
import { getErrorMessages } from '../../../common/utils/error'
import {
  AzureAccountDetails,
  SetAccountOnboardingDataPayload,
  TemplateRows,
} from './reducer'
import { deactivateAzureAccount, fetchAzureAccountDetails } from '../../api'
import { getItem, setItem } from '../../../common/utils/localStorage'
import {
  PartnerData,
  PartnerType,
} from '../../../common/modules/partner/action'
import { patchPartnerEngineService } from '../../../common/api'
import {
  FlyOutListingData,
  FlyOutListingUserType,
} from '../../../admin/modules/flyOutSyncs/reducer'
import { patchSellersData } from '../../../flyout/api/markeplace'
import { AlertMessageType } from '../../../common/modules/appAlert/reducer'

export enum AccountsActions {
  SET_ACCOUNT_ONBOARDING_DATA = 'SET_ACCOUNT_ONBOARDING_DATA',
  SET_ACCOUNT_NAME = 'SET_ACCOUNT_NAME',
  SET_EXTERNAL_ACCOUNT_ID = 'SET_EXTERNAL_ACCOUNT_ID',
  SET_TEMPLATE_INPUT_FIELD = 'SET_TEMPLATE_INPUT_FIELD',
  SET_TEST_STATUS_ARN = 'SET_TEST_STATUS_ARN',
  SET_AZURE_DATA = 'SET_AZURE_DATA',
  UPDATE_VALUE_ON_ACCOUNT_DETAILS_AZURE = 'UPDATE_VALUE_ON_ACCOUNT_DETAILS_AZURE',
  SET_AZURE_ACCOUNT_DETAILS = 'SET_AZURE_ACCOUNT_DETAILS',
  UPDATE_VALUE_ON_ACCOUNT_DETAILS_AWS = 'UPDATE_VALUE_ON_ACCOUNT_DETAILS_AWS',
}

type getSpecificFieldsBasedOnProductTypeProps = {
  partnerData: PartnerData | null
  productType: ProductType
  flyoutSellerinfo?: FlyOutListingData
}

export const getSpecificFieldsBasedOnProductType = ({
  partnerData,
  productType,
  flyoutSellerinfo,
}: getSpecificFieldsBasedOnProductTypeProps) => {
  switch (productType) {
    case 'oppsync': {
      return {
        industries: snakeCase(partnerData?.industry || ''),
        partnershipTier: partnerData?.partnershipTier || '',
        partnershipType: partnerData?.partnershipType || '',
        terms: partnerData?.acceptedTermsAndConditions || false,
        isIsv: partnerData?.isIsv || false,
        isSrrp: partnerData?.isSrrp || false,
        accelerateProgram: partnerData?.accelerateProgram || false,
      }
    }
    case 'flyout': {
      return {
        isIsv: partnerData?.isIsv,
        origin: flyoutSellerinfo?.origin || '',
      }
    }
    case 'cloudfaas': {
      return {}
    }
    default: {
      return {}
    }
  }
}

interface GetInputFieldsArgs {
  productType: ProductType
  id: string
  accountFromAllAccounts?: Account
  flyoutSellerinfo?: FlyOutListingData
}

const getInputsFields = ({
  productType,
  accountFromAllAccounts,
  id,
  flyoutSellerinfo,
}: GetInputFieldsArgs) => {
  switch (productType) {
    case 'oppsync': {
      return [
        {
          keyName: 'iamRoleArn',
          value:
            accountFromAllAccounts?.accountDetails?.roles.rows.find(
              r => r.awsTemplateId === id
            )?.iamRoleArn || '',
          error: '',
          label: 'Enter Your ARN',
          showTestStatusButton: true,
          isRequired: true,
        },
      ]
    }
    case 'flyout': {
      return [
        {
          keyName: 'awsRoleArn',
          value: get(flyoutSellerinfo, 'sellerMetadata.awsRoleArn', ''),
          error: '',
          label: 'Enter Your ARN',
          showTestStatusButton: false,
          isRequired: true,
        },
        {
          keyName: 'awsCasS3BucketName',
          value: get(flyoutSellerinfo, 'sellerMetadata.awsCasS3BucketName', ''),
          error: '',
          label: 'Enter Your S3 CAS Bucket Name',
          showTestStatusButton: false,
          isRequired: true,
        },
        {
          keyName: 'awsCasSnsTopicArn',
          value: get(flyoutSellerinfo, 'sellerMetadata.awsCasSnsTopicArn', ''),
          error: '',
          label: 'Enter Your CAS SNS Topic ARN',
          showTestStatusButton: false,
          isRequired: true,
        },
      ]
    }
    case 'cloudfaas': {
      return []
    }
  }
}

export const getAccountRegistrationData =
  (
    cloudType: string,
    name: string,
    productId: string,
    partnerId: string,
    productType: ProductType,
    partnerType: PartnerType,
    accountFromAllAccounts?: Account,
    flyoutSellerinfo?: FlyOutListingData
  ) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    await dispatch(startLoading(LoadingTypes.TEMPLATES))
    const partnerData = getState().PartnerData[partnerType].partnerData
    try {
      const { data } = await fetchFlyoutAccountDetails(
        name,
        cloudType,
        productId,
        partnerId
      )
      if (!isEmpty(data.rows)) {
        data.rows.forEach((row: any) => {
          row.inputsFields = getInputsFields({
            productType,
            accountFromAllAccounts,
            id: row.id,
            flyoutSellerinfo,
          })
        })
      }

      const newData = {
        ...camelize(data),
        organizationId: partnerId,
        productId: productId,
        name: accountFromAllAccounts?.accountDetails?.name || '',
        externalId:
          accountFromAllAccounts?.accountDetails?.externalAccountId || '',
        ...getSpecificFieldsBasedOnProductType({
          partnerData,
          productType,
          flyoutSellerinfo,
        }),
      }
      await dispatch(
        actionTypeWrapper(productType, setAccountRegistrationData(newData))
      )
    } catch (error) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            (error as AxiosError).response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      await dispatch(stopLoading(LoadingTypes.TEMPLATES))
    }
  }
type SetAccountRegistrationDataProps = SetAccountOnboardingDataPayload & {
  rows: TemplateRows[]
  partnershipType?: string
}
export const setAccountRegistrationData = (
  data: SetAccountRegistrationDataProps
) => {
  const {
    cloudType,
    organizationId,
    rows,
    productId,
    name,
    externalId,
    industries,
    partnershipTier,
    partnershipType,
    terms,
    isIsv,
    isSrrp,
    accelerateProgram,
    origin,
  } = data
  return {
    type: AccountsActions.SET_ACCOUNT_ONBOARDING_DATA as AccountsActions.SET_ACCOUNT_ONBOARDING_DATA,
    payload: {
      cloudType,
      organizationId,
      roles: rows,
      productId,
      name,
      externalId,
      industries,
      partnershipTier,
      partnershipTypes: partnershipType,
      terms,
      isIsv,
      isSrrp,
      accelerateProgram,
      origin,
    },
  } as unknown as AnyAction
}

export const updateValueOnAccountDetails = (key: string, value: unknown) =>
  ({
    type: AccountsActions.UPDATE_VALUE_ON_ACCOUNT_DETAILS_AZURE as AccountsActions.UPDATE_VALUE_ON_ACCOUNT_DETAILS_AZURE,
    payload: {
      key,
      value,
    },
  } as unknown as AnyAction)
export const updateValueOnAccountDetailsForAWS = (
  key: string,
  value: unknown
) =>
  ({
    type: AccountsActions.UPDATE_VALUE_ON_ACCOUNT_DETAILS_AWS as AccountsActions.UPDATE_VALUE_ON_ACCOUNT_DETAILS_AWS,
    payload: {
      key,
      value,
    },
  } as unknown as AnyAction)

export const sendAccountRegistrationData =
  (productType: ProductType, partnerId: string, onboardingComplete?: boolean) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    await dispatch(startLoading(LoadingTypes.CMCM_FETCHING_ACCOUNTS))
    try {
      const state = getState()
      const data = state.accountForm[productType]
      const origin = data.accountDetails.AWS.origin
      const isFlyOut = productType === 'flyout'
      const shouldSendNameOrAccountId =
        productType === 'oppsync' ||
        (isFlyOut && origin !== FlyOutListingUserType.MANAGED)
      const name = shouldSendNameOrAccountId ? data.accountDetails.AWS.name : ''
      const externalAccountId = shouldSendNameOrAccountId
        ? data.accountDetails.AWS.externalAccountId
        : ''
      const roles = data.accountDetails.AWS.roles.rows.map(row => {
        const arn = get(row, 'inputsFields[0].value', '')
        return {
          templateId: row.id,
          iamRoleArn: isEmpty(arn) ? temporaryArn : arn,
        }
      })
      const accountId = (state.accounts[productType] || [])[0]?.id
      const newData = {
        ...data,
        accountDetails: {
          name,
          externalAccountId,
          type: 'ROOT',
          roles: { rows: roles },
        },
        name: `${productType}_prd`,
      }
      const isIsv = data.accountDetails.AWS.isIsv
      let dataToUpdatePartner: Record<string, unknown> = {
        isIsv: isIsv,
      }

      if (productType === 'oppsync') {
        dataToUpdatePartner = {
          ...dataToUpdatePartner,
          partnershipTier: data.accountDetails.AWS.partnershipTier,
          partnershipType: snakeCase(data.accountDetails.AWS.partnershipTypes),
          industry: data.accountDetails.AWS.industries,
          isSRRP: data.accountDetails.AWS.isSrrp,
          acceptedTermsAndConditions: data.accountDetails.AWS.terms,
          accelerateProgram: isIsv
            ? data.accountDetails.AWS.accelerateProgram
            : false,
        }
      }

      if (accountId) {
        if (shouldSendNameOrAccountId) {
          await patchAccount(
            accountId,
            snakeize({ accountDetails: newData.accountDetails })
          )
        }
      } else {
        await postFlyoutAccountDetails(snakeize(newData))
      }

      await dispatch(
        fetchAccounts(
          awsTemplateNameCMCM,
          newData.cloudType as CloudType,
          productType
        ) as never
      )
      await patchPartnerEngineService(partnerId, snakeize(dataToUpdatePartner))
      const alert = { message: '', messageType: '' }
      if (isFlyOut) {
        const sellersMetaData: Record<string, string> = {}
        data.accountDetails.AWS.roles.rows[0]?.inputsFields.forEach(
          field => (sellersMetaData[field.keyName] = field.value)
        )
        const seller = {
          origin: data.accountDetails.AWS.origin,
          awsAccountId: externalAccountId,
          sellersMetaData,
        }
        await patchSellersData(
          partnerId,
          snakeize(seller),
          CloudType.AWS,
          'update_partner_details'
        )
        alert.messageType = 'SUCCESS'
        alert.message = FlyOutSettingsSuccessMessage
      } else {
        alert.messageType =
          productType === accountId && onboardingComplete
            ? 'SUCCESS'
            : 'WARNING'
        alert.message =
          accountId && onboardingComplete
            ? AccountUpdateSuccessMessage
            : `Your ${newData.cloudType} integration is in progress. Please contact your account manager.`
      }
      await dispatch(
        updateAppAlert({
          message: alert.message,
          messageType: alert.messageType as AlertMessageType,
          autoClose: !!(accountId && onboardingComplete),
        })
      )
    } catch (error) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            (error as AxiosError).response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      await dispatch(stopLoading(LoadingTypes.CMCM_FETCHING_ACCOUNTS))
    }
  }

export const sendOppsyncAWSSettingsData =
  (partnerData: PartnerData) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      const state = getState()
      const data = state.accountForm.oppsync
      const accountRoles = data.accountDetails.AWS.roles
      const accountId = (state.accounts.oppsync || [])[0]?.id
      const updateAccountDetails: Record<string, any> = {}

      const name = data.accountDetails.AWS.name || ''
      if (!isEmpty(name)) {
        updateAccountDetails['name'] = name
      }
      const externalAccountId = data.accountDetails.AWS.externalAccountId || ''
      if (!isEmpty(externalAccountId)) {
        updateAccountDetails['externalAccountId'] = externalAccountId
      }
      const roles: { templateId: string; iamRoleArn: string }[] = []
      data.accountDetails.AWS.roles.rows.forEach(row => {
        const arn = get(row, 'inputsFields[0].value', '')
        if (!isEmpty(arn) && row.inputsFields[0].testStatus === 'success') {
          roles.push({ templateId: row.id, iamRoleArn: arn })
        }
      })

      if (roles.length > 0) {
        updateAccountDetails['roles'] = { rows: roles }
      }

      if (accountId) {
        await patchAccount(
          accountId,
          snakeize({
            accountDetails: { ...updateAccountDetails, type: 'ROOT' },
          })
        )
      } else {
        const response = await postFlyoutAccountDetails(
          snakeize({
            cloudType: CloudType.AWS,
            name: 'oppsync_prd',
            organizationId: partnerData?.partnerId,
            productId: 'oppsync',
            accountDetails: { ...updateAccountDetails, type: 'ROOT' },
          })
        )
        const account = response.data

        await dispatch(
          actionTypeWrapper('oppsync', setAccounts(camelize([account])))
        )

        if (!isEmpty(accountRoles)) {
          accountRoles.rows.forEach((row: any) => {
            row.inputsFields = getInputsFields({
              productType: 'oppsync',
              accountFromAllAccounts: account,
              id: row.id,
            })
          })
        }

        await dispatch(
          actionTypeWrapper(
            'oppsync',
            setAccountRegistrationData({
              ...camelize(accountRoles),
              organizationId: partnerData.partnerId,
              productId: 'oppsync',
              name: account?.account_details?.name || '',
              externalId: account?.account_details?.external_account_id || '',
              ...getSpecificFieldsBasedOnProductType({
                partnerData,
                productType: 'oppsync',
              }),
            })
          )
        )
      }
    } catch (error) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            (error as AxiosError).response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    }
  }

export const testTemplate =
  (
    templateId: string,
    cloudType: CloudType,
    productType: ProductType,
    partnerId: string,
    keyName: string
  ) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    await dispatch(
      actionTypeWrapper(productType, setStatus(templateId, 'loading', keyName))
    )

    const state = getState()
    const templateInputsFields = state.accountForm[
      productType
    ].accountDetails.AWS.roles.rows.find(r => r.id === templateId)?.inputsFields
    const iamRoleArn =
      templateInputsFields?.find(
        templateInputsField => templateInputsField.keyName === keyName
      )?.value || ''
    try {
      await testTemplateEndpoint(templateId, cloudType, iamRoleArn, partnerId)
      await dispatch(
        actionTypeWrapper(
          productType,
          setStatus(templateId, 'success', keyName)
        )
      )
    } catch (err) {
      await dispatch(
        actionTypeWrapper(
          productType,
          setStatus(
            templateId,
            'error',
            keyName,
            getErrorMessages([RequestFailureMessage])(
              (err as AxiosError).response as AxiosResponse<ErrorResponse>
            )
          )
        )
      )
      const error: Error = {
        name: 'productType',
        message: `Getting error in this product Type: ${productType} , with this templateId ${templateId}`,
      }
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    }
  }

export const getCloudAccountDataForAzure =
  (productType: ProductType, partnerId: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    await dispatch(startLoading(LoadingTypes.CMCM_FETCHING_ACCOUNTS))
    const state = getState()
    const partnerData = state.PartnerData.user.partnerData
    try {
      let newData
      const value = await getItem('azureOauth')
      if (!value) {
        const { data } = await getUrlFromCloudAccount(partnerId)
        newData = {
          ...data,
          cloudType: partnerData?.activeCloudProvider,
          organizationId: partnerData?.partnerId,
          productId: productType.toUpperCase(),
        }
        await setItem('azureOauth', newData, { ttlInSeconds: 60 * 5 })
      }
      const finalData = value ? value : newData

      await dispatch(
        actionTypeWrapper(
          productType,
          setAzureAccountDetails(camelize(finalData))
        )
      )
    } catch (error) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            (error as AxiosError).response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      await dispatch(stopLoading(LoadingTypes.CMCM_FETCHING_ACCOUNTS))
    }
  }

export const sendAzureAccountRegistration =
  (
    productType: ProductType,
    code: string,
    stateFromUrl: string,
    sessionState: string,
    partnerId: string
  ) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    await dispatch(startLoading(LoadingTypes.GENERAL))
    const azureAccountDetails =
      getState().accountForm[productType].accountDetails.AZURE
    try {
      const dataToSend = {
        cloudType: cloudTypeToApiValue(CloudType.AZURE),
        productId: productType.toUpperCase(),
        name: oppSyncProductPrd,
        scopes: azureScopesUrls,
        accountDetails: {
          code,
          state: stateFromUrl,
          sessionState,
        },
      }
      const partnerDataToSend = {
        mpnId: azureAccountDetails.MPNID,
        companyName: azureAccountDetails.name,
        industry: azureAccountDetails.industries,
        partnerType: azureAccountDetails.microsoftPartnerType,
        isIsv: azureAccountDetails.ISV,
      }

      await sendAzureData(snakeize(dataToSend), partnerId)
      await sendAzurePartnerData(snakeize(partnerDataToSend), partnerId)

      await dispatch(
        updateAppAlert({
          message: 'Connection Established Successfully',
          messageType: 'SUCCESS',
          autoClose: true,
        })
      )
    } catch (error) {
      const e = error as AxiosError
      const errorResponse = e.response as AxiosResponse<ErrorResponse>
      if (errorResponse && errorResponse.status === 409) {
        // if returns 409, it means that the Azure account was already created together with its auth flow
        return
      }
      await dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            (error as AxiosError).response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      await dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }

export const setAccountName = (name: string) =>
  ({
    type: AccountsActions.SET_ACCOUNT_NAME as AccountsActions.SET_ACCOUNT_NAME,
    payload: name,
  } as unknown as AnyAction)

export const setExternalAccountId = (externalAccountId: string) =>
  ({
    type: AccountsActions.SET_EXTERNAL_ACCOUNT_ID as AccountsActions.SET_EXTERNAL_ACCOUNT_ID,
    payload: externalAccountId,
  } as unknown as AnyAction)

export const setTemplateInputFields = (
  templateId: string,
  keyName: string,
  value: string
) => ({
  type: AccountsActions.SET_TEMPLATE_INPUT_FIELD as AccountsActions.SET_TEMPLATE_INPUT_FIELD,
  payload: {
    templateId,
    keyName,
    value,
  },
})
export const setStatus = (
  templateId: string,
  status: TestButtonStatus,
  keyName: string,
  error?: string
) => ({
  type: AccountsActions.SET_TEST_STATUS_ARN as AccountsActions.SET_TEST_STATUS_ARN,
  payload: {
    templateId,
    status,
    keyName,
    error,
  },
})

export const setAzureAccountDetails = (
  accountDetails: AzureAccountDetails & {
    cloudType?: string
    productId?: string
    organizationId?: string
  }
) => ({
  type: AccountsActions.SET_AZURE_ACCOUNT_DETAILS,
  payload: accountDetails,
})

export const getAzureAccountDetails =
  (partnerId: string, product: ProductType) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.AZURE_FETCHING_ACCOUNT_DETAILS))
    try {
      const azureAccountDetails = await fetchAzureAccountDetails(partnerId)
      const cloudAccountsResponse = await fetchOrganizationAccounts(
        partnerId,
        CloudType.AZURE,
        product
      )
      dispatch(
        actionTypeWrapper<ProductType>(
          product,
          setAzureAccountDetails({
            ...azureAccountDetails,
            id: cloudAccountsResponse.data.rows[0]?.id,
            status: cloudAccountsResponse.data.rows[0]?.status,
            organizationId: partnerId,
            fetchedForPartner: partnerId,
            terms: true,
            grantConsent: true,
          })
        )
      )
    } catch (error) {
      const e = error as AxiosError
      const errorResponse = e.response as AxiosResponse<ErrorResponse>
      if (errorResponse && errorResponse.status === 404) {
        // if the partner is not found, that just means that the customer has not saved the data yet
        // not an error to alert for.
        return
      }
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(errorResponse),
          messageType: 'ERROR',
          autoClose: false,
        })
      )
      errorLogger({ globalState: getState() })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.AZURE_FETCHING_ACCOUNT_DETAILS))
    }
  }

export const disconnectAzureAccount =
  (partnerId: string, product: ProductType, accountId: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.AZURE_DISCONNECTING_ACCOUNT))
    const azureAccountDetails =
      getState().accountForm.oppsync.accountDetails.AZURE
    try {
      const payload = { productId: product }
      await deactivateAzureAccount(partnerId, accountId, snakeize(payload))

      await dispatch(
        actionTypeWrapper<ProductType>(
          product,
          setAzureAccountDetails({
            ...azureAccountDetails,
            status: 'deactivated',
          })
        )
      )

      await dispatch(
        updateAppAlert({
          message: 'Disconnected account successfully',
          messageType: 'SUCCESS',
          autoClose: true,
        })
      )
    } catch (error) {
      const e = error as AxiosError
      const errorResponse = e.response as AxiosResponse<ErrorResponse>
      if (errorResponse && errorResponse.status === 404) {
        // if the partner is not found, that just means that the customer has not saved the data yet
        // not an error to alert for.
        return
      }
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(errorResponse),
          messageType: 'ERROR',
          autoClose: false,
        })
      )
      errorLogger({ globalState: getState() })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.AZURE_DISCONNECTING_ACCOUNT))
    }
  }
