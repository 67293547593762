import { snakeize } from 'casing'
import { isEmpty } from 'lodash'
import { ArrayReducerRow } from '../../../common/modules/arrayReducer'
import { extractData } from '../../../common/modules/arrayReducer/utils'
import {
  CRMConfigurationState,
  DefaultValues,
  PartnerCentralDetailsWithCloudProviders,
} from './reducer'

export const mapAwsCrmConfigurationForBackend = (config: {
  metadata: CRMConfigurationState
  partnerCentralDetails: PartnerCentralDetailsWithCloudProviders
  defaultValues: ArrayReducerRow<DefaultValues>[]
}) => {
  const extractedDefaultValues = extractData(config?.defaultValues || [])
  const defaultValuesAsObject = extractedDefaultValues.reduce((obj, item) => {
    const isNotEmpty = !isEmpty(item.defaultValueField) && !isEmpty(item.value)
    return {
      ...obj,
      ...(isNotEmpty && { [item.defaultValueField]: item.value }),
    }
  }, {})
  const labraFieldsHubspotFieldsMapping =
    config.metadata.hubspot.CRMFormData.AWS?.labraFieldsHubspotFieldsMapping
  return {
    crm_schema: {
      aws: {
        ...snakeize(config.metadata.hubspot.CRMFormData.AWS),
        partner_central_details: snakeize(
          extractData(config?.partnerCentralDetails?.AWS || [])
        ),
        labra_fields_default_values: defaultValuesAsObject,
        labra_fields_hubspot_fields_mapping: labraFieldsHubspotFieldsMapping,
      },
    },
  }
}
