import { TestButtonStatus } from '@labrav/react-components/lib/@types/components/TestButtonComponent'
import { Reducer } from 'redux'
import { AccountsActions } from './action'
import { InputsFields } from '@labrav/react-components/lib/@types/components/CFTemplateView'
import { isEmpty } from 'lodash'
import {
  IamArnRegex,
  awsCasS3BucketNameRegex,
  snsTopicARNPattern,
} from '../../../common/utils/constants'
import { FlyOutListingUserType } from '../../../admin/modules/flyOutSyncs/reducer'

export enum TypeOfAccount {
  ORGANIZATION_ROOT = 'ORGANIZATION_ROOT',
  ORGANIZATION_UNIT = 'ORGANIZATION_UNIT',
  ORGANIZATION_MEMBER = 'ORGANIZATION_MEMBER',
}
export interface TemplateRows {
  id: string
  name?: string
  version?: string
  hash: string
  productId: string
  isDefault?: boolean
  template: string
  description: string
  title?: string
  iamRoleArn?: string
  awsTemplateId?: string
  testStatus?: TestButtonStatus
  inputsFields: InputsFields[]
}

export interface AzureBackendAccountDetails {
  id?: string
  crm_id: string
  tenant_id?: string
  mpn_id: string
  company_name: string
  contact_email: string
  is_internal_account: boolean
  industry: string
  partner_type: string[]
  is_isv: boolean
}

export interface AzureAccountDetails {
  id?: string
  fetchedForPartner?: string
  name: string
  authUri?: string
  state?: string
  scopes?: string[]
  MPNID: string
  ISV: boolean
  grantConsent: boolean
  terms: boolean
  microsoftPartnerType: string[]
  industries: string
  status?: string
}

export interface AWSAccountDetails {
  externalAccountId?: string
  type?: string
  name?: string
  roles: {
    rows: TemplateRows[]
  }
  origin?: FlyOutListingUserType
  partnershipTier?: string
  partnershipTypes?: string
  industries?: string
  terms?: boolean
  isIsv?: boolean
  isSrrp?: boolean
  accelerateProgram?: boolean
}

export interface AccountsState {
  cloudType: string
  productId?: string
  organizationId?: string

  accountDetails: {
    AWS: AWSAccountDetails
    AZURE: AzureAccountDetails
    GCP: Record<string, unknown>
    REDHAT: Record<string, unknown>
  }
}
export interface GetUpdatedInputFieldErrorArgs {
  keyName: string
  value: string
}

export const getUpdatedInputFieldError = ({
  keyName,
  value,
}: GetUpdatedInputFieldErrorArgs) => {
  switch (keyName) {
    case 'awsRoleArn':
    case 'iamRoleArn': {
      if (isEmpty(value)) return 'ARN is required'
      else if (!IamArnRegex.test(value)) {
        return 'Please enter valid ARN'
      } else {
        return ''
      }
    }
    case 'awsCasS3BucketName': {
      if (isEmpty(value)) return 'S3 CAS Bucket Name is required'
      else if (!awsCasS3BucketNameRegex.test(value)) {
        return 'Please enter valid S3 CAS Bucket Name'
      } else {
        return ''
      }
    }
    case 'awsCasSnsTopicArn': {
      if (isEmpty(value)) return 'CAS SNS Topic ARN is required'
      else if (!snsTopicARNPattern.test(value)) {
        return 'Please enter valid CAS SNS Topic ARN'
      } else {
        return ''
      }
    }
    default: {
      return ''
    }
  }
}

export const initialState: AccountsState = {
  cloudType: '',
  productId: '',
  organizationId: '',
  accountDetails: {
    AWS: {
      externalAccountId: '',
      type: '',
      name: '',
      roles: {
        rows: [],
      },
    },
    AZURE: {
      name: '',
      ISV: false,
      grantConsent: false,
      MPNID: '',
      terms: false,
      microsoftPartnerType: [],
      industries: '',
    },
    GCP: {},
    REDHAT: {},
  },
}

type AccountOnBoardingRolesAndPartnershipType = {
  roles: TemplateRows[]
  partnershipTypes?: string
}

export interface SetAccountOnboardingDataPayload {
  cloudType: string
  organizationId: string
  productId: string
  externalId: string
  type: string
  name: string
  industries?: string
  partnershipTier?: string
  terms?: boolean
  isIsv: boolean
  isSrrp?: boolean
  accelerateProgram?: boolean
  origin?: FlyOutListingUserType
}

type actionType =
  | {
      type: AccountsActions.SET_ACCOUNT_ONBOARDING_DATA
      payload: SetAccountOnboardingDataPayload &
        AccountOnBoardingRolesAndPartnershipType
    }
  | {
      type: AccountsActions.SET_ACCOUNT_NAME
      payload: string
    }
  | {
      type: AccountsActions.SET_EXTERNAL_ACCOUNT_ID
      payload: string
    }
  | {
      type: AccountsActions.SET_TEMPLATE_INPUT_FIELD
      payload: {
        templateId: string
        keyName: string
        value: string
      }
    }
  | {
      type: AccountsActions.SET_TEST_STATUS_ARN
      payload: {
        templateId: string
        status: TestButtonStatus
        keyName: string
        error?: string
      }
    }
  | {
      type: AccountsActions.UPDATE_VALUE_ON_ACCOUNT_DETAILS_AZURE
      payload: {
        key: string
        value: unknown
      }
    }
  | {
      type: AccountsActions.SET_AZURE_ACCOUNT_DETAILS
      payload: AzureAccountDetails & {
        cloudType?: string
        productId?: string
        organizationId?: string
      }
    }
  | {
      type: AccountsActions.UPDATE_VALUE_ON_ACCOUNT_DETAILS_AWS
      payload: {
        key: string
        value: unknown
      }
    }

interface FindIndexOfInputFieldsArgs {
  inputFields: InputsFields[]
  keyName: string
}

export const findIndexOfInputFields = ({
  inputFields,
  keyName,
}: FindIndexOfInputFieldsArgs) => {
  return inputFields.findIndex(inputField => inputField.keyName === keyName)
}

export const reducer: Reducer<AccountsState, actionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case AccountsActions.SET_ACCOUNT_ONBOARDING_DATA: {
      const {
        cloudType,
        organizationId,
        productId,
        externalId,
        name,
        roles,
        partnershipTypes,
        partnershipTier,
        industries,
        terms,
        isIsv,
        isSrrp,
        accelerateProgram,
        origin,
      } = action.payload
      return {
        ...state,
        cloudType,
        organizationId,
        productId,
        accountDetails: {
          ...state.accountDetails,
          AWS: {
            ...state.accountDetails.AWS,
            externalAccountId: externalId || '',
            type: '',
            name: name || '',
            roles: { rows: roles },
            partnershipTier,
            partnershipTypes,
            industries,
            terms,
            isIsv,
            isSrrp,
            accelerateProgram,
            origin,
          },
        },
      }
    }
    case AccountsActions.SET_ACCOUNT_NAME: {
      return {
        ...state,
        accountDetails: {
          ...state.accountDetails,
          AWS: {
            ...state.accountDetails.AWS,
            name: action.payload,
          },
        },
      }
    }
    case AccountsActions.SET_EXTERNAL_ACCOUNT_ID: {
      return {
        ...state,
        accountDetails: {
          ...state.accountDetails,
          AWS: {
            ...state.accountDetails.AWS,
            externalAccountId: action.payload,
          },
        },
      }
    }

    case AccountsActions.SET_TEMPLATE_INPUT_FIELD: {
      const { templateId, keyName, value } = action.payload
      const newRows = state.accountDetails.AWS.roles.rows.map(row => {
        if (row.id === templateId) {
          const updateInputsFields = row.inputsFields
          const inputFieldIndex = findIndexOfInputFields({
            inputFields: row.inputsFields,
            keyName,
          })
          updateInputsFields[inputFieldIndex].value = value
          updateInputsFields[inputFieldIndex].error = getUpdatedInputFieldError(
            { keyName, value }
          )
          updateInputsFields[inputFieldIndex].testStatus =
            'standBy' as TestButtonStatus
          return {
            ...row,
            inputsFields: updateInputsFields,
          }
        }
        return row
      })
      return {
        ...state,
        accountDetails: {
          ...state.accountDetails,
          AWS: {
            ...state.accountDetails.AWS,
            roles: {
              rows: newRows,
            },
          },
        },
      }
    }
    case AccountsActions.SET_TEST_STATUS_ARN: {
      const { templateId, status, keyName, error } = action.payload
      const newRows = state.accountDetails.AWS.roles.rows.map(row => {
        if (row.id === templateId) {
          const updateInputsFields = row.inputsFields
          const inputFieldIndex = findIndexOfInputFields({
            inputFields: row.inputsFields,
            keyName,
          })
          updateInputsFields[inputFieldIndex].testStatus = status
          updateInputsFields[inputFieldIndex].error = error || ''
          return {
            ...row,
            inputsFields: updateInputsFields,
          }
        }
        return row
      })
      return {
        ...state,
        accountDetails: {
          ...state.accountDetails,
          AWS: {
            ...state.accountDetails.AWS,
            roles: {
              rows: newRows,
            },
          },
        },
      }
    }
    case AccountsActions.UPDATE_VALUE_ON_ACCOUNT_DETAILS_AZURE: {
      const { key, value } = action.payload
      return {
        ...state,
        accountDetails: {
          ...state.accountDetails,
          AZURE: {
            ...state.accountDetails.AZURE,
            [key]: value,
          },
        },
      }
    }
    case AccountsActions.UPDATE_VALUE_ON_ACCOUNT_DETAILS_AWS: {
      const { key, value } = action.payload
      return {
        ...state,
        accountDetails: {
          ...state.accountDetails,
          AWS: {
            ...state.accountDetails.AWS,
            [key]: value,
          },
        },
      }
    }
    case AccountsActions.SET_AZURE_ACCOUNT_DETAILS: {
      const accountDetails = action.payload
      return {
        ...state,
        cloudType: action.payload.cloudType || '',
        productId: action.payload.productId || '',
        organizationId: action.payload.organizationId || '',
        accountDetails: {
          ...state.accountDetails,
          AZURE: {
            ...state.accountDetails.AZURE,
            ...accountDetails,
          },
        },
      }
    }

    default: {
      return state
    }
  }
}
