import { Reducer } from 'redux'
import { NewCustomerActions } from './actions'
import { UserRoleForTable } from '../../../common/utils/roles'
import { CRMTypes } from '../../../common/utils/constants'

export interface Users {
  email: string
  role: UserRoleForTable
}
export type ProductType = 'OPPSYNC' | 'FLYOUT'
export type CloudProvidersType = 'AWS' | 'GCP' | 'AZURE'
export type PlanType = 'free_tier' | 'legacy'
export type SubscribeProducts = Record<
  ProductType,
  {
    cloudProviders: CloudProvidersType[]
    plan: PlanType
  }
>

export interface Customer {
  companyName?: string
  quotationLink: string
  internalNotes: string
  crmType?: CRMTypes | null
  numberOfSeats: number
  isInternal: boolean
  includeLabraBot: boolean
  users: Users[]
  subscribedProducts: Partial<SubscribeProducts>
}

export const initialState: Customer = {
  companyName: '',
  quotationLink: '',
  internalNotes: '',
  crmType: null,
  numberOfSeats: 100,
  isInternal: false,
  includeLabraBot: false,
  users: [
    {
      email: '',
      role: 'PARTNER_OWNER' as UserRoleForTable,
    },
  ],
  subscribedProducts: {},
}
type actionType =
  | {
      type: NewCustomerActions.UPDATE_NEW_CUSTOMER_FORM
      payload: Customer
    }
  | {
      type: NewCustomerActions.RESET_NEW_CUSTOMER_FORM
    }

export const reducer: Reducer<Customer, actionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case NewCustomerActions.UPDATE_NEW_CUSTOMER_FORM: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case NewCustomerActions.RESET_NEW_CUSTOMER_FORM: {
      return (state = initialState)
    }
    default: {
      return state
    }
  }
}
