import React, { Suspense, lazy, useEffect, useState } from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom'
import AuthWrapper from './components/AuthWrapper/AuthWrapper'
import { useAnalytics } from '../utils/Analytics'

import { serviceEnvironment } from '../utils/constants'
import { PageNotFound } from '../components/PageNotFound/PageNotFound'
import { OnboardingTerms } from './components/data/OnboardingTerms'
import { useFlag, useFlagValue } from '@labrav/flags'
import { LoadingLogo } from '@labrav/react-components'
import {
  useAuth0,
  Auth0Provider,
  CacheLocation,
  Auth0ProviderOptions,
} from '@auth0/auth0-react'
import {
  auth0Domain,
  auth0ClientID,
  JWTRegex,
  labraAuth0APIAudience,
} from '../utils/constants'
import LandingPageRouting from './components/LandingPage/LandingPageRouting'
import Auth0Register from './components/Auth0Redirects/Auth0Register'
import Auth0Logout from './components/Auth0Redirects/Auth0Logout'
import Auth0Callback from './components/Auth0Redirects/Auth0Callback'
import Auth0SAMLCallback from './components/Auth0Redirects/Auth0SAMLCallback'

const ExternalAppsRoutes = lazy(() => import('../../externalApps'))

const SecureRoutes = () => {
  const {
    isAuthenticated,
    loginWithRedirect,
    isLoading,
    getAccessTokenSilently,
  } = useAuth0()
  const { loading: isFlagsLoading, value: auth0Enabled } = useFlagValue('auth0')
  const [loading, setLoading] = useState(isFlagsLoading)

  const updateAccessToken = async () => {
    try {
      const token = await getAccessTokenSilently()
      localStorage.setItem('auth0-access-token', token)
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i)
        if (key) {
          if (key.includes('okta')) {
            localStorage.removeItem(key)
            i--
          }
        }
      }
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (auth0Enabled) {
      updateAccessToken()
    }
  }, [isAuthenticated, auth0Enabled])

  if (loading || isLoading || isFlagsLoading) {
    return <LoadingLogo />
  }
  if (!isAuthenticated) {
    loginWithRedirect({
      authorizationParams: {
        prompt: 'login',
      },
    })
  }

  return <LandingPageRouting />
}
export const Auth0SecuredApp = () => {
  const navigate = useNavigate()
  const onRedirectCallback = () => {
    setTimeout(() => navigate('/'), 3000)
  }

  const providerConfig: Auth0ProviderOptions = {
    domain: auth0Domain,
    clientId: auth0ClientID,
    authorizationParams: {
      audience: labraAuth0APIAudience,
      redirect_uri: window.location.origin + '/auth/callback',
    },
    onRedirectCallback: onRedirectCallback,
    cacheLocation: 'localstorage' as CacheLocation,
  }
  if (serviceEnvironment === 'prd') {
    useAnalytics()
  }
  const { flag: isReplaceAuthFlow, loading } = useFlag('replaceAuthFlow')
  if (loading) {
    return <></>
  }
  return (
    <Suspense fallback={<div></div>}>
      <Auth0Provider {...providerConfig}>
        <Routes>
          <Route path="/external-apps/*" element={<ExternalAppsRoutes />} />
          <Route path="/signin/*" element={<AuthWrapper />} />
          <Route path="/auth/register" element={<Auth0Register />} />
          <Route path="/auth/logout" element={<Auth0Logout />} />
          <Route path="/auth/callback" element={<Auth0Callback />} />
          <Route path="/auth/callbackSAML" element={<Auth0SAMLCallback />} />
          <Route path="/privacy" element={<OnboardingTerms />} />
          <Route path="/*" element={<SecureRoutes />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Auth0Provider>
    </Suspense>
  )
}
