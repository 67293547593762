import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxHeight: '50px',
    },
    close: {
      cursor: 'pointer',
      paddingBottom: 13,
      marginLeft: 10,
    },
    times: {
      textAlign: 'end',
    },
    appbar: {
      maxHeight: '50px',
    },
    appbarError: {
      backgroundColor: theme.palette.alerts.dark,
    },
    appbarInfo: {
      backgroundColor: theme.palette.alerts.main,
    },
    appbarSuccess: {
      backgroundColor: theme.palette.check.main,
    },
    appbarWarning: {
      backgroundColor: theme.palette.alerts.contrastText,
    },
    toolbarContent: {
      fontSize: '16px',
      paddingBottom: 13,
      color: 'white',
      flexGrow: 1,
      textAlign: 'center',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    buttons: {
      flexGrow: 1,
      textAlign: 'end',
    },
    icon: {
      marginRight: theme.spacing(2),
    },
  })
)
