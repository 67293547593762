import React from 'react'
import { Qna } from '../../../modules/faqDrawer/reducer'
import { useStyles } from './HelpContent.styles'
import { Link, Typography } from '@material-ui/core'
import { clsx } from 'clsx'
import DOMPurify from 'dompurify'
import { HelpContentData, HelpTextData } from '../HelpDrawer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons'

DOMPurify.addHook('afterSanitizeAttributes', function (node) {
  // set all elements owning target to target=_blank
  if ('target' in node) {
    node.setAttribute('target', '_blank')
    node.setAttribute('rel', 'noopener')
  }
})
export type HelpContentProps = {
  helpContent?: HelpContentData[]
}
export const HelpContent = React.memo(
  ({ helpContent = [] }: HelpContentProps) => {
    const classes = useStyles()
    return (
      <div className={classes.container} data-testid="content">
        {helpContent.map(help => (
          <HelpData helpData={help} />
        ))}
      </div>
    )
  }
)

export type HelpDataProps = {
  helpData: HelpContentData
}
export const HelpData = React.memo(({ helpData }: HelpDataProps) => {
  const classes = useStyles()
  const hasQuestion = !!helpData.question

  return (
    <>
      {hasQuestion ? (
        <div className={classes.qnaContainer}>
          <div data-testid="question">
            <Typography className={classes.questionText}>
              {helpData.question}
            </Typography>
          </div>
          {(helpData?.helpAnswer || []).map(data => (
            <Answers answers={data} />
          ))}
        </div>
      ) : (
        (helpData?.helpAnswer || []).map(data => (
          <Answers answers={data} hasNoQuestion={!hasQuestion} />
        ))
      )}
    </>
  )
})

export type AnswersProps = {
  answers: HelpTextData
  hasNoQuestion?: boolean
}
export const Answers = React.memo(
  ({ answers, hasNoQuestion }: AnswersProps) => {
    const classes = useStyles()
    const sanitizer = DOMPurify.sanitize
    switch (answers.type) {
      case 'text': {
        return (
          <div>
            <div
              data-testid="answer-text"
              className={clsx(classes.questionText, classes.answerText)}
              dangerouslySetInnerHTML={{
                __html: sanitizer(answers?.text || ''),
              }}
            />
          </div>
        )
      }
      case 'link': {
        return (
          <div>
            <Link
              target="_blank"
              className={classes.link}
              href={answers?.link || ''}
              data-testid="link"
            >
              <Typography
                className={clsx(
                  classes.questionText,
                  classes.answerText,
                  classes.linkText
                )}
              >
                {answers?.linkText || ''}
              </Typography>
            </Link>
          </div>
        )
      }
      case 'textWithLink': {
        return (
          <div className={hasNoQuestion ? classes.withoutQuestion : ''}>
            <Typography
              className={clsx(classes.questionText, classes.answerText)}
            >
              {answers.text}
              <Link
                target="_blank"
                className={classes.link}
                href={answers?.link || ''}
                data-testid="link"
              >
                <span
                  className={clsx(
                    classes.questionText,
                    classes.answerText,
                    classes.linkText
                  )}
                >
                  {answers.linkText}
                  <span className={classes.icon}>
                    <FontAwesomeIcon
                      icon={faExternalLink}
                      style={{ width: '12px' }}
                    />
                  </span>
                </span>
              </Link>
            </Typography>
          </div>
        )
      }
      default:
        return <></>
    }
  }
)
