import {
  ErrorCauses,
  ObjectSyncAttempt,
} from '../salesPipeline/partnerStats/reducer'
export enum dashboardChartsActionTypes {
  SET_CHARTS_DATA = 'SET_CHARTS_DATA',
}

export interface ChartsData {
  leads: ObjectSyncAttempt
  opportunities: ObjectSyncAttempt
  errorCauses: ErrorCauses
}

export const setChartsData = (stats: ChartsData) => ({
  type: dashboardChartsActionTypes.SET_CHARTS_DATA,
  payload: stats,
})
