import React from 'react'
import * as yup from 'yup'
import { useFormik } from 'formik'

import Divider from '@material-ui/core/Divider/Divider'
import Button from '@material-ui/core/Button/Button'
import TextField from '@material-ui/core/TextField/TextField'
import { useStyles } from './UserPasswordInfo.styles'
import { UserInfoProps } from '../UserBasicInfo/UserBasicInfo'
import { DeleteUser } from '../DeleteUser/DeleteUser'
import {
  AuthProvider,
  getUsers,
  hideUserSidePanel,
  updateUser,
} from '../../../modules/userList/action'
import { useDispatch, useSelector } from '../../../../store'
import { UserListState } from '../../../modules/userList/reducer'
import { useFlagValue } from '@labrav/flags'
import { ButtonV2 } from '@labrav/react-components'
import { useUserType } from '../../../../common/utils/Hooks/usePartnerData'

const passwordValidationSchema = yup.object({
  newPassword: yup
    .string()
    .min(8, 'Password should be of minimum 8 characters length')
    .matches(/[a-z]+/, 'Atleast one lowercase character')
    .matches(/[A-Z]+/, 'Atleast one uppercase character')
    .matches(
      /[@.$!%*?&#^]+/,
      'Atleast one special character (@, $, !, . , %, *, ?, &, #, ^)'
    )
    .matches(/\d+/, 'Atleast one number'),

  confirmPassword: yup
    .string()
    .min(8, 'Password should be of minimum 8 characters length')
    .matches(/[a-z]+/, 'Atleast one lowercase character')
    .matches(/[A-Z]+/, 'Atleast one uppercase character')
    .matches(
      /[@.$!%*?&#^]+/,
      'Atleast one special character (@, $, !, . , %, *, ?, &, #, ^)'
    )
    .matches(/\d+/, 'Atleast one number')
    .test(
      'check confirm passowrd',
      'Your confirmation password should be same as new password',
      function (value) {
        return this.parent.newPassword === value ? true : false
      }
    ),
})

export const UserPasswordInfo: React.FC<UserInfoProps> = ({ user }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const partnerType = useUserType()
  const { loading: isFlagsLoading, value: auth0Enabled } = useFlagValue('auth0')
  const partnerId = useSelector<string | undefined>(
    state => state.PartnerData.user.partnerData?.partnerId
  )
  const { userStateForBE, pageNumber, pageSize } = useSelector<UserListState>(
    state => state.userList[partnerType]
  )

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: passwordValidationSchema,
    onSubmit: async values => {
      if (partnerId) {
        await dispatch(
          updateUser(
            partnerId,
            user.id,
            {
              ...userStateForBE,
              password: values.newPassword,
            },
            auth0Enabled ? AuthProvider.AUTH0 : AuthProvider.OKTA
          )
        )
        formik.setFieldValue('newPassword', '')
        formik.setFieldValue('confirmPassword', '')
        await dispatch(
          getUsers({
            partnerId,
            pageNumber,
            pageSize,
            authProvider: auth0Enabled ? AuthProvider.AUTH0 : AuthProvider.OKTA,
          })
        )
      }
    },
  })

  if (isFlagsLoading) return <></>

  return (
    <div data-testid="userPassword" className={classes.root}>
      <form onSubmit={formik.handleSubmit}>
        {auth0Enabled ? null : (
          <>
            <p className={classes.header}>PASSWORD</p>
            <div className={classes.passwordContainer}>
              <TextField
                id="outlined-password-input"
                name="newPassword"
                label="Create new password"
                type="password"
                value={formik.values.newPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.newPassword &&
                  Boolean(formik.errors.newPassword)
                }
                helperText={
                  formik.touched.newPassword && formik.errors.newPassword
                }
                variant="standard"
                inputProps={{ 'data-testid': 'newPassword' }}
              />
            </div>
            <div className={classes.passwordContainer}>
              <TextField
                id="outlined-password-input"
                name="confirmPassword"
                label="Confirm password"
                type="password"
                value={formik.values.confirmPassword}
                error={
                  formik.touched.confirmPassword &&
                  Boolean(formik.errors.confirmPassword)
                }
                helperText={
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                variant="standard"
                inputProps={{ 'data-testid': 'confirmPassword' }}
              />
            </div>
          </>
        )}

        <Divider className={classes.passwordDivider} />
        <div className={classes.btnsContainer}>
          <ButtonV2
            styleType="primary"
            disabled={!formik.isValid}
            type="submit"
            variant="contained"
            className={classes.saveBtn}
            data-testid="saveBtn"
            disableElevation
          >
            Save
          </ButtonV2>
          <ButtonV2
            styleType="outlined"
            variant="contained"
            className={classes.btnCancel}
            data-testid="cancelBtn"
            disableElevation
            onClick={() => dispatch(hideUserSidePanel())}
          >
            Cancel
          </ButtonV2>
          <div className={classes.deleteUser}>
            <DeleteUser user={user} />
          </div>
        </div>
      </form>
    </div>
  )
}
