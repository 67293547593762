import { useAuth0 } from '@auth0/auth0-react'
import { LoadingLogo } from '@labrav/react-components'
import { useEffect } from 'react'

const Auth0Logout = () => {
  const { logout } = useAuth0()

  useEffect(() => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    })
  }, [])

  return <LoadingLogo />
}

export default Auth0Logout
