import { actionTypeWrapper } from '../../../utils/actionTypeWrapper'

export interface Validate {
  key: {
    touched: boolean
    error: boolean
  }
  table: {
    touched: boolean
    error: boolean
  }
  value: {
    touched: boolean
    error: boolean
  }
}
export enum ValidateActions {
  SET_VALIDATIONS = 'SET_VALIDATIONS',
}

export const updateValidations = (
  objectType: string,
  key: string,
  touched: boolean,
  error: boolean
) => actionTypeWrapper(objectType, setValidations(key, touched, error))

export const setValidations = (
  key: string,
  touched: boolean,
  error: boolean
) => ({
  type: ValidateActions.SET_VALIDATIONS as ValidateActions.SET_VALIDATIONS,
  payload: { key: key, touched: touched, error: error },
})
