import { DateTime } from 'luxon'
import { Reducer } from 'redux'
import {
  AzureOfferPlan,
  AzurePriceInputOption,
  Dimension,
  Installments,
  OfferRecipients,
  PriceTypes,
  PrivateOffer,
  RecurrentPriceModeType,
} from '../reducer'
import {
  KeyValue,
  PrivateOfferCreationActionTypes,
  initialPricesObject,
} from './actions'
import { filter, get, includes, isEmpty, isNil, lowerCase } from 'lodash'
import { checkEmailAndPhone } from '../../../../common/utils/validateFields/ValidateFields'
import { isStringOfLength } from '../../../../common/utils/helperFunctions'
import { checkIsEmptyAndLength } from '../../../../common/utils/checkIsEmptyAndLength'
import { isNonEmptyNumber } from '../../../../common/utils/validateMinMax'
import {
  initialSpaceRegex,
  offerNameInvalidErrMsg,
  resaleAuthNameValidationRegex,
  specCharRegEx,
} from '../../../../common/utils/constants'

import { cloneDeep } from 'lodash'
import {
  ProductPlan,
  CloudMarketplace,
  ProductDimension,
  AzurePlanDimension,
} from '../../productsListing/reducer'

export enum PrivateOfferFields {
  DIMENSIONS = 'dimensions',
  INSTALLMENTS = 'installments',
  OFFER_RECIPIENTS = 'offerRecipients',
  PLANS = 'plans',
}
export type PrivateOfferErrors = {
  errors: Record<string, unknown>
  hasBeenSent: boolean
  noOfTimesSaved: number
}
export type OffersWithErrors = PrivateOffer & PrivateOfferErrors
export interface PrivateOfferObjectInCreation {
  [x: string]: OffersWithErrors
}
export interface PrivateOffersInCreation {
  currentPrivateOfferInCreation: PrivateOfferObjectInCreation
}
const initialState: PrivateOffersInCreation = {
  currentPrivateOfferInCreation: {},
}
export interface getInstallmentErrorsProps {
  installments: Installments[]
  installmentErrors: Array<Record<string, unknown>>
  offerExpirationDate: string
  agreementStartDate?: string
  serviceOrDurationLength?: number
  isResale?: boolean
}

export const convertProductPlanPricingToOffer = (productPlan: ProductPlan) => {
  let customMetersObject = {}
  const productsMeters = (productPlan?.dimensions as AzurePlanDimension)?.meters
  productsMeters.forEach(
    ({ cloudDimensionId, ...remaingProductsMeterData }) => {
      customMetersObject = {
        ...customMetersObject,
        [cloudDimensionId]: {
          ...remaingProductsMeterData,
        },
      }
    }
  )

  const recurrentPriceModeValue =
    isNonEmptyNumber(productPlan?.minQuantity) &&
    isNonEmptyNumber(productPlan?.maxQuantity)
      ? RecurrentPriceModeType.PERUSER
      : RecurrentPriceModeType.FLATRATE
  const meters = { ...customMetersObject }
  return {
    recurrentPrice: {
      recurrentPriceMode: recurrentPriceModeValue,
      priceInputOption: AzurePriceInputOption.USD,
      userLimits: {
        min: productPlan?.minQuantity,
        max: productPlan?.maxQuantity,
        minEnable: true,
        maxEnable: true,
      },
      prices: productPlan?.pricing,
    },
    customMeters: {
      meters: { ...meters },
    },
  }
}

export const getMinMaxDateAzureOffer = ({ timeZone }: { timeZone: string }) => {
  const currentDate = DateTime.local({ zone: timeZone }).endOf('day')
  const validMinDate =
    currentDate.day === 1
      ? currentDate
      : currentDate.plus({ months: 1 }).startOf('month')
  const validMaxDate = validMinDate
    .plus({ years: 20 })
    .endOf('year')
    .toFormat('yyyy-MM-dd')
    .toLocaleString()
  const validMaxEndDate = currentDate
    .plus({ years: 20 })
    .endOf('year')
    .toFormat('yyyy-MM-dd')
    .toLocaleString()
  return {
    minDate: validMinDate.toFormat('yyyy-MM-dd').toLocaleString(),
    maxDate: validMaxDate,
    minEndDate: currentDate
      .endOf('month')
      .toFormat('yyyy-MM-dd')
      .toLocaleString(),
    maxEndDate: validMaxEndDate,
  }
}

export const getMinMaxDateAwsOffer = ({ timeZone }: { timeZone: string }) => {
  const currentDate = DateTime.local({ zone: timeZone })
  const validMaxDate = currentDate
    .plus({ years: 3 })
    .toFormat('yyyy-MM-dd')
    .toLocaleString()
  const validMaxEndDate = currentDate
    .plus({ years: 3 })
    .toFormat('yyyy-MM-dd')
    .toLocaleString()
  return {
    minDate: currentDate.toFormat('yyyy-MM-dd').toLocaleString(),
    maxDate: validMaxDate,
    minEndDate: currentDate.toFormat('yyyy-MM-dd').toLocaleString(),
    maxEndDate: validMaxEndDate,
  }
}

export const getInstallmentErrors = ({
  installments,
  offerExpirationDate,
  agreementStartDate,
  installmentErrors,
  serviceOrDurationLength,
  isResale = false,
}: getInstallmentErrorsProps) => {
  const paymentDates = installments.reduce(
    (acc, { paymentDate }) => acc.concat(paymentDate as string),
    [] as string[]
  )
  const duplicatePaymentDates = filter(paymentDates, (val, i, iteratee) =>
    includes(iteratee, val, i + 1)
  )

  let sameAsExpirationDatelength = 0
  return installments.map((installment, index) => {
    const installmentError = {
      ...installmentErrors[index],
    }
    let paymentDateErrMsg = ''
    if (
      duplicatePaymentDates.includes(installment.paymentDate) &&
      installment.paymentDate !== null &&
      !isEmpty(installment.paymentDate)
    ) {
      paymentDateErrMsg = 'Duplicate installment dates are not allowed.'
    }
    if (!isEmpty(offerExpirationDate) && installment.paymentDate !== null) {
      if (
        DateTime.fromISO(installment.paymentDate) <=
        DateTime.fromISO(offerExpirationDate)
      ) {
        if (sameAsExpirationDatelength !== 0 && !isResale) {
          paymentDateErrMsg =
            'Only one payment date is allowed before offer expiration date.'
        }

        if (isResale) {
          paymentDateErrMsg =
            'Payment date is not allowed before authorization end date.'
        }
        sameAsExpirationDatelength += 1
      }
    }
    if (
      isNonEmptyNumber(serviceOrDurationLength) &&
      installment.paymentDate !== null &&
      !isNil(agreementStartDate)
    ) {
      const agreementEndDate = DateTime.fromISO(
        agreementStartDate as string
      ).plus({ month: serviceOrDurationLength })
      const paymentDate = DateTime.fromISO(installment.paymentDate)
      if (paymentDate > agreementEndDate) {
        paymentDateErrMsg = 'Payment date should be before service end date.'
      }
    }
    installmentError.paymentDate = paymentDateErrMsg
    return installmentError
  })
}
export const getOfferRecipientsError = (
  offerRecipients: OfferRecipients[],
  error: Record<string, unknown>,
  index: number,
  field?: string
) => {
  switch (field) {
    case 'firstName':
      isEmpty(offerRecipients[index].firstName)
        ? (error.firstName = 'First Name cannot be empty.')
        : (error.firstName = '')
      break
    case 'lastName':
      isEmpty(offerRecipients[index].lastName)
        ? (error.lastName = 'Last Name cannot be empty.')
        : (error.lastName = '')
      break
    case 'email':
      {
        if (isEmpty(offerRecipients[index].email)) {
          error.email = 'Email cannot be empty.'
        } else {
          const { errMess } = checkEmailAndPhone(
            'email',
            offerRecipients[index].email
          )
          error.email = errMess
        }
      }
      break
    default:
      break
  }
  return error
}
export const getDimensionError = (
  dimensions: Dimension[],
  error: Record<string, unknown>,
  index: number,
  field?: string,
  isResale = false,
  standardDimensions?: ProductDimension[]
) => {
  switch (field) {
    case 'quantity':
      {
        const quantity = dimensions[index]?.quantity || NaN
        if (isNaN(quantity)) {
          error.quantity = 'Quantity cannot be empty.'
        } else {
          error.quantity = Number(quantity) === 0 ? 'Quantity cannot be 0.' : ''
        }
      }
      break
    case 'price':
    case 'price1m':
    case 'price12m':
    case 'price24m':
    case 'price36m':
    case 'additionalUsagePrice':
    case 'subscriptionPrice':
      {
        let price
        if (isResale && field !== 'price') {
          const prices =
            (get(dimensions, `[${index}].prices`, {}) as PriceTypes) ||
            ({} as PriceTypes)
          price = prices[field] || NaN
        } else {
          price = get(dimensions, `[${index}].price`, '') || NaN
        }
        if (isNaN(price)) {
          error[field] = 'Price cannot be empty.'
          error.quantity = ''
        } else {
          error[field] = ''
          error.quantity = ''
        }
      }
      break
    case 'name':
      {
        const dimensionName = dimensions[index].name || ''
        const stdDimensionsNames =
          standardDimensions?.map(stdDim => stdDim.name) || []
        const currentDimensionNames =
          dimensions
            .filter((dim, id) => dim.type === 'custom' && index != id)
            .map(dim => dim?.name || '') || []
        const dimensionNames = stdDimensionsNames.concat(currentDimensionNames)
        error.name = ''
        if (dimensionName?.length == 0 || dimensionName?.length > 16) {
          error.name =
            'API name can not be empty and can contain upto 16 characters.'
        } else if (specCharRegEx.test(dimensionName || '')) {
          error.name =
            'API name can only contain _ no other special characters allowed.'
        } else {
          const dim_exists = dimensionNames.includes(dimensionName)
          error.name = dim_exists ? 'API name already exists.' : ''
        }
      }
      break
    case 'label':
      {
        error.label = checkIsEmptyAndLength(
          'Label',
          dimensions[index].label || '',
          24
        )
      }
      break
    case 'labelDescription':
      {
        error.labelDescription = checkIsEmptyAndLength(
          'Description',
          dimensions[index].labelDescription || '',
          70
        )
      }
      break
    default:
      break
  }
  return error
}

export const getUpdatedErrors = (
  key: string,
  privateOffer: PrivateOffer,
  errors: Record<string, unknown>,
  cloudType: CloudMarketplace,
  standardDimensions?: ProductDimension[],
  field?: string,
  index?: number,
  timeZone?: string
) => {
  const errorsCopy = cloneDeep(errors)
  switch (key) {
    case 'companyName': {
      const companyName = privateOffer.companyName
      errorsCopy.companyName = isEmpty(companyName)
        ? 'Name cannot be empty.'
        : ''
      return errorsCopy
    }
    case 'preparedBy': {
      const preparedBy = privateOffer.preparedBy
      if (isEmpty(preparedBy)) {
        errorsCopy.preparedBy = 'Prepared by cannot be empty.'
      } else {
        const { errMess } = checkEmailAndPhone('email', preparedBy!)
        errorsCopy.preparedBy = errMess
      }
      return errorsCopy
    }
    case 'awsAccountId': {
      const awsAccountId = privateOffer.awsAccountId
      const awsAccountIdString = awsAccountId as string
      errorsCopy.awsAccountId = isEmpty(awsAccountId)
        ? 'AWS Account ID cannot be empty.'
        : !/^\d+$/.test(awsAccountIdString)
        ? 'AWS Account ID must contain only digits.'
        : !isStringOfLength(12, awsAccountIdString)
        ? 'Please enter a 12 digit account ID.'
        : ''
      return errorsCopy
    }
    case 'billingAccountId': {
      const billingAccountId = privateOffer.billingAccountId
      errorsCopy.billingAccountId = isEmpty(billingAccountId)
        ? 'Azure billing account ID cannot be empty.'
        : ''
      return errorsCopy
    }
    case 'emailCustomMessage': {
      const emailCustomMessage = privateOffer.emailCustomMessage
      errorsCopy.emailCustomMessage = isEmpty(emailCustomMessage)
        ? 'Message cannot be empty.'
        : ''
      return errorsCopy
    }
    case 'privateOfferName': {
      const privateOfferName = privateOffer.privateOfferName
      errorsCopy.privateOfferName = validateOfferResaleName(
        privateOfferName,
        false,
        150
      )
      return errorsCopy
    }
    case 'serviceLength':
    case 'installments':
    case 'subscriptionEndDate':
    case 'offerStartDate':
    case 'acceptBy':
    case 'variableStartDate':
    case 'agreementStartDate':
    case 'offerExpirationDate': {
      const installments = privateOffer.installments
      const errorsInstallment = errorsCopy.installments as Array<
        Record<string, unknown>
      >
      if (cloudType === 'AWS') {
        if (
          field === 'paymentDate' ||
          key === 'offerExpirationDate' ||
          key === 'agreementStartDate' ||
          key === 'variableStartDate' ||
          key === 'serviceLength'
        ) {
          if (installments && !isEmpty(installments)) {
            errorsCopy.installments = getInstallmentErrors({
              installments: installments,
              installmentErrors: errorsInstallment,
              offerExpirationDate: privateOffer.offerExpirationDate,
              agreementStartDate: privateOffer.agreementStartDate,
              serviceOrDurationLength: privateOffer.serviceLength,
              isResale: false,
            })
          }
          if (key === 'serviceLength') {
            errorsCopy.serviceLength = getServiceLengthOrDurationError({
              lengthValue: privateOffer?.serviceLength,
              errKeyText: 'Service length',
            })
          }
        } else if (field === 'amount' && installments) {
          const finalIndex = index || 0
          const getInstallmentAmountErrorParam = installments[finalIndex].amount
            ? { amountValue: installments[finalIndex].amount as number }
            : {}
          errorsInstallment[finalIndex].amount = getInstallmentAmountError(
            getInstallmentAmountErrorParam
          )
          errorsCopy.installments = errorsInstallment
        }
        if (key === 'subscriptionEndDate' || key === 'offerExpirationDate') {
          if (
            !isEmpty(privateOffer.subscriptionEndDate) &&
            !isEmpty(privateOffer.offerExpirationDate) &&
            privateOffer.subscriptionEndDate
          ) {
            if (
              DateTime.fromISO(privateOffer.subscriptionEndDate) <=
              DateTime.fromISO(privateOffer.offerExpirationDate)
            ) {
              errorsCopy.subscriptionEndDate =
                'Subscription end date cannot be smaller than or equal to offer expiration date.'
            } else {
              errorsCopy.subscriptionEndDate = ''
            }
          } else {
            errorsCopy.subscriptionEndDate = ''
          }
        }
        if (key === 'agreementStartDate' || key === 'offerExpirationDate') {
          if (
            !isNil(privateOffer?.agreementStartDate) &&
            !isEmpty(privateOffer?.agreementStartDate) &&
            !isEmpty(privateOffer?.offerExpirationDate)
          ) {
            if (
              DateTime.fromISO(privateOffer?.agreementStartDate) <=
              DateTime.fromISO(privateOffer.offerExpirationDate)
            ) {
              errorsCopy.agreementStartDate =
                'Start date cannot be before offer expiration date.'
            } else {
              errorsCopy.agreementStartDate = ''
            }
          }
        }
      } else if (cloudType === 'AZURE') {
        if (
          key === 'offerStartDate' ||
          key === 'offerExpirationDate' ||
          key === 'acceptBy' ||
          key === 'variableStartDate'
        ) {
          return getAzureDatesError({
            errorsCopy,
            privateOffer,
            timeZone: timeZone!,
          })
        }
      }
      return errorsCopy
    }
    case 'dimensions': {
      const dimensions = privateOffer.dimensions
      const dimensionsErrors = errorsCopy.dimensions as Array<
        Record<string, unknown>
      >
      if (dimensions && !isEmpty(dimensions)) {
        const finalIndex = index || 0
        const dimensionError = { ...dimensionsErrors[finalIndex] }
        const error = getDimensionError(
          dimensions,
          { ...dimensionError },
          finalIndex,
          field,
          false,
          standardDimensions
        )
        dimensionsErrors[finalIndex] = error
        errorsCopy.dimensions = [...dimensionsErrors]
      }
      return errorsCopy
    }
    case 'offerRecipients': {
      const offerRecipients = privateOffer.offerRecipients
      const offerRecipientsErrors = errorsCopy.offerRecipients as Array<
        Record<string, unknown>
      >
      if (!isEmpty(offerRecipients)) {
        const finalIndex = index || 0
        const offerRecipientError = offerRecipientsErrors[finalIndex]
        const error = getOfferRecipientsError(
          offerRecipients,
          { ...offerRecipientError },
          finalIndex,
          field
        )
        offerRecipientsErrors[finalIndex] = error
        errorsCopy.offerRecipients = [...offerRecipientsErrors]
      }
      return errorsCopy
    }
    case 'metaData': {
      const metaData = privateOffer.metaData as unknown as Array<
        Record<string, string>
      >

      return getMetadataError({ metaData, errors: errorsCopy, field, index })
    }
    default:
      return errorsCopy
  }
}

export interface getPlansErrorProps {
  plans: AzureOfferPlan[]
  errors: Record<string, unknown>[]
  field?: string
  index?: number
  subIndex?: number
  meter?: string
}

export const getPlansError = ({
  plans,
  errors: errorsCopy,
  field,
  index,
  subIndex,
  meter,
}: getPlansErrorProps) => {
  const plansErrors = [...errorsCopy]
  const finalIndex = index || 0
  const plan = plans[finalIndex]
  const planErr = { ...plansErrors[finalIndex] }
  if (!isEmpty(plans) && field) {
    if (field === 'discountPercentage') {
      planErr.discountPercentage = planErr.discountPercentage =
        !isNonEmptyNumber(plan.discountPercentage)
          ? 'Discount percantage cannot be empty.'
          : ''
    } else if (
      (field === 'quantity' || field === 'isInfinite') &&
      meter &&
      subIndex !== undefined
    ) {
      const includedQuantitieObj =
        plan?.pricing?.customMeters?.meters[meter]?.includedQuantities[subIndex]
      const updatedIncludedQuantitiesErr = [
        ...get(planErr.meters, meter).includedQuantities,
      ]
      updatedIncludedQuantitiesErr[subIndex].quantity =
        isNonEmptyNumber(includedQuantitieObj?.quantity) ||
        field === 'isInfinite'
          ? ''
          : `Base quantity (${includedQuantitieObj?.billingTerm.value} ${includedQuantitieObj?.billingTerm.type}) cannot be empty.`
      planErr.meters = {
        ...(planErr.meters as Record<string, unknown>),
        [meter]: {
          ...get(planErr.meters, meter),
          includedQuantities: updatedIncludedQuantitiesErr,
        },
      }
    } else if (field === 'pricePerPaymentInUsd' && meter) {
      const planMeter = plan?.pricing?.customMeters?.meters[meter]
      planErr.meters = {
        ...(planErr.meters as Record<string, unknown>),
        [meter]: {
          ...get(planErr.meters, meter),
          pricePerPaymentInUsd: !isNonEmptyNumber(
            planMeter?.pricePerPaymentInUsd
          )
            ? 'Price per unit cannot be empty.'
            : '',
        },
      }
    } else if (
      field === 'min' ||
      field === 'max' ||
      field === 'minEnable' ||
      field === 'maxEnable'
    ) {
      const planUserLimitData = { ...plan?.pricing?.recurrentPrice.userLimits }
      const planOriginalUserLimitData = {
        ...plan?.originalPricing?.recurrentPrice.userLimits,
      }

      const userLimitErr = {
        ...(planErr.userLimits as Record<string, unknown>),
      }

      const minValue = planUserLimitData.minEnable
        ? planUserLimitData.min
        : planOriginalUserLimitData.min
      const maxValue = planUserLimitData.maxEnable
        ? planUserLimitData.max
        : planOriginalUserLimitData.max
      const isValidMin = isNonEmptyNumber(minValue)

      const isValidMax = isNonEmptyNumber(maxValue)

      let minErr = userLimitErr.min
      let maxErr = userLimitErr.max
      if (field === 'min') {
        minErr = !isValidMin ? 'Minimum users cannot be empty.' : ''
      } else if (field === 'max') {
        maxErr = !isValidMax ? 'Maximum users cannot be empty.' : ''
      }

      if (isValidMin && isValidMax) {
        minErr =
          minValue! >= maxValue! && planUserLimitData.minEnable
            ? 'Minimum users cannot be greater than or equal to maximum users.'
            : ''
        maxErr =
          minValue! >= maxValue! && planUserLimitData.maxEnable
            ? 'Maximum users cannot be less than or equal to minimum users.'
            : ''
      }

      planErr.userLimits = {
        ...(planErr.userLimits as Record<string, unknown>),
        min: minErr,
        max: maxErr,
      }
    } else if (field === 'paymentOption' && subIndex !== undefined) {
      const recurrentPrice = [...plan.pricing.recurrentPrice.prices][subIndex]
      const updatedPlanPriceErr = [
        ...(planErr.prices as Record<string, unknown>[]),
      ]
      updatedPlanPriceErr[subIndex].pricePerPaymentInUsd = isNonEmptyNumber(
        recurrentPrice.pricePerPaymentInUsd
      )
        ? ''
        : 'Price per payment cannot be empty.'
      planErr.prices = [...updatedPlanPriceErr]
    }

    plansErrors[finalIndex] = cloneDeep(planErr)
    errorsCopy = [...plansErrors]
  }
  return errorsCopy
}

export interface getAzureDatesErrorProps {
  privateOffer: PrivateOffer
  timeZone: string
  errorsCopy: Record<string, unknown>
}

export const getAzureDatesError = ({
  privateOffer,
  timeZone,
  errorsCopy,
}: getAzureDatesErrorProps) => {
  const { minDate, minEndDate } = getMinMaxDateAzureOffer({
    timeZone: timeZone,
  })
  const selectedStartDate = privateOffer?.offerStartDate
  const isVisibleStartDate =
    selectedStartDate && privateOffer?.variableStartDate === false
  const selectedOfferExpirationDate = privateOffer?.offerExpirationDate
  const selectedAcceptByDate = privateOffer?.acceptBy

  if (isVisibleStartDate) {
    const isStartDateValid =
      DateTime.fromISO(minDate) <= DateTime.fromISO(selectedStartDate)
    if (!isStartDateValid) {
      errorsCopy.offerStartDate = 'Specified month cannot be in past.'
    } else if (
      selectedAcceptByDate &&
      DateTime.fromISO(selectedStartDate) <=
        DateTime.fromISO(selectedAcceptByDate)
    ) {
      errorsCopy.offerStartDate =
        'Specified month cannot be smaller than or equal to offer expiration date.'
    } else {
      errorsCopy.offerStartDate = ''
    }
  } else {
    errorsCopy.offerStartDate = ''
  }

  if (selectedOfferExpirationDate) {
    const isValidExprirationDate =
      DateTime.fromISO(minEndDate) <=
      DateTime.fromISO(selectedOfferExpirationDate)
    if (!isValidExprirationDate) {
      errorsCopy.offerExpirationDate = 'End date cannot be in past.'
    } else if (
      selectedAcceptByDate &&
      DateTime.fromISO(selectedOfferExpirationDate) <=
        DateTime.fromISO(selectedAcceptByDate)
    ) {
      errorsCopy.offerExpirationDate =
        'End date cannot be smaller than or equal to offer expiration date.'
    } else if (
      isVisibleStartDate &&
      DateTime.fromISO(selectedOfferExpirationDate) <=
        DateTime.fromISO(selectedStartDate)
    ) {
      errorsCopy.offerExpirationDate =
        'End date cannot be smaller than or equal to offer start date.'
    } else {
      errorsCopy.offerExpirationDate = ''
    }
  }

  return errorsCopy
}

export interface getInstallmentAmountErrorProps {
  amountValue?: number
}

export const getInstallmentAmountError = ({
  amountValue,
}: getInstallmentAmountErrorProps) => {
  if (!isNonEmptyNumber(amountValue)) {
    return 'Amount cannot be empty.'
  } else if ((amountValue as number) <= 0) {
    return 'Amount cannot be 0.'
  } else {
    return ''
  }
}

export interface getServiceLengthOrDurationErrorProps {
  lengthValue?: number
  errKeyText: string
}

export const getServiceLengthOrDurationError = ({
  lengthValue,
  errKeyText,
}: getServiceLengthOrDurationErrorProps) => {
  if (!isNonEmptyNumber(lengthValue)) {
    return `${errKeyText} cannot be empty.`
  } else if (Number(lengthValue) > 60) {
    return `${errKeyText} cannot be greater than 60.`
  } else if (Number(lengthValue) === 0) {
    return `${errKeyText} cannot be 0.`
  } else {
    return ''
  }
}

export interface getMetadataErrorProps {
  metaData: Record<string, string>[]
  errors: Record<string, unknown>
  field?: string
  index?: number
}

export const getMetadataError = ({
  metaData,
  errors,
  field,
  index,
}: getMetadataErrorProps) => {
  const metaDataErrors = errors.metaData as Array<Record<string, unknown>>
  if (!isEmpty(metaData)) {
    const finalIndex = index || 0
    const metaDataError = { ...metaDataErrors[finalIndex] }
    if (field === 'key') {
      metaDataError.key = isEmpty(get(metaData, `${[finalIndex]}.key`, {}))
        ? 'Key cannot be empty.'
        : ''
    } else if (field === 'value') {
      metaDataError.value = isEmpty(get(metaData, `${[finalIndex]}.value`, {}))
        ? 'Value cannot be empty.'
        : ''
    }
    metaDataErrors[finalIndex] = { ...metaDataError }
    errors.metaData = [...metaDataErrors]
  }
  return errors
}

export const getEmptyPricesArray = (pricesArray: [keyof PriceTypes]) => {
  const prices: Record<string, null> = {}
  pricesArray.forEach(price => (prices[price] = null))
  return prices
}

export const getRowObjectForAField = (
  key: string,
  productId: string,
  type = 'standard',
  isResale = false,
  pricesArray = [] as unknown as [keyof PriceTypes]
) => {
  switch (key) {
    case 'offerRecipients' || 'agreementRecipients': {
      return {
        firstName: '',
        lastName: '',
        email: '',
        title: '',
      }
    }
    case 'installmentInfo':
    case 'installments': {
      return {
        paymentDate: null,
        amount: null,
        currency: 'USD',
        crmObjectId: '',
      }
    }
    case 'dimensions': {
      if (type === 'custom') {
        return {
          name: '',
          label: '',
          labelDescription: '',
          currency: 'USD',
          type: 'custom',
          awsProductId: productId,
          price: null,
          quantity: null,
          ...(isResale ? { prices: getEmptyPricesArray(pricesArray) } : {}),
        }
      } else {
        const newDimension = {
          dimensionId: '',
          currency: 'USD',
          quantity: null,
          type: 'standard',
          awsProductId: productId,
        }
        if (isResale) {
          return {
            ...newDimension,
            prices: getEmptyPricesArray(pricesArray),
          }
        }
        return {
          ...newDimension,
          price: null,
        }
      }
    }
    case 'metaData': {
      return {
        key: '',
        value: '',
        crmObjectId: '',
      }
    }
    case 'plans': {
      return {
        displayName: '',
        offerPricingType: 'saasNewCustomizedPlans',
        description: '',
        labraPlanId: '',
        discountPercentage: '',
        pricing: {
          recurrentPrice: {
            recurrentPriceMode: '',
            priceInputOption: 'usd',
            prices: [],
          },
        },
      }
    }
    default: {
      return void 0
    }
  }
}
export const getErrorObjectForAField = (
  key: string,
  type = 'standard',
  isResale = false,
  pricesArray = ['price']
) => {
  switch (key) {
    case 'installmentInfo':
    case 'installments': {
      return {
        paymentDate: ' ',
        amount: ' ',
      }
    }
    case 'dimensions': {
      if (type === 'custom') {
        return {
          name: '',
          labelDescription: '',
          price: '',
          quantity: '',
          ...(isResale ? initialPricesObject : {}),
        }
      } else {
        const dimensionError = {
          dimensionId: '',
          price: '',
          quantity: '',
        }
        if (isResale) {
          return { ...dimensionError, ...initialPricesObject }
        }
        return dimensionError
      }
    }
    case 'metaData': {
      return {
        key: '',
        value: '',
      }
    }
    case 'offerRecipients' || 'agreementRecipients': {
      return {
        firstName: '',
        lastName: '',
        email: '',
      }
    }
    case 'resellersInfo': {
      return []
    }
    case 'plans': {
      return {
        planId: '',
        discountPercentage: '',
        prices: [],
        meters: {},
      }
    }
    default: {
      return void 0
    }
  }
}
type actionType =
  | {
      type: PrivateOfferCreationActionTypes.SET_CREATE_PRIVATE_OFFERS_DATA
      payload: {
        productId: string
        privateOfferData: PrivateOffer
        noOfTimesSaved: number
        errors: Record<string, unknown>
      }
    }
  | {
      type: PrivateOfferCreationActionTypes.UPDATE_PRIVATE_OFFERS
      payload: { productId: string; privateOfferData: Record<string, string> }
    }
  | {
      type: PrivateOfferCreationActionTypes.ADD_ROWS_ON_PRIVATE_OFFER
      payload: { productId: string; key: string; type: string }
    }
  | {
      type: PrivateOfferCreationActionTypes.DELETE_ROWS_ON_PRIVATE_OFFER
      payload: { productId: string; key: string; index: number }
    }
  | {
      type: PrivateOfferCreationActionTypes.UPDATE_VALUES_OF_ARRAY_ON_PRIVATE_OFFER
      payload: {
        productId: string
        key: string
        index: number
        privateOfferData: KeyValue
      }
    }
  | {
      type: PrivateOfferCreationActionTypes.UPDATE_PRIVATE_OFFER_CREATION_ERRORS
      payload: {
        productId: string
        key: string
        cloudType: CloudMarketplace
        index?: number
        field?: string
        timeZone?: string
        standardDimensions?: ProductDimension[]
      }
    }
  | {
      type: PrivateOfferCreationActionTypes.ADD_EULA_FILES_ON_PRIVATE_OFFERS
      payload: {
        productId: string
        eulaFiles: string[]
      }
    }
  | {
      type: PrivateOfferCreationActionTypes.UPDTE_PRIVATE_OFFER_HAS_BEEN_SENT
      payload: { hasBeenSent: boolean; productId: string }
    }
  | {
      type: PrivateOfferCreationActionTypes.CLEAR_PRIVATE_OFFER_IN_CREATION
      payload: string
    }
  | {
      type: PrivateOfferCreationActionTypes.UPDATE_STANDARD_DIMENSIONS_ON_PRIVATE_OFFER
      payload: { index: number; productId: string; dimension: Dimension }
    }
  | {
      type: PrivateOfferCreationActionTypes.UPDATE_NUMBER_OF_TIMES_SAVED_ON_PRIVATE_OFFER
      payload: string
    }
  | {
      type: PrivateOfferCreationActionTypes.UPDATE_PRODUCT_PLAN_ON_PRIVATE_OFFER
      payload: {
        index: number
        productId: string
        plan: AzureOfferPlan
        keyForErrorCheck?: string
        subIndex?: number
        meter?: string
      }
    }
  | {
      type: PrivateOfferCreationActionTypes.UPDATE_PRIVATE_OFFER_SINGLE_FIELD_ERROR
      payload: {
        productId: string
        key: string
        errMsg: string
      }
    }
export const reducer: Reducer<PrivateOffersInCreation, actionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case PrivateOfferCreationActionTypes.SET_CREATE_PRIVATE_OFFERS_DATA: {
      const { productId, privateOfferData, errors, noOfTimesSaved } =
        action.payload
      return {
        ...state,
        currentPrivateOfferInCreation: {
          ...state.currentPrivateOfferInCreation,
          [productId]: {
            ...privateOfferData,
            errors: {
              ...errors,
            },
            hasBeenSent: false,
            noOfTimesSaved: noOfTimesSaved,
          },
        },
      }
    }
    case PrivateOfferCreationActionTypes.UPDATE_PRIVATE_OFFERS: {
      const { productId, privateOfferData } = action.payload
      const { key, value } = privateOfferData
      return {
        ...state,
        currentPrivateOfferInCreation: {
          ...state.currentPrivateOfferInCreation,
          [productId]: {
            ...state.currentPrivateOfferInCreation[productId],
            [key]: value,
          },
        },
      }
    }
    case PrivateOfferCreationActionTypes.ADD_ROWS_ON_PRIVATE_OFFER: {
      const { productId, key, type } = action.payload
      const privateOffer = state.currentPrivateOfferInCreation[
        productId
      ] as unknown as Record<string, unknown>
      const updatedArray = [
        ...(privateOffer[key] as unknown as Array<Record<string, unknown>>),
      ]
      const errors = privateOffer.errors as unknown as Record<string, unknown>
      const updatedErrorsArray = [
        ...(errors[key] as unknown as Array<Record<string, unknown>>),
      ]
      const objectRowToBeAdded = getRowObjectForAField(key, productId, type)
      const objectErrorToBeAdded = getErrorObjectForAField(key, type)
      updatedArray.push({ ...objectRowToBeAdded })
      updatedErrorsArray.push({ ...objectErrorToBeAdded })
      return {
        ...state,
        currentPrivateOfferInCreation: {
          ...state.currentPrivateOfferInCreation,
          [productId]: {
            ...state.currentPrivateOfferInCreation[productId],
            [key]: updatedArray,
            errors: {
              ...state.currentPrivateOfferInCreation[productId].errors,
              [key]: updatedErrorsArray,
            },
          },
        },
      }
    }
    case PrivateOfferCreationActionTypes.DELETE_ROWS_ON_PRIVATE_OFFER: {
      const { productId, key, index } = action.payload
      const privateOffer = state.currentPrivateOfferInCreation[
        productId
      ] as unknown as PrivateOfferObjectInCreation
      const updatedArray = [
        ...(privateOffer[key] as unknown as Array<Record<string, unknown>>),
      ]
      updatedArray.splice(index, 1)
      const errors = privateOffer.errors as unknown as Record<string, unknown>
      const updatedErrorsArray = [
        ...(errors[key] as unknown as Array<Record<string, unknown>>),
      ]
      updatedErrorsArray.splice(index, 1)
      return {
        ...state,
        currentPrivateOfferInCreation: {
          ...state.currentPrivateOfferInCreation,
          [productId]: {
            ...state.currentPrivateOfferInCreation[productId],
            [key]: updatedArray,
            errors: {
              ...state.currentPrivateOfferInCreation[productId].errors,
              [key]: updatedErrorsArray,
            },
          },
        },
      }
    }
    case PrivateOfferCreationActionTypes.UPDATE_VALUES_OF_ARRAY_ON_PRIVATE_OFFER: {
      const { productId, key, index, privateOfferData } = action.payload
      const privateOffer = state.currentPrivateOfferInCreation[
        productId
      ] as unknown as Record<string, unknown>
      const updatedArray = cloneDeep([
        ...(privateOffer[key] as unknown as Array<Record<string, unknown>>),
      ])
      const updatedObject = updatedArray[index] as Record<string, unknown>
      updatedObject[privateOfferData.key] = privateOfferData.value
      updatedArray[index] = { ...updatedObject }
      return {
        ...state,
        currentPrivateOfferInCreation: {
          ...state.currentPrivateOfferInCreation,
          [productId]: {
            ...state.currentPrivateOfferInCreation[productId],
            [key]: updatedArray,
          },
        },
      }
    }
    case PrivateOfferCreationActionTypes.ADD_EULA_FILES_ON_PRIVATE_OFFERS: {
      const { productId, eulaFiles } = action.payload
      let updatedEulaFiles =
        state.currentPrivateOfferInCreation[productId].eulaFile
      updatedEulaFiles =
        updatedEulaFiles !== null && !isEmpty(updatedEulaFiles)
          ? updatedEulaFiles
          : []
      updatedEulaFiles?.push(...eulaFiles)
      return {
        ...state,
        currentPrivateOfferInCreation: {
          ...state.currentPrivateOfferInCreation,
          [productId]: {
            ...state.currentPrivateOfferInCreation[productId],
            eulaFile: updatedEulaFiles,
          },
        },
      }
    }
    case PrivateOfferCreationActionTypes.UPDATE_PRIVATE_OFFER_CREATION_ERRORS: {
      const {
        key,
        productId,
        cloudType,
        index,
        field,
        timeZone,
        standardDimensions,
      } = action.payload
      const { errors, ...privateOffer } =
        state.currentPrivateOfferInCreation[productId]
      const updatedErrors = getUpdatedErrors(
        key,
        { ...privateOffer },
        { ...errors },
        cloudType,
        standardDimensions,
        field,
        index,
        timeZone
      )
      return {
        ...state,
        currentPrivateOfferInCreation: {
          ...state.currentPrivateOfferInCreation,
          [productId]: {
            ...state.currentPrivateOfferInCreation[productId],
            errors: {
              ...(updatedErrors as Record<string, unknown>),
            },
          },
        },
      }
    }
    case PrivateOfferCreationActionTypes.UPDTE_PRIVATE_OFFER_HAS_BEEN_SENT: {
      const { productId, hasBeenSent } = action.payload
      return {
        ...state,
        currentPrivateOfferInCreation: {
          ...state.currentPrivateOfferInCreation,
          [productId]: {
            ...state.currentPrivateOfferInCreation[productId],
            hasBeenSent,
          },
        },
      }
    }
    case PrivateOfferCreationActionTypes.CLEAR_PRIVATE_OFFER_IN_CREATION: {
      const productId = action.payload
      const privateOffers = { ...state.currentPrivateOfferInCreation }
      delete privateOffers[productId]
      return {
        ...state,
        currentPrivateOfferInCreation: {
          ...privateOffers,
        },
      }
    }
    case PrivateOfferCreationActionTypes.UPDATE_STANDARD_DIMENSIONS_ON_PRIVATE_OFFER: {
      const { productId, index, dimension } = action.payload
      const privateOffer = state.currentPrivateOfferInCreation[productId]
      const updatedDimensions = [...privateOffer.dimensions!]
      const updatedObject = updatedDimensions[index]
      const updatedDimension = { ...updatedObject, ...dimension }
      updatedDimensions[index] = { ...updatedDimension }
      return {
        ...state,
        currentPrivateOfferInCreation: {
          ...state.currentPrivateOfferInCreation,
          [productId]: {
            ...state.currentPrivateOfferInCreation[productId],
            dimensions: updatedDimensions,
          },
        },
      }
    }
    case PrivateOfferCreationActionTypes.UPDATE_NUMBER_OF_TIMES_SAVED_ON_PRIVATE_OFFER: {
      const productId = action.payload
      const noOfTimesSaved =
        state.currentPrivateOfferInCreation[productId].noOfTimesSaved + 1
      return {
        ...state,
        currentPrivateOfferInCreation: {
          ...state.currentPrivateOfferInCreation,
          [productId]: {
            ...state.currentPrivateOfferInCreation[productId],
            noOfTimesSaved,
          },
        },
      }
    }
    case PrivateOfferCreationActionTypes.UPDATE_PRODUCT_PLAN_ON_PRIVATE_OFFER: {
      const { index, plan, productId, keyForErrorCheck, subIndex, meter } =
        action.payload
      const { errors, ...privateOffer } =
        state.currentPrivateOfferInCreation[productId]
      const updatedPlans = [...privateOffer.plans!]
      const updateNewPlan = updatedPlans[index]
      updatedPlans[index] = { ...updateNewPlan, ...plan }
      const updatedNewPlansErr = [
        ...(errors?.plans as Record<string, unknown>[]),
      ]
      updatedNewPlansErr[index] = createErrorObjectForPlan({
        ...updateNewPlan,
        ...plan,
      })
      const updatedPlansError = keyForErrorCheck
        ? getPlansError({
            errors: [...(errors.plans as Record<string, unknown>[])],
            plans: updatedPlans,
            field: keyForErrorCheck,
            index,
            subIndex,
            meter,
          })
        : updatedNewPlansErr
      return {
        ...state,
        currentPrivateOfferInCreation: {
          ...state.currentPrivateOfferInCreation,
          [productId]: {
            ...state.currentPrivateOfferInCreation[productId],
            plans: updatedPlans,
            errors: {
              ...(errors as Record<string, unknown>),
              plans: updatedPlansError,
            },
          },
        },
      }
    }

    case PrivateOfferCreationActionTypes.UPDATE_PRIVATE_OFFER_SINGLE_FIELD_ERROR: {
      const { key, productId, errMsg } = action.payload
      const { errors } = state.currentPrivateOfferInCreation[productId]
      const updatedErrors = {
        ...errors,
        [key]: errMsg,
      }
      return {
        ...state,
        currentPrivateOfferInCreation: {
          ...state.currentPrivateOfferInCreation,
          [productId]: {
            ...state.currentPrivateOfferInCreation[productId],
            errors: {
              ...(updatedErrors as Record<string, unknown>),
            },
          },
        },
      }
    }

    default:
      return state
  }
}

export const createErrorObjectForPlan = (plan: AzureOfferPlan) => {
  let metersErr = {}
  const pricesErr = plan?.pricing?.recurrentPrice?.prices.map(() => ({
    pricePerPaymentInUsd: '',
  }))

  const metersObj = plan?.pricing?.customMeters?.meters
  if (metersObj) {
    Object.keys(metersObj).forEach(meterKey => {
      metersErr = {
        ...metersErr,
        [meterKey]: {
          pricePerPaymentInUsd: '',
          includedQuantities: (
            metersObj[meterKey].includedQuantities || []
          ).map(() => ({ quantity: '' })),
        },
      }
    })
  }
  return {
    discountPercentage: '',
    meters: metersErr,
    prices: pricesErr,
    userLimits: {
      min: '',
      max: '',
    },
  }
}

export const validateOfferResaleName = (
  name: string,
  isResale: boolean,
  length: number
) => {
  const offerText = isResale ? 'Authorization' : 'Offer'
  if (isEmpty(name)) {
    return `${offerText} name cannot be empty.`
  } else if (
    !new RegExp(resaleAuthNameValidationRegex).test(name) &&
    isResale
  ) {
    return 'Authorization name cannot contain special characters and spaces.'
  } else if (
    (name.includes('\\') ||
      name.includes('>') ||
      name.includes('<') ||
      !new RegExp(initialSpaceRegex).test(name)) &&
    !isResale
  ) {
    return offerNameInvalidErrMsg
  } else if (name.length > length) {
    return `Max ${length} characters are allowed.`
  } else {
    return ''
  }
}
