import React from 'react'

import { useStyles } from './UserBasicInfo.styles'
import { DateAndTime } from '../../../../common/components/DateAndTime/DateAndTime'
import { LightToolTip } from '../../../../common/components/ToolTipHtml/LightToolTip'
import { UserState } from '../../../modules/userList/reducer'
import { RootState, useDispatch, useSelector } from '../../../../store'
import {
  startUserLoading,
  stopUserLoading,
} from '../../../modules/userList/action'
import { updateAppAlert } from '../../../../common/modules/appAlert/actions'
import {
  postResendInvite,
  resendInvitationIdentityService,
} from '../../../../common/api'
import { truncateString } from '../../../../common/utils/truncateId'
import {
  InviteUserRequestSuccess,
  RequestFailureMessage,
} from '../../../../common/utils/messagesContants'
import { Status } from '../../../../common/utils/statusMapping'
import { errorLogger } from '../../../../common/utils/errorLogger'
import { useFlagValue } from '@labrav/flags'

export interface UserInfoProps {
  user: UserState
}

export interface UserInfoMapping {
  'Email ID': string
  'Last activity': string
  'Invitation pending'?: string
}

let userInfoMapping: UserInfoMapping = {
  'Email ID': 'email',
  'Last activity': 'lastActivity',
  'Invitation pending': 'invite',
}

export const ResendInvite: React.FC<{ handleClick: () => void }> = ({
  handleClick,
}) => {
  const classes = useStyles()

  return (
    <span
      onClick={handleClick}
      data-testid="resend"
      className={classes.resendInvite}
    >
      Resend invite
    </span>
  )
}

export const UserBasicInfo: React.FC<UserInfoProps> = ({ user }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const partnerId = useSelector<string | undefined>(
    state => state.PartnerData.user.partnerData?.partnerId
  )
  const { loading: isFlagsLoading, value: auth0Enabled } = useFlagValue('auth0')
  const globalState = useSelector<RootState>(state => state)
  const handleResendInvite = () => {
    if (partnerId) {
      dispatch(startUserLoading())
      const resendVerificationEmail = auth0Enabled
        ? resendInvitationIdentityService
        : postResendInvite

      resendVerificationEmail(partnerId, user.id)
        .then(() => {
          dispatch(
            updateAppAlert({
              message: InviteUserRequestSuccess,
              messageType: 'SUCCESS',
              autoClose: true,
              sidePanelAlert: true,
            })
          )
        })
        .catch(err => {
          const errMess = err?.response?.data?.message || RequestFailureMessage
          dispatch(
            updateAppAlert({
              message: errMess,
              messageType: 'ERROR',
              autoClose: true,
              sidePanelAlert: true,
            })
          )
          errorLogger({ globalState })(err as Error)
        })
        .finally(() => {
          dispatch(stopUserLoading())
        })
    }
  }

  const updatedUserObj: Record<string, string | JSX.Element | null> = {
    ...user,
    status: user.status,
    roles: null,
    invite: <ResendInvite handleClick={handleResendInvite} />,
    lastActivity: user.lastActivity ? (
      <DateAndTime date={user.lastActivity as string} />
    ) : (
      '-'
    ),
  }

  if (updatedUserObj.status !== Status.PENDING) {
    delete userInfoMapping['Invitation pending']
    updatedUserObj.email = truncateString(updatedUserObj.email as string, 50)
  } else {
    userInfoMapping = { ...userInfoMapping, 'Invitation pending': 'invite' }
    updatedUserObj.email = truncateString(updatedUserObj.email as string, 35)
  }

  return (
    <div data-testid="basicInfo" className={classes.root}>
      {Object.entries(userInfoMapping).map(([key, value]) => (
        <div className={classes.keyValContainer}>
          <div className={classes.objKey}>{key}</div>
          <div className={classes.objVal}>
            {value === 'email' && updatedUserObj['email'] !== user['email'] ? (
              <div>
                <LightToolTip
                  isCopyable
                  label={`${user.email}`}
                  fontWeight={400}
                >
                  {updatedUserObj[value]}
                </LightToolTip>
              </div>
            ) : (
              updatedUserObj[value]
            )}
          </div>
        </div>
      ))}
    </div>
  )
}
