import { snakeize } from 'casing'
import { API } from '../../../common/api'
import { crmServiceUrl } from '../../../common/utils/constants'
import { ActionType } from '../../../externalApps/modules/referrals/actions'

export type SaveReferralAPIParams = {
  referralObject: Record<string, unknown>
  invokeSync: boolean
  referralMetadata: {
    crmId: string
    crmParentTable: string
    crmPrimaryKeyName: string
    crmPrimaryKeyValue?: string
    referralType: string
    referralStatus: 'draft' | 'submitted'
    referralId?: string
  }
  labraSettingObject: {
    consultingPartner: boolean
    technologyPartner: boolean
    isv: boolean
    srrp: boolean
    accelerateProgram: boolean
    awsFieldsVersion: string
  }
}

export const saveReferral = (
  params: SaveReferralAPIParams,
  referralId?: string,
  actionType?: ActionType
) => {
  const referralObject = params.referralObject
  const _params = snakeize(params)
  const endpoint = referralId
    ? `${crmServiceUrl}/referrals/${referralId}`
    : `${crmServiceUrl}/referrals`
  return API(`${endpoint}`, {
    method: actionType ? 'patch' : referralId ? 'put' : 'post',
    data: {
      ..._params,
      sync_direction: 'crm_to_ace',
      referral_object: referralObject,
    },
  })
}

export const fetchReferral = (referralId?: string) => {
  return API.get(`${crmServiceUrl}/referrals/${referralId}`)
}

export const deleteReferral = (referralId?: string) => {
  return API.delete(`${crmServiceUrl}/referrals/${referralId}`)
}
export const fetchReferralSyncRecords = (referralId?: string) => {
  return API.get(`${crmServiceUrl}/referrals/${referralId}/syncs`)
}