export const categories = {
  'Referral details': {
    expanded: true,
    fields: [
      'Name',
      'Labra__Company_Name__c',
      'Labra__AWS_Campaign_Name__c',
      'Labra__Acceptance_Status__c',
      'Labra__Country__c',
      'Labra__Description__c',
      'Labra__Primary_Need_From_AWS__c',
      'Labra__Use_Case__c',
      'Labra__Website__c',
      'Labra__AWS_Partner_Central_User_Email__c',
      'Labra__AWS_Partner_Central_User_Name__c',
      'Labra__Industry__c',
      'Labra__Target_Close_Date__c',
      'Labra__Delivery_Model__c',
      'Labra__Postal_Code__c',
      'Labra__Expected_Monthly_AWS_Revenue__c',
      'Labra__Referred_By__c',
      'Labra__Sync_to_AWS__c',
      'Labra__IsOppFromMarketingActivity__c',
      'Labra__Use_Case_Workload__c',
      'Labra__Net_New_Business_For_Company__c',
    ],
  },
  'Customer Details': {
    expanded: false,
    fields: [
      'Labra__Customer_Email__c',
      'Labra__Email__c',
      'Labra__Full_Name__c',
      'Labra__Customer_First_Name__c',
      'Labra__First_Name__c',
      'Labra__Customer_Last_Name__c',
      'Labra__Last_Name__c',
      'Labra__Customer_Phone__c',
      'Labra__Phone__c',
      'Labra__Customer_Title__c',
      'Labra__City__c',
      'Labra__State__c',
      'Labra__Street_Address__c',
      'Labra__Street__c',
      'Labra__Other_Industry__c',
      'Labra__Sales_Territory__c',
      'Labra__Customer_Segment__c',
      'Labra__Title__c',
      'Labra__Company_Size__c',
    ],
  },
  'AWS Related Details': {
    expanded: false,
    fields: [
      'Labra__AWS_Internal_Stage__c',
      'Labra__AWS_Account_Id__c',
      'Labra__APN_CRM_Unique_Identifier__c',
      'Labra__AWS_Acceptance_Status__c',
      'Labra__ACE_Created_Date__c',
      'Labra__ACE_Last_Modified_Date__c',
      'Labra__AWS_Close_Date__c',
      'Labra__AWS_Closed_Lost_Reason__c',
      'Labra__Next_step__c',
      'Labra__Next_Step_History__c',
      'Labra__Labra_ACE_Reviewer_Comments__c',
      'Labra__Labra_ACE_CRM_Lead_UniqueIdentifier__c',
      'Labra__Labra_Is_Marketing_Development_Funded__c',
      'Labra__Labra_Accept_By_Date__c',
      'Labra__Competitive_Tracking__c',
      'Labra__Other_Competitive_Tracking__c',
      'Labra__Lead_Source__c',
      'Labra__AWS_Lead_Source__c',
      'Labra__Marketplace__c',
      'Labra__MDF_Needed__c',
      'Labra__Referral_Stage__c',
      'Labra__RFX_Number__c',
      'Labra__Campaign_Name__c',
      'Labra__AWS_Field_Engagement__c',
      'Labra__Additional_Comments__c',
      'Labra__Public_Reference__c',
      'Labra__Public_Reference_Title__c',
      'Labra__Public_Reference_Url__c',
      'Labra__Sub_Use_Case__c',
      'Labra__Contract_Vehicle__c',
      'Labra__Other_Primary_Need_From_AWS__c',
      'Labra__awsSFCampaignName__c',
      'Labra__marketingActivityUsecase__c',
      'Labra__marketingActivityChannel__c',
      'Labra__AWS_Lead_Status__c',
      'AWS Lead Source',
      'Labra__AWS_Lead_Status_Reason__c',
      'Labra__Campaign_Member_Status__c',
      'Labra__Level_of_AWS_Usage__c',
      'Labra__AWS_Lead_Age__c',
    ],
  },

  'Sale Related Details': {
    expanded: false,
    fields: [
      'Labra__Primary_Sales_Rep_Email__c',
      'Labra__Primary_Sales_Rep_First_Name__c',
      'Labra__Primary_Sales_Rep_Last_Name__c',
      'Labra__Primary_Sales_Rep_Phone__c',
    ],
  },
}
