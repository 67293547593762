import { camelize } from 'casing'
import { updateAppAlert } from '../appAlert/actions'
import { RequestFailureMessage } from '../../utils/messagesContants'
import { AxiosResponse } from 'axios'
import { errorLogger } from '../../utils/errorLogger'
import { AppDispatch, RootState } from '../../../store'
import { getErrorMessages } from '../../utils/error'
import { fetchSlackChannelsData } from '../../api/notifcationAPI'
import { actionTypeWrapper } from '../../utils/actionTypeWrapper'
import { LoadingTypes } from '../loading/reducer'
import { startLoading, stopLoading } from '../loading/actions'

export interface SlackChannelsData {
  channelId: string
  name: string
  isPrivate: boolean
}

export enum SlackChannelsActionTypes {
  SET_SLACK_CHANNELS = 'SET_SLACK_CHANNELS',
  SET_SLACK_ACTIVE_CHANNEL = 'SET_SLACK_ACTIVE_CHANNEL',
  CLEAR_SLACK_CHANNEL = 'CLEAR_SLACK_CHANNEL',
}

export const getSlackChannels =
  (partnerId: string, product: string) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    await dispatch(startLoading(LoadingTypes.SLACK_CHANNELS_LOADING))
    try {
      const { data } = await fetchSlackChannelsData(partnerId, product)
      dispatch(
        actionTypeWrapper(
          product,
          setSlackChannels(data?.getSlackChannels?.channels)
        )
      )
      dispatch(
        actionTypeWrapper(
          product,
          setSlackActiveChannel(data?.getSlackChannels?.activeChannelId)
        )
      )
    } catch (err: unknown) {
      const errMess = getErrorMessages([RequestFailureMessage])(
        err as AxiosResponse<ErrorResponse>
      )
      const globalState = getState()
      errorLogger({ globalState })(err as Error)

      dispatch(
        updateAppAlert({
          message: errMess,
          messageType: 'ERROR',
          autoClose: true,
        })
      )
    } finally {
      await dispatch(stopLoading(LoadingTypes.SLACK_CHANNELS_LOADING))
    }
  }

export const setSlackChannels = (data: SlackChannelsData[]) => ({
  type: SlackChannelsActionTypes.SET_SLACK_CHANNELS,
  payload: camelize(data),
})

export const setSlackActiveChannel = (data: string) => ({
  type: SlackChannelsActionTypes.SET_SLACK_ACTIVE_CHANNEL,
  payload: data,
})

export const clearSlackChannels = () => ({
  type: SlackChannelsActionTypes.CLEAR_SLACK_CHANNEL,
})
