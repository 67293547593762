import { Product } from '../partner/action'
import { PlatformSettingsActionTypes } from './action'

type Action = {
  type: PlatformSettingsActionTypes.SET_SELECTED_PRODUCT_ON_SETTINGS
  payload: Product
}
export interface PlatformSettingsState {
  selectedProductOnSettings: Product
}
const initialState = {
  selectedProductOnSettings: '' as Product,
}
export const reducer = (
  state: PlatformSettingsState = initialState,
  action: Action
) => {
  switch (action.type) {
    case PlatformSettingsActionTypes.SET_SELECTED_PRODUCT_ON_SETTINGS: {
      return {
        ...state,
        selectedProductOnSettings: action.payload,
      }
    }

    default:
      return state
  }
}
