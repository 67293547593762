import { AxiosResponse } from 'axios'
import { camelize } from 'casing'
import { AppDispatch, RootState } from '../../../store'
import {
  Categories,
  FormField,
  FormMetaData,
} from '@labrav/react-components/lib/@types/types/forms'
import {
  awsFields,
  getCategories,
} from '../../../oppsync/data/referral/awsFields'
import { fetchReferralDetails, fetchReferralFields } from '../../api/referral'
import { actionTypeWrapper } from '../../../common/utils/actionTypeWrapper'
import { getErrorMessages } from '../../../common/utils/error'
import { updateAppAlert } from '../../../common/modules/appAlert/actions'
import {
  startLoading,
  stopLoading,
} from '../../../common/modules/loading/actions'
import { LoadingTypes } from '../../../common/modules/loading/reducer'

import { errorLogger } from '../../../common/utils/errorLogger'

export interface ReferralMetadata {
  crm_id: string
  crm_account_id: string
  crm_parent_table: string
  crm_primary_key_name: string
  crm_primary_key_value: string
  referral_type: 'lead' | 'opportunity'
  referral_status: 'draft' | 'submitted'
  referral_id: string
  crm_user_id: string
}

export interface PartnerCentralUsers {
  email: string
  first_name: string
  last_name: string
  username: string
}

export interface LabraSettingObject {
  consulting_partner: boolean
  technology_partner: boolean
  isv: boolean
  srrp: boolean
  accelerate_program: boolean
  aws_fields_version: 'v13' | 'v14'
}
export interface UserForm {
  productName?: string
  companyName?: string
  marketplaceUrl?: string
  infoFormMetaData?: FormMetaData
  referralMetadata?: ReferralMetadata
  referralObjects?: Record<string, string>
  labraSettingObject?: LabraSettingObject
  partnerCentralUsers?: PartnerCentralUsers[]
  formFields: FormField[]
  categoriesFields?: Categories
  secretKey?: string
  portalId?: string
}
export type ReferralActionType = 'create' | 'update' | 'reject'
export enum userFormTypes {
  GET_USER_FORM_DATA = 'GET_USER_FORM_DATA',
  GET_USER_FORM_ERROR = 'GET_USER_FORM_ERROR',
  USER_FORM_LOADING = 'USER_FORM_LOADING',
  USER_FORM_LOADING_SUBMIT = 'USER_FORM_LOADING_SUBMIT',
  USER_FORM_UPDATE_SUBMIT = 'USER_FORM_UPDATE_SUBMIT',
  USER_FORM_UPDATE_TRACK_ID = 'USER_FORM_UPDATE_TRACK_ID',
  USER_FORM_UPDATE_REFERRAL_METADATA_AND_OBJECT = 'USER_FORM_UPDATE_REFERRAL_METADATA_AND_OBJECT',
  SET_FORM_FIELD_ERRORS = 'SET_FORM_FIELD_ERRORS',
  CLEAR_FORM_FIELD_ERRORS = 'CLEAR_FORM_FIELD_ERRORS',
  SET_OBJECTS_FROM_URL = 'SET_OBJECTS_FROM_URL',
  CHANGE_DEFAULT_OPTIONS = 'CHANGE_DEFAULT_OPTIONS',
  CHANGE_DEFAULT_VALUE = 'CHANGE_DEFAULT_VALUE',
  GET_FIELDS_CATEGORIES = 'GET_FIELDS_CATEGORIES',
}

export const setFormData = (data: unknown) => ({
  type: userFormTypes.GET_USER_FORM_DATA,
  payload: camelize(data),
})

export const setFormDataLoading = (loading: boolean) => ({
  type: userFormTypes.USER_FORM_LOADING,
  payload: loading,
})

export const setFormDataLoadingSubmit = (loading: boolean) => ({
  type: userFormTypes.USER_FORM_LOADING_SUBMIT,
  payload: loading,
})

export const updateSubmitted = (loading: boolean) => ({
  type: userFormTypes.USER_FORM_UPDATE_SUBMIT,
  payload: loading,
})
export const updateTrackId = (trackId: unknown, marketplaceUrl?: string) => ({
  type: userFormTypes.USER_FORM_UPDATE_TRACK_ID,
  payload: { trackId: trackId, marketplaceUrl: marketplaceUrl },
})

export const setErrorMess = (err: unknown) => ({
  type: userFormTypes.GET_USER_FORM_ERROR,
  payload: err,
})
export const setMetadata = (
  data: unknown,
  crmUserId: string,
  referralActionType: ReferralActionType,
  defaultReferralType: string,
  portalId: string
) => ({
  type: userFormTypes.USER_FORM_UPDATE_REFERRAL_METADATA_AND_OBJECT,
  payload: {
    data,
    crmUserId,
    referralActionType,
    defaultReferralType,
    portalId,
  },
})

export const changeDefaultOptions = (data: unknown, key: string) => ({
  type: userFormTypes.CHANGE_DEFAULT_OPTIONS,
  payload: { options: data, key: key },
})
export const changeDefaultValue = (data: { [x: string]: unknown }) => ({
  type: userFormTypes.CHANGE_DEFAULT_VALUE,
  payload: { values: data },
})

export const getReferralFormData =
  (
    portalId: string,
    crmPrimaryKeyValue: string,
    referralActionType: ReferralActionType,
    defaultReferralType: string,
    crmUserId: string
  ) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.GENERAL))

    try {
      const response = await fetchReferralDetails(
        crmPrimaryKeyValue,
        portalId,
        crmUserId
      )

      const {
        labra_setting_object,
      }: { labra_setting_object: LabraSettingObject } = response.data

      const aceVersion = labra_setting_object.aws_fields_version

      const awsFieldsFromJSON =
        defaultReferralType === 'lead'
          ? awsFields[aceVersion].lead
          : awsFields[aceVersion].opportunity
      const getReferralFieldsResponse = async () => {
        const fieldsResponse = await fetchReferralFields(
          response.data.referral_metadata?.crm_id || '',
          defaultReferralType === 'lead'
            ? 'Labra__Labra_Lead__c'
            : 'Labra__Labra_Referral__c'
        )
        return {
          form_fields: fieldsResponse.data,
        }
      }

      const awsFieldsByReferralType =
        aceVersion === 'v13'
          ? awsFieldsFromJSON
          : await getReferralFieldsResponse()

      await dispatch(
        actionTypeWrapper('hubspotForm', setFormData(awsFieldsByReferralType))
      )
      await dispatch(
        actionTypeWrapper(
          'hubspotForm',
          setMetadata(
            response.data,
            crmUserId,
            referralActionType,
            defaultReferralType,
            portalId
          )
        )
      )

      const referralMetadata =
        getState().customForm.hubspotForm.customData?.referralMetadata
      const allCategories = getCategories(
        defaultReferralType,
        referralMetadata?.referral_status || '',
        aceVersion
      )
      await dispatch(
        actionTypeWrapper('hubspotForm', setFieldsCategories(allCategories))
      )
    } catch (e) {
      const errorMessage = getErrorMessages([
        'Unable to fetch referral information',
      ])(e as AxiosResponse<ErrorResponse>)
      dispatch(
        updateAppAlert({
          message: errorMessage,
          messageType: 'ERROR',
        })
      )

      const globalState = getState()
      errorLogger({ globalState })(e as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }

export const setFormFieldErrors = (errors: Record<string, unknown>) => ({
  type: userFormTypes.SET_FORM_FIELD_ERRORS,
  payload: errors,
})

export const clearFormFieldErrors = () => ({
  type: userFormTypes.CLEAR_FORM_FIELD_ERRORS,
})

export const setFieldsCategories = (data: Categories) => ({
  type: userFormTypes.GET_FIELDS_CATEGORIES,
  payload: camelize(data),
})

export const updateCategoriesProperties =
  (categories: Categories, errors: Record<string, unknown>) =>
  async (dispatch: AppDispatch) => {
    await dispatch(actionTypeWrapper('hubspotForm', setFormFieldErrors(errors)))
    await dispatch(startLoading(LoadingTypes.GENERAL))
    Object.keys(categories).forEach(key => {
      Object.keys(errors).map(f => {
        if (categories[key].fields.includes(f)) {
          categories[key].expanded = true
        }
      })
    })

    await dispatch(setFieldsCategories({ ...categories }))
    await dispatch(stopLoading(LoadingTypes.GENERAL))
  }
