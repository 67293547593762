import { isEmpty } from 'lodash/fp'

export const hasNotEmptyProp =
  (prop: string) =>
  (obj?: Record<string, unknown>): boolean =>
    !!obj && !isEmpty(obj[prop])

export const hasNotEmptyProps =
  (props: string[]) =>
  (obj?: Record<string, unknown>): boolean =>
    props.every(x => hasNotEmptyProp(x)(obj))
