import { AxiosError } from 'axios'
import { camelize } from 'casing'
import { Dispatch } from 'redux'
import { fetchSyncReport } from '../../../common/api'
import {
  startLoading,
  stopLoading,
} from '../../../common/modules/loading/actions'
import { LoadingTypes } from '../../../common/modules/loading/reducer'
import { errorLogger } from '../../../common/utils/errorLogger'
import { RootState } from '../../../store'
import { getErrorMessages } from '../../../common/utils/error'
export enum SyncReportActionTypes {
  SET_SYNC_REPORT = 'SET_SYNC_REPORT',
  SET_SYNC_REPORT_LIMIT = 'SET_SYNC_REPORT_LIMIT',
  SET_SYNC_REPORT_PAGE_NUMBER = 'SET_SYNC_REPORT_PAGE_NUMBER',
  SET_SYNC_REPORT_ERROR = 'SET_SYNC_REPORT_ERROR',
  CLEAR_SYNC_REPORT = 'CLEAR_SYNC_REPORT',
}

export interface SyncInvoker {
  id: string
  partnerUserId: string
  name: string
  email: string
  status: string
}
export interface SyncReportData {
  syncId: string
  status: string
  direction: string
  objectType: string
  originatedAt: string
  crmId: string | null
  aceId: string | null
  resultJsonType: string
  errorCause?: string | null
  resultJson: any
  actionName: string
  createdAt: string
  filePath: string
  syncInvoker?: SyncInvoker
}
export interface syncReportResponse {
  syncsData: SyncReportData[]
  totalCount: number
}

export const getSyncReport =
  (partnerId: string, pageSize: number, pageNumber: number) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.GENERAL))
    await dispatch(setDefaultSyncReport(pageSize))
    return fetchSyncReport(partnerId, pageSize, pageNumber)
      .then(res => {
        dispatch(setSyncReport(camelize(res.data), pageSize))
      })
      .catch((_error: unknown) => {
        const error = _error as AxiosError<ErrorResponse>

        dispatch(
          setSyncReportError(
            getErrorMessages(['Some error occurred'])(error.response)
          )
        )

        const globalState = getState()
        errorLogger({ globalState })(error as Error)
      })
      .finally(() => {
        dispatch(stopLoading(LoadingTypes.GENERAL))
      })
  }
export const setSyncReport = (data: syncReportResponse, pageSize: number) => ({
  type: SyncReportActionTypes.SET_SYNC_REPORT,
  payload: { data: camelize(data), pageSize: pageSize },
})

export const setSyncReportError = (error: unknown) => ({
  type: SyncReportActionTypes.SET_SYNC_REPORT_ERROR,
  payload: error,
})
export const setPageNumber = (pageNumber: number) => ({
  type: SyncReportActionTypes.SET_SYNC_REPORT_PAGE_NUMBER,
  payload: pageNumber,
})

export const updatePageSize =
  (partnerId: string, pageSize: number) => async (dispatch: Dispatch) => {
    await dispatch(setPageSize(pageSize))
    await dispatch(getSyncReport(partnerId, pageSize, 1) as any)
  }

export const setPageSize = (pageSize: number) => ({
  type: SyncReportActionTypes.SET_SYNC_REPORT_LIMIT,
  payload: pageSize,
})

export const clearSyncReport = () => ({
  type: SyncReportActionTypes.CLEAR_SYNC_REPORT,
})

export const setDefaultSyncReport = (pageSize: number) => ({
  type: SyncReportActionTypes.SET_SYNC_REPORT,
  payload: {
    data: { syncsData: [], count: 0 },
    pageSize: pageSize,
  },
})
